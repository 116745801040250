import React from "react";
import "./CheckboxComponent.scss";

const CheckboxComponent = ({ label, checked, className, disabled, ...props }) => {
	return (
		<label className={`checkbox-container ${className}`} {...props}>
			{label}
			<input type="checkbox" checked={checked} disabled />
			<span className="checkbox-container__checkmark"></span>
		</label>
	);
};

export default CheckboxComponent;
