import React from "react";

import "./CustomInput.scss";
import SkeletonLoader from "../atoms/SkeletonLoader";

function CustomInput({
	label,
	name,
	type = "text",
	value,
	errMessage,
	inputClasses,
	isRequired,
	inputIcon,
	isLoading = false,
	loaderProps,
	...props
}) {
	return (
		<div className="custom-input">
			{isLoading ? (
				<SkeletonLoader
					width={loaderProps.width ?? "100%"}
					height={loaderProps.height ?? "45px"}
				/>
			) : (
				<>
					<label
						htmlFor={name}
						className={`as-label pb-0 ${isRequired ? "isRequired" : ""}`}
					>
						{label}
					</label>
					{inputIcon ? (
						<div className="inputWithIcon">
							<span>{inputIcon}</span>
							<input
								id={name}
								type={type}
								className={`J-inputComp-input ${inputClasses || ""}`}
								name={name}
								value={value}
								{...props}
							/>
						</div>
					) : (
						<input
							id={name}
							type={type}
							className={`J-inputComp-input ${inputClasses || ""}`}
							name={name}
							value={value}
							{...props}
						/>
					)}
				</>
			)}

			{errMessage && <span className="error-txt">{errMessage}</span>}
		</div>
	);
}

export default CustomInput;
