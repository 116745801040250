import React, { SetStateAction, useMemo, useState } from "react";
import "./FilteringWrapper.scss";
import SearchInputComponent from "../../../../shared/component/SearchInputComponent/SearchInputComponent";
import { Select } from "../../../../shared/component";
import * as Variable from "../../../../shared/utils/variables";
import { useEnvironment } from "shared/hooks/useEnvironment";
import useTranslate from "shared/hooks/useTranslate";

type SearchJobAddProps = {
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder: string;
	value?: string;
	selectedSortType: {
		sortType: string;
		sortBy: string;
	};
	setSelectedSortType: React.Dispatch<
		SetStateAction<{
			sortType: string;
			sortBy: string;
		}>
	>;
	activeTab: string;
};

const SearchJobAd = ({
	onChange,
	placeholder,
	value,
	selectedSortType,
	setSelectedSortType,
	activeTab,
}: SearchJobAddProps) => {
	const ENV_NAME = useEnvironment();
	const t = useTranslate();

	const sortTypeItems = [
		{ id: "published_at", name: t("POSTING_DATE") },
		{
			id: "job_title",
			name: t("JOB_TITLE_FILTER_LBL"),
		},
		{
			id: "expired_at",
			name: t("EXPIRY_DATE"),
		},
	];

	const sortByArray = [
		{
			key: "job_title",
			items: [
				{
					id: `asc`,
					name: t("A_TO_Z"),
				},
				{ id: `desc`, name: t("Z_TO_A") },
			],
		},
		{
			key: "expired_at",
			items: [
				{
					id: `asc`,
					name: t("NEWEST_TO_OLDEST"),
				},
				{ id: `desc`, name: t("OLDEST_TO_NEWEST") },
			],
		},
		{
			key: "published_at",
			items: [
				{
					id: `asc`,
					name: t("NEWEST_TO_OLDEST"),
				},
				{ id: `desc`, name: t("OLDEST_TO_NEWEST") },
			],
		},
	];

	const selectedSortItem = useMemo(() => {
		return sortByArray.find((obj) => obj.key === selectedSortType.sortType) ?? sortByArray[1];
	}, [selectedSortType.sortType, activeTab, ENV_NAME]);

	const onChangeSortType = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { value } = e.currentTarget;
		setSelectedSortType({
			...selectedSortType,
			sortType: value,
		});
	};

	const onChangeSortBy = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { value } = e.target;
		setSelectedSortType((prev) => ({
			...prev,
			sortBy: value,
		}));
	};

	return (
		<div className="job-ads-listing__filtering-wrapper">
			<SearchInputComponent
				value={value}
				placeholder={placeholder}
				onChange={onChange}
				className="job-ads-listing__filtering-wrapper__search-input"
			/>
			<div className="job-ads-listing__filtering-wrapper__dropdown">
				<p className="job-ads-listing__filtering-wrapper__dropdown-text">
					{activeTab === "draft" ? t("SORT_BY_JOB_TITLE") : t("SORT_BY")}:{" "}
				</p>
				{activeTab !== "draft" && (
					<Select
						items={activeTab !== "draft" ? sortTypeItems : [sortTypeItems[1]]}
						placeholder="Select"
						searchable={false}
						required
						className="job-ads-listing__filtering-wrapper__dropdown-item"
						onChange={onChangeSortType}
						value={selectedSortType.sortType}
					/>
				)}
				<Select
					items={selectedSortItem.items}
					className="job-ads-listing__filtering-wrapper__dropdown-item"
					placeholder="Select"
					searchable={false}
					onChange={onChangeSortBy}
					value={selectedSortType.sortBy}
				/>
			</div>
		</div>
	);
};

export default SearchJobAd;
