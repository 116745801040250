import React from "react";
import { connect } from "react-redux";
import * as Variable from "shared/utils/variables";
import { classHelper } from "../../utils/stringUtils";
import bookmarkIcon from "../../../assets/svg/bookmark.svg";
import errorIcon from "../../../assets/svg/alert_circle.svg";
import warningIcon from "../../../assets/svg/alert_triangle.svg";
import successIcon from "../../../assets/svg/success_icon.svg";
import resetIcon from "../../../assets/svg/reset_password.svg";
import emailIcon from "../../../assets/svg/mail.svg";

import "./IconComponent.scss";
import Image from "../UI/Image";

// DOCUMENTATION
// iconType : [error, warning, success, reset, email, bookmark]
// borderType : [outline, bold]
// isActive : for change State bookmark icon

const IconComponent = ({ iconType, borderType, disable, isActive, ENV_NAME, ...props }) => {
	return (
		<div className={classHelper("J-iconComp", props.className)}>
			<div
				className={`J-iconComp-container ${
					iconType === "error" && borderType === "outline"
						? "error"
						: iconType === "warning" && borderType === "outline"
						? "warning"
						: iconType === "success" && borderType === "outline"
						? "success"
						: iconType === "reset" && borderType === "outline"
						? "reset"
						: iconType === "email" && borderType === "outline"
						? "email"
						: iconType === "bookmark" && borderType === "outline" && isActive
						? "bookmark"
						: iconType === "bookmark" && borderType === "outline"
						? "bookmark-outline"
						: iconType === "error" && borderType === "bold"
						? "error-bold"
						: iconType === "warning" && borderType === "bold"
						? "warning-bold"
						: iconType === "success" && borderType === "bold"
						? "success-bold"
						: iconType === "reset" && borderType === "bold"
						? "reset-bold"
						: iconType === "email" && borderType === "bold"
						? "email-bold"
						: iconType === "bookmark" && borderType === "bold"
						? ""
						: ""
				}`}
				style={props.style}
			>
				<div
					style={props.style}
					className={`J-iconComp-content ${
						iconType === "error" && borderType === "bold"
							? "error-bold"
							: iconType === "warning" && borderType === "bold"
							? "warning-bold"
							: iconType === "success" && borderType === "bold"
							? "success-bold"
							: iconType === "reset" && borderType === "bold"
							? "reset-bold"
							: iconType === "email" && borderType === "bold"
							? "email-bold"
							: iconType === "bookmark" && borderType === "outline" && isActive
							? "bookmark"
							: iconType === "bookmark" && borderType === "outline"
							? "bookmark-outline"
							: iconType === "error"
							? "error"
							: iconType === "warning"
							? "warning"
							: iconType === "success"
							? "success"
							: iconType === "reset"
							? "reset"
							: iconType === "email"
							? "email"
							: iconType === "bookmark"
							? "bookmark"
							: ""
					} ${iconType === "bookmark" && disable ? "bookmark disable" : ""}`}
				>
					<Image
						className={`J-iconComp-img ${
							(iconType === "error" && borderType === "bold") ||
							(iconType === "warning" && borderType === "bold") ||
							(iconType === "success" && borderType === "bold") ||
							(iconType === "reset" && borderType === "bold") ||
							(iconType === "email" && borderType === "bold")
								? "white"
								: iconType === "bookmark" && borderType === "outline" && isActive
								? "bookmark"
								: iconType === "bookmark" && borderType === "outline"
								? "bookmark-outline"
								: iconType === "warning"
								? "warning"
								: iconType === "success"
								? "success"
								: iconType === "bookmark"
								? "bookmark"
								: iconType === "reset"
								? "reset"
								: iconType === "email"
								? "email"
								: iconType === "error"
								? "error"
								: ""
						}`}
						src={`${
							iconType === "error"
								? errorIcon
								: iconType === "warning"
								? warningIcon
								: iconType === "success"
								? successIcon
								: iconType === "bookmark"
								? bookmarkIcon
								: iconType === "reset"
								? resetIcon
								: iconType === "email"
								? emailIcon
								: successIcon
						}`}
					/>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(IconComponent);
