import { X } from "@phosphor-icons/react";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { Text } from "shared/component/Typography/Typography";
import { useEnvironment } from "shared/hooks/useEnvironment";
import * as Variable from "shared/utils/variables";
import * as RoutePath from "shared/utils/routeLink";
import CardRepostMethod from "./components/CardRepostMethod";
import { Button } from "shared/component";

import "./ModalRepost.scss";

type ModalRepostPropsType = {
	show: boolean;
	onHide: () => void;
	jobId: string;
};

const ModalRepost = ({ show, onHide, jobId }: ModalRepostPropsType) => {
	const ENV_NAME = useEnvironment();
	const [selectedMethod, setSelectedMethod] = useState("");
	const history = useHistory();

	const handleClickCardMethod = (e: React.MouseEvent<HTMLDivElement>) => {
		setSelectedMethod(e.currentTarget.id);
	};

	const methodTypeDescription = {
		duplicate: {
			id: "duplicate",
			title: Variable.DUPLICATE[ENV_NAME],
			description: Variable.DUPLICATE_DESCRIPTION[ENV_NAME],
			onClick: handleClickCardMethod,
		},
		carry_over: {
			id: "carry_over",
			title: Variable.CARRY_OVER[ENV_NAME],
			description: Variable.CARRY_OVER_DESCRIPTION[ENV_NAME],
			onClick: handleClickCardMethod,
		},
	};

	const handleClickNext = () => {
		history.push(
			`${RoutePath.JOB_AD}?job_ad=${jobId}&status=inactive&method=${selectedMethod}`,
		);
	};

	return (
		<Modal
			show={show}
			contentClassName="border-0 p-0"
			onHide={onHide}
			dialogClassName="modal-dialog-centered modal-collaborate"
			size="lg"
		>
			<Modal.Header className="modal-collaborate__modal-header modal-job-ad-delete">
				<Text size="xl" fontWeight="semibold">
					{Variable.REPOST[ENV_NAME]}
				</Text>
				<X
					width={32}
					height={32}
					className="cursorPointer tw text-gray-500"
					onClick={onHide}
				/>
			</Modal.Header>
			<Modal.Body className="modal-collaborate__modal-body modal-job-ad-delete">
				<CardRepostMethod
					method={methodTypeDescription.duplicate}
					selectedMethod={selectedMethod}
				/>
				<CardRepostMethod
					method={methodTypeDescription.carry_over}
					selectedMethod={selectedMethod}
				/>
			</Modal.Body>
			<Modal.Footer className="modal-collaborate__modal-body modal-job-ad-delete">
				<div className="d-flex modal-deactivate__modal-body__button-groups">
					<Button
						type="outline"
						size="md"
						className="modal-deactivate__modal-body__button-groups-wrapper"
						btnClassName="modal-deactivate__modal-body__button-groups-btn"
						onClick={onHide}
					>
						{Variable.CANCEL_BTN_LABEL[ENV_NAME]}
					</Button>
					<Button
						type="primary"
						size="md"
						className="modal-deactivate__modal-body__button-groups-wrapper"
						btnClassName="modal-deactivate__modal-body__button-groups-btn"
						onClick={handleClickNext}
						disabled={!selectedMethod}
					>
						{Variable.NEXT_LABEL[ENV_NAME]}
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalRepost;
