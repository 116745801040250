import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Variable from "shared/utils/variables";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button } from "shared/component";
import "./ModalCollaborate.scss";
import SearchInputComponent from "shared/component/SearchInputComponent/SearchInputComponent";
import CardStaffList from "./CardStaffList";
import { X } from "@phosphor-icons/react";
import useFetch from "shared/hooks/useFetch";
import useDebounce from "shared/hooks/debounce";
import usePost from "shared/hooks/usePost";
import { RootState } from "store/types/RootStateTypes";
import {
	CollaboratorType,
	CompanyStaffType,
	ViewCollaboratorResType,
} from "component/JobAdListing/types/types";
import useToast from "shared/hooks/useToast";
import { USER_TYPES } from "constants/constants";

const ModalCollaborate = ({ show, onHide, jobId }) => {
	const toast = useToast();
	const ENV_NAME = useSelector((state: RootState) => state.auth.selectedEnvironment);
	const [search, setSearch] = useState<string | null>(null);
	const searchDebouce = useDebounce(search, 300);
	const [collaborators, setCollaborators] = useState<CollaboratorType[]>([]);
	const [selectedCollaborators, setSelectedCollaborators] = useState<CollaboratorType[]>([]);
	const { getDatas } = useFetch<CompanyStaffType[] | CollaboratorType[]>(
		"getCompanyStaff",
		{
			queryParams: {
				...(searchDebouce && { full_name: searchDebouce }),
			},
		},
		{ lazy: true },
	);
	const [idCollaborators, setIdCollaborators] = useState<number[]>([]);

	const { getDatas: getCollaborators } = useFetch<ViewCollaboratorResType>(
		"viewCollaborators",
		{
			urlParams: jobId,
		},
		{ lazy: true },
	);

	const { postFunction: addCollaborators } = usePost("addCollaborators");

	const handleSaveCollaborator = async () => {
		await addCollaborators({
			body: {
				collaborators: idCollaborators,
			},
			urlParams: jobId,
		}).then((res) => {
			if (res.code === 200) {
				toast.success(Variable.ADD_COLLABORATOR_SUCCESS[ENV_NAME]);
			} else {
				toast.error(res?.message || Variable.SOMETHING_WENT_WRONG_TRY_AGAIN[ENV_NAME]);
			}
			onHide();
		});
	};

	const handleOnChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { id, checked } = e.currentTarget;

		if (checked) {
			setIdCollaborators([...idCollaborators, Number(id)]);
			return;
		}
		const filteredIdCollaborators = idCollaborators.filter(
			(collabId) => collabId !== Number(id),
		);
		setIdCollaborators(filteredIdCollaborators);
	};

	useEffect(() => {
		if (!!searchDebouce) {
			getDatas().then((res) => {
				if (res.data) {
					const filtered = res.data.map((collaborator) => {
						const matchingObj2 = selectedCollaborators.find(
							(obj2) => obj2.id === collaborator.id,
						);

						return matchingObj2 ? { ...matchingObj2, ...collaborator } : collaborator;
					});
					setCollaborators(filtered);
				}
			});
			return;
		}

		if (search !== null) {
			fetchCollaborators();
		}
	}, [searchDebouce]);

	useEffect(() => {
		fetchCollaborators();
	}, []);

	const fetchCollaborators = async () => {
		return getCollaborators().then((res) => {
			if (!res.data) return;

			const idCollab = res.data.collaborators.map((collab) => Number(collab.id));
			const userAllJobsPermission = res.data.company_member.filter((member) =>
				member?.staff_user_permissions?.includes("all_jobs"),
			);

			// const companyMember = res.data?.company_member.filter(
			// 	(member) =>
			// 		!member?.staff_user_permissions?.includes("all_jobs") &&
			// 		member.id !== res.data?.created_by.id,
			// );

			// const adminCompany = res.data.company_member.find(
			// 	(member) => member.id === res.data?.created_by.id,
			// );

			// const collaboratorsFiltered = res.data.company_member.filter((member) =>
			// 	idCollab.includes(member.id),
			// );

			setIdCollaborators([...idCollab]);
			setCollaborators([
				{ ...res.data.created_by, is_creator: true },
				...res.data.collaborators,
				...res.data.company_member,
			]);
			setSelectedCollaborators([
				{ ...res.data.created_by, is_creator: true },
				...userAllJobsPermission,
				...res.data.collaborators,
			]);
		});
	};

	const isCollaborator = useCallback(
		(staff) =>
			staff?.staff_user_permissions?.includes("all_jobs") ||
			(staff?.user_type === USER_TYPES.JOB_MARKET_SUPER_ADMIN && !!staff?.is_creator),
		[],
	);

	return (
		<Modal
			show={show}
			contentClassName="border-0 p-0"
			onHide={onHide}
			dialogClassName="modal-dialog-centered modal-collaborate"
			size="lg"
		>
			<Modal.Header className="modal-collaborate__modal-header">
				<h4 className="modal-collaborate__modal-header_text">
					{Variable.COLLABORATE[ENV_NAME]}
				</h4>
				<X
					width={32}
					height={32}
					color="#667085"
					className="modal-collaborate__modal-header_btn-close"
					onClick={onHide}
				/>
			</Modal.Header>
			<Modal.Body className="modal-collaborate__modal-body">
				<SearchInputComponent
					placeholder={Variable.SEARCH_STAFF_NAME[ENV_NAME]}
					className="modal-collaborate__modal-body__search-input"
					onChange={(e) => {
						if (e.target.value.length < 1) {
							setSearch(null);
						}
						setSearch(e.target.value);
					}}
				/>
				<p className="modal-collaborate__modal-body__text-head">
					{Variable.STAFF_LIST[ENV_NAME]}
				</p>
				<div
					className={`modal-collaborate__modal-body__card-staff-wrapper ${
						collaborators.length > 3 ? "scroll" : ""
					}`}
				>
					{collaborators.length > 0 ? (
						collaborators.map((staff) => (
							<CardStaffList
								data={staff}
								handleChange={handleOnChecked}
								checked={
									idCollaborators.includes(Number(staff.id)) ||
									isCollaborator(staff)
								}
								disabled={isCollaborator(staff)}
							/>
						))
					) : (
						<p className="empty-staff-state">{Variable.EMPTY_STATE_STAFF[ENV_NAME]}</p>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					size="sm"
					btnClassName="modal-collaborate__btn-save-btn"
					className="modal-collaborate__btn-save"
					type="primary"
					onClick={handleSaveCollaborator}
					disabled={!idCollaborators.length}
				>
					{Variable.SAVE_LABEL[ENV_NAME]}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalCollaborate;
