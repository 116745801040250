import { FC } from 'react'

interface SwitchProps {
    label?: string,
    id: string,
    onToggle: (e: boolean) => void,
    checked: boolean,
}

const Switch: FC<SwitchProps> = ({
        label = '',
        id,
        onToggle,
        checked,
}) => {
	return (
		<div>
			<input
				className="togle-switch switch"
				type="checkbox"
				id={`label-switch-${id}`}
				onChange={(e) => onToggle(e.target.checked)}
				checked={checked}
			/>
			<label className="toggle-switch" htmlFor={`label-switch-${id}`}>
				{label}
			</label>
		</div>
	);
};

export default Switch