import { X } from '@phosphor-icons/react';
import { Modal } from 'react-bootstrap';
import cx from 'classnames';
import useTranslate from 'shared/hooks/useTranslate';
import { FC } from 'react';
import { useFlags } from 'shared/hooks/useFlags';

interface BulkStatusProps {
    handleMoveTo: (v: number) => void;
    moveto: number | null;
    handlerCloseModal: () => void;
    bulkSetCandidateCategory: () => void;
}

const BulkStatus: FC<BulkStatusProps> = ({
    handleMoveTo,
    moveto,
    handlerCloseModal,
    bulkSetCandidateCategory
}) => {
    const { flag } = useFlags();
    const t = useTranslate();
    return (
        <>
            <Modal.Header>
                <span>{t("SET_STATUS")}</span>
                <X className="close" onClick={handlerCloseModal} />
            </Modal.Header>
            <Modal.Body>
                <div
                    onClick={() => handleMoveTo(1)}
                    className={cx('option-div', { 'selected': moveto === 1 })}
                >
                    <p className="option-heading m-0">{t("PROSPECT")}</p>
                </div>
                <div
                    onClick={() => handleMoveTo(2)}
                    className={cx('option-div', { 'selected': moveto === 2 })}
                >
                    <p className="option-heading m-0">{t("SHORTLIST")}</p>
                </div>
                {flag("CandidateManagement.statusInterview") && (
                    <div
                        onClick={() => handleMoveTo(3)}
                        className={cx('option-div', { 'selected': moveto === 3 })}
                    >
                        <p className="option-heading m-0">{t("INTERVIEW")}</p>
                    </div>
                )}
                <div
                    onClick={() => handleMoveTo(4)}
                    className={cx('option-div', { 'selected': moveto === 4 })}
                >
                    <p className="option-heading m-0">{t("OFFERED")}</p>
                </div>
                <div
                    onClick={() => handleMoveTo(5)}
                    className={cx('option-div', { 'selected': moveto === 5 })}
                >
                    <p className="option-heading m-0">{t("HIRED")}</p>
                </div>
                <div
                    onClick={() => handleMoveTo(6)}
                    className={cx('option-div', { 'selected': moveto === 6 })}
                >
                    <p className="option-heading m-0">{t("REJECTED")}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div onClick={handlerCloseModal} className="btn btn-cancel">
                    {t("CANCEL_LABEL")}
                </div>
                <div
                    onClick={bulkSetCandidateCategory}
                    className={cx('btn btn-download', { 'disabled': !moveto })}
                >
                    {t("APPLY")}
                </div>
            </Modal.Footer>
        </>
    );
}

export default BulkStatus;
