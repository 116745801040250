import { ScreeningAnswer } from "component/CandidateManagement/components/Filters/types";

export const firstLetterUpperCase = (text: string) => {
    return text[0].toUpperCase() + text.slice(1)
}

export const isMatchWithIdealAnswer = (v: ScreeningAnswer) => {
    switch (v.question.question_type) {
        case 'progressive':
        case 'mcq':
        case 'single_select':
            return v.question?.mandatory_choices?.some(answer => v.mcq_answer.includes(answer));
        case 'fill_in_the_blanks':
            return v.question?.fill_in_the_blanks_options?.some(answer => v?.fill_in_the_blanks_answer?.includes(answer));
        case 'true_false':
            return v?.tf_answer === v.question?.boolean_accepted_answer;
        case 'numeric':
            return v?.numeric_answer === v.question?.numeric_accepted_answer;
        default:
            return false;
    }
}

export const screenQuestionAnswers = (v: ScreeningAnswer) => {
    switch (v.question.question_type) {
        case 'progressive':
        case 'mcq':
        case 'single_select':
            return v.mcq_answer.join(', ');
        case 'fill_in_the_blanks':
            return v.fill_in_the_blanks_answer.join(', ');
        case 'short_answer':
            return v.text_answer;
        case 'true_false':
            return v.tf_answer ? "Yes" : "No";
        case 'numeric':
            return v.numeric_answer;
        default:
            return null;
    }
}