export const isValidImage = (file, allowed) => {
  let invalid;
  if (file instanceof File) {
    return allowed?.includes?.(file?.type);
  }
  if (file instanceof FileList) {
    for (let i = 0; i < file.length; i++) {
      invalid = allowed?.includes?.(file[i]?.type);
      if (!invalid) return false;
    }
    return true;
  }
  return false;
};
export const isFileSizeLessOrEqual = (file, size = 1024) => {
  let invalid;
  if (file instanceof File) {
    return file?.size <= size;
  }
  if (file instanceof FileList) {
    for (let i = 0; i < file.length; i++) {
      invalid = file[i]?.size <= size;
      if (!invalid) return false;
    }
    return true;
  }
  return false;
};
