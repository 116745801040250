import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Alert from '../../../../assets/svg/alert-image.svg';
import { Button } from '../../../../shared/component';
// import Image from '../../../../shared/component/UI/Image';
import { updateInterviewMethod } from '../../../../store/actions/interview';
import useTranslate from 'shared/hooks/useTranslate';
import { useMemo } from 'react';
import Image from 'shared/component/UI/Image';

export default function Warning({ handlerClose, data, quickMovetoUnfit, quickMovetoReject }) {
    const t = useTranslate()
    const route = useHistory();

    const handlerConfirm = () => {
        if (data.customData === 'interview') {
        handleUpdateMethodInterview();
        } 
        else if(data?.customData === "unfit") {quickMovetoUnfit();}
        else if(data?.customData === "reject") {quickMovetoReject();}
        else {
        handlerClose();
        // route.push('/interview-schedule');
        }
    };

    /**
     * function handle confirm modal interview and update interview method
     * @param {method} method interview with enum value ['exact', 'slots]
     * @returns
     */
    const handleUpdateMethodInterview = async () => {
        return await updateInterviewMethod({
        id: String(3),
        interview_schedule_type: data.data,
        })
        .then((res) => {
            if (res.code === 200) {
            handlerClose();
            route.push('/interview-schedule');
            }
        })
        .catch((err) => {
            alert('Something went wrong');
            console.log(err);
        });
    };
    const messageExplanation = useMemo(() => {
        if (data.customData === 'interview') {
            return t("WARNING_MESSAGE_1");
        }
        if (data.customData === 'reject') {
            return `${t("QUICK_MOVE_TO_REJECT")}......`;
        }
        if (data.customData === 'unfit') {
            return `${t("QUICK_MOVE_TO_UNFIT")}......`;
        }
        return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.customData])

    return (
        <Modal.Body className="modal-employer-assist">
            <Image src={Alert} />
            <h1 className="modal-employer-heading">{t("DIALOG_CONFIRM_TITLE")}</h1>
            <ul className='w-100 px-5'>
                <li className="mt-3 fw-normal tw text-gray-500">
                    {messageExplanation}
                </li>
            </ul>
            <div className="row w-100 mt-lg">
                <Button
                    type="outline"
                    size="md"
                    className="col"
                    btnClassName="w-100"
                    onClick={handlerClose}
                >
                    {t("CANCEL_LABEL")}
                </Button>

                <Button
                    type="error"
                    size="md"
                    className="col"
                    btnClassName="w-100"
                    onClick={handlerConfirm}
                >
                    {t("CONFIRM")}
                </Button>
            </div>
        </Modal.Body>
    );
}
