import cx from "classnames";
import { FC, useRef } from "react";
import { useSelector } from "react-redux";
import { Overlay } from "react-bootstrap";
import { Faders } from "@phosphor-icons/react";

import { CANDIDATE_TYPES } from "types/types";
import { useScroll } from "shared/hooks/useScroll";
import { Button, FormInput } from "shared/component";

import Switch from "shared/component/atoms/Switch";
import useTranslate from "shared/hooks/useTranslate";

import { IModal } from "../types";

import "./SectionHead.scss";

type SectionHeadProps = {
	setSearch: (value: string) => void;
	employerAssist: boolean;
	setmodalExactTime: (value: boolean) => void;
	handlerShowModal: (value: string) => void;
	handlerToogleAssist: () => void;
	quickMovetoUnfit: () => void;
	setShowQuickmoveDropdown: (value: boolean) => void;
	quickMovetoReject: () => void;
	getJobs: (value: object) => void;
	showQuickmoveDropdown: boolean;
	quickmoveRef: React.RefObject<HTMLDivElement>;
	router: any;
	smartSearchRef: any;
	showSmartSearchDropdown: boolean;
	setShowSmartSearchDropdown: (value: boolean) => void;
	getSmartSearchList: (value: string) => void;
	smartsearchDropdownData: any;
	sethandlerModal: (value: any) => void;
	handlerModal?: IModal;
	job_title?: string;
};

const SectionHead: FC<SectionHeadProps> = ({
	setShowSmartSearchDropdown,
	handlerShowModal,
	router,
	job_title,
	smartSearchRef,
	getJobs,
	setSearch,
	getSmartSearchList,
	showSmartSearchDropdown,
	smartsearchDropdownData,
	employerAssist,
	handlerToogleAssist,
	sethandlerModal,
	handlerModal = {},
	setShowQuickmoveDropdown,
	quickmoveRef,
	showQuickmoveDropdown,
	quickMovetoReject,
}) => {
	const t = useTranslate();
	const { scrollDirection } = useScroll()
	const searchRef = useRef<HTMLInputElement>(null);
	
	// const INTERVIEW = useSelector((state: any) => state.interview);
	// const handlerModalInterviewMethod = () => {
	// 	if (
	// 		INTERVIEW.jobInterview.interview_schedule_type === "slot" ||
	// 		INTERVIEW.jobInterview.interview_schedule_type === "exact"
	// 	) {
	// 		handlerShowModal("interview");

	// 		// router.push("/interview-schedule");
	// 	} else {
	// 		// temporary function untill BE give response interview_schedule_type
	// 		router.push("/interview-schedule");

	// 		// handlerShowModal("interview");
	// 	}
	// };

	return (
		<div className="position-relative d-flex justify-content-between align-items-center">
			<div className="w-50">
				<h3 className="font-weight-semibold tw text-black text-display-xs mb-0 pb-0">
					{job_title}
				</h3>
				<div
					className={cx(
						"quickmove-btn-container",
						{
							"quickmove-btn-show": !handlerModal?.isShow && scrollDirection === "up",
						}
					)}
				>
					{!handlerModal?.isShow && scrollDirection === "up" && (
						<div ref={smartSearchRef} className="d-flex gap-4" style={{ marginTop: "2rem" }}>
							<FormInput
								withIcon
								formRef={searchRef}
								onCloseIcon={() => {
									setSearch("");
									if (searchRef?.current) {
										searchRef.current.value = "";
									}
									getJobs({ candidate_name: true });
								}}
								className="w-100"
								typeIcon="search"
								placeholder={t("SEARCH_CANDIDATE_NAME")}
								isDoubleIcon={(searchRef?.current?.value as string)?.length > 0}
								onChange={(event) => {
									getSmartSearchList(event.target.value);
								}}
								onKeyPress={(event) => {
									const code = event.keyCode || event.which;
									if (code === 13) {
										getJobs({});
									}
								}}
							/>
							<Overlay
								target={smartSearchRef.current}
								show={showSmartSearchDropdown}
								placement="bottom-start"
								rootClose
								onHide={() => {
									setShowSmartSearchDropdown(false);
								}}
							>
								{({
									placement = "top",
									arrowProps = {},
									show: _show = false,
									popper = null,
									...OverlayProps
								}) => (
									<div
										{...OverlayProps}
										id="hn-dropdown-lang"
										className="hn-dropdown hn-lang"
									>
										{smartsearchDropdownData.map((candidate: CANDIDATE_TYPES) => {
											const fullNameCandidate = `${candidate.created_by.first_name} ${candidate.created_by.last_name}`;
											return (
												<button
													key={candidate.id}
													className={cx(
														"hn-dropdown-item quickmove-dropdown-item",
														{ active: false }, // TECHDEBT: Need to ensure which variable triggers this class
													)}
													onClick={() => {
														setSearch(fullNameCandidate);
														if (searchRef?.current) {
															searchRef.current.value = fullNameCandidate;
														}
														setShowSmartSearchDropdown(false);
													}}
												>
													<div className="hn-dropdown-item-label">
														{fullNameCandidate}
													</div>
												</button>
											);
										})}
									</div>
								)}
							</Overlay>
							<Button
								type="outline"
								size="sm"
								onClick={() => {
									handlerShowModal("filter");
								}}
								btnClassName="d-flex align-items-center gap-2 justify-content-center"
							>
								<span className="d-flex align-items-center gap-2 justify-content-center">
									{t("FILTERS")}
									<span>
										<Faders color="#193560" weight="bold" />
									</span>
								</span>
							</Button>
						</div>
					)}
				</div>
			</div>

			<div className="section-head__employer-assist--wrapper positon-relative d-flex flex-column justify-content-between p-0 m-0">
				<div className="section-head__employer-assist d-flex justify-content-end">
					<span>{t("EMP_ASSIST")}</span>
					<Switch
						id="1"
						checked={employerAssist}
						onToggle={() => {
							handlerToogleAssist();
						}}
					/>
				</div>
				
				<div
					className={cx(
						"quickmove-btn-container",
						{
							"quickmove-btn-show": !handlerModal.isShow && scrollDirection === "up",
						}
					)}
				>
					{!handlerModal.isShow && scrollDirection === "up" && (
						<div
							className="position-relative d-flex gap-4 align-items-center"
							style={{ marginTop: "2.6rem" }}
						>
							{/* Interview */}
							{/* <Button
					type="outline"
					size="sm"
					onClick={() => setmodalExactTime(true)}>
					Exact Time
				</Button> */}
							{/* Interview */}
							{/* <Button type="outline" size="sm" onClick={handlerModalInterviewMethod}>
								<Gear height={18} width={18} color="#667085" className="me-2" />
								<span className="ml-3">Setup</span>
							</Button> */}

							{/* Button Move */}
							<div className="d-flex">
								<p
									className="quickmove-btn"
									onClick={() =>
										sethandlerModal({
											isShow: true,
											name: "warning",
											customData: "unfit",
										})
									}
								>
									{t("QUICK_MOVE_TO_UNFIT")}
								</p>
								<i
									onClick={() => setShowQuickmoveDropdown(true)}
									ref={quickmoveRef}
									className="fa fa-solid fa-angle-down quickmove-arrow"
								/>
								<Overlay
									target={quickmoveRef.current}
									show={showQuickmoveDropdown}
									placement="bottom-end"
									rootClose
									onHide={() => {
										setShowQuickmoveDropdown(false);
									}}
								>
									{({
										placement = "bottom-end",
										arrowProps = {},
										show: _show = false,
										popper = null,
										...OverlayProps
									}) => (
										<div
											{...OverlayProps}
											id="hn-dropdown-lang"
											className="hn-dropdown hn-lang p-3"
										>
											<button
												className={cx(
													"hn-dropdown-item quickmove-dropdown-item",
													{ active: false }, // TECHDEBT: Need to ensure what variable trigger this class
												)}
												onClick={() => {
													sethandlerModal({
														isShow: true,
														name: "warning",
														customData: "reject",
													});
												}}
											>
												<div className="hn-dropdown-item-label">
													{t("QUICK_MOVE_TO_REJECT")}
												</div>
											</button>
										</div>
									)}
								</Overlay>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default SectionHead;
