import React from "react";
import { classHelper } from "../../../shared/utils/stringUtils";

interface Props {
	icon?: React.ReactNode;
	text: string;
	className?: string;
}

const Banner = ({ icon, text, className }: Props) => {
	return (
		<div>
			<p className={classHelper("info-banner", className)}>
				{icon}
				{text}
			</p>
		</div>
	);
};

export default Banner;
