import React, { useEffect, useState } from "react";
import * as Variable from "../../../../shared/utils/variables";
import { useSelector } from "react-redux";
import "./TabNavigation.scss";
import { RootState } from "store/types/RootStateTypes";
import { STATUS_JOB_TYPE } from "component/JobAdListing/types/types";

export type TabNavigationType = {
	header: string;
	setHeader: React.Dispatch<React.SetStateAction<STATUS_JOB_TYPE | string>>;
	resultCounter: {
		active: number;
		draft: number;
		inactive: number;
	};
};

const TabNavigation = ({ header, setHeader, resultCounter }: TabNavigationType) => {
	const ENV_NAME = useSelector((state: RootState) => state.auth.selectedEnvironment || "bhs");
	const [widthAnim, setWidthAnim] = useState(120);
	const ACTIVE = "active";
	const INACTIVE = "inactive";
	const DRAFT = "draft";

	const TAB_NAMES = [
		{ value: Variable.ACTIVE[ENV_NAME], key: ACTIVE },
		{ value: Variable.INACTIVE[ENV_NAME], key: INACTIVE },
		{ value: Variable.DRAFT[ENV_NAME], key: DRAFT },
	];

	useEffect(() => {
		setWidthAnim(10);
		setTimeout(() => {
			setWidthAnim(
				header !== INACTIVE
					? ENV_NAME === "bhs"
						? 90
						: 100
					: ENV_NAME === "bhs"
					? 130
					: 110,
			);
		}, 150);
		// setCandidateData(null);
	}, [header]);

	return (
		<div className="job-ads-listing__header-div">
			{TAB_NAMES.map((item) => (
				<div
					key={item.key}
					onClick={() => {
						if (item.value !== header) {
							setHeader(item.key);
						}
					}}
					className={[
						`job-ads-listing__header-div__header-item tw capitalize`,
						header === item.key ? "selected" : "",
					].join(" ")}
				>
					{/* convert _ to space */}
					{item.value.split("_").join(" ")}
					<span>{resultCounter?.[item.key] || 0}</span>
				</div>
			))}
			<div
				className="job-ads-listing__header-div__border-bottom-blue"
				style={{
					width: widthAnim,
					marginLeft:
						header === ACTIVE
							? 0
							: header === INACTIVE
							? ENV_NAME === "bhs"
								? 115
								: 125
							: ENV_NAME === "bhs"
							? 270
							: 260,
				}}
			/>
		</div>
	);
};

export default TabNavigation;
