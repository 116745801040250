import { ChangeEvent, useEffect, useState } from "react";
import * as RoutePath from "shared/utils/routeLink";
import { useHistory } from "react-router-dom";
import { PiRepeatBold } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";

import makeRequest from "shared/utils/request";
import { generateRequestOptions } from "shared/utils/apiEndPoints";
import useTranslate from "shared/hooks/useTranslate";
import { Button } from "shared/component";
import Input from "shared/component/InputComponent/Input";
import PasswordInput from "shared/component/atoms/InputType/PasswordInput";
import { isValueAllowed } from "shared/utils/numberUtils";

import DefaultUser from "../../assets/svg/default_user.svg";
import WarningIcon from "../../assets/svg/warn-triangle.svg";

import "./Profile.scss";
import { formatString } from "shared/utils/string";
import ModalInfo from "shared/component/ModalComponent/ModalInfo";
import Image from "shared/component/UI/Image";
import ProfilePhotoModal from "./ProfilePhotoModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "./types";
import { pick } from "lodash";

interface FormFields {
	profile_picture: string;
	first_name: string;
	last_name: string;
	position: string;
	department: string;
	email: string;
	mobile_phone_number: string;
}

interface ErrorFields {
	full_name: boolean;
	position: boolean;
	department: boolean;
	mobile_phone_number: boolean;
}

const Profile = () => {
	const t = useTranslate();
	const { userId } = useSelector((state: RootState) => ({
		userId: state?.auth?.userDetails?.id,
	}));

	const history = useHistory();
	const [form, setForm] = useState({} as FormFields);
	const [errors, setErrors] = useState({} as ErrorFields);
	const [monitor, setMonitor] = useState({});
	const [deleteModal, setDeleteModal] = useState(false);
	const [profileModal, setProfileModal] = useState(false);

	useEffect(() => {
		userId && retrieveProfile();
	}, [userId]);

	const retrieveProfile = async () => {
		const res = await makeRequest(generateRequestOptions("getProfile", { urlParams: userId }));

		if (res?.code === 200) {
			const pickedFields = pick(res.data, [
				"profile_picture",
				"first_name",
				"last_name",
				"position",
				"department",
				"email",
				"mobile_phone_number",
			]);

			setForm(pickedFields);
		} else toast.error(res.message);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>, type: string = "") => {
		const { name, value } = e.target;

		switch (type) {
			case "phone":
				setForm((prev) => ({
					...prev,
					[name]: isValueAllowed(value, type) ? value : prev[name],
				}));
				break;
			default:
				setForm((prev) => ({ ...prev, [name]: value }));
		}

		setErrors((p) => ({ ...p, [name]: false }));
		setMonitor((p) => ({ ...p, [name]: true }));
	};

	const handleSave = async () => {
		let errors = {} as ErrorFields;
		const { first_name, department, position, mobile_phone_number } = form;

		if (monitor["first_name"] && !first_name) errors["first_name"] = true;
		if (monitor["position"] && !position) errors["position"] = true;
		if (monitor["department"] && !department) errors["department"] = true;
		if (monitor["mobile_phone_number"] && !mobile_phone_number)
			errors["mobile_phone_number"] = true;

		const hasError = Object.values(errors).some((err) => err);
		if (hasError) return setErrors(errors);

		const data = {};
		Object.keys(monitor).forEach((key) => {
			data[key] = form[key];
		});

		const res = await makeRequest({ ...generateRequestOptions("editProfile"), data });

		if (res?.code === 200) toast.success(t("PROFILE_UPDATE_SUCCESS"));
		else toast.error(res.message);
	};

	const handleDeletePicture = async () => {
		if (!form?.profile_picture) return;

		const res = await makeRequest({
			...generateRequestOptions("editProfile"),
			data: { profile_picture: null },
		});

		if (res?.code === 200) {
			setDeleteModal(false);
			toast.success(t("PROFILE_IMG_REMOVED"));
			retrieveProfile();
		} else toast.error(res.message);
	};

	return (
		<div className="jp-Profile">
			<div className="jp-cont container">
				<h3>{t("PROFILE")}</h3>
				<div className="profile-photo">
					<img src={form?.profile_picture || DefaultUser} alt="Default" />
					<div>
						<p className="upload" onClick={() => setProfileModal(true)}>
							<PiRepeatBold />{" "}
							{form?.profile_picture ? t("CHANGE_PHOTO") : t("UPLOAD_PHOTO")}
						</p>
						<p
							className="delete"
							onClick={() => {
								if (!form?.profile_picture) return;
								setDeleteModal(true);
							}}
						>
							<FaRegTrashAlt /> {t("DELETE_FILE")}
						</p>
					</div>
				</div>
				<div className="form-row">
					<Input
						label={t("FIRST_NAME")}
						placeholder={formatString(t("PLACEHOLDER"), t("FIRST_NAME").toLowerCase())}
						value={form?.first_name || ""}
						name="first_name"
						onChange={handleChange}
						inputType="label"
						isError={errors["first_name"]}
						className="w-100"
						required
					/>
					<Input
						label={t("LAST_NAME")}
						placeholder={formatString(t("PLACEHOLDER"), t("LAST_NAME").toLowerCase())}
						value={form?.last_name || ""}
						name="last_name"
						onChange={handleChange}
						inputType="label"
						className="w-100"
					/>
				</div>
				<Input
					label={t("DESIGNATION")}
					placeholder={formatString(t("PLACEHOLDER"), t("DESIGNATION").toLowerCase())}
					value={form?.position || ""}
					name="position"
					onChange={handleChange}
					inputType="label"
					isError={errors["position"]}
					required
				/>
				<Input
					label={t("DEPARTMENT")}
					placeholder={formatString(t("PLACEHOLDER"), t("DEPARTMENT").toLowerCase())}
					value={form?.department || ""}
					name="department"
					onChange={handleChange}
					inputType="label"
					isError={errors["department"]}
					required
				/>
				<Input
					label={t("EMAIL_ADDRESS")}
					placeholder={t("EMAIL_PLACEHOLDER")}
					value={form?.email || ""}
					name="email"
					onChange={handleChange}
					inputType="email"
					disabled
				/>
				<div className="pass-field">
					<p
						className="change-pass-text"
						onClick={() => history.push(RoutePath.CHANGE_PASSWORD)}
					>
						{t("CHANGE_PASSWORD")}
					</p>
					<PasswordInput
						name="password"
						label={t("PASSWORD")}
						value={""}
						placeholder="********"
						readOnly
						disabled
					/>
				</div>
				<Input
					label={t("MOBILE_NUMBER")}
					placeholder={t("PHONE_PLACEHOLDER")}
					value={form?.mobile_phone_number || ""}
					name="mobile_phone_number"
					onChange={(e) => handleChange(e, "phone")}
					inputType="mobile_number"
					isError={errors["mobile_phone_number"]}
					required
				/>

				<hr className="m-0" />
				<div>
					<Button
						type="primary"
						size="sm"
						className="save"
						title={t("SAVE_LABEL")}
						onClick={handleSave}
						disabled={
							!form?.first_name ||
							!form?.department ||
							!form?.position ||
							!form?.mobile_phone_number
						}
					/>
				</div>
			</div>

			{/* Profile Photo Modal */}
			<ProfilePhotoModal
				show={profileModal}
				picture={form?.profile_picture}
				onHide={() => setProfileModal(false)}
				onFetchData={retrieveProfile}
			/>

			{/* Delete Modal */}
			<ModalInfo
				isShow={deleteModal}
				isHide={() => setDeleteModal(false)}
				onConfirm={handleDeletePicture}
				type="delete"
				title={t("ARE_YOU_SURE") + "?"}
				subtitle={t("DELETE_PIC_DESC")}
				cancelLabel={t("NO")}
				confirmLabel={t("YES")}
				customIcon={<Image src={WarningIcon} className="modal-image-icon" />}
			/>
		</div>
	);
};

export default Profile;
