import { useState } from "react";
import { useSelector } from "react-redux";
import { ProgressBar } from "react-step-progress-bar";

import { RenderIf } from "../../shared/utils/common";

import TitleAndCity from "./TitleAndCity";
import WorkModelAndJob from "./WorkModelAndJob";
import Salary from "./Salary";
import JobDetails from "./JobDetail";
import Urgency from "./Urgency";
import PreScreening from "./PreScreening";
import EmpAssist from "./EmpAssist";
import Schedule from "./Schedule";
import PreviewJob from "./PreviewJob";

import { ENV, Nullable, State } from "../../types";

import "./jobCreation.scss";
import "react-step-progress-bar/styles.css";
import useTranslate from "shared/hooks/useTranslate";

export default function JobCreation() {
	const t = useTranslate();
	const { ENV_NAME, company } = useSelector((state: State) => ({
		ENV_NAME: state?.auth?.selectedEnvironment as ENV,
		company: state?.auth?.userDetails?.company_detail,
	}));
	const TOTAL_STEPS = 9;

	const [activeForm, setActiveForm] = useState(1);
	const [jobAdId, setJobAdId] = useState<Nullable<number>>(null);
	const [publishDate, setPublishDate] = useState<Nullable<string>>(null);

	const updateActiveForm = (direction: string) => {
		if (direction === "next" && activeForm < TOTAL_STEPS)
			return setActiveForm((prev) => prev + 1);

		if (direction === "prev" && activeForm > 0) {
			return setActiveForm((prev) => prev - 1);
		}
	};

	return (
		<div className="jc">
			<div className="progress-bar">
				<ProgressBar
					percent={(activeForm * 100) / TOTAL_STEPS}
					filledBackground="#193560"
					unfilledBackground="#F2F4F7"
				/>
			</div>

			<div className="jc-wrapper">
				<h2>{t("POST_JOB_LABEL")}</h2>
				{/* Title & City */}
				<RenderIf condition={activeForm === 1}>
					<TitleAndCity
						ENV_NAME={ENV_NAME}
						onNext={() => updateActiveForm("next")}
						onUpdateJobAdId={(id: number) => setJobAdId(id)}
					/>
				</RenderIf>

				{/* Work Model & Job Type */}
				<RenderIf condition={activeForm === 2}>
					<WorkModelAndJob
						ENV_NAME={ENV_NAME}
						jobAdId={jobAdId}
						onNext={() => updateActiveForm("next")}
						onPrev={() => updateActiveForm("prev")}
					/>
				</RenderIf>

				{/* Salary */}
				<RenderIf condition={activeForm === 3}>
					<Salary
						ENV_NAME={ENV_NAME}
						jobAdId={jobAdId}
						onNext={() => updateActiveForm("next")}
						onPrev={() => updateActiveForm("prev")}
					/>
				</RenderIf>

				{/* Job detail */}
				<RenderIf condition={activeForm === 4}>
					<JobDetails
						ENV_NAME={ENV_NAME}
						jobAdId={jobAdId}
						onNext={() => updateActiveForm("next")}
						onPrev={() => updateActiveForm("prev")}
					/>
				</RenderIf>

				{/* Urgency */}
				<RenderIf condition={activeForm === 5}>
					<Urgency
						ENV_NAME={ENV_NAME}
						jobAdId={jobAdId}
						onNext={() => updateActiveForm("next")}
						onPrev={() => updateActiveForm("prev")}
					/>
				</RenderIf>

				{/* Prescreening Questions */}
				<RenderIf condition={activeForm === 6}>
					<PreScreening
						ENV_NAME={ENV_NAME}
						jobAdId={jobAdId}
						companyName={company?.name}
						onNext={() => updateActiveForm("next")}
						onPrev={() => updateActiveForm("prev")}
					/>
				</RenderIf>

				{/* Employer Assist */}
				<RenderIf condition={activeForm === 7}>
					<EmpAssist
						ENV_NAME={ENV_NAME}
						jobAdId={jobAdId}
						onNext={() => updateActiveForm("next")}
						onPrev={() => updateActiveForm("prev")}
					/>
				</RenderIf>

				{/* Schedule */}
				<RenderIf condition={activeForm === 8}>
					<Schedule
						ENV_NAME={ENV_NAME}
						jobAdId={jobAdId}
						publishDate={publishDate}
						updatePublishDate={(item: string) => setPublishDate(item)}
						onNext={() => updateActiveForm("next")}
						onPrev={() => updateActiveForm("prev")}
					/>
				</RenderIf>

				{/* Preview Job */}
				<RenderIf condition={activeForm === 9}>
					<PreviewJob
						ENV_NAME={ENV_NAME}
						jobAdId={jobAdId}
						publishDate={publishDate}
						onPrev={() => updateActiveForm("prev")}
					/>
				</RenderIf>
			</div>
		</div>
	);
}
