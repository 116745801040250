import React from 'react';
import Card from '../../../../shared/component/CardComponent/Card';
import { RenderIf } from '../../../../shared/utils/common';
import { ArrowRight, Users, UsersThree } from '@phosphor-icons/react';

export default function ListType({ onClick, name, desc }) {
  return (
    <Card onClick={onClick} type="custom">
      <div className="position-relative d-flex justify-content-between align-items-center w-100">
        <div className="position-relative d-flex gap-4 align-items-center">
          <RenderIf condition={name === 'one-on-one'}>
            <Users weight="fill" color="#FC9607" height={64} width={64} />
          </RenderIf>
          <RenderIf condition={name === 'group'}>
            <UsersThree weight="fill" color="#FC9607" height={64} width={64} />
          </RenderIf>
          <div>
            <h4 className="mb-0 pb-0 text-md fw-semibold tw capitalize">
              {name}
            </h4>
            <p className="mb-0 pb-0 text-sm mt-2">{desc}</p>
          </div>
        </div>

        <span>
          <ArrowRight />
        </span>
      </div>
    </Card>
  );
}
