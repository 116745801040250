export const COLLAPSE_SIDEBAR = "COLLAPSE_SIDEBAR";

type SidebarReducerType = {
    isCollapse: boolean;
}

type CollapseSidebar = {
    type: typeof COLLAPSE_SIDEBAR;
    payload: boolean;
}

const initialState: SidebarReducerType = {
    isCollapse: true
};

type ActionReducerType = | CollapseSidebar


// eslint-disable-next-line import/no-anonymous-default-export
const reducer = (state = initialState, action: ActionReducerType) => {
    switch (action.type) {
        case COLLAPSE_SIDEBAR:
            return {
                isCollapse: !state.isCollapse,
            };
        default:
            return state;
    }
};

export default reducer;
