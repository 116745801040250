import moment from 'moment';
import React from 'react';
import useTranslate from 'shared/hooks/useTranslate';

export default function ApplicantHistory({candidateApplicationHistory}) {
    const t = useTranslate();
    return (
        <div id="applicationhistory-id">
            <p className="heading size-sm">{t("APPLICATION_HISTORY")}</p>
            {candidateApplicationHistory.map(his => (
                <div className="history-data">
                    <div className="application-history-item">
                        <div className="date-data-div">
                            <p className="application-date">{moment(his.submitted_at).format("DD MMM YYYY")}</p>
                            {his?.job_poster_feedback && (
                                <p className="application-status">{his.job_poster_feedback}</p>
                            )}
                        </div>
                        <div>
                            <p className="applied-role">{his.job_ad.job_title}</p>
                            <p className="applied-company">{his.job_ad.name}</p>
                            <p className="reviewed-by">{t("LAST_REVIEWED_BY_NAME").replace("{{_name_}}", "Christine Miller")}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
