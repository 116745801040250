import {
	FC,
	useState,
	ChangeEvent,
	ReactNode,
	RefObject,
	KeyboardEvent
} from 'react';
import { XCircle } from '@phosphor-icons/react';
import { RenderIf } from '../../../utils/common';
import InputType from '../../atoms/InputType/InputType';
import Label from '../../atoms/Label/Label';
import ErrorIcon from './ErrorIcon';
import ErrorMessage from './ErrorMessage';
import './FormInput.scss';
import PasswordIcon from './PasswordIcon';
import SetIconInput from './SetIconInput';

type FormInputProps = {
	type?: string | 'text' | 'number' | 'date' | 'time' | 'password' | 'email';
	format?: 'text' | 'currency';
	className?: string;
	placeholder?: string;
	value?: string | any;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void | any | null;
	disabled?: boolean;
	style?: Object;
	required?: boolean;
	name?: string;
	withIcon?: boolean;
	isError?: boolean;
	children?: ReactNode;
	titleLabel?: string;
	classNameLabel?: string;
	classNameInput?: string;
	classNameContent?: string;
	classNameIcon?: string;
	typeIcon?: string;
	width?: string;
	height?: string;
	message?: string;
	unit?: string;
	freeText?: string;
	styleParent?: {};
	isDoubleIcon?: boolean;
	onCloseIcon?: void | any | null;
	min?: number;
	max?: number;
	onClick?: void;
	id?: string;
	formRef?: RefObject<HTMLInputElement>;
	onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
	onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
	onCurrencyChange?: (e?: number) => void;
  };
  
  const FormInput: FC<FormInputProps> = props => {
	const [showPassword, setshowPassword] = useState(false);
	const formattedValue = new Intl.NumberFormat('id-ID').format(props.value);

	const handleCurrencyChange = (e) => {
		const numericValue = e.target.value.replace(/\./g, '');

		if (!isNaN(numericValue)) {
			props.onCurrencyChange?.(numericValue);
		}
	};

	return (
		<div className={['formInputType', props.className].join(' ')} style={props.styleParent}>
			{props.titleLabel && (
				<Label
					name={props.name}
					className={props.classNameLabel}
					label={props.titleLabel}
					isRequired={props.required}
				>
					{props.children}
				</Label>
			)}

			{/* Content Input */}
			<div className={[props.classNameContent].join(' ')}>
				{/* Set Icon */}
				<SetIconInput
					type={props.type}
					freeText={props.freeText}
					classNameIcon={props.classNameIcon}
					typeIcon={props.typeIcon}
					height={props.height}
					width={props.width}
				/>

				{/* Set Double Icon */}
				<RenderIf condition={props.isDoubleIcon}>
					<span onClick={props.onCloseIcon!} className="double-icon tw cursor-pointer">
						{/* Close Icon */}
						<XCircle height={props.height ?? 24} width={props.width ?? 24} />
					</span>
				</RenderIf>

				{/* Set Unit */}
				<RenderIf condition={props.unit}>
					<p className="formInputType unit">{props.unit}</p>
				</RenderIf>
				{/* Input Type Section */}
				<InputType
					ref={props.formRef}
					id={props.name}
					type={showPassword ? 'text' : props.type}
					className={[props.classNameInput].join(' ')}
					disabled={props.disabled}
					isError={props.isError}
					name={props.name}
					onChange={props.format === 'currency' ? handleCurrencyChange : props.onChange}
					placeholder={props.placeholder}
					required={props.required}
					style={props.style}
					value={props.format === 'currency' ? formattedValue : props.value}
					withIcon={props.withIcon}
					min={props.min}
					max={props.max}
					onClick={props.onClick}
					onKeyPress={props.onKeyPress}
					onKeyDown={props.onKeyDown}
				/>

				{/* Input Password */}
				{props.type === 'password' && (
					<PasswordIcon
						height={props.height}
						setshowPassword={setshowPassword}
						showPassword={showPassword}
						width={props.width}
					/>
				)}

				{/* Error Conditon */}
				<RenderIf condition={props.isError}>
					<>
						<RenderIf condition={props.type !== 'password'}>
							<ErrorIcon height={props.height} width={props.width} />
						</RenderIf>

						<ErrorMessage message={props.message ?? 'Type message error.'} />
					</>
				</RenderIf>
			</div>
		</div>
	);
}

export default FormInput;
