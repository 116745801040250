import { FC } from "react";
import cx from 'classnames';
import { useSelector } from "react-redux";

import HeaderDashboard from "./HeaderDashboard/HeaderDashboard";
import Sidebar from "./Sidebar/Sidebar";
import { RootState } from "store/types/RootStateTypes";

interface LayoutWithNavigationProps {
	children: React.ReactNode
	className?: string;
}

const LayoutWithNavigation: FC<LayoutWithNavigationProps> = ({ children, className }) => {
	const isCollapse = useSelector((state: RootState) => state.sidebar.isCollapse);

	return (
		<>
			<HeaderDashboard isCollapse={isCollapse} />
			<div className="position-relative d-flex">
				<Sidebar />
				<div
					className={cx(
						'activeComponent tw h-screen overflow-auto bg-gray-50 tw transition-all duration-300',
						{
						  'collapse-sidebar': !isCollapse,
						},
						className
					  )}
				>
					{children}
				</div>
			</div>
		</>
	);
}

export default LayoutWithNavigation;
