import React from 'react';
import { RenderIf } from '../../../../shared/utils/common';
import FormInput from '../../../../shared/component/molecules/FormInput/FormInput';
import { Copy, Plus, Trash } from '@phosphor-icons/react';
import {
  DataTime,
  ListTime,
  PropsHandleInputListTime,
} from '../../../../store/types/interview';

type ListInputTimeProps = {
  item: DataTime;
  handleCopy: (item: DataTime) => void;
  handleChangeValue?: ({
    event,
    type,
    dataDate,
    dataTime,
  }: PropsHandleInputListTime) => void;
  handleRemove: (item: DataTime, idTime: string) => void;
  handleAddTimeOrDate: (type: string, item: any) => void;
};

export default function ListInputTime({
  item,
  handleChangeValue,
  handleRemove,
  handleAddTimeOrDate,
  handleCopy,
}: ListInputTimeProps) {
  return (
    <div className="row gap-4">
      <FormInput
        titleLabel="Date"
        type="date"
        className="col"
        name="date"
        value={item.date}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeValue?.({
            event,
            type: 'date',
            dataDate: item,
            dataTime: item.listTime![0],
          })
        }
        required={true}
      />
      <div className="col row gap-4">
        {item.listTime?.map((time: ListTime, indexSub: number) => (
          <div className="col d-flex align-items-end gap-4" key={indexSub}>
            <FormInput
              titleLabel={time.id === item.id ? 'Start time' : ''}
              name={'start_time'}
              value={time.start_time}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeValue?.({
                  event,
                  type: 'time',
                  dataDate: item,
                  dataTime: time,
                })
              }
              type={'time'}
            />
            <p className="pb-3">__</p>
            <FormInput
              name={'end_time'}
              value={time.end_time}
              titleLabel={time.id === item.id ? 'End time' : ''}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeValue?.({
                  event,
                  type: 'time',
                  dataDate: item,
                  dataTime: time,
                })
              }
              type={'time'}
            />
            <RenderIf
              condition={
                (item.listTime?.length! === 1 && indexSub === 0) || indexSub > 0
              }>
              <Trash
                className="mb-4 tw cursor-pointer"
                height={24}
                width={24}
                onClick={() => handleRemove(item, time.id)}
              />
            </RenderIf>
          </div>
        ))}
      </div>

      <div className="col d-flex justify-content-end align-items-start pt-lg gap-3 ">
        <Plus
          onClick={() => handleAddTimeOrDate('time', item)}
          height={24}
          width={24}
          className="tw cursor-pointer"
        />

        <Copy
          height={24}
          width={24}
          onClick={() => handleCopy(item)}
          className="tw cursor-pointer"
        />
      </div>
    </div>
  );
}
