import React from "react";
import * as Variable from "shared/utils/variables";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Image from "shared/component/UI/Image";
import AlertCircleIcon from "assets/svg/alert_circle_icon.svg";
import { AuthStateType } from "types/types";
import { Text, Title } from "shared/component/Typography/Typography";
import { Button } from "shared/component";
import usePost from "shared/hooks/usePost";
import useToast from "shared/hooks/useToast";

type ModalDeleteProps = {
	show: boolean;
	onHide: () => void;
	jobId: string;
	refetch: () => void;
};

const ModalDelete = ({ show, onHide, jobId, refetch }: ModalDeleteProps) => {
	const toast = useToast();
	const ENV_NAME = useSelector<AuthStateType>((state) => state.auth.selectedEnvironment);

	const { postFunction: deleteJobAd } = usePost("jobAdDelete");

	const handleDeleteJobAd = async () => {
		await deleteJobAd({ urlParams: jobId }).then((res) => {
			if (res.code === 200) {
				toast.success(Variable.DELETE_JOB_AD_SUCCESS_MSG[ENV_NAME]);
				refetch();
			} else {
				toast.error(res?.message || Variable.SOMETHING_WENT_WRONG_TRY_AGAIN[ENV_NAME]);
			}
		});
		onHide();
	};

	return (
		<Modal
			show={show}
			contentClassName="border-0 p-0"
			onHide={onHide}
			dialogClassName="modal-dialog-centered modal-deactivate"
		>
			<Modal.Body className="modal-deactivate__modal-body">
				<Image src={AlertCircleIcon} className="modal-deactivate__alert-icon-circle" />
				<Title size="xs" fontWeight="semibold">
					{Variable.ARE_YOU_SURE[ENV_NAME]}?
				</Title>
				<Text className="tw text-gray-500">
					{Variable.DELETE_JOB_AD_DESCRIPTION[ENV_NAME]}
				</Text>
				<div className="d-flex modal-deactivate__modal-body__button-groups">
					<Button
						type="outline"
						size="md"
						className="modal-deactivate__modal-body__button-groups-wrapper"
						btnClassName="modal-deactivate__modal-body__button-groups-btn"
						onClick={onHide}
					>
						{Variable.CANCEL_LABEL[ENV_NAME]}
					</Button>
					<Button
						type="error"
						size="md"
						className="modal-deactivate__modal-body__button-groups-wrapper"
						btnClassName="modal-deactivate__modal-body__button-groups-btn"
						onClick={handleDeleteJobAd}
					>
						{Variable.DELETE_BTN_LABEL[ENV_NAME]}
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalDelete;
