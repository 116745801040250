import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IoBookmarkOutline } from "react-icons/io5";
import queryString from "query-string";
import { PiArrowLeft } from "react-icons/pi";
import * as RoutePath from "shared/utils/routeLink";
import { JOB_AD_LISTING } from "../../shared/utils/routeLink";
import { Button } from "../../shared/component";
import { formatString, getCurrencySymbol, getLocale } from "../../shared/utils/string";
import Image from "shared/component/UI/Image";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import { capitalize } from "../../shared/utils/stringUtils";
import formatNumber from "shared/utils/formatNumber";
import usePost from "shared/hooks/usePost";

import Form from "./Reusable/Form";

import { ENV, Nullable } from "../../types";
import useTranslate from "shared/hooks/useTranslate";
import useToast from "shared/hooks/useToast";
import { salaryRangeDesc } from "shared/utils/salaryRangeDesc";

interface Props {
	ENV_NAME: ENV;
	jobAdId: Nullable<number>;
	publishDate: Nullable<string>;
	onPrev: () => void;
}

export interface Company {
	id: number;
	name: string;
	company_address: string;
	company_city: Nullable<string>;
	company_logo: string;
	company_district: Nullable<string>;
}

export interface JobDetail {
	id: number;
	company: Company;
	job_title?: string;
	posting_location_country: string;
	posting_location_city: string;
	posting_location_district: string;
	posting_location_other_location: string;
	work_model: string;
	experience_level: string;
	job_type: string[];
	hours_per_week: number;
	contract_period: number;
	contract_period_unit: string;
	contract_amount: Nullable<string>;
	internship_period: Nullable<string>;
	internship_period_unit: Nullable<string>;
	internship_amount: Nullable<string>;
	temporary_period: Nullable<string>;
	temporary_period_unit: Nullable<string>;
	temporary_amount: Nullable<string>;
	display_salary: boolean;
	currency: string;
	salary_type: string;
	salary_currency: string;
	salary_rate: string;
	salary_amount: number;
	salary_starting_amount: Nullable<number>;
	salary_maximum_amount: Nullable<number>;
	job_requirements: string;
	job_description: string;
	job_benefits: string;
	urgency: Nullable<string>;
	min_days_urgency: Nullable<string>;
	max_days_urgency: Nullable<string>;
	skill_tags: string[];
	last_active_employer: Nullable<string>;
	posting_timestamp: string;
	applicants: number;
	is_applied: boolean;
	is_bookmarked: boolean;
	is_submitted: boolean;
	status?: string;
}

const PreviewJob = ({ ENV_NAME, jobAdId, publishDate, onPrev }: Props) => {
	const t = useTranslate();
	const toast = useToast();
	const PERIOD_UNIT = {
		per_hour: t("PER_HOUR"),
		per_day: t("PER_DAY"),
		per_week: t("PER_WEEK"),
		per_month: t("PER_MONTH_2"),
		per_year: t("PER_YEAR"),
	};
	const JOB_TYPE = {
		full_time: t("FULL_TIME_LABEL"),
		part_time: t("PART_TIME_LABEL"),
		freelance: t("FRELANCE_LABEL"),
		contract: t("CONTRACT_LABEL"),
		internship: t("INTERNSHIP_LABEL"),
		temporary: t("TEMPORARY_LABEL"),
	};
	const EXPERIENCE_LEVEL = {
		entry_level: t("ENTRY_LEVELLABEL"),
		associate: t("ASSOCIATE_LABEL"),
		intermediate: t("INTERMEDIATE"),
		senior: t("SENIOR"),
		director: t("DIRECTOR_LABEL"),
		executive: t("EXECUTIVE_LABEL"),
	};
	const WORK_MODEL = {
		onsite: t("ONSITE_LABEL"),
		hybrid: t("HYBRID_LABEL"),
		remote: t("REMOTE_LABEL"),
	};
	const URGENCY = {
		"1_to_3_days": `1 - 3 ${t("DAYS_2").toLowerCase()}`,
		"3_to_7_days": `3 - 7 ${t("DAYS_2").toLowerCase()}`,
		"1_to_2_weeks": `1 - 2 ${t("WEEKS_2").toLowerCase()}`,
		"2_to_4_weeks": `2 - 4 ${t("WEEKS_2").toLowerCase()}`,
		more_than_4_weeks: t("MORE_THAN_4_WEEKS"),
	};

	const history = useHistory();
	const jobIdFromUrl = (queryString.parse(history.location.search)?.job_ad ||
		null) as Nullable<string>;
	const [jobDetail, setJobDetail] = useState({} as JobDetail);
	const search = useLocation().search;
	const methodRepost = new URLSearchParams(search).get("method");

	useEffect(() => {
		if (jobAdId || jobIdFromUrl) getJob();
	}, [jobAdId, jobIdFromUrl]);

	const getJob = async () => {
		const res = await makeRequest(
			generateRequestOptions("getJobAd", { urlParams: jobAdId || jobIdFromUrl }),
		);

		if (res?.code === 200) setJobDetail(res.data);
		else toast.error(res.message);
	};

	const goToPath = (path = JOB_AD_LISTING) => history.push(path);

	const handlePostJob = async () => {
		const duplicatedJobAd = jobDetail?.status === "draft" && methodRepost === "duplicate";

		if (duplicatedJobAd) {
			toast.success(t("REPOST_JOB_AD_MSG_SUCCESSFULLY"));
			history.push(`${RoutePath.JOB_AD_LISTING}?status=inactive`);
			return;
		}

		if (jobIdFromUrl && jobDetail?.status === "active") return goToPath();

		const res = await makeRequest({
			...generateRequestOptions("publishJob", { urlParams: jobAdId || jobIdFromUrl }),
			data: {
				published_at: publishDate || null,
			},
		});

		if (res.code === 200) {
			if (!!methodRepost) {
				toast.success(t("REPOST_JOB_AD_MSG_SUCCESSFULLY"));
				history.push(`${RoutePath.JOB_AD_LISTING}?status=inactive`);
				return;
			}
			goToPath();
		} else toast.error(res.message);
	};

	return (
		<div className="form-block">
			<Form title={t("PREVIEW")}>
				<div className="preview-job">
					<div className="jb-cont mb-sm">
						<div className="jl-company-logo mb-md">
							<Image src={jobDetail?.company?.company_logo} />
						</div>
						<h3 className="jl-job-name mb-sm">{jobDetail?.job_title}</h3>
						<h4 className="jl-company-name mb-sm">{jobDetail?.company?.name}</h4>
						<div className="jl-city-name mb-sm">{`${jobDetail?.posting_location_city}, ${jobDetail?.posting_location_district}`}</div>
						{jobDetail?.display_salary && (
							<div className="mb-std font-weight-semibold">
								{`${salaryRangeDesc(jobDetail)}
								${jobDetail?.salary_rate ? PERIOD_UNIT[jobDetail?.salary_rate] : ""}
								`}
							</div>
						)}
						<div className="text-sm mb-std d-flex align-items-center flex-wrap">
							{formatString(t("POSTED_AGO"), t("POSTED_DAY_AGO"))}
							<div className="mx-xxs">|</div>
							{formatString(
								t("URGENCY"),
								URGENCY[jobDetail?.urgency || ""] ||
									`1 - 3 ${t("DAYS_2").toLowerCase()}`,
							)}
							<div className="mx-xxs">|</div>
							{formatString(t("APPLICANTS"), jobDetail?.applicants || 280)}
							<div className="mx-xxs">|</div>
							<span className="light-text">{t("OPEN_IN_NEW_TAB")}</span>
						</div>
						<div className="d-flex align-items-center gap-xs">
							<Button
								type="primary"
								title={t("APPLY_NOW")}
								size="sm"
								onClick={() => {}}
								disabled
							/>
							<Button
								type="outline"
								title={t("ADD_TO_COMPARE")}
								size="sm"
								onClick={() => {}}
								disabled
							/>
							<div className="J-cardComp-btn cursorPointer">
								<Button
									type="outline"
									title={t("SAVE_LABEL")}
									size="sm"
									onClick={() => {}}
									customIcon={<IoBookmarkOutline size={20} color="#193560" />}
									disabled
								/>
							</div>
						</div>
					</div>
					<div className="divider show-mobile" />
					<div className="jb-cont mb-lg">
						<h4 className="text-lg text-label-black font-weight-semibold mb-std">
							{t("FULL_JOB_DESCRIPTION")}
						</h4>
						<h3 className="text-md text-label-black font-weight-semibold mb-sm">
							{t("QUALIFICATIONS")}
						</h3>
						<div
							className="mb-std detail"
							dangerouslySetInnerHTML={{
								__html: jobDetail?.job_requirements,
							}}
						/>
						<h3 className="text-md text-label-black font-weight-semibold mb-sm">
							{t("RESPONSIBILITIES")}
						</h3>
						<div
							className="mb-std detail"
							dangerouslySetInnerHTML={{
								__html: jobDetail?.job_description,
							}}
						/>
						<h3 className="text-md text-label-black font-weight-semibold mb-sm">
							{t("JOB_BENEFIT")}
						</h3>
						<div
							className="mb-std detail"
							dangerouslySetInnerHTML={{
								__html: jobDetail?.job_benefits,
							}}
						/>
						<div className="divider" />
						<h4 className="text-lg text-label-black font-weight-semibold mb-std">
							{t("JOB_DETAILS")}
						</h4>
						<div className="job-info-details-grid">
							<div className="job-info-details-item">
								<h3 className="text-md text-label-black font-weight-semibold mb-sm">
									{t("JOB_TYPE_LABEL")}
								</h3>
								<p className="text-sm">
									{capitalize(jobDetail?.job_type?.map((job) => JOB_TYPE[job])) ||
										"-"}
								</p>
							</div>
							<div className="job-info-details-item">
								<h3 className="text-md text-label-black font-weight-semibold mb-sm">
									{t("EXPERIENCE_LEVEL_LABEL")}
								</h3>
								<p className="text-sm">
									{EXPERIENCE_LEVEL[jobDetail?.experience_level]}
								</p>
							</div>
							<div className="job-info-details-item">
								<h3 className="text-md text-label-black font-weight-semibold mb-sm">
									{t("WORK_MODE_LABEL")}
								</h3>
								<p className="text-sm">
									{WORK_MODEL[jobDetail?.work_model] || "-"}
								</p>
							</div>
						</div>
						<div className="divider" />
						<div className="d-flex align-items-center gap-xs">
							<Button
								type="outline"
								title={t("MORE_JOB_SEARCH")}
								size="sm"
								onClick={() => {}}
								disabled
							/>

							<Button
								type="textGray"
								title={t("REPORT")}
								size="sm"
								onClick={() => {}}
								disabled
							/>
						</div>
					</div>
				</div>
			</Form>
			<div className="btn-grp">
				<Button
					type="outline"
					title={t("PREVIOUS_LABEL")}
					onClick={onPrev}
					btnClassName="customBtn"
					customIcon={<PiArrowLeft size={18} className="icon icon-prev" />}
				/>
				<Button
					type="primary"
					title={t("POST_JOB_LABEL")}
					onClick={handlePostJob}
					btnClassName="customBtn btn-save"
				/>
			</div>
		</div>
	);
};

export default PreviewJob;
