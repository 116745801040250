type CardTimeSlotProps = {
  title: string;
  color: string;
  type: string;
  location: string;
};

export default function CardTimeSlot(props: CardTimeSlotProps) {
  return (
    <div
      className="positon-relative p-sm d-flex flex-column justify-content-between"
      style={{
        width: '30rem',
        borderLeft: '0.8rem solid ' + props.color,
        borderTop: '0.1rem solid #E0E0E0',
        borderBottom: '0.1rem solid #E0E0E0',
        borderRight: '0.1rem solid #E0E0E0',
        borderRadius: '0.8rem',
      }}>
      <div>
        <h3 className="text-lg fw-semibold text-black">{props.title}</h3>
        <div className="d-flex align-items-center gap-2 tw text-gray-500 fw-regular text-md capitalize">
          <p className="m-0 p-0">{props.type}</p> <p className="">.</p>{' '}
          <p className="m-0 p-0">{props.location}</p>
        </div>
      </div>

      <div className="mt-std">
        <h5 className="tw text-app-primary text-sm">View scheduled page</h5>
      </div>
    </div>
  );
}
