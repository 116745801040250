import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import moment from 'moment';
import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

type DateCalendarProps = {
  onChange: (date: any) => void;
  selectRange?: boolean;
  showNeighboringMonth?: boolean;
  minDate?: Date;
  disableWeekends: boolean;
};

export default function DateCalendar(props: DateCalendarProps) {
  const disableWeekend = props.disableWeekends
    ? ({ date }: { date: Date }) => date.getDay() === 0 || date.getDay() === 6
    : null;

  return (
    <>
      <Calendar
        next2Label={null}
        nextLabel={<CaretRight height={20} width={20} weight="bold" />}
        prevLabel={<CaretLeft height={20} width={20} weight="bold" />}
        prev2Label={null}
        showNeighboringMonth={false}
        selectRange={true}
        minDate={props.minDate || new Date()}
        tileDisabled={disableWeekend as any}
        tileClassName={({ date }) => {
          if (
            moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
          ) {
            return 'tw border-2';
          }
          return 'tw border-2-transparent fw-normal tw transition-all duration-300';
        }}
        onChange={props.onChange}
        className="border-0 shadow-none"
      />
    </>
  );
}
