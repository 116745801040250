import React from "react";

interface Props {
	title?: string;
	children: React.ReactNode;
	isRequired?: boolean;
}

const Form = ({ title, children, isRequired }: Props) => {
	return (
		<div className="jc-form">
			{title && <h4 className={`${isRequired ? "isRequired" : ""}`}>{title}</h4>}
			<div className="jc-form-body">{children}</div>
		</div>
	);
};

export default Form;
