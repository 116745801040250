import React, { useEffect, useState, useCallback } from "react";
import Header from "./components/Header/Header";
import * as Variable from "../../shared/utils/variables";
import * as RoutePath from "shared/utils/routeLink";
import { useSelector } from "react-redux";
import TabNavigation, { TabNavigationType } from "./components/TabNavigation/TabNavigation";
import WrapperJobListing from "./components/WrapperJobListing/WrapperJobListing";
import useFetch from "../../shared/hooks/useFetch";
import "./JobAdListing.scss";
import FilteringWrapper from "./components/FilteringWrapper/FilteringWrapper";
import useDebounce from "../../shared/hooks/debounce";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "store/types/RootStateTypes";
import { JobType, STATUS_JOB_TYPE, VerificationCompanyType } from "./types/types";
import LayoutWithNavigation from "shared/layouts/LayoutWithNavigation";
import CardVerifyCompany from "./components/CardVerifyCompany/CardVerifyCompany";
import PaginationWrapper from "container/PaginationWrapper/PaginationWrapper";

const verificationCompanyStatus = {
	approved: "",
	rejected: "error",
	"waiting confirmation": "primary",
	"not verified": "secondary",
};

const JobAdListing = () => {
	const ENV_NAME = useSelector((state: RootState) => state.auth.selectedEnvironment) || "bhs";
	const history = useHistory();
	const search = useLocation().search;
	const statusTab = new URLSearchParams(search).get("status");
	const { data: dataVerification, error: errorVerification } =
		useFetch<VerificationCompanyType>("getCompanyVerification");
	const [activeTab, setActiveTab] = useState<STATUS_JOB_TYPE | string>(statusTab ?? "active");
	const [searchValue, setSearchValue] = useState("");
	const [selectedSortType, setSelectedSortType] = useState({
		sortType: "published_at",
		sortBy: "asc",
	});
	const PAGE_SIZE = 10;
	const debounceJobAd = useDebounce(searchValue, 500);
	const isAscJobTitle =
		selectedSortType.sortBy === "asc" && selectedSortType.sortType === "job_title";
	const isDescNotJobTitle =
		selectedSortType.sortBy === "desc" && selectedSortType.sortType !== "job_title";
	const queryParams = {
		...(debounceJobAd && { job_title: debounceJobAd }),
		status: activeTab,
		...(!!selectedSortType.sortType && {
			order_by:
				isAscJobTitle || isDescNotJobTitle
					? selectedSortType.sortType
					: "-" + selectedSortType.sortType,
		}),
		page_size: PAGE_SIZE,
	};
	const [page, setPage] = useState(1);

	const {
		response: responseJobAds,
		loading,
		getDatas: getDatasJobAds,
	} = useFetch<JobType[]>(
		"getJobAds",
		{
			queryParams: {
				...(debounceJobAd && { job_title: debounceJobAd }),
				...(!!selectedSortType.sortType && {
					order_by:
						isAscJobTitle || isDescNotJobTitle
							? selectedSortType.sortType
							: "-" + selectedSortType.sortType,
				}),
			},
		},
		{ lazy: true },
	);

	const {
		data: dataJobAdsByStatus,
		loading: loadingJobAdsByStatus,
		getDatas: getJobAdsByStatus,
		response,
	} = useFetch<JobType[]>(
		"getJobAds",
		{
			queryParams: queryParams,
		},
		{ lazy: true },
	);

	const onJobAdSearch = useCallback((e) => {
		if (e) {
			setSearchValue(e.target.value);
		}
	}, []);

	const handleClickCard = (id) => {
		history.push(`${RoutePath.CANDIDATE_MANAGEMENT}/${id}`);
	};

	const handleRefetch = () => {
		getDatasJobAds();
		getJobAdsByStatus();
	};

	const handleNavigationTab = (currStatus: React.SetStateAction<string>) => {
		setActiveTab(currStatus);
		history.push(`${history.location.pathname}?status=${currStatus}`);
		setPage(1);
		if (currStatus !== "draft") {
			setSelectedSortType({
				sortType: "published_at",
				sortBy: "asc",
			});
			return;
		}
		setSelectedSortType({
			sortType: "job_title",
			sortBy: "asc",
		});
	};

	useEffect(() => {
		getJobAdsByStatus();
	}, [activeTab, debounceJobAd, , selectedSortType.sortBy, selectedSortType.sortType]);

	useEffect(() => {
		getDatasJobAds();
	}, [debounceJobAd]);

	return (
		<LayoutWithNavigation>
			<div className="candidate-management h-100">
				{((dataVerification && dataVerification.verification_status !== "approved") ||
					errorVerification) && (
					<CardVerifyCompany
						type={
							verificationCompanyStatus[
								dataVerification?.verification_status ?? "not verified"
							]
						}
					/>
				)}
				<div className="white-background-div job-ads-listing">
					<Header />
					<TabNavigation
						header={activeTab}
						setHeader={handleNavigationTab}
						resultCounter={
							(responseJobAds &&
								(responseJobAds.result_counter as TabNavigationType["resultCounter"])) ?? {
								inactive: 0,
								active: 0,
								draft: 0,
							}
						}
					/>
					<FilteringWrapper
						activeTab={activeTab}
						onChange={onJobAdSearch}
						placeholder={Variable.SEARCH_JOB_AD_PLACEHOLDER[ENV_NAME]}
						selectedSortType={selectedSortType}
						setSelectedSortType={setSelectedSortType}
					/>
					<WrapperJobListing
						page={page}
						setPage={setPage}
						pageSize={PAGE_SIZE}
						totalItems={response?.total_items ?? 1}
						getJobAds={async (number) => {
							getJobAdsByStatus({
								queryParams: { ...queryParams, page: number ?? 1 },
							});
						}}
						datas={dataJobAdsByStatus ?? []}
						loading={loading || loadingJobAdsByStatus}
						activeTab={activeTab}
						handleClick={handleClickCard}
						refetch={handleRefetch}
					/>
					{dataJobAdsByStatus && !loading && (
						<PaginationWrapper
							page_size={PAGE_SIZE}
							total_items={response?.total_items ?? 1}
							ENV={ENV_NAME}
							onChangePage={(pageNumber) => {
								getJobAdsByStatus({
									queryParams: { ...queryParams, page: pageNumber ?? 1 },
								});
							}}
							page={page}
							setPage={setPage}
						/>
					)}
				</div>
			</div>
		</LayoutWithNavigation>
	);
};

export default JobAdListing;
