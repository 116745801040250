import React from "react";
import "./LoadingSpinner.scss";

interface LoadingSpinnerProps {
    size?: "small" | "medium" | "large";
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = "medium" }) => {
    return (
        <div className={`loading-spinner loading-spinner-${size}`}>
            <div className="spinner" />
        </div>
    );
};

export default LoadingSpinner;
