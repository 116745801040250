import React from "react";
import "./VerifyCompany.scss";
import cookies from "react-cookies";
import UploadFile from "../../shared/component/UploadFile/UploadFile";
import { useSelector } from "react-redux";
import Button from "../../shared/component/ButtonComponent/Button";
import * as Variable from "../../shared/utils/variables";
import { useState } from "react";
import CheckboxComponent from "../../shared/component/CheckboxComponent/CheckboxComponent";
import { useMemo } from "react";
import useFetch from "../../shared/hooks/useFetch";
import { useEffect } from "react";
import usePost from "../../shared/hooks/usePost";
import ModalError from "./ModalError";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { homeUrl } from "../../shared/utils/apiEndPoints";
import useToast from "shared/hooks/useToast";
import { PROFILE } from "shared/utils/routeLink";

const TAX_STEP = 1;
const BUSINESS_LICENSE = 2;

const VerifyCompany = () => {
	const toast = useToast();
	const { ENV_NAME } = useSelector((state) => ({
		ENV_NAME: state.auth.selectedEnvironment || "bhs",
	}));
	const MAX_STEP = 2;
	const [activeStep, setActiveStep] = useState(TAX_STEP);
	const [isUploading, setIsUploading] = useState(false);
	const [isUploadSuccess, setIsUploadSuccess] = useState(null);
	const { data, getDatas } = useFetch("getCompanyVerification");
	const { postFunction: postTaxCard } = usePost("addTaxCardCompany");
	const { postFunction: postBusinessLicense } = usePost("addBusinessLicenceCompany");
	const { postFunction: updateVerificationData } = usePost("updateVerificationData");
	const [modalError, setModalError] = useState({
		isShow: false,
		message: "",
	});
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: {
			"application/pdf": [".pdf"],
			"image/png": [".png"],
		},
		multiple: false,
		maxFiles: 1,
		// onDrop: (acceptedFiles, fileRejections) =>
		// 	onFileReject(acceptedFiles, fileRejections),
	});
	const [selectedFiles, setSelectedFiles] = useState([]);
	const history = useHistory();

	useEffect(() => {
		if (!cookies.load("verify_company")) {
			history.push("/");
		}

		if (!data) {
			getDatas();
		}
	}, [data]);

	const existingFile = (identity) => {
		if (!data) return false;

		if (identity === "tax_card_image") {
			return !!data.tax_card_image;
		}

		if (identity === "business_license") {
			return !!data.business_or_registration_license;
		}
	};

	const fileExisting = useMemo(() => {
		if (!data) return false;

		const taxFileName = data?.tax_card_image
			? data?.tax_card_image.split("/")[data?.tax_card_image.split("/").length - 1]
			: "";

		const businessLicenseFilename = data?.business_or_registration_license
			? data.business_or_registration_license.split("/")[
					data.business_or_registration_license.split("/").length - 1
			  ]
			: "";

		const existingFileName = activeStep === TAX_STEP ? taxFileName : businessLicenseFilename;

		if (!!taxFileName) {
			setActiveStep(BUSINESS_LICENSE);
		}

		if (!!businessLicenseFilename) {
			setActiveStep(TAX_STEP);
		}

		return existingFileName;
	}, [activeStep, data]);

	const handleUploadFile = async () => {
		const MAXIMUM_FILE_SIZE = 20971520;
		if (selectedFiles[0].size > MAXIMUM_FILE_SIZE) {
			setModalError({
				isShow: true,
				message: Variable.FILE_TOO_LARGE[ENV_NAME],
			});
			return;
		}

		const formData = new FormData();

		setIsUploading(true);

		if (
			(data?.tax_card_image && activeStep === TAX_STEP) ||
			(data?.business_or_registration_license && activeStep === BUSINESS_LICENSE)
		) {
			await updateVerificationData(formData, false, true).then((res) => {
				if (res.error && res.message) {
					setIsUploading(false);
					setIsUploadSuccess(false);
					setModalError({
						isShow: true,
						message: res.message,
					});
					return;
				}
				setSelectedFiles([]);
				setIsUploading(false);
				setIsUploadSuccess(true);
				getDatas();

				if (activeStep < MAX_STEP) {
					// setIsUploadSuccess(null);
					setActiveStep((prev) => prev + 1);
				}
			});
			return;
		}

		if (activeStep === TAX_STEP) {
			formData.append("tax_card_image", selectedFiles[0]);
			await postTaxCard({
				body: formData,
				isFormData: true,
			}).then((res) => {
				if (res.error && res.message) {
					setIsUploading(false);
					setIsUploadSuccess(false);
					setModalError({
						isShow: true,
						message: res.message,
					});
					return;
				}
				toast.success(Variable.FILE_UPLOADED_SUCCESS[ENV_NAME]);
				setSelectedFiles([]);
				setIsUploading(false);
				setIsUploadSuccess(null);
				getDatas();
				setActiveStep((prev) => prev + 1);
				return;
			});
		}
		if (activeStep === BUSINESS_LICENSE) {
			formData.append("business_or_registration_license", selectedFiles[0]);

			if (!data?.business_or_registration_license) {
				await postBusinessLicense({
					body: formData,
					isFormData: true,
				}).then((res) => {
					if (res.error && res.message) {
						setIsUploading(false);
						setIsUploadSuccess(false);
						setModalError({
							isShow: true,
							message: res.message,
						});
						return;
					}

					setSelectedFiles([]);
					toast.success(Variable.FILE_UPLOADED_SUCCESS[ENV_NAME]);
					setIsUploading(false);
					setIsUploadSuccess(true);
					cookies.remove("verify_company", {
						path: "/",
						domain: homeUrl,
					});
					history.push(PROFILE);
					getDatas();
					return;
				});
			}
		}
	};

	return (
		<div className="row p-0 gx-0">
			<div className="col-md-5 d-md-block p-0">
				<div className="verify-success-left verify-company-left">
					<h1 className="verify-company-left__content-title">
						{Variable.VERIFY_YOUR_ACCOUNT[ENV_NAME]}
					</h1>
					<div className="verify-company-left__wrapper-step">
						<div className="verify-company-left__wrapper-step__content-step">
							<CheckboxComponent
								className={`company-checkbox-status ${
									activeStep === TAX_STEP ? " active" : ""
								}`}
								label={Variable.UPLOAD_NPWP[ENV_NAME]}
								checked={existingFile("tax_card_image")}
							/>
						</div>
						<div className="verify-company-left__wrapper-step__content-step">
							<CheckboxComponent
								label={Variable.UPLOAD_TDP_SIUP[ENV_NAME]}
								className={`company-checkbox-status ${
									activeStep === BUSINESS_LICENSE ? " active" : ""
								}`}
								checked={existingFile("business_license")}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-7 p-0">
				<div className="verify-success-right verify-company-right">
					<h2 className="verify-company-right__title mb-0">
						{activeStep === 1
							? Variable.UPLOAD_NPWP[ENV_NAME]
							: Variable.UPLOAD_TDP_SIUP[ENV_NAME]}
					</h2>
					<UploadFile
						ENV_NAME={ENV_NAME}
						isUploadSuccess={isUploadSuccess}
						isUploading={isUploading}
						setIsUploading={setIsUploading}
						handleUploadFile={handleUploadFile}
						isFileExist={!!fileExisting}
						existFileName={fileExisting}
						getRootProps={getRootProps}
						getInputProps={getInputProps}
						acceptedFiles={acceptedFiles}
						setSelectedFiles={setSelectedFiles}
						selectedFiles={selectedFiles}
					/>
					<div className="verify-company-right__button-group">
						<Button
							onClick={() => {
								cookies.remove("verify_company", {
									path: "/",
									domain: homeUrl,
								});
								history.push(PROFILE);
							}}
							size="sm"
							type="outline"
							title={Variable.SKIP_LABEL[ENV_NAME]}
						/>
						<Button
							onClick={() => {
								handleUploadFile(acceptedFiles);
							}}
							type="primary"
							size="sm"
							disabled={!selectedFiles.length}
							title={Variable.NEXT[ENV_NAME]}
						/>
					</div>
				</div>
			</div>
			<ModalError
				isShow={modalError.isShow}
				onHide={() =>
					setModalError({
						isShow: false,
					})
				}
				modalTitle={Variable.UPLOAD_FAILED[ENV_NAME]}
				modalText={modalError.message}
			/>
		</div>
	);
};

export default VerifyCompany;
