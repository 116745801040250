import React, { Fragment, SetStateAction, useState } from "react";
import * as RoutePath from "shared/utils/routeLink";
import CardJobList from "../CardJobList/CardJobList";
import "./WrapperJobListing.scss";
import EmptyStateJobList from "../EmptyStateJobList/EmptyStateJobList";
import ModalCollaborate from "../Modal/ModalCollaborate/ModalCollaborate";
import ModalDeactivate from "../Modal/ModalDeactivate/ModalDeactivate";
import { useHistory } from "react-router-dom";
import ModalRepost from "../Modal/ModalRepost/ModalRepost";
import ModalDelete from "../Modal/ModalDelete/ModalDelete";
import { JobType, STATUS_JOB_TYPE } from "component/JobAdListing/types/types";
import "./WrapperJobListing.scss";
import { JOB_AD } from "shared/utils/routeLink";
import PaginationWrapper from "container/PaginationWrapper/PaginationWrapper";
import { useEnvironment } from "shared/hooks/useEnvironment";
import SkeletonLoader from "shared/component/atoms/SkeletonLoader";

type HandlerModalFunctionParams = {
	modalName: "collaborator" | "deactivate" | "repost" | "delete";
	show: boolean;
};

type WrapperJobListProps = {
	datas: JobType[];
	loading: boolean;
	activeTab: STATUS_JOB_TYPE | string;
	handleClick: (e: React.MouseEvent<HTMLDivElement>) => void;
	refetch: () => void;
	getJobAds: (pageNumber: number) => void;
	totalItems: number;
	pageSize: number;
	page: number;
	setPage: React.Dispatch<SetStateAction<number>>;
};

const WrapperJobList = ({
	datas,
	loading,
	activeTab,
	handleClick,
	refetch,
	getJobAds,
	totalItems,
	pageSize,
	page,
	setPage,
}: WrapperJobListProps) => {
	const [handlerModal, setHandlerModal] = useState({
		collaborator: false,
		deactivate: false,
		repost: false,
		delete: false,
	});
	const [jobId, setJobId] = useState("");
	const ENV = useEnvironment();

	const setHandlerModalFunction = ({ modalName, show }: HandlerModalFunctionParams) => {
		setHandlerModal((prev) => ({
			...prev,
			[modalName]: show,
		}));
	};

	const handleClickCollaborate = (e: React.MouseEvent<HTMLParagraphElement>) => {
		e.stopPropagation();
		const { id } = e.currentTarget;
		setJobId(id);
		setHandlerModalFunction({
			modalName: "collaborator",
			show: true,
		});
	};

	const history = useHistory();

	const handleClickDeactivate = (e: React.MouseEvent<HTMLParagraphElement>) => {
		e.stopPropagation();
		const { id } = e.currentTarget;
		setJobId(id);
		setHandlerModalFunction({
			modalName: "deactivate",
			show: true,
		});
	};

	const handleClickDelete = (e: React.MouseEvent<HTMLParagraphElement>) => {
		e.stopPropagation();
		const { id } = e.currentTarget;
		setJobId(id);
		setHandlerModalFunction({
			modalName: "delete",
			show: true,
		});
	};

	const handleClickView = (e: React.MouseEvent<HTMLParagraphElement>) => {
		e.stopPropagation();
		const { id } = e.currentTarget;
		history.push(`${RoutePath.PREVIEW_JOB_AD}?job_ad=${id}`);
	};

	const handleClickEdit = (e: React.MouseEvent<HTMLParagraphElement>) => {
		e.stopPropagation();
		const { id } = e.currentTarget;
		const status = datas.find((d) => d.id === +id)?.status;

		history.push(`${JOB_AD}/?job_ad=${id}&status=${status}`);
	};

	const handleClickRepost = (e: React.MouseEvent<HTMLParagraphElement>) => {
		e.stopPropagation();
		const { id } = e.currentTarget;
		setJobId(id);
		setHandlerModalFunction({
			modalName: "repost",
			show: true,
		});
	};

	return !loading && datas.length < 1 ? (
		<EmptyStateJobList />
	) : (
		<div className="wrapper-job-listing">
			{loading ? (
				<>
					<SkeletonLoader width="100%" height="275px" />
					<SkeletonLoader width="100%" height="275px" />
					<SkeletonLoader width="100%" height="275px" />
				</>
			) : (
				datas &&
				datas.map((jobAd, i) => {
					return (
						<Fragment key={i}>
							<CardJobList
								data={jobAd}
								activeTab={activeTab}
								handleClick={handleClick}
								handleClickCollaborate={handleClickCollaborate}
								handleClickView={handleClickView}
								handleClickDeactivate={handleClickDeactivate}
								handleClickEdit={handleClickEdit}
								handleClickRepost={handleClickRepost}
								handleClickDelete={handleClickDelete}
							/>
						</Fragment>
					);
				})
			)}

			{handlerModal.collaborator && jobId && (
				<ModalCollaborate
					show={handlerModal.collaborator}
					onHide={() =>
						setHandlerModalFunction({
							modalName: "collaborator",
							show: false,
						})
					}
					jobId={jobId}
				/>
			)}

			{handlerModal.deactivate && jobId && (
				<ModalDeactivate
					show={handlerModal.deactivate}
					onHide={() =>
						setHandlerModalFunction({
							modalName: "deactivate",
							show: false,
						})
					}
					jobId={jobId}
					refetch={refetch}
				/>
			)}

			{handlerModal.repost && jobId && (
				<ModalRepost
					show={handlerModal.repost}
					onHide={() =>
						setHandlerModalFunction({
							modalName: "repost",
							show: false,
						})
					}
					jobId={jobId}
				/>
			)}

			{handlerModal.delete && jobId && (
				<ModalDelete
					show={handlerModal.delete}
					onHide={() =>
						setHandlerModalFunction({
							modalName: "delete",
							show: false,
						})
					}
					jobId={jobId}
					refetch={refetch}
				/>
			)}
		</div>
	);
};

export default WrapperJobList;
