import { combineReducers } from "redux";
import authReducer from "./authReducer";
import jobCreation from "./jobCreation";
import interview from "./interview";
import sidebarReducer from "./sidebarReducer";

const rootReducer = combineReducers({
	auth: authReducer,
	jobCreation: jobCreation,
	interview: interview,
	sidebar: sidebarReducer,
});

export default rootReducer;
