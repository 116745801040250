import React from "react";
import Image from "shared/component/UI/Image";
import EmptyStateJobListImage from "assets/svg/JobAds/empty-state-job-list.svg";
import "./EmptyStateJobList.scss";
import usePermission from "shared/hooks/usePermission";
import useTranslate from "shared/hooks/useTranslate";

const EmptyStateJobList = () => {
	const permission = usePermission();
	const t = useTranslate();
	return (
		<div className="empty-state-job-ads">
			<Image src={EmptyStateJobListImage} />
			<h5 className="empty-state-job-ads__title">
				{!!permission.manage_candidate
					? t("EMPTY_JOB_ADS_TITLE")
					: t("NO_PERMISSION_JOB_AD_TITLE")}
			</h5>
			<p className="empty-state-job-ads__description">
				{!!permission.manage_candidate
					? t("EMPTY_JOB_ADS_DESCRIPTION")
					: t("NO_PERMISSION_JOB_AD_DESC")}
			</p>
		</div>
	);
};

export default EmptyStateJobList;
