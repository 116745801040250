import React from 'react';
import Image from '../../../../shared/component/UI/Image';
import Building from '../../../../assets/svg/building-icon.svg';
import moment from 'moment';
import { formattedNumber } from 'shared/utils/string';
import useTranslate from 'shared/hooks/useTranslate';

export default function Experiences({ candidateData }) {
    const t = useTranslate();

    const getExperience = (str, end) => {
        let months = end.getMonth() - str.getMonth() + (12 * (end.getFullYear() - str.getFullYear()))
        if(months % 12 === 0) return t("NUMBER_YEARS").replace("{{number}}", months / 12)
        if(months < 12) return t("NUMBER_MONTHS").replace("{{number}}", months)
        return t("YEARS_NUMBER_MONTHS").replace("{{_year_}}", Math.floor(months/12)).replace("{{_month_}}", months % 12)
    }

    return (
        <div className='m-2'>
            <p className="heading">{t("EXPERIENCE")}</p>
            {candidateData?.created_by?.experience_history?.map(exp => (
                <div className="experience-div">
                    <Image className={'building-icon'} src={Building} />
                    <div>
                        <p className="company-name">{exp?.company_name}</p>
                        <p className="role">
                            {`${exp?.job_title} • `}
                            <span className="light-data">
                                {`${moment(exp.started_at).format("MMM YYYY")} - ${exp.is_currently_working ? t("PRESENT") : moment(exp.ended_at).format("MMM YYYY")} • ${getExperience(new Date(exp.started_at),exp.ended_at ? new Date(exp.ended_at):new Date())}`}
                            </span>
                        </p>
                        <p className="light-data">{[exp?.city, exp?.country].filter(Boolean).join(", ")}</p>
                        {exp?.salary && (
                            <p className="light-data">{`${t("MONTH_SALARY")} : ${exp.currency.toUpperCase()+" "}${formattedNumber(exp.salary)}`}</p>
                        )}
                        <p className="description">{candidateData?.desc}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}
