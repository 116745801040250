export const INTERVIEW_METHOD = 'INTERVIEW_METHOD';
export const INTERVIEW_TYPE = 'INTERVIEW_TYPE';
export const INTERVIEW_FORM = 'INTERVIEW_FORM';
export const INTERVIEW_JOB_POSTER = 'INTERVIEW_JOB_POSTER';

export type ListTime = {
  id: string;
  start_time: string;
  end_time: string;
};

export type DataTime = {
  id: string;
  date: string;
  listTime: ListTime[];
};

export type TimeSlot = {
  start_time: 'string';
  end_time: 'string';
  date: 'string';
  timezone: 'string';
};

export type FormTimeSlot = {
  meeting_type: string;
  schedule_name: string;
  slot_target?: string;
  video_call_link?: string;
  date_time_schedule_exact?: string;
  location?: string;
  phone_number?: string;
  interview_pic?: string;
  message?: string;
  max_capacity_in_slots?: number;
  job_ad: string | number;
  allow_interview_reschedule?: boolean;
  max_date_to_interview?: string;
  no_of_reschedule_allowed?: number;
  slot: TimeSlot[];
  schedule_color?: string;
};

export type FormExact = {
  meeting_type: 'on_site' | 'phone_call' | 'video_call' | string;
  schedule_name: string;
  slot_target?: string;
  video_call_link?: string;
  date_time_schedule_exact?: string;
  location?: string;
  phone_number?: string;
  interview_pic?: string;
  message?: string;
  job_ad: number | null; //id job poster
  job_applications: Array<number>; //list of id candidate
};

export type PropsHandleInputListTime = {
  event: React.ChangeEvent<HTMLInputElement>;
  dataTime?: ListTime;
  dataDate: DataTime;
  type: 'date' | 'time';
};

type JobPosterDetailCompany = {
  id: number;
  name: string;
  company_address: string;
  company_city: string;
  company_logo: string;
};

export type InterviewJobPosterDetail = {
  applicants: number;
  auto_decline_days: null;
  company: JobPosterDetailCompany;
  contract_amount: null;
  contract_period: null;
  contract_period_unit: null;
  created_at: string;
  created_by: number;
  display_salary: boolean;
  employer_assist: boolean;
  employer_assist_last_updated_by: string;
  employer_assist_off_at: null;
  employer_assist_on_at: null;
  experience_level: string;
  expired_at: string;
  form_percentage: number;
  hires: null;
  hours_per_week: null;
  id: number;
  internship_amount: null;
  internship_period: null;
  internship_period_unit: null;
  is_applied: boolean;
  is_bookmarked: boolean;
  job_benefits: Text;
  job_description: Text;
  job_requirements: Text;
  job_title: string;
  job_type: Array<string>;
  last_active_employer: null;
  max_days_urgency: null;
  min_days_urgency: null;
  package_type: string;
  past_expired_timestamp: [];
  past_published_timestamp: [];
  posting_location_city: string;
  posting_location_country: string;
  posting_location_district: string;
  posting_location_other_location: string;
  posting_timestamp: null;
  published_at: null;
  reactivate_counter: number;
  salary_amount: null;
  salary_currency: string;
  salary_maximum_amount: number;
  salary_rate: string;
  salary_starting_amount: number;
  salary_type: string;
  skill_tags: [];
  status: string;
  temporary_amount: null;
  temporary_period: null;
  temporary_period_unit: null;
  updated_at: Date;
  updated_by: null;
  urgency: string;
  work_model: string;
  interview_schedule_type: string;
};

export type InterviewReducerInitial = {
  methodInterview: string;
  typeInterview: string;
  formInterview: any;
  jobInterview: InterviewJobPosterDetail;
};

export interface InterviewMethod {
  type: typeof INTERVIEW_METHOD;
  payload: string;
}

export interface InterviewType {
  type: typeof INTERVIEW_TYPE;
  payload: string;
}

export interface InterviewForm {
  type: typeof INTERVIEW_FORM;
  payload: FormTimeSlot;
}

export interface InterviewJobPoster {
  type: typeof INTERVIEW_JOB_POSTER;
  payload: InterviewJobPosterDetail;
}

export type ListInterviewTypes =
  | InterviewMethod
  | InterviewType
  | InterviewForm
  | InterviewJobPoster;
