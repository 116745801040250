
const currencyFormatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: 0
})

export const salaryRangeDesc = (el) => {
    switch (el?.salary_type) {
        case "range_amount":
            return [currencyFormatter.format(el?.salary_starting_amount), currencyFormatter.format(el?.salary_maximum_amount)].filter(Boolean).join(" - ")
        case "exact_amount":
            return currencyFormatter.format(el?.salary_amount)
        case "starting_amount":
            return currencyFormatter.format(el?.salary_starting_amount)
        case "maximum_amount":
            return currencyFormatter.format(el?.salary_maximum_amount)
        default:
            return "-"
    }
}