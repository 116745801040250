import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as Variable from "../../utils/variables";
import { classHelper } from "../../utils/stringUtils";
import closeCircle from "../../../assets/svg/close_circle.svg";
import arrowDown from "../../../assets/svg/arrow_dropdown.svg";
import arrowTop from "../../../assets/svg/arrow_top.svg";
import helpIcon from "../../../assets/svg/help.svg";
import warningIcon from "../../../assets/svg/warning.svg";
import parsePhoneNumber from "libphonenumber-js";
import cx from "classnames";

import "./InputComponent.scss";
import Image from "../UI/Image";

import countries from "countries-list";
import { orderBy, uniqBy } from "lodash";

const countryCodes = Object.keys(countries.countries);
let PHONE_LIST = [];

countryCodes.forEach((code) => {
	if (countries.countries[code].phone?.includes(",")) {
		countries.countries[code].phone?.split(",")?.forEach((codeInside) => {
			if (codeInside.length > 3) return;
			PHONE_LIST.push({
				id: `+${codeInside}`,
				name: `+${codeInside}`,
				pureNumber: Number(codeInside),
			});
		});
	} else if (countries.countries[code].phone) {
		if (countries.countries[code].phone.length > 3) return;
		PHONE_LIST.push({
			id: `+${countries.countries[code].phone}`,
			name: `+${countries.countries[code].phone}`,
			pureNumber: Number(countries.countries[code].phone),
		});
	}
});
PHONE_LIST = uniqBy(orderBy(PHONE_LIST, "pureNumber"), "id");

// DOCUMENTATION
// inputType : [label, email, salary, phone, password, textarea, salary, phone]
// dropdownType : [location, search, college, company]
// if use textarea, we have to put value and onChange props

const InputComponent = ({
	dropdownType,
	inputType,
	spesificType,
	autoComplete,
	label,
	name,
	placeholder,
	value,
	onChange,
	rows,
	cols,
	noHint,
	withTooltip,
	required,
	isError,
	disabled,
	onClickClose,
	ENV_NAME,
	isReadOnly,
	showIcon = true,
	id,
	choiceLabel,
	checked,
	characterLimit = 5000,
	showWarning = true,
	hintText = "",
	...props
}) => {
	// Probably temporary value
	const [isOpen, setIsOpen] = useState(false);
	const [phoneCode, setPhoneCode] = useState("+62");

	// Change this approach beacuse causes problems
	// useEffect(() => {
	// 	let code = getPhoneValue(typeof value === "string" ? value : "", "code");
	// 	if (PHONE_LIST && inputType === "phone" && code !== phoneCode) {
	// 		if (PHONE_LIST?.find((i) => i?.id === code)) {
	// 			setPhoneCode(code);
	// 		} else {
	// 			setPhoneCode("+62");
	// 		}
	// 	}
	// }, [PHONE_LIST, value, inputType, phoneCode]); // eslint-disable-line

	const getPhoneValue = (value, type = "number") => {
		const phoneNumber = parsePhoneNumber(value || "");
		if (!phoneNumber) return null;
		if (type === "code") return `+${phoneNumber.countryCallingCode}`;

		return phoneNumber.nationalNumber;
	};

	const handleMaxChange = (event) => {
		onChange(event?.target?.value);
	};

	return (
		<div className={classHelper("J-inputComp", props.className)}>
			{
				// These are for Input dropdown input
				dropdownType ? (
					<div className="positionRelative">
						{label && (
							<label>
								{!!label
									? label
									: dropdownType === "location"
									? Variable.LOCATION_LABEL[ENV_NAME]
									: dropdownType === "search"
									? Variable.SEARCH_LABEL[ENV_NAME]
									: dropdownType === "college"
									? Variable.COLLEGE_LABEL[ENV_NAME]
									: dropdownType === "company"
									? Variable.COMPANY[ENV_NAME]
									: Variable.PLACEHOLDER[ENV_NAME]}
							</label>
						)}
						{required && <span className="text-danger text-sm">*</span>}
						<input
							className={`${cx(
								"J-inputComp-input",
								dropdownType === "location"
									? "J-inputComp-location"
									: dropdownType === "search"
									? "J-inputComp-search"
									: dropdownType === "college"
									? "J-inputComp-college"
									: dropdownType === "company"
									? "J-inputComp-company"
									: "J-inputComp-dropdown",
								{ "J-inputComp-no-icon": !showIcon, borError: isError },
							)}`}
							autoComplete={autoComplete}
							type={spesificType}
							name={name || `inputComponent-${dropdownType}`}
							placeholder={placeholder ?? Variable.SRC_PLACEHOLDER[ENV_NAME]}
							value={value}
							onKeyDown={props.onKeyDown ?? null}
							onChange={(e) => {
								onChange(e);
								if (dropdownType === "dropdown" && e?.target?.value?.length !== 0) {
									setIsOpen(true);
								} else {
									setIsOpen(false);
								}
							}}
							disabled={disabled}
							readOnly={isReadOnly}
						/>
						{(value?.length !== 0 && dropdownType === "location") ||
						(value?.length !== 0 && dropdownType === "search") ||
						(value?.length !== 0 && dropdownType === "college") ||
						(value?.length !== 0 && dropdownType === "company") ? (
							<Image
								className="J-inputComp-rightBtn cursorPointer"
								onClick={onClickClose}
								src={closeCircle}
							/>
						) : dropdownType === "dropdown" ? (
							<Image
								className="J-inputComp-rightBtn cursorPointer"
								onClick={() => setIsOpen(false)}
								src={`${isOpen ? arrowDown : arrowTop}`}
							/>
						) : (
							<></>
						)}
						{isError && (
							<Image className="J-inputComp-rightBtn warningIcon" src={warningIcon} />
						)}
					</div>
				) : // These are for input field
				inputType ? (
					<div>
						{label && (
							<label>
								{inputType === "label"
									? label || Variable.LABEL_NAME[ENV_NAME]
									: inputType === "email"
									? label || Variable.EMAIL[ENV_NAME]
									: inputType === "salary"
									? label || Variable.SALARY[ENV_NAME]
									: inputType === "phone"
									? label || Variable.PHONE[ENV_NAME]
									: inputType === "password"
									? label || Variable.PASSWORD[ENV_NAME]
									: inputType === "textarea"
									? label || Variable.TEXTAREA[ENV_NAME]
									: label}
								{withTooltip && (
									<Image className="J-inputComp-tooltip" src={helpIcon} />
								)}
							</label>
						)}
						{required && <span className="text-danger text-sm">*</span>}
						{
							// These are for salary and phone type
							inputType === "salary" || inputType === "phone" ? (
								<div
									className={`d-flex positionRelative${
										isError ? "borError" : ""
									} ${disabled ? "disable" : ""}`}
								>
									{inputType === "salary" ? (
										<select
											className="J-inputComp-curSalDropdown"
											disabled={disabled}
										>
											{Variable.CURRENCY_LIST?.map((cl, clIdx) => (
												<option key={clIdx}>{cl?.name}</option>
											))}
										</select>
									) : inputType === "phone" ? (
										<select
											className="J-inputComp-curSalDropdown phone-input"
											disabled={disabled}
											value={phoneCode}
											onChange={(e) => {
												setPhoneCode(e.target.value);
												onChange({
													type: "change",
													target: {
														name: name || `inputComponent-${inputType}`,
														value: `${e.target.value}${getPhoneValue(
															value,
															"number",
														)}`,
													},
												});
											}}
										>
											{PHONE_LIST?.map((pl, plIdx) => (
												<option key={pl?.id} value={pl?.id}>
													{pl?.name}
												</option>
											))}
										</select>
									) : (
										""
									)}
									<input
										id="special-field"
										className={`J-inputComp-input`}
										autoComplete={autoComplete}
										type={`${
											inputType === "salary" || inputType === "phone"
												? "number"
												: spesificType
										}`}
										min={`${
											inputType === "salary" || inputType === "phone" ? 0 : ""
										}`}
										name={name || `inputComponent-${inputType}`}
										placeholder={
											inputType === "salary"
												? Variable.SALARY_PLACEHOLDER[ENV_NAME]
												: inputType === "phone"
												? Variable.PHONE_PLACEHOLDER[ENV_NAME]
												: placeholder
										}
										value={getPhoneValue(value)}
										onChange={(e) => {
											onChange({
												type: "change",
												target: {
													name: name || `inputComponent-${inputType}`,
													value: `${phoneCode}${e.target.value}`,
												},
											});
										}}
										disabled={disabled}
										readOnly={isReadOnly}
									/>
									{isError && (
										<Image
											className="J-inputComp-rightBtn warningIcon"
											src={warningIcon}
										/>
									)}
								</div>
							) : inputType === "textarea" ? (
								// This is for Textarea input field
								<div>
									<textarea
										id="textarea"
										name="textarea"
										rows={rows ?? "4"}
										cols={cols ?? "50"}
										maxLength={characterLimit || "5000"}
										onChange={handleMaxChange}
										value={value}
										placeholder={placeholder}
										className={`J-inputComp-input ${isError ? "borError" : ""}`}
										disabled={disabled}
									/>
								</div>
							) : inputType === "radio" ? (
								<div className="J-inputComp-choice">
									<input
										id={id}
										type="radio"
										name={name}
										className="J-inputComp-choice-option"
										value={value}
										onChange={onChange}
										checked={checked}
										disabled={disabled}
									/>
									<label
										htmlFor={id}
										className={`J-inputComp-choice-label ${
											isError ? "choice-field-error" : ""
										}`}
									>
										{choiceLabel}
									</label>
								</div>
							) : inputType === "checkbox" ? (
								<div className="J-inputComp-choice">
									<input
										id={id}
										type="checkbox"
										name={name}
										className="J-inputComp-choice-option"
										value={value}
										onChange={onChange}
										checked={checked}
										disabled={disabled}
									/>
									<label
										htmlFor={id}
										className={`J-inputComp-choice-label ${
											isError ? "choice-field-error" : ""
										}`}
									>
										{choiceLabel}
									</label>
								</div>
							) : (
								// These are for email, label, password, and etc
								<div className="positionRelative">
									<input
										id={`${inputType === "email" ? "" : "normal-field"}`}
										className={cx(
											`J-inputComp-input ${
												inputType === "email" && showIcon
													? "J-inputComp-email"
													: "J-inputComp-no-icon"
											} ${isError ? "borError" : ""} ${
												disabled ? "disable" : ""
											}`,
											{ "J-inputComp-no-icon": !showIcon },
										)}
										autoComplete={autoComplete}
										type={`${
											inputType === "label"
												? "text"
												: inputType === "password"
												? "password"
												: inputType === "email"
												? "email"
												: spesificType
										}`}
										name={name || `inputComponent-${inputType}`}
										placeholder={
											placeholder ?? Variable.SRC_PLACEHOLDER[ENV_NAME]
										}
										value={value}
										onChange={(e) => {
											onChange(e);
										}}
										disabled={disabled}
										readOnly={isReadOnly}
									/>
									{isError && showWarning && (
										<Image
											className="J-inputComp-rightBtn warningIcon"
											src={warningIcon}
										/>
									)}
								</div>
							)
						}
						<div
							className={`${
								noHint
									? "d-flex justify-content-between"
									: "d-flex justify-content-end"
							}`}
						>
							{noHint && (
								<p className={`J-inputComp-below ${isError ? "error" : ""}`}>
									{hintText
										? hintText
										: isError
										? Variable.ERROR_HINT_MESSAGE[ENV_NAME]
										: Variable.HINT_MESSAGE[ENV_NAME]}
								</p>
							)}
							{inputType === "textarea" && (
								<p className={`J-inputComp-below ${isError ? "error" : ""}`}>
									{value?.length} / {characterLimit || 5000}
								</p>
							)}
						</div>
					</div>
				) : (
					<></>
				)
			}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(InputComponent));
