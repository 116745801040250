import React from "react";
import { useEnvironment } from "shared/hooks/useEnvironment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IoBookmarkOutline } from "react-icons/io5";
import queryString from "query-string";
import * as Variable from "shared/utils/variables";
import Button from "shared/component/ButtonComponent/Button";
import { formatString, getCurrencySymbol, getLocale } from "shared/utils/string";
import Image from "shared/component/UI/Image";
import makeRequest from "shared/utils/request";
import { generateRequestOptions } from "shared/utils/apiEndPoints";
import { capitalize } from "shared/utils/stringUtils";
import formatNumber from "shared/utils/formatNumber";
import Form from "component/JobCreation/Reusable/Form";
import { Nullable } from "types";

import "../../../JobCreation/jobCreation.scss";
import useToast from "shared/hooks/useToast";
import { salaryRangeDesc } from "shared/utils/salaryRangeDesc";

export interface Company {
	id: number;
	name: string;
	company_address: string;
	company_city: Nullable<string>;
	company_logo: string;
	company_district: Nullable<string>;
}

interface JobDetailType {
	id: number;
	company: Company;
	job_title: string;
	posting_location_country: string;
	posting_location_city: string;
	posting_location_district: string;
	posting_location_other_location: string;
	work_model: string;
	experience_level: string;
	job_type: string[];
	hours_per_week: number;
	contract_period: number;
	contract_period_unit: string;
	contract_amount: Nullable<string>;
	internship_period: Nullable<string>;
	internship_period_unit: Nullable<string>;
	internship_amount: Nullable<string>;
	temporary_period: Nullable<string>;
	temporary_period_unit: Nullable<string>;
	temporary_amount: Nullable<string>;
	display_salary: boolean;
	currency: string;
	salary_type: string;
	salary_currency: string;
	salary_rate: string;
	salary_amount: number;
	salary_starting_amount: Nullable<number>;
	salary_maximum_amount: Nullable<number>;
	job_requirements: string;
	job_description: string;
	job_benefits: string;
	urgency: Nullable<string>;
	min_days_urgency: Nullable<string>;
	max_days_urgency: Nullable<string>;
	skill_tags: string[];
	last_active_employer: Nullable<string>;
	posting_timestamp: string;
	applicants: number;
	is_applied: boolean;
	is_bookmarked: boolean;
	is_submitted: boolean;
	status?: string;
}

const PreviewJobAd = () => {
	const toast = useToast();
	const history = useHistory();
	const [jobDetail, setJobDetail] = useState({} as JobDetailType);
	const jobIdFromUrl = (queryString.parse(history.location.search)?.job_ad ||
		null) as Nullable<string>;
	const ENV_NAME = useEnvironment();

	const PERIOD_UNIT = {
		per_hour: Variable.PER_HOUR[ENV_NAME],
		per_day: Variable.PER_DAY[ENV_NAME],
		per_week: Variable.PER_WEEK[ENV_NAME],
		per_month: Variable.PER_MONTH_2[ENV_NAME],
		per_year: Variable.PER_YEAR[ENV_NAME],
	};
	const JOB_TYPE = {
		full_time: Variable.FULL_TIME_LABEL[ENV_NAME],
		part_time: Variable.PART_TIME_LABEL[ENV_NAME],
		freelance: Variable.FRELANCE_LABEL[ENV_NAME],
		contract: Variable.CONTRACT_LABEL[ENV_NAME],
		internship: Variable.INTERNSHIP_LABEL[ENV_NAME],
		temporary: Variable.TEMPORARY_LABEL[ENV_NAME],
	};
	const EXPERIENCE_LEVEL = {
		entry_level: Variable.ENTRY_LEVELLABEL[ENV_NAME],
		associate: Variable.ASSOCIATE_LABEL[ENV_NAME],
		intermediate: Variable.INTERMEDIATE[ENV_NAME],
		senior: Variable.SENIOR[ENV_NAME],
		director: Variable.DIRECTOR_LABEL[ENV_NAME],
		executive: Variable.EXECUTIVE_LABEL[ENV_NAME],
	};
	const WORK_MODEL = {
		onsite: Variable.ONSITE_LABEL[ENV_NAME],
		hybrid: Variable.HYBRID_LABEL[ENV_NAME],
		remote: Variable.REMOTE_LABEL[ENV_NAME],
	};

	const getJobDetail = async () => {
		const res = await makeRequest(
			generateRequestOptions("getJobAd", { urlParams: jobIdFromUrl }),
		);

		if (res?.code === 200) setJobDetail(res.data);
		else toast.error(res.message);
	};

	useEffect(() => {
		if (jobIdFromUrl) getJobDetail();
	}, [jobIdFromUrl]);

	return (
		<div className="jc">
			<div className="jc-wrapper">
				{/* Title & City */}
				<div className="form-block">
					<div className="preview-job">
						<div className="jb-cont mb-sm">
							<div className="jl-company-logo mb-md">
								<Image src={jobDetail?.company?.company_logo} />
							</div>
							<h3 className="jl-job-name mb-sm">{jobDetail?.job_title}</h3>
							<h4 className="jl-company-name mb-sm">{jobDetail?.company?.name}</h4>
							<div className="jl-city-name mb-sm">{`${jobDetail?.posting_location_city}, ${jobDetail?.posting_location_district}`}</div>
							<div className="mb-std font-weight-semibold">
								{`${getCurrencySymbol(
									jobDetail?.currency || "IDR",
									getLocale(ENV_NAME),
								)} ${salaryRangeDesc(jobDetail)}
							${jobDetail?.salary_rate ? PERIOD_UNIT[jobDetail?.salary_rate] : ""}
							`}
							</div>
							<div className="mb-std d-flex align-items-center flex-wrap">
								{formatString(
									Variable.POSTED_AGO[ENV_NAME],
									Variable.POSTED_DAY_AGO[ENV_NAME],
								)}
								<div className="mx-xxs">|</div>
								{formatString(
									Variable.URGENCY[ENV_NAME],
									capitalize(jobDetail?.urgency) ||
										`1 - 3 ${Variable.DAYS_2[ENV_NAME].toLowerCase()}`,
								)}
								<div className="mx-xxs">|</div>
								{formatString(
									Variable.APPLICANTS[ENV_NAME],
									jobDetail?.applicants || 280,
								)}
								<div className="mx-xxs">|</div>
								<span className="light-text">
									{Variable.OPEN_IN_NEW_TAB[ENV_NAME]}
								</span>
							</div>
							<div className="d-flex align-items-center gap-xs">
								<Button
									type="primary"
									title={Variable.APPLY_NOW[ENV_NAME]}
									size="sm"
									onClick={() => {}}
								/>
								<Button
									type="outline"
									title={Variable.ADD_TO_COMPARE[ENV_NAME]}
									size="sm"
									onClick={() => {}}
								/>
								<div className="J-cardComp-btn cursorPointer">
									<Button
										type="outline"
										title={Variable.SAVE_LABEL[ENV_NAME]}
										size="sm"
										onClick={() => {}}
										customIcon={<IoBookmarkOutline size={20} color="#193560" />}
									/>
								</div>
							</div>
						</div>
						<div className="divider show-mobile" />
						<div className="jb-cont mb-lg">
							<h4 className="text-lg text-label-black font-weight-semibold mb-std">
								{Variable.FULL_JOB_DESCRIPTION[ENV_NAME]}
							</h4>
							<h3 className="text-md text-label-black font-weight-semibold mb-sm">
								{Variable.QUALIFICATIONS[ENV_NAME]}
							</h3>
							<p
								className="mb-std text-sm"
								dangerouslySetInnerHTML={{
									__html: jobDetail?.job_requirements,
								}}
							/>
							<h3 className="text-md text-label-black font-weight-semibold mb-sm">
								{Variable.RESPONSIBILITIES[ENV_NAME]}
							</h3>
							<p
								className="mb-std text-sm"
								dangerouslySetInnerHTML={{
									__html: jobDetail?.job_description,
								}}
							/>
							<h3 className="text-md text-label-black font-weight-semibold mb-sm">
								{Variable.JOB_BENEFIT[ENV_NAME]}
							</h3>
							<p
								className="mb-std text-sm"
								dangerouslySetInnerHTML={{
									__html: jobDetail?.job_benefits,
								}}
							/>
							<div className="divider" />
							<h4 className="text-lg text-label-black font-weight-semibold mb-std">
								{Variable.JOB_DETAILS[ENV_NAME]}
							</h4>
							<div className="job-info-details-grid">
								<div className="job-info-details-item">
									<h3 className="text-md text-label-black font-weight-semibold mb-sm">
										{Variable.JOB_TYPE_LABEL[ENV_NAME]}
									</h3>
									<p className="text-sm">
										{capitalize(
											jobDetail?.job_type?.map((job) => JOB_TYPE[job]),
										) || "-"}
									</p>
								</div>
								<div className="job-info-details-item">
									<h3 className="text-md text-label-black font-weight-semibold mb-sm">
										{Variable.EXPERIENCE_LEVEL_LABEL[ENV_NAME]}
									</h3>
									<p className="text-sm">
										{EXPERIENCE_LEVEL[jobDetail?.experience_level]}
									</p>
								</div>
								<div className="job-info-details-item">
									<h3 className="text-md text-label-black font-weight-semibold mb-sm">
										{Variable.WORK_MODE_LABEL[ENV_NAME]}
									</h3>
									<p className="text-sm">
										{WORK_MODEL[jobDetail?.work_model] || "-"}
									</p>
								</div>
							</div>
							<div className="divider" />
							<div className="d-flex align-items-center gap-xs">
								<Button
									type="outline"
									title={Variable.MORE_JOB_SEARCH[ENV_NAME]}
									size="sm"
									onClick={() => {}}
								/>

								<Button
									type="textGray"
									title={Variable.REPORT[ENV_NAME]}
									size="sm"
									onClick={() => {}}
								/>
							</div>
						</div>
						<Button
							type="primary"
							size="sm"
							title={Variable.BACK_TO_JOB_AD[ENV_NAME]}
							onClick={() => history.push(`/job-ads-list?status=${jobDetail.status}`)}
							className="mt-2"
							btnClassName="customBtn btn-save"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PreviewJobAd;
