import { FC } from 'react'
import { IconProps } from './IconTypes'

const WhatsappIcon: FC<IconProps> = ({
    width = "14",
    height = "14",
    color = "#32D951"
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 668 668" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="334" cy="334" r="334" fill={color} />
            <g clip-path="url(#clip0_455_39900)">
            <path
                d="M428.645 363.431C427.876 363.062 399.121 348.902 394.011 347.063C391.925 346.313 389.69 345.582 387.313 345.582C383.43 345.582 380.168 347.517 377.628 351.318C374.755 355.588 366.06 365.752 363.373 368.789C363.022 369.19 362.543 369.669 362.256 369.669C361.999 369.669 357.548 367.836 356.201 367.251C325.354 353.851 301.94 321.629 298.73 316.195C298.271 315.414 298.252 315.059 298.248 315.059C298.361 314.646 299.398 313.606 299.934 313.069C301.5 311.52 303.197 309.477 304.838 307.501C305.616 306.565 306.394 305.627 307.159 304.744C309.541 301.973 310.601 299.821 311.831 297.329L312.475 296.034C315.477 290.07 312.913 285.037 312.084 283.412C311.404 282.052 299.262 252.748 297.972 249.67C294.867 242.241 290.765 238.782 285.065 238.782C284.536 238.782 285.065 238.782 282.847 238.875C280.146 238.989 265.438 240.926 258.935 245.025C252.039 249.373 240.372 263.232 240.372 287.605C240.372 309.541 254.293 330.253 260.269 338.13C260.418 338.329 260.691 338.732 261.086 339.311C283.975 372.738 312.509 397.511 341.435 409.066C369.283 420.19 382.47 421.476 389.967 421.476H389.968C393.118 421.476 395.64 421.229 397.864 421.01L399.276 420.875C408.896 420.022 430.037 409.068 434.845 395.704C438.634 385.178 439.632 373.678 437.112 369.505C435.386 366.667 432.411 365.239 428.645 363.431Z"
                fill="white"
            />
            <path
                d="M337.5 136.781C230.674 136.781 143.765 223.037 143.765 329.061C143.765 363.352 152.942 396.919 170.326 426.297L137.051 524.452C136.432 526.282 136.893 528.306 138.246 529.684C139.223 530.681 140.549 531.219 141.903 531.219C142.421 531.219 142.944 531.141 143.453 530.978L245.802 498.455C273.81 513.419 305.465 521.318 337.502 521.318C444.317 521.32 531.218 435.072 531.218 329.061C531.218 223.037 444.317 136.781 337.5 136.781ZM337.5 481.266C307.355 481.266 278.158 472.562 253.06 456.093C252.216 455.538 251.236 455.254 250.25 455.254C249.729 455.254 249.206 455.333 248.698 455.495L197.427 471.792L213.978 422.962C214.514 421.382 214.246 419.639 213.26 418.292C194.148 392.177 184.045 361.323 184.045 329.061C184.045 245.123 252.884 176.834 337.499 176.834C422.103 176.834 490.935 245.123 490.935 329.061C490.937 412.988 422.106 481.266 337.5 481.266Z"
                fill="white"
            />
            </g>
            <defs>
                <clipPath id="clip0_455_39900">
                <rect width="394.438" height="394.438" fill="white" transform="translate(136.781 136.781)"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default WhatsappIcon