import cx from 'classnames';

import { FunctionComponent, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { RootState } from "store/types/RootStateTypes";
import { useSelector } from "react-redux";
import { PiWarningBold } from "react-icons/pi";
import { useDropzone, FileRejection } from "react-dropzone";
import { isEqual } from "lodash";
import { PiRepeatBold } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";

import { generateRequestOptions } from "shared/utils/apiEndPoints"
import { VerificationCompanyType } from "component/JobAdListing/types/types";

import useFetch from "shared/hooks/useFetch";
import makeRequest from "shared/utils/request";
import DefaultUser from "assets/image/user.png";
import useTranslate from "shared/hooks/useTranslate";
import ChecklistIcon from "assets/icons/ChecklistIcon";
import ModalConfirmation from "shared/component/ModalConfirmation";
import WaitingConfirmationIcon from "assets/icons/WaitingConfirmationIcon";
import SuccessIcon from '../../assets/svg/success_iconn.svg'
import { Data } from "./types";

import "./style.scss";
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { PROFILE } from 'shared/utils/routeLink';
import { PiWarningCircleBold } from "react-icons/pi";
import useToast from 'shared/hooks/useToast';

export const DEFAULT_COMPANY_DOCS = {
    npwp: "",
    tdp: "",
    verification_status: "",
    message: ""
}

export interface ICompanyDocs {
    npwp: string | null,
    tdp: string | null,
    verification_status: string,
    message: string | null,
}

const CompanyManagement: FunctionComponent = (): ReactElement => {
    const t = useTranslate();
    const toast = useToast();
    const { userDetails } = useSelector((state: RootState) => state?.auth);
	// const { data: dataVerification } = useFetch<VerificationCompanyType>("getCompanyVerification");

    const [isVerified, setIsVerified] = useState(false)
    // const [isDisableButton, setIsDisableButton] = useState(true);
    const [companyLogo, setCompanyLogo] = useState<string | null>(null)
    const [showModalUpload, setModalUpload] = useState<boolean>(false);
    const [showModalDelete, setModalDelete] = useState<boolean>(false);

    const history = useHistory()
    const backToProfile = () => history.push(PROFILE)
    const uploadLogoRef = useRef(null);

    const [data, setData] = useState<null | Data>(null);
    const [templateFileTDP, setTemplateFileTDP] = useState<File | null>(null);
    const [templateFileNPWP, setTemplateFileNPWP] = useState<File | null>(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [successModal, setSuccessModal] = useState(false)

    const [companyDocs, setCompanyDocs] = useState<ICompanyDocs>(DEFAULT_COMPANY_DOCS)

    const { acceptedFiles: acceptedFilesNPWP, getRootProps: getRootPropsNPWP, getInputProps: getInputPropsNPWP } = useDropzone({
        accept: {
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            "text/plain": [".txt"],
            "application/rtf": [".rtf"],
            "application/pdf": [".pdf"],
            "image/jpg": [".jpg"],
            "image/jpeg": [".jpeg"],
            "image/png": [".png"],
        },
        multiple: false,
        maxFiles: 1,
        maxSize: 5000000,
        onDrop: (_, fileRejections: FileRejection[]) => onFileReject(_, fileRejections),
    });
    const { acceptedFiles: acceptedFilesTDP, getRootProps: getRootPropsTDP, getInputProps: getInputPropsTDP } = useDropzone({
        accept: {
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            "text/plain": [".txt"],
            "application/rtf": [".rtf"],
            "application/pdf": [".pdf"],
            "image/jpg": [".jpg"],
            "image/jpeg": [".jpeg"],
            "image/png": [".png"],
        },
        multiple: false,
        maxFiles: 1,
        maxSize: 5000000,
        onDrop: (_, fileRejections: FileRejection[]) => onFileReject(_, fileRejections),
    });

    const onFileReject = (_: File[], fileRejections: FileRejection[]) => {
        if (fileRejections.length > 0) {
            fileRejections?.[0]?.errors.forEach((i) => {
                if (i.code === "file-invalid-type") {
                    return toast.error(t("FILE_UPLOAD_INVALID"));
                }
                if (i.code === "file-too-large") {
                    return toast.error(t("FILE_UPLOAD_TOO_LARGE"));
                }
            });
            return;
        }
    };

    const getCompanyData = useCallback( async () => {
        try {
            const { code, error, data } = await makeRequest({
                    ...generateRequestOptions("getCompany", {
                    urlParams: userDetails?.company?.id
                })
            })
            if (code === 200 && error === false) {
                setData(data)
            } else {
                toast.error(t("RESOURCE_NOT_FOUND"))
            }
        } catch (err) {
            toast.error(t("RESOURCE_NOT_FOUND"))
        }
    }, [userDetails])

    const getCompanyVerification = useCallback(async() => {
        try {
            const { code, error, data, message } = await makeRequest({
                ...generateRequestOptions("getCompanyVerification")
            })
            if (code === 404 && error === true && message === "Verification data not found.") {
                setIsVerified(false)
            }
            if (code === 200 && error === false) {
                setCompanyDocs(prev => ({ ...prev, npwp: data.tax_card_image, tdp: data.business_or_registration_license, verification_status: data.verification_status, message: data.messages }))
                if (data.verification_status === "approved") {
                    setIsVerified(true)
                }
            }
        } catch (err) {
            toast.error(t("RESOURCE_NOT_FOUND"))
        }
    }, [])

    const uploadNPWP = async () => {
        if (templateFileNPWP) {
            let requestData = new FormData()
            requestData.append("tax_card_image", templateFileNPWP)
            const url = {
                ...generateRequestOptions("addTaxCardCompany"),
                body: requestData,
                json: true,
            };
            try {
                const { code, error } = await makeRequest(url)
                if (code === 200 && error === false) {
                    // toast.success(t("NPWP_UPLOADED"))
                    return true
                }
            } catch (err) {
                toast.error(t("RESOURCE_NOT_FOUND"))
            }
        }
    }
    const uploadTDP = async () => {
        if (templateFileTDP) {
            let requestData = new FormData()
            requestData.append("business_or_registration_license", templateFileTDP)
            const url = {
                ...generateRequestOptions("addBusinessLicenceCompany"),
                body: requestData,
                json: true,
            };
            try {
                const { code, error } = await makeRequest(url)
                if (code === 200 && error === false) {
                    // toast.success(t("TDP_UPLOADED"))
                    return true
                }
            } catch (err) {
                toast.error(t("RESOURCE_NOT_FOUND"))
            }
        }
    }

    const handleUpdateRejectedFile = async () => {
        if (!templateFileNPWP && !templateFileTDP) {
            toast.error(t("NO_FILE_CHANGED"))
        }
        let requestData = new FormData();

        if (templateFileNPWP) {
            requestData.append("tax_card_image", templateFileNPWP)
        }
        if (templateFileTDP) {
            requestData.append("business_or_registration_license", templateFileTDP)
        }
        try {
            const { code, error } = await makeRequest({
                ...generateRequestOptions("updateFileCompanyVerification"),
                body: requestData,
                json: true,
            })
            if (code === 200) {
                setSuccessModal(true)
            }
        } catch (err) {
            toast.error(t("RESOURCE_NOT_FOUND"))
        }
    }

    const handleVerification = async () => {
        if (companyDocs.verification_status === 'rejected') {
            return handleUpdateRejectedFile();
        }
        if (!templateFileNPWP || !templateFileTDP) {
            return
        } else {
            const npwp = await uploadNPWP()
            if (npwp) {
                const tdp = await uploadTDP()
                if (tdp) {
                    await getCompanyVerification()
                    setSuccessModal(true)
                }
            }
            return;
        }
        // await uploadNPWP()
        // await uploadTDP()
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const maxSize = 5 * 1024 * 1024;
        if (file?.size > maxSize) {
            setModalUpload(false);
            toast.error(t("FILE_ONLY_5MB"));
            return;
        }
        setSelectedFile(file);
    };

    const handleUploadLogo = () => {
        (uploadLogoRef as any)?.current?.click?.();
    }

    const onFileUploaded = async () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append("company_logo", selectedFile);

            try {
                const res = await makeRequest({
                    ...generateRequestOptions("companyUpdate"),
                    json: true,
                    body: formData,
                });

                setCompanyLogo(res?.data?.company_logo);
                toast.success(t("FILE_UPLOADED_SUCCESS"));
            } catch (error) {
                toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
            } finally {
                setModalUpload(false);
            }
        } else {
            toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
        }
    };

    const handleDeleteInMOdals = () => {
        setModalUpload(false);
        setModalDelete(true);
    }

    const handleDeleteLogo = async () => {
        try {
            const formData = new FormData();
            formData.append("company_logo", "");

            const res = await makeRequest({
                ...generateRequestOptions("companyUpdate"),
                json: true,
                body: formData,
            });

            if (res.code === 200) {
                setCompanyLogo(null)
                setSelectedFile(null)
                toast.success(t("FILE_DELETED_SUCCESS"));
            } else {
                toast.error(res?.message || t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
            }
        } catch (error) {
            console.error("Gagal mengunggah file:", error);
            toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
        } finally {
            setModalDelete(false);
        }
    }

    const npwpName = (): string | undefined => {
        if (companyDocs.npwp) {
            const arr = companyDocs.npwp?.split('.')
            const filename = arr[arr.length - 2].split('/')[arr[arr.length - 2].split('/').length - 1]
            const ext = arr[arr.length - 1]
            return `${filename}.${ext}`
        } else {
            return undefined
        }
    }

    const tdpName = (): string | undefined => {
        if (companyDocs.tdp) {
            const arr = companyDocs.tdp.split('.')
            const filename = arr[arr.length - 2].split('/')[arr[arr.length - 2].split('/').length - 1]
            const ext = arr[arr.length - 1]
            return `${filename}.${ext}`
        } else {
            return undefined
        }
    }

    useEffect(() => {
        setCompanyLogo(userDetails?.company_detail?.company_logo)
    }, [userDetails?.company_detail?.company_logo])

    // useEffect(() => {
    //     let newValue = true
    //     if (!isVerified && templateFileNPWP !== null) {
    //         newValue = false
    //     }
    //     setIsDisableButton(newValue)
    // }, [isVerified, templateFileNPWP])

    useEffect(() => {
        getCompanyData()
    }, [getCompanyData])

    useEffect(() => {
        getCompanyVerification()
    }, [getCompanyVerification])

    useEffect(() => {
        if (acceptedFilesNPWP?.length > 0 && !isEqual(acceptedFilesNPWP, templateFileNPWP)) {
            setTemplateFileNPWP(acceptedFilesNPWP?.[0]);
        }
    }, [acceptedFilesNPWP]); // eslint-disable-line

    useEffect(() => {
        if (acceptedFilesTDP?.length > 0 && !isEqual(acceptedFilesTDP, templateFileTDP)) {
            setTemplateFileTDP(acceptedFilesTDP?.[0]);
        }
    }, [acceptedFilesTDP]); // eslint-disable-line


    return (
        <>
            <div className="company_management">
                <ModalConfirmation
                    title={t("LOGO_COMPANY")}
                    type="success"
                    show={showModalUpload}
                    showIcon={false}
                    dialogClassName="company_management__modal-wrapper"
                    handleClose={() => setModalUpload(false)}
                    handleConfirm={onFileUploaded}
                    disabled={!selectedFile}
                >
                    <div className="wrapper">
                        <div className="profile_photo">
                            <div>
                                <div>
                                    <img src={selectedFile ? URL.createObjectURL(selectedFile) : (companyLogo || DefaultUser)} alt="Logo" />
                                </div>

                                <span>{t("IMAGE_MAXIMUM_FIVE_MB")}</span>
                            </div>
                            <div>
                                <input ref={uploadLogoRef} type="file" onChange={handleFileChange} accept="image/*" />
                                <p onClick={handleUploadLogo}><PiRepeatBold /> {t("UPLOAD_LOGO")}</p>
                                {companyLogo && (
                                    <p onClick={handleDeleteInMOdals}>
                                        <FaRegTrashAlt /> {t("DELETE_FILE")}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </ModalConfirmation>

                <ModalConfirmation
                    title={t("DIALOG_CONFIRM_TITLE")}
                    type="error"
                    show={showModalDelete}
                    showIcon={true}
                    handleClose={() => setModalDelete(false)}
                    handleConfirm={handleDeleteLogo}
                    confirmLabel={t("DELETE_BTN_LABEL")}
                />

                <div className="company_management_container">
                    <h3>{t("COMPANY_ACCOUNT_STATUS")}</h3>
                    <div className="wrapper">
                        <h4>{t("LOGO_COMPANY")}</h4>
                        <div className="profile_photo">
                            <div>
                                <img src={companyLogo || DefaultUser} alt="Default" />
                            </div>
                            <div>
                                <p onClick={() => setModalUpload(true)}><PiRepeatBold /> {t("UPLOAD_LOGO")}</p>
                                {companyLogo && (
                                    <p onClick={() => setModalDelete(true)}>
                                        <FaRegTrashAlt /> {t("DELETE_FILE")}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="wrapper">
                        <p>{data?.name}</p>
                        {
                            !isVerified && (
                                <div className="notif">
                                    <p className='warning'>
                                        <span><PiWarningBold /></span>
                                        {t("NPWP_TDP_WARNING")}
                                    </p>
                                    {
                                        companyDocs.verification_status === 'rejected' && (
                                            <p className='reject'>
                                                <span><PiWarningCircleBold /></span>
                                                {companyDocs.message ? companyDocs.message : 'No messages provided from the backend team'}
                                            </p>
                                        )
                                    }
                                </div>
                            )
                        }
                        <hr />
                        <div className="form">
                            <div className="label">
                                <span className="label">NPWP</span>
                                {
                                    (companyDocs.npwp && companyDocs.verification_status === "waiting confirmation") && (
                                        <div>
                                            <span className="status">{t("WAITING_CONFIRMATION")}</span>
                                            <WaitingConfirmationIcon />
                                        </div>
                                    )
                                }
                                {
                                    (companyDocs.tdp && companyDocs.verification_status === "approved") && (
                                        <div>
                                            <span className="status status--success">{t("APPROVED")}</span>
                                            <ChecklistIcon />
                                        </div>
                                    )
                                }
                            </div>
                            <div {...((companyDocs.verification_status !== "approved" && companyDocs.verification_status !== "waiting confirmation") ? getRootPropsNPWP() : {})} className={cx("form-control", { "status-approved": companyDocs.verification_status === "approved" })}>
                            {
                                !templateFileNPWP && !companyDocs?.npwp ? (
                                    <>
                                        <input {...getInputPropsNPWP()} />
                                        <p className="drag-choose">
                                            {t("DRAG_FILE_HERE")}
                                            <span>{t("CHOOSE_FILE")}</span>
                                        </p>
                                        <div className="file-allowed">
                                            {t("FILE_ALLOWED")} <br /> {t("MAX_20MB")}
                                        </div>
                                    </>
                                ) : (
                                    <div className={`has-file ${companyDocs.npwp ? 'exists' : ''}`}>
                                        <span>{templateFileNPWP?.name || npwpName() || companyDocs?.npwp}</span>
                                        {(companyDocs?.verification_status !== "approved" && companyDocs.verification_status !== "waiting confirmation") && (
                                            <p>{t("REPLACE_FILE")}</p>
                                        )}
                                    </div>
                                )
                            }
                            </div>
                        </div>
                        <hr />
                        <div className="form">
                            <div className="label">
                                <span className="label">TDP/NIB/SIUP</span>
                                {
                                    (companyDocs.tdp && companyDocs.verification_status === "waiting confirmation") && (
                                        <div>
                                            <span className="status">{t("WAITING_CONFIRMATION")}</span>
                                            <WaitingConfirmationIcon />
                                        </div>
                                    )
                                }
                                {
                                    (companyDocs.tdp && companyDocs.verification_status === "approved") && (
                                        <div>
                                            <span className="status status--success">{t("APPROVED")}</span>
                                            <ChecklistIcon />
                                        </div>
                                    )
                                }
                            </div>

                            <div {...((companyDocs.verification_status !== "approved" && companyDocs.verification_status !== "waiting confirmation") ? getRootPropsTDP() : {})} className={cx("form-control", { "status-approved": companyDocs.verification_status === "approved" })}>
                                {
                                    !templateFileTDP && !companyDocs?.tdp ? (
                                        <>
                                            <input {...getInputPropsTDP()} />
                                            <p className="drag-choose">
                                                {t("DRAG_FILE_HERE")}
                                                <span>{t("CHOOSE_FILE")}</span>
                                            </p>
                                            <div className="file-allowed">
                                                {t("FILE_ALLOWED")} <br /> {t("MAX_20MB")}
                                            </div>
                                        </>
                                    ) : (
                                        <div className={`has-file ${companyDocs.tdp ? 'exists' : ''}`}>
                                            <span>{templateFileTDP?.name || tdpName() || companyDocs?.tdp}</span>
                                            {(companyDocs?.verification_status !== "approved" && companyDocs.verification_status !== "waiting confirmation") && (
                                                <p>{t("REPLACE_FILE")}</p>
                                            )}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {!companyDocs?.verification_status ?
                            <>
                                <hr />
                                <button onClick={handleVerification}>{t("REQUEST_VERIFICATION")}</button>
                            </>
                         : ""}
                    </div>
                    <div className="wrapper">
                        <h4>{t("COMPANY_PROFILE")}</h4>
                        <div className="company_profile">
                            {t("COMPANY_PROFILE_DESC")}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={successModal}
                dialogClassName="success_popup"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                    <Modal.Body>
                        <div className="top">
                            <img src={SuccessIcon} alt="Success" />
                        </div>
                        <div className="content">
                            <p>{t("REQUEST_VERIFICATION_SUCCESS")}</p>
                            <span>{t("DOCUMENT_CHECKED")}</span>
                        </div>
                        <div className="bottom">
                            <button onClick={backToProfile}>Ok</button>
                        </div>
                    </Modal.Body>
            </Modal>
        </>
    )
}

export default CompanyManagement