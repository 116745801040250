import { PiCheck, PiPlusLight } from "react-icons/pi";

interface Props {
	label: string;
	isSelected: boolean;
	isDisabled?: boolean;
	onClick: () => void;
}

const Badge = ({ label, isSelected, isDisabled, onClick }: Props) => {
	return (
		<div
			className={`badge ${isDisabled ? "badge-disabled" : isSelected && "badge-selected"}`}
			onClick={onClick}
		>
			{isSelected && !isDisabled ? (
				<PiCheck size={18} className="icon" />
			) : (
				<PiPlusLight size={18} className="icon" />
			)}
			<p>{label}</p>
		</div>
	);
};

export default Badge;
