import React from "react";
import "./Checkbox.scss";
import clx from "classnames";

type CheckboxProps = {
	checked: boolean;
	disabled: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	id?: string;
} & React.ComponentPropsWithoutRef<"input">;

const Checkbox = ({ checked, disabled, onChange, className, id, ...props }: CheckboxProps) => {
	return (
		<label className={clx("checkbox__container-checkbox", className)}>
			<input
				type="checkbox"
				id={id}
				checked={checked}
				onChange={onChange}
				disabled={disabled}
				{...props}
			/>
			<span className="checkbox__container-checkbox_checkmark"></span>
		</label>
	);
};

export default Checkbox;
