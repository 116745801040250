import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/";
import queryString from "query-string";
import { PiArrowLeft } from "react-icons/pi";

import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import { Button } from "../../shared/component";
import { RenderIf } from "../../shared/utils/common";
import { capitalize } from "../../shared/utils/stringUtils";

import Form from "./Reusable/Form";
import Badge from "./Reusable/Badge";
import CustomPreviewModal from "./Modal/CustomPreviewModal";
import PreScreenQuestion from "./PreScreenQuestion";

import { ENV, Nullable } from "../../types";
import useTranslate from "shared/hooks/useTranslate";
import useToast from "shared/hooks/useToast";

interface Props {
	ENV_NAME: ENV;
	jobAdId: Nullable<number>;
	companyName: string;
	onNext: () => void;
	onPrev: () => void;
}

export interface Question {
	id: number;
	question_text: string;
	is_active: Nullable<boolean>;
	mcq_choices: string[];
	mandatory_choices: string[];
	is_mandatory: Nullable<boolean>;
	fill_in_the_blanks_options: string[];
	boolean_accepted_answer: Nullable<boolean>;
	question_category: string;
	question_type: string;
	numeric_accepted_answer: Nullable<number>;
	max_numeric_value: Nullable<boolean>;
	min_numeric_value: Nullable<boolean>;
	job_ad: Nullable<number> | string;
	is_preloaded?: Nullable<boolean>;
	created_by?: number;
	company?: Nullable<number>;

	// custom properties
	is_custom?: boolean;
	limit_type?: string;
	is_published?: boolean;
	status?: string;
}

export interface QuestionResponse {
	id?: number;
	question_type: string;
	question_text: string;
	question_category: string;
	is_mandatory: boolean;
	is_preloaded: boolean;
	job_ad: Nullable<number> | string;

	mcq_choices?: (string | number)[];
	mandatory_choices?: (string | number)[];
	boolean_accepted_answer?: Nullable<boolean>;
	numeric_accepted_answer?: Nullable<number>;
	max_numeric_value?: Nullable<boolean>;
	min_numeric_value?: Nullable<boolean>;
}

export interface QuestionError {
	[key: number]: boolean;
}

interface PreviewModal {
	show: boolean;
	data: {
		question: Question;
	} | null;
}

interface Category {
	id: number;
	category_en: string;
	category_id: string;
}

const QUESTION_TYPE = [
	"mcq", // checbox
	"fill_in_the_blanks", // radio
	"short_answer", // textarea
	"true_false", // radio
	"numeric", // numeric
	"progressive", //radio
	"single_select", //radio
];

const PreScreening = ({ ENV_NAME, jobAdId, companyName, onNext, onPrev }: Props) => {
	const t = useTranslate();
	const toast = useToast();
	const history = useHistory();
	const jobIdFromUrl = (queryString.parse(history.location.search)?.job_ad ||
		null) as Nullable<string>;
	const status = (queryString.parse(history.location.search)?.status || null) as Nullable<string>;

	const DEFAULT_QUESTION: Question = {
		id: Date.now(),

		question_text: "",
		question_category: "",
		question_type: "",
		job_ad: jobAdId || jobIdFromUrl || "",

		is_active: null,
		mcq_choices: [],
		mandatory_choices: [],
		is_mandatory: false,
		fill_in_the_blanks_options: [],
		boolean_accepted_answer: null,
		numeric_accepted_answer: null,
		max_numeric_value: null,
		min_numeric_value: null,
		is_custom: true,
		limit_type: "",
	};

	const QUESTIONS_CUSTOM_CAT = {
		id: "custom",
		question_category: t("CREATE_CUSTOM_QUES"),
	};

	const [categories, setCategories] = useState<Category[]>([]);
	const [preloadedQuestions, setPreloadedQuestions] = useState<Question[]>([]);
	const [questions, setQuestions] = useState<Question[]>([]);
	const [newQuestions, setNewQuestions] = useState<number[]>([]);
	const [editingQuestion, setEditingQuestion] = useState<number[]>([]);
	const [selectedQuestions, setSelectedQuestions] = useState<(string | number)[]>([]);
	const [isEdited, setIsEdited] = useState(false);
	const [errors, setErrors] = useState<QuestionError>({});
	const [showPreviewModal, setShowPreviewModal] = useState<PreviewModal>({
		show: false,
		data: null,
	});

	useEffect(() => {
		if (jobAdId || jobIdFromUrl) {
			getPreloadedQuestions();
			getPrescreenQuestions();
			getCategories();
		}
	}, [jobAdId, jobIdFromUrl]);

	const getCategories = async () => {
		const res = await makeRequest(generateRequestOptions("listPrescreeningCategories"));

		if (res?.code === 200) {
			setCategories(res.data);
		} else toast.error(res.message);
	};

	const getPreloadedQuestions = async () => {
		const res = await makeRequest(generateRequestOptions("listPreloadedPreScreenQuestions"));

		if (res?.code === 200) setPreloadedQuestions(res.data);
		else toast.error(res.message);
	};

	const getPrescreenQuestions = async () => {
		const res = await makeRequest(
			generateRequestOptions("listPreScreenQuestions", {
				urlParams: jobAdId || jobIdFromUrl,
			}),
		);

		if (res?.code === 200) {
			setQuestions(
				res.data.map((q) => ({
					...q,
					is_published: true,
					status,
					...(q?.question_type === "numeric"
						? { limit_type: q?.max_numeric_value ? "max" : "min" }
						: {}),
				})),
			);
		} else toast.error(res.message);
	};

	const handleChange = (
		e: ChangeEvent<HTMLFormElement>,
		id: number,
		type: Nullable<string> = null,
		name: Nullable<string> = null,
	) => {
		const questionsclone = [...questions];
		const question: Question | undefined = questionsclone.find(
			(question) => question?.id === id,
		);

		if (question) {
			// If we are modifying an exisiting question then it should be
			// added to editingQuestions array because for those questions we
			// need to call the update prescreening api
			// also, if the we need to classify whether we are changing a custom question or exisitng question
			if (!newQuestions.includes(question?.id))
				if (question?.is_custom && question?.is_published)
					setNewQuestions((prev) => [...new Set([...prev, question?.id])]);

			let mandatoryChoices = [...question?.mandatory_choices];

			switch (type) {
				case "textarea":
					question[name as keyof Question] = e as never;
					break;
				case "checkbox":
					question.is_mandatory = e.target.checked;
					break;
				case "mcq":
					if (mandatoryChoices.includes(e.target.value)) {
						mandatoryChoices = mandatoryChoices.filter(
							(choice) => choice !== e.target.value,
						);
					} else mandatoryChoices = [...mandatoryChoices, e.target.value];

					question.mandatory_choices = mandatoryChoices;
					break;
				case "single_select":
					question.mandatory_choices = [e.target.value];
					break;
				case "yes_no":
					question["boolean_accepted_answer"] =
						typeof e.target.value === "string"
							? e.target.value === t("YES")
								? true
								: e.target.value === t("NO")
								? false
								: e.target.value === "" && null
							: null;

					break;
				case "numberWithoutDecimal":
					question["numeric_accepted_answer"] = /^(\d*)$/.test(e.target.value)
						? e.target.value
						: question["numeric_accepted_answer"];
					break;
				case "numberWithDecimal":
					question["numeric_accepted_answer"] = /^(\d*(\.\d*)?|\.\d+)$/.test(
						e.target.value,
					)
						? e.target.value
						: question["numeric_accepted_answer"];
					break;
				default:
					question[e.target.name as keyof Question] = e.target.value as never;
			}

			setQuestions(questionsclone);
			setErrors((prev) => ({ ...prev, [id]: false }));
			setIsEdited(true);
		}
	};

	const handleUpdateChoices = (
		id: number,
		choices: string[],
		type: ("mcq_choices" | "mandatory_choices") | null = null,
	) => {
		const questionsClone = [...questions];
		const question = questionsClone.find((question) => question?.id === id);

		if (question && type) {
			question[type] = choices;
			setQuestions(questionsClone);
		}
	};

	const handleQuestionSelect = (questionId: number | string) => {
		if (questionId === "custom") {
			setQuestions((prev) => [...prev, { ...DEFAULT_QUESTION }]);
			setNewQuestions((prev) => [...new Set([...prev, DEFAULT_QUESTION?.id])]);
			setIsEdited(true);
			return;
		}

		let selectedQuestionsClone = [...selectedQuestions];

		if (selectedQuestionsClone?.includes(questionId)) {
			// 1. Remove Selected Question
			selectedQuestionsClone = selectedQuestionsClone?.filter((job) => job !== questionId);

			// 2. Remove the question from the list
			setQuestions(questions?.filter((question) => question?.id !== questionId));
			setNewQuestions(newQuestions.filter((id: number) => id !== questionId));
		} else {
			// 1. Add selected question
			setNewQuestions((prev) => [...new Set([...prev, +questionId])]);
			selectedQuestionsClone = [...(selectedQuestionsClone || []), questionId];

			// 2. Add question to the list
			const question = preloadedQuestions.find((question) => question?.id === questionId);
			if (question) setQuestions((prev) => [...prev, question]);
		}

		setSelectedQuestions(selectedQuestionsClone);
	};

	const handleQuestionRemove = async (id: number) => {
		const question = questions.find((question) => question?.id === id);
		if (question?.is_preloaded || question?.is_custom)
			setQuestions(questions?.filter((question) => question?.id !== id));
		else {
			// delete the question
			const res = await makeRequest(
				generateRequestOptions("deletePrescreenQuestion", { urlParams: id }),
			);

			if (res?.code === 200) {
				setQuestions(questions?.filter((question) => question?.id !== id));
			} else toast.error(res.message);
		}
		setNewQuestions(newQuestions.filter((question) => question !== id));
	};

	const handleSave = async () => {
		if (!newQuestions.length && !editingQuestion.length) return onNext();

		const errors = {} as QuestionError;
		const questionsList = [
			...questions.filter((q) => newQuestions?.includes(q?.id)),
			...questions.filter((q) => editingQuestion?.includes(q?.id)),
		];

		questionsList.forEach((question) => {
			question.mandatory_choices = question?.mandatory_choices.filter((choice) =>
				question.mcq_choices?.includes(choice),
			);

			switch (question?.question_type) {
				case QUESTION_TYPE[3]:
					if (typeof question.boolean_accepted_answer !== "boolean")
						errors[question?.id] = true;
					break;
				case QUESTION_TYPE[4]:
					if (!question.numeric_accepted_answer) errors[question?.id] = true;
					break;
				case QUESTION_TYPE[0]:
				case QUESTION_TYPE[5]:
				case QUESTION_TYPE[6]:
					if (question?.mcq_choices.length <= 1 || !question?.mandatory_choices.length)
						errors[question?.id] = true;
					break;
				default:
					break;
			}
			if (!question.question_text) errors[question?.id] = true;
		});

		const hasError = Object.values(errors).some((err) => err);
		if (hasError) return setErrors(errors);

		const data: QuestionResponse[] = [];

		questionsList.forEach((question) => {
			// if the question is not a custom question and it is not a new question
			// then return from the function
			// we only want to pass new questions to the API
			if (
				!question?.is_custom &&
				!newQuestions.includes(question?.id) &&
				!editingQuestion.includes(question?.id)
			)
				return;

			const questionObj: QuestionResponse = {
				...(question?.is_preloaded || question?.is_published ? { id: question?.id } : {}),
				question_text: question?.question_text || "",
				question_category: question?.is_custom
					? "other"
					: question?.question_category || "",
				question_type: question?.question_type || "",
				job_ad: jobAdId || jobIdFromUrl || "",
				is_mandatory: question?.is_mandatory || false,
				is_preloaded: question?.is_preloaded || false,
			};

			switch (question?.question_type) {
				case QUESTION_TYPE[0]:
				case QUESTION_TYPE[5]:
				case QUESTION_TYPE[6]:
					questionObj["mcq_choices"] = question?.mcq_choices;
					questionObj["mandatory_choices"] = question?.mandatory_choices.filter(
						(choice) => question.mcq_choices?.includes(choice),
					);
					break;
				case QUESTION_TYPE[3]:
					questionObj["boolean_accepted_answer"] =
						typeof question?.boolean_accepted_answer === "string"
							? question?.boolean_accepted_answer === t("YES")
								? true
								: question?.boolean_accepted_answer === t("NO") && false
							: question?.boolean_accepted_answer;

					break;
				case QUESTION_TYPE[4]:
					if (question?.limit_type === "max") {
						questionObj["max_numeric_value"] = true;
						questionObj["min_numeric_value"] = false;
					} else {
						questionObj["max_numeric_value"] = false;
						questionObj["min_numeric_value"] = true;
					}
					questionObj["numeric_accepted_answer"] = question?.numeric_accepted_answer;
					break;
				default:
					break;
			}

			data.push(questionObj);
		});

		const preloadedQuestions = data.filter((question) => question.is_preloaded);
		const customQuestions = data.filter((question) => !question?.id);
		const editingQuestionsList = data.filter((question) =>
			editingQuestion.includes(question?.id || 0),
		);

		// copies the preloaded question
		let resPreloaded, resCustom, resEditing;

		if (preloadedQuestions.length) {
			resPreloaded = await makeRequest({
				...generateRequestOptions("copyPreloadQuestion"),
				data: preloadedQuestions,
			});
		}

		// create new prescreening question
		if (customQuestions.length) {
			resCustom = await makeRequest({
				...generateRequestOptions("createPreScreenQuestions"),
				data: customQuestions,
			});
		}

		// update pre screening questions
		if (editingQuestionsList.length) {
			const updatePromises = editingQuestionsList.map((question) => {
				return updatePrescreeningQuestion(question);
			});

			await Promise.all(updatePromises).then(() => (resEditing = { code: 200 }));
		}

		if (
			(resPreloaded && resPreloaded?.code === 200) ||
			(resCustom && resCustom?.code === 200) ||
			(resEditing && resEditing?.code === 200)
		)
			onNext();

		if (resPreloaded && resPreloaded?.code !== 200) toast.error(resPreloaded.message);
		if (resCustom && resCustom?.code !== 200) toast.error(resCustom.message);
	};

	const updatePrescreeningQuestion = async (question: QuestionResponse) => {
		const res = await makeRequest({
			...generateRequestOptions("updatePrescreeningQuestion", { urlParams: question?.id }),
			data: question,
		});

		if (res.code !== 200) return toast.error(res.message);
	};

	return (
		<div className="form-block prescreen">
			<Form title={t("PRESCREENING_HEAD")}>
				<div className="prescreen-header">
					<h3>{t("SCREENING_QUESTION")}</h3>
					<p>{t("QUESTION_RECOMMEND_TEXT")}</p>
				</div>
				<RenderIf condition={questions?.length}>
					<div className="questions">
						{questions?.map((question) => (
							<PreScreenQuestion
								key={question?.id}
								ENV_NAME={ENV_NAME}
								onChange={handleChange}
								question={question}
								isError={errors[question?.id]}
								isEditingQuestion={
									!question?.is_preloaded &&
									editingQuestion.includes(question?.id)
								}
								isDisabled={
									status === "active" &&
									!newQuestions.includes(question?.id) &&
									!editingQuestion.includes(question?.id)
								}
								onRemove={() => handleQuestionRemove(question?.id)}
								onUpdateChoices={handleUpdateChoices}
								onShowPreview={() =>
									(editingQuestion.includes(question?.id) ||
										question?.is_custom) &&
									setShowPreviewModal({ show: true, data: { question } })
								}
								onEditQuestion={(e: React.MouseEvent) => {
									e.stopPropagation();
									if (question?.status === "active" || question?.is_preloaded)
										return;
									setEditingQuestion((prev) => [
										...new Set([...prev, question?.id]),
									]);
								}}
								onUpdateErrors={() =>
									setErrors((p) => ({ ...p, [question?.id]: false }))
								}
							/>
						))}
					</div>
				</RenderIf>

				<div className="questions-type">
					<p>{t("ADD_SCREENING_QUESTION")}</p>
					<div className="job-type-container">
						{[
							...preloadedQuestions?.filter(
								(ques) =>
									ques.question_category && ques?.question_category !== "other",
							),
							QUESTIONS_CUSTOM_CAT,
						].map((cat) => (
							<Badge
								key={cat?.id}
								label={capitalize(cat?.question_category)}
								isSelected={false}
								isDisabled={
									cat?.id !== "custom" &&
									questions.some(
										(q: Question) =>
											q.question_category === cat?.question_category,
									)
								}
								onClick={() => handleQuestionSelect(cat?.id)}
							/>
						))}
					</div>
				</div>
			</Form>

			<div className="btn-grp">
				<Button
					type="outline"
					title={t("PREVIOUS_LABEL")}
					onClick={onPrev}
					btnClassName="customBtn"
					customIcon={<PiArrowLeft size={18} className="icon icon-prev" />}
				/>
				<Button
					type="primary"
					title={t("SAVE_CONTINUE_LABEL")}
					onClick={handleSave}
					btnClassName="customBtn btn-save"
					disabled={!isEdited && !jobIdFromUrl}
				/>
			</div>

			<CustomPreviewModal
				ENV_NAME={ENV_NAME}
				show={showPreviewModal?.show}
				question={showPreviewModal?.data?.question}
				companyName={companyName}
				onHide={() => setShowPreviewModal({ show: false, data: null })}
			/>
		</div>
	);
};

export default PreScreening;
