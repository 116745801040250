import { WarningCircle } from '@phosphor-icons/react';
import React from 'react';

export default function ErrorIcon({ width, height }) {
  return (
    <span className="formInputType icon password">
      <WarningCircle
        height={height ?? 24}
        width={width ?? 24}
        style={{ color: '#f04438' }}
      />
    </span>
  );
}
