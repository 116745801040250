import * as type from '../actions/jobCreation';

const initialState = {
  jobAdd: {
    job_title: '',
    posting_location_country: '',
    posting_location_city: '',
    posting_location_district: '',
    posting_location_other_location: '',
    work_model: '',
    experience_level: '',
    job_type: [],
    hours_per_week: 0,
    contract_period: 0,
    contract_period_unit: '',
    contract_amount: 0,
    internship_period: 0,
    internship_period_unit: '',
    internship_amount: 0,
    temporary_period: 0,
    temporary_period_unit: '',
    temporary_amount: 0,
    display_salary: true,
    salary_type: '',
    salary_currency: '',
    salary_rate: '',
    salary_amount: 0,
    salary_starting_amount: 0,
    salary_maximum_amount: 0,
    job_requirements: '',
    job_description: '',
    job_benefits: '',
    job_requirements_plain: '',
    job_description_plain: '',
    job_benefits_plain: '',
    urgency: '',
    skill_tags: [],
    hires: 0,
    form_percentage: 0,
    last_active_employer: '',
    employer_assist: true,
    auto_decline_days: 0,
    employer_assist_on_at: '',
    employer_assist_off_at: '',
    employer_assist_last_updated_by: 0,
  },
  jobPoster: [],
  jobCreationFormActive: '',
  jobCreationMessage: '',
  jobCreationError: {},
  listQuestionPreload: [],
  preloadQuestionSelected: [],
  preloadQuestionAnswers: [],
  customQuestionSelected: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.JOB_CREATION_FORM_ACTIVE:
      return {
        ...state,
        jobCreationFormActive: action.payload,
      };
    case type.JOB_CREATION:
      return {
        ...state,
        jobAdd: action.payload,
      };
    case type.JOB_CREATION_MESSAGE:
      return {
        ...state,
        jobCreationMessage: action.payload,
      };
    case type.JOB_CREATION_ERROR:
      return {
        ...state,
        jobCreationError: action.payload,
      };

    case type.PRELOAD_QUESTION_SELECTED:
      return {
        ...state,
        preloadQuestionSelected: action.payload,
      };
    case type.CUSTOM_QUESTION_SELECTED:
      return {
        ...state,
        customQuestionSelected: action.payload,
      };

    case type.PRELOAD_QUESTION:
      return {
        ...state,
        listQuestionPreload: action.payload,
      };

    case type.PRELOAD_QUESTION_ANSWERS:
      return {
        ...state,
        preloadQuestionAnswers: action.payload,
      };

    case type.JOB_POSTER:
      return {
        ...state,
        jobPoster: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
