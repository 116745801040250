import {
	faArrowDown,
	faArrowUp,
	faMagnifyingGlass,
	faStar,
	faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AvatarIcon from "assets/svg/default-profile-pic.svg";

import ModalConfirm from "../../shared/component/ModalComponent/ModalConfirm";
import Pagination from "../../shared/component/Pagination/Pagination";
import Toggle from "../../shared/component/ToggleComponent/Toggle";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import * as Variable from "../../shared/utils/variables";
import makeRequest from "./../../shared/utils/request";

import AddEditUser from "./AddEditUser";

import deactivateIcon from "../../assets/svg/deactivate.svg";

import "./UserManagement.scss";
import LayoutWithNavigation from "shared/layouts/LayoutWithNavigation";
import { Button } from "shared/component";
import Image from "shared/component/UI/Image";
import SkeletonLoader from "shared/component/atoms/SkeletonLoader";
import useDebounce from "shared/hooks/debounce";
import useToast from "shared/hooks/useToast";
import { USER_TYPES } from "constants/constants";

const Sort = ({ isDescending, onSort }) => {
	return (
		<FontAwesomeIcon
			icon={isDescending ? faArrowDown : faArrowUp}
			onClick={onSort}
			className="icon"
		/>
	);
};

function UserManagement({ ENV_NAME, userId, ...props }) {
	const toast = useToast();
	const MAX_PAGE_SIZE = 10;
	const [pageData, setPageData] = useState({
		current_page: 1,
		max_pages: 1,
	});
	const [lastItemIndex, setLastItemIndex] = useState(0);
	const [activeSort, setActiveSort] = useState({
		sortField: "first_name",
		descending: true,
	});

	const [users, setUsers] = useState([]);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [addEditUser, setAddEditUser] = useState(
		JSON.parse(sessionStorage.getItem(`ad_${userId}`))?.handling_user,
	);
	const [isDeleting, setIsDeleting] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const debounceSearch = useDebounce(searchTerm, 500);

	useEffect(() => {
		fetchUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debounceSearch]);

	const fetchUsers = async () => {
		setIsLoading(true);
		const res = await makeRequest(
			generateRequestOptions("fetchCompanyUsers", {
				queryParams: {
					page_size: 20,
					keyword: searchTerm || undefined,
				},
			}),
		);

		if (res.code === 200) {
			const admin = res.data.filter(
				(u) => u?.user_type === USER_TYPES.JOB_MARKET_SUPER_ADMIN,
			);
			const non_admin = orderBy(
				res.data.filter(
					(u) => u?.user_type !== USER_TYPES.JOB_MARKET_SUPER_ADMIN && u?.is_active,
				),
				[(_) => _.first_name.toLowerCase()],
			);

			setUsers([...admin, ...non_admin]);
			setPageData({
				...pageData,
				max_pages: Math.ceil([...admin, ...non_admin].length / MAX_PAGE_SIZE),
			});
		} else {
			toast.error(res.message || Variable.SOMETHING_WENT_WRONG_TRY_AGAIN[ENV_NAME]);
		}
		setIsLoading(false);
	};

	const handleUserFormVisibility = (isVisible) => setAddEditUser(isVisible);

	const activateDeactivateUser = async (user, status) => {
		const res = await makeRequest({
			...generateRequestOptions("deactivateStaffUser", {
				urlParams: user,
			}),
			json: true,
			body: { is_deactivated: status },
		});

		if (res.code === 200) {
			setSelectedUser(null);
			toast.success(
				!selectedUser?.status
					? Variable.ACCOUNT_ACTIVATE_SUCCESS[ENV_NAME]
					: Variable.ACCOUNT_DEACTIVATE_SUCCESS[ENV_NAME],
			);
			fetchUsers();
		} else toast.error(res.message);
	};

	const deleteUser = async (user) => {
		const res = await makeRequest({
			...generateRequestOptions("deleteUser", {
				urlParams: user,
			}),
			json: true,
			body: { closed_account_answer: "Account Deletion" },
		});

		if (res.code === 200) {
			setSelectedUser(null);
			toast.success(Variable.ACCOUNT_DELETE_SUCCESS[ENV_NAME]);
			fetchUsers();
		} else toast.error(res.message);
	};

	const onConfirmAction = async (action) => {
		if (!action) {
			setSelectedUser(null);
			return isDeleting ? setShowDeleteDialog(false) : setShowDeactivateDialog(false);
		}

		if (isDeleting) {
			setShowDeleteDialog(false);
			deleteUser(selectedUser?.id);
		} else {
			setShowDeactivateDialog(false);
			activateDeactivateUser(selectedUser?.id, selectedUser?.status);
		}
	};

	const handleSort = (field) => {
		setUsers(orderBy(users, [activeSort?.sortField], activeSort?.descending ? "desc" : "asc"));
		setActiveSort({
			...activeSort,
			descending: !activeSort?.descending,
		});
	};

	const handleToggleStatus = (user) => {
		if (user?.user_type === USER_TYPES.JOB_MARKET_SUPER_ADMIN) return;
		setIsDeleting(false);
		setSelectedUser({
			id: user?.id,
			status: !user?.is_deactivated,
		});

		if (user?.is_deactivated) activateDeactivateUser(user?.id, !user?.is_deactivated);
		else setShowDeactivateDialog(true);
	};

	if (!!addEditUser)
		return (
			<LayoutWithNavigation>
				<AddEditUser
					loading={isLoading}
					ENV_NAME={ENV_NAME}
					hideForm={() => handleUserFormVisibility(false)}
					userId={selectedUser?.id}
					userType={props.user_type}
					setSelectedUser={setSelectedUser}
					fetchUsersData={() => fetchUsers()}
				/>
			</LayoutWithNavigation>
		);

	return (
		<LayoutWithNavigation>
			<div className="user__wrapper">
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12 p-0">
							<div className="header">
								<h2>{Variable.USER_MANAGEMENT[ENV_NAME]}</h2>
								<p>{users[0]?.company}</p>
							</div>
						</div>
						<hr className="linear" />
						<div className="col-sm-12">
							<div className="add-users">
								<div className="search">
									<FontAwesomeIcon
										icon={faMagnifyingGlass}
										className="search__icon"
									/>
									<input
										className="search__input"
										placeholder={Variable.SEARCH_LABEL[ENV_NAME]}
										value={searchTerm}
										onChange={(e) => setSearchTerm(e.target.value)}
										autoComplete="off"
									/>
								</div>
								<Button
									size="sm"
									type="primary"
									onClick={() => handleUserFormVisibility(true)}
								>
									{Variable.ADD_USER[ENV_NAME]}
								</Button>
							</div>
						</div>
						<div className="col-sm-12 table-wrapper">
							<div className="user__table">
								<table className="table table-responsive table-user-management">
									<thead>
										<tr>
											<th>
												<span
													onClick={() =>
														setActiveSort({
															...activeSort,
															sortField: "first_name",
														})
													}
												>
													{Variable.FULL_NAME[ENV_NAME]}
												</span>
												{activeSort?.sortField === "first_name" && (
													<Sort
														isDescending={activeSort?.descending}
														onSort={handleSort}
													/>
												)}
											</th>
											<th>
												<span
													onClick={() =>
														setActiveSort({
															...activeSort,
															sortField: "position",
														})
													}
												>
													{Variable.DESIGNATION[ENV_NAME]}
												</span>
												{activeSort?.sortField === "position" && (
													<Sort
														isDescending={activeSort?.descending}
														onSort={handleSort}
													/>
												)}
											</th>
											<th>
												<span
													onClick={() =>
														setActiveSort({
															...activeSort,
															sortField: "email",
														})
													}
												>
													{Variable.LOGIN_EMAIL[ENV_NAME]}
												</span>
												{activeSort?.sortField === "email" && (
													<Sort
														isDescending={activeSort?.descending}
														onSort={handleSort}
													/>
												)}
											</th>
											<th
												className="text-center"
												style={{
													paddingLeft: "3.5rem",
												}}
											>
												{Variable.ACTIVE[ENV_NAME]}
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{isLoading && (
											<>
												{Array.apply(0, new Array(8)).map((_v, index) => (
													<tr key={`${index}-loader`}>
														<td colSpan={5}>
															<SkeletonLoader
																width="100%"
																height="50px"
															/>
														</td>
													</tr>
												))}
											</>
										)}
										{!isLoading &&
											users
												.slice(lastItemIndex, lastItemIndex + MAX_PAGE_SIZE)
												.map((user) => (
													<tr key={user?.id}>
														<td>
															<Image
																src={
																	user?.profile_picture ||
																	AvatarIcon
																}
																width={24}
																height={24}
																className="mr-1 profile-image"
															/>
															<span
																className={`${
																	user?.user_type !==
																	USER_TYPES.JOB_MARKET_SUPER_ADMIN
																		? "cursorPointer"
																		: ""
																}`}
																onClick={() => {
																	if (
																		user?.user_type ===
																		USER_TYPES.JOB_MARKET_SUPER_ADMIN
																	)
																		return;

																	setSelectedUser({
																		id: user?.id,
																	});
																	setAddEditUser(true);
																}}
															>{`${user?.first_name || "-"} ${
																user?.last_name || ""
															}`}</span>
															{user?.user_type ===
																USER_TYPES.JOB_MARKET_SUPER_ADMIN && (
																<OverlayTrigger
																	placement="top"
																	delay={{
																		show: 250,
																		hide: 400,
																	}}
																	overlay={
																		<Tooltip id="tooltip">
																			<span
																				style={{
																					padding:
																						"1rem 0.5rem",
																					fontSize:
																						"1.3rem",
																				}}
																			>
																				{
																					Variable
																						.SUPER_ADMIN[
																						ENV_NAME
																					]
																				}
																			</span>
																		</Tooltip>
																	}
																>
																	<FontAwesomeIcon
																		icon={faStar}
																		className="icon icon-star"
																	/>
																</OverlayTrigger>
															)}
														</td>
														<td>{user?.position || "-"}</td>
														<td>{user?.email || "-"}</td>
														<td className="">
															<Toggle
																type="switch"
																name={`au_${user?.id}`}
																checked={
																	user?.user_type ===
																	USER_TYPES.JOB_MARKET_SUPER_ADMIN
																		? true
																		: !user?.is_deactivated
																}
																onChange={() =>
																	handleToggleStatus(user)
																}
																disabled={
																	user?.user_type ===
																	USER_TYPES.JOB_MARKET_SUPER_ADMIN
																}
																className="emp-assist-toggle"
															/>
														</td>
														<td>
															{" "}
															<FontAwesomeIcon
																icon={faTrashCan}
																className={`icon icon-trash${
																	user?.user_type ===
																	USER_TYPES.JOB_MARKET_SUPER_ADMIN
																		? "-disabled"
																		: ""
																}`}
																onClick={() => {
																	if (
																		user?.user_type ===
																		USER_TYPES.JOB_MARKET_SUPER_ADMIN
																	)
																		return;
																	setIsDeleting(true);
																	setSelectedUser({
																		id: user?.id,
																	});
																	setShowDeleteDialog(true);
																}}
															/>
														</td>
													</tr>
												))}
									</tbody>
								</table>
							</div>
						</div>
						{pageData?.max_pages > 1 && (
							<div className="pagination-user-mgt">
								<Pagination
									selectedEnvironment={ENV_NAME}
									page={pageData?.current_page}
									paginationSize={pageData?.max_pages}
									getRequestedPage={(inputPage) => {
										if (inputPage > 0 && inputPage <= pageData?.max_pages) {
											setPageData({
												...pageData,
												current_page: Number(inputPage),
											});
											setLastItemIndex(
												inputPage * MAX_PAGE_SIZE - MAX_PAGE_SIZE,
											);
										}
									}}
									maxPageSize={MAX_PAGE_SIZE}
									lastItemIndex={lastItemIndex}
									handleLastItemIndex={setLastItemIndex}
									className="py-0 d-flex justify-content-start"
								/>
							</div>
						)}
					</div>
				</div>

				<ModalConfirm
					show={isDeleting ? showDeleteDialog : showDeactivateDialog}
					hide={() =>
						isDeleting ? setShowDeleteDialog(false) : setShowDeactivateDialog(false)
					}
					onAction={onConfirmAction}
					icon={!isDeleting ? deactivateIcon : ""}
					headTitle={!isDeleting ? Variable.ACCOUNT_DEACTIVATE[ENV_NAME] : ""}
					body={
						<div className="delete-subtitle-small">
							{isDeleting
								? Variable.ACCOUNT_DELETE_DESC[ENV_NAME]
								: Variable.ACCOUNT_DEACTIVATE_DESC[ENV_NAME]}
						</div>
					}
					labelYes={
						isDeleting
							? Variable.DELETE_LABEL[ENV_NAME]
							: Variable.DEACTIVATE_LABEL[ENV_NAME]
					}
					labelNo={Variable.CANCEL_LABEL[ENV_NAME]}
				/>
			</div>
		</LayoutWithNavigation>
	);
}

const mapStateToProps = (state) => {
	const userId = state?.auth?.userDetails?.id;

	return {
		ENV_NAME: state.auth.selectedEnvironment || "bhs",
		user_type: state.auth.userDetails?.user_type,
		userId,
	};
};

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(UserManagement));
