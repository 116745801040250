import { generateRequestOptions } from '../../shared/utils/apiEndPoints';
import makeRequest from '../../shared/utils/request';
import {
  DataTime,
  FormExact,
  FormTimeSlot,
  INTERVIEW_FORM,
  INTERVIEW_JOB_POSTER,
  INTERVIEW_METHOD,
  INTERVIEW_TYPE,
  InterviewForm,
  InterviewJobPoster,
  InterviewJobPosterDetail,
  InterviewMethod,
  InterviewType,
} from '../types/interview';

export const setInterviewMethod = (payload: string): InterviewMethod => ({
  type: INTERVIEW_METHOD,
  payload,
});

export const setInterviewType = (payload: string): InterviewType => ({
  type: INTERVIEW_TYPE,
  payload,
});

export const setInterviewForm = (payload: FormTimeSlot): InterviewForm => ({
  type: INTERVIEW_FORM,
  payload,
});

export const setInterviewJobPoster = (
  payload: InterviewJobPosterDetail,
): InterviewJobPoster => ({
  type: INTERVIEW_JOB_POSTER,
  payload,
});

// Function Integration with API

/**
 * function for fetch detail job poster with id
 * @param id job poster
 * @returns
 */
export const fetchDetailJobPoster = async (id: string) => {
  try {
    const res = await makeRequest({
      ...generateRequestOptions('getDetailJobPoster', {
        urlParams: id,
      }),
      json: true,
    });
    if (res.code === 200) {
      return res;
    }
  } catch (err: any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};

/**
 * function for set method interview "exact time" or "time slots"
 * @param {id} id job poster
 * @param {interview_schedule_type} with enum value ['exact', 'slots', null]
 * @returns
 */
export const updateInterviewMethod = async ({
  id,
  interview_schedule_type,
}: {
  id: string;
  interview_schedule_type: string;
}) => {
  try {
    const res = await makeRequest({
      ...generateRequestOptions('updateInterviewMethod', {
        urlParams: id,
      }),
      data: {
        interview_schedule_type,
      },
      json: true,
    });
    if (res.code === 200) {
      return res;
    }
  } catch (err: any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};

/**
 * function for add interview type even "one-on-one" or "group"
 * @param {*} form
 * @returns
 */
export const insertTimeSlotSchedule = async (form: FormTimeSlot) => {
  try {
    const res = await makeRequest({
      ...generateRequestOptions('insertInterviewType'),
      data: form,
      json: true,
    });
    console.log(res, '<<<res');

    return res;
  } catch (err: any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};

/**
 * function for add new time slot without create interview type
 * @param {interview} id interview
 * @returns
 */
export const addSlotInterview = async (form: DataTime) => {
  try {
    const res = await makeRequest({
      ...generateRequestOptions('addSlotInterview'),
      data: form,
      json: true,
    });
    console.log(res, '<<<res');

    return res;
  } catch (err: any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};

export const insertExactSchedule = async (form: FormExact) => {
  try {
    const res = await makeRequest({
      ...generateRequestOptions('addExactInterview'),
      data: form,
      json: true,
    });
    console.log(res, '<<<res');

    return res;
  } catch (err: any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};

export const fetchListTimeSlot = async (id?: string) => {
  try {
    const res = await makeRequest({
      ...generateRequestOptions('listInterviewSlot'),
      json: true,
    });
    // console.log(res, '<<<res');

    return res;
  } catch (err: any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};

export const fetchListTimeSchedule = async (id?: string) => {
  try {
    const res = await makeRequest({
      ...generateRequestOptions('listInterviewSchedule'),
      json: true,
    });
    // console.log(res, '<<<res');

    return res;
  } catch (err: any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};

export const fetchDetailSchedule = async (id: number) => {
  try {
    const res = await makeRequest({
      ...generateRequestOptions('detailInterviewSchedule', {
        urlParams: id,
      }),
      json: true,
    });
    // console.log(res, '<<<res');

    return res;
  } catch (err: any) {
    console.log(err?.response?.data);
    return err?.response?.data;
  }
};
