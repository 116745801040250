import cx from "classnames";
import { useState, useRef, useEffect, useCallback, Fragment } from "react";
import { X, Info } from "@phosphor-icons/react";
import { Modal, Overlay } from "react-bootstrap";

import Switch from "shared/component/atoms/Switch";
import useTranslate from "shared/hooks/useTranslate";
import Checkbox from "shared/component/atoms/Checkbox";
import Tooltip from "shared/component/atoms/Tooltips/Tooltip";
import { CandidateManagementRes, MustHaveQuestions } from "component/CandidateManagement/types";

import { firstLetterUpperCase } from "shared/utils/helper";
import { setCookies, getCookies, removeCookies } from "shared/utils/cookies";

import QuestionRangeSlider from "./questionsFilter/QuestionRangeSlider";
import QuestionSingleChoice from "./questionsFilter/QuestionSingleChoice";
import QuestionsYesNoChoice from "./questionsFilter/QuestionsYesNoChoice";
import QuestionNumericChoice from "./questionsFilter/QuestionNumericChoice";
import QuestionMultipleChoice from "./questionsFilter/QuestionMultipleChoice";

import "./Filter.scss"
import useToast from "shared/hooks/useToast";

const CANDIDATE_MCQ_STATUS = {
    "prospect": 0,
    "shortlist": 0,
    "interview": 0,
    "offered": 0,
    "hired": 0,
    "rejected": 0,
};
const RESUME_UPDATED_MONTH = [3, 6, 9, 12];
const DEFAULT_STATUS_FILTER = ["status", "salary"]

interface FilterProps {
    handlerCloseModal: () => void;
    checked: boolean;
    setLastUpdateResumeChecked: (val: boolean) => void;
    resumeDuration: number;
    setResumeDuration: (val: number) => void;
    setStatusFilters: (val: string[]) => void;
    mandatoryQuestions: CandidateManagementRes["must_have_questions"] | [];
    salaryJobs: any;
}

const Filter: React.FC<FilterProps> = ({
    handlerCloseModal,
    checked,
    setLastUpdateResumeChecked,
    resumeDuration,
    setResumeDuration,
    setStatusFilters,
    mandatoryQuestions,
    salaryJobs,
}) => {

    const toast = useToast();
    const t = useTranslate();
    
    const lastUpdateRef = useRef<HTMLDivElement>(null);
    
    const [isUpdatedResomeOn, setUpdatedResumeOn] = useState<boolean>(checked);
    const [tempResumeDuration, setTempResumeDuration] = useState<number>(resumeDuration);

    const [showToggleResume, setToggleResume] = useState<boolean>(false);
    const [candidateJobStatus, setCandidateJobStatus] = useState<string[]>([]);
    const [selectedFilterOptions, setSelectedFilterOption] = useState<number>(0);
    const [showMandatoryQuestion, setMandatoryQuestion] = useState<boolean>(false);
    const [idMandatoryQuestions, setIdMandatoryQuestions] = useState<number[]>([]);

    const [candidateRangeSalary, setCandidateRangeSalary] = useState<any>({});
    const [customQuestionFilter, setCustomQuestionFilter] = useState<MustHaveQuestions | {}>({});
    const [listMandatoryQuestions, setListMandatoryQuestions] = useState<MustHaveQuestions | {}>({});

    useEffect(() => {
        let newListQuestions = {};
        mandatoryQuestions?.map?.(((d) => {
            if (d.id) {
                newListQuestions[d.id] = d
            }
            return null;
        }));

        setListMandatoryQuestions(newListQuestions)
    }, [mandatoryQuestions, showMandatoryQuestion]);

    useEffect(() => {
        const candidateJobStatusCookies = getCookies("candidateJobStatus");
        if (candidateJobStatusCookies?.length > 0) {
            setCandidateJobStatus(candidateJobStatusCookies);
        }
    }, [])

    const handleDefaultFilter = () => {
        if (!showMandatoryQuestion) {
            const idMandatoryQuestionsCookies = getCookies("idMandatoryQuestions");
            const customQuestionFilterCookies = getCookies("customQuestionFilter");
            const customCandidateRangeSalary = getCookies("candidateRangeSalary");


            setIdMandatoryQuestions(idMandatoryQuestionsCookies?.length > 0 ? idMandatoryQuestionsCookies : [])

            if (customQuestionFilterCookies && Object.keys(customQuestionFilterCookies)?.length > 0) {                
                setListMandatoryQuestions(customQuestionFilterCookies);
            }
            if (customCandidateRangeSalary && Object.keys(customCandidateRangeSalary)?.length > 0) {
                setCandidateRangeSalary(customCandidateRangeSalary);
            } else {
                setCandidateRangeSalary({ min: salaryJobs.salary_starting_amount, max: salaryJobs.salary_maximum_amount});
            }

            return;
        };
        const defaultMandatoryQuestions = getCookies("defaultMandatoryQuestions") || {};
        const defaultSelectedFilters: number[] = Object.keys(defaultMandatoryQuestions)
            ?.filter((d) => defaultMandatoryQuestions[d]?.is_mandatory)
            ?.map(v => defaultMandatoryQuestions[v]?.id);

        setIdMandatoryQuestions(defaultSelectedFilters);

        const newListQuestions: MustHaveQuestions = Object.keys(defaultMandatoryQuestions).reduce((acc, item) => {
            acc[defaultMandatoryQuestions[item].id] = defaultMandatoryQuestions[item];
            return acc;
        }, {});

        setListMandatoryQuestions(newListQuestions);
    }

    useEffect(() => {
        handleDefaultFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMandatoryQuestion])

    const handleSingleChoice = useCallback((option: string[]) => {
        const newListQuestions = Object.keys(listMandatoryQuestions).reduce((acc, item) => {
            acc[listMandatoryQuestions[item].id] = listMandatoryQuestions[item];
            return acc;
        }, {});

        newListQuestions[selectedFilterOptions].mandatory_choices = option;

        setCustomQuestionFilter(newListQuestions);
        setListMandatoryQuestions(newListQuestions);
    }, [listMandatoryQuestions, selectedFilterOptions])

    const handleTrueFalseChoices = (val) => {
        if (!listMandatoryQuestions?.[val.id]) return;
        
        const newListQuestions = { ...listMandatoryQuestions };
        newListQuestions[val.id] = val;

        setCustomQuestionFilter(newListQuestions);
        setListMandatoryQuestions(newListQuestions);
    }

    const handleNumberChoices = val => {
        if (!listMandatoryQuestions?.[val.id]) return;
        
        const newListQuestions = { ...listMandatoryQuestions };
        newListQuestions[val.id] = val;

        setCustomQuestionFilter(newListQuestions);
        setListMandatoryQuestions(newListQuestions);
    };

    const onCandidateStatus = data => {
        setCandidateJobStatus(data);
    }

    const handleActivateMustHaveFilters = () => {
        setMandatoryQuestion(prev => !prev);
    };

    const handleSliderValue = (val) => {
        if (!listMandatoryQuestions?.[val.id]) return;
        
        const newListQuestions = { ...listMandatoryQuestions };
        newListQuestions[val.id] = val;

        setCustomQuestionFilter(newListQuestions);
        setListMandatoryQuestions(newListQuestions);
    }

    const handleSalaryRange = (val) => {
        setCandidateRangeSalary(val)
    }

    const handleApplyFilter = () => {
        let statusFilterApplied = {};
        idMandatoryQuestions?.map(v => {
            statusFilterApplied[v] = customQuestionFilter[v]
            return null;
        });

        setCookies("candidateRangeSalary", candidateRangeSalary);
        setCookies("candidateJobStatus", candidateJobStatus);
        setCookies("idMandatoryQuestions", idMandatoryQuestions);
        setCookies("customQuestionFilter", customQuestionFilter);

        setLastUpdateResumeChecked(isUpdatedResomeOn);
        setResumeDuration(tempResumeDuration);
        setStatusFilters(candidateJobStatus);

        handlerCloseModal();
    }

    const handleResetFilter = () => {
        setLastUpdateResumeChecked(false);
        setResumeDuration(6);
        setStatusFilters([]);
        handlerCloseModal();

        removeCookies("candidateRangeSalary");
        removeCookies("candidateJobStatus");
        removeCookies("idMandatoryQuestions");
        removeCookies("customQuestionFilter");

        toast.success(t("FILTER_SUCCESSFULLY_RESET"))
    }

    return (
        <div className="modal-filter">
            <Modal.Header className="d-flex justify-content-between align-items-cente tw rounded-t-xl bg-gray-50 py-md px-lg">
                <h4 className="text-xl fw-semibold mb-0 pb-0">
                    {t("FILTERS")}
                </h4>
                <X
                    width={24}
                    height={24}
                    className="close cursorPointer"
                    onClick={handlerCloseModal}
                />
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex align-items-center">
                    <p className="fw-semibold mb-0 pb-0">{t("LAST_UPDATED_RESUME")}</p>
                    <Tooltip
                        placement="bottom-left"
                        description={t("CANDIDATE_INFO_TOOLTIP")}
                    >
                        <Info
                            width={18}
                            height={18}
                            color="#667085"
                            className="tw cursor-pointer mx-2"
                        />
                    </Tooltip>

                    <Switch
                        id="11"
                        checked={isUpdatedResomeOn}
                        onToggle={() => setUpdatedResumeOn(prev => !prev)}
                    />

                </div>
                <div
                    onClick={() => setToggleResume(!showToggleResume)}
                    className="my-3"
                    ref={lastUpdateRef}
                >
                    <div className="status-dropdown">
                        <span>{t("NUMBER_MONTHS").replace("{{number}}", String(tempResumeDuration))}</span>
                        <i className="fa fa-solid fa-angle-down"></i>
                    </div>
                    <Overlay
                        target={lastUpdateRef.current}
                        show={showToggleResume}
                        placement="bottom-start"
                        rootClose
                        onHide={() => setToggleResume(false)}
                    >
                    {({
                        placement,
                        arrowProps,
                        show: _show,
                        popper,
                        ...OverlayProps
                    }) => (
                        <div
                            {...OverlayProps}
                            id="hn-dropdown-lang"
                            className="hn-dropdown hn-lang"
                        >
                            {RESUME_UPDATED_MONTH.map((v, k)=>(
                                <button
                                    key={k}
                                    onClick={()=>setTempResumeDuration(v)}
                                    className="hn-dropdown-item status-dropdown-item"
                                >
                                    <div className="hn-dropdown-item-label">
                                        {t("NUMBER_MONTHS").replace("{{number}}", v)}
                                    </div>
                                </button>
                            ))}
                        </div>
                    )}
                    </Overlay>
                </div>
                <div className="horizontal-line" />
                <div className="d-flex col-12">
                    <div className="col-6 pe-3">
                        {DEFAULT_STATUS_FILTER.map((v, k) => {
                            return (
                                <div
                                    id={String(k)}
                                    key={String(k)}
                                    className={cx(
                                        "mt-4 tw cursor-pointer",
                                        {
                                            "p-1 background-light-blue": selectedFilterOptions === k,
                                            "mb-5": k === DEFAULT_STATUS_FILTER.length - 1
                                        }
                                    )}
                                    style={{ borderRadius: 4 }}
                                    onClick={() => setSelectedFilterOption(k)}
                                >
                                    {t(v?.toUpperCase() as any)}
                                </div>
                            )
                        })}
                        <div className="horizontal-line" />
                        <div className="d-flex pt-4">
                            <p>{t("MUST_HAVE_FILTERS")}</p>
                            <Switch
                                id="9"
                                checked={showMandatoryQuestion}
                                onToggle={handleActivateMustHaveFilters}
                            />
                        </div>
                        <div className="horizontal-line" />
                        {Object.keys(listMandatoryQuestions)?.map((v, k) => {
                            return (
                                <div
                                    key={k}
                                    className={cx(
                                        "d-flex align-items-center py-2",
                                        { "m-0 py-2 pe-0 background-light-blue": selectedFilterOptions === listMandatoryQuestions[v].id }
                                    )}
                                    onClick={() => setSelectedFilterOption(listMandatoryQuestions[v].id)}
                                >
                                    <Checkbox
                                        id={String(listMandatoryQuestions[v].id)}
                                        labelPosition="left"
                                        enableLabelChecked={false}
                                        checked={idMandatoryQuestions.includes(listMandatoryQuestions[v].id)}
                                        onChange={() => {
                                            if(idMandatoryQuestions.includes(listMandatoryQuestions[v].id)) {
                                                return setIdMandatoryQuestions(idMandatoryQuestions.filter(x => x !== listMandatoryQuestions[v].id))
                                            }
                                            return setIdMandatoryQuestions([...idMandatoryQuestions, listMandatoryQuestions[v].id])
                                        }}
                                        label={firstLetterUpperCase(listMandatoryQuestions[v].question_text)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="col-6 ps-3" style={{ borderLeft: "1px solid #B4C7E5" }}>
                        {
                            selectedFilterOptions === 0 && (
                                <QuestionMultipleChoice
                                    mcq_choices={CANDIDATE_MCQ_STATUS}
                                    candidateJobStatus={candidateJobStatus}
                                    onChange={onCandidateStatus}
                                />
                            )
                        }
                        {
                            selectedFilterOptions === 1 && (
                                <QuestionRangeSlider
                                    value={candidateRangeSalary}
                                    onChange={value => handleSalaryRange(value)}
                                    isCurrencyRange
                                    title={t("SALARY_FILTER")}
                                    {...salaryJobs}
                                />
                            )
                        }
                        {Object.keys(listMandatoryQuestions).map((v, i) => {
                            const data = listMandatoryQuestions[v];
                            if (["progressive", "mcq", "single_select"].includes(data.question_type) && selectedFilterOptions === data.id) {
                                return (
                                    <Fragment key={i}>
                                        <QuestionSingleChoice
                                            {...data}
                                            showMandatoryQuestion={showMandatoryQuestion}
                                            idMandatoryQuestions={idMandatoryQuestions}
                                            onChange={handleSingleChoice}
                                        />
                                    </Fragment>
                                )
                            }
                            if (data.question_type === "true_false" && selectedFilterOptions === data.id) {
                                return (
                                    <Fragment key={i}>
                                        <QuestionsYesNoChoice
                                            {...data}
                                            showMandatoryQuestion={showMandatoryQuestion}
                                            idMandatoryQuestions={idMandatoryQuestions}
                                            onChange={value => handleTrueFalseChoices({ ...data, value })} 
                                        />
                                    </Fragment>
                                )
                            }
                            if (data.question_type === "numeric" && selectedFilterOptions === data.id) {
                                if (data.max_numeric_value && data.min_numeric_value) {
                                    return (
                                        <Fragment key={i}>
                                            <QuestionRangeSlider
                                                {...data}
                                                showMandatoryQuestion={showMandatoryQuestion}
                                                idMandatoryQuestions={idMandatoryQuestions}
                                                onChange={value => handleSliderValue({ ...data, value })}
                                                isCurrencyRange
                                                title={t("SALARY_FILTER")}
                                            />
                                        </Fragment>
                                    );
                                }
                                return (
                                    <Fragment key={i}>
                                        <QuestionNumericChoice
                                            {...data}
                                            showMandatoryQuestion={showMandatoryQuestion}
                                            onChange={value => handleNumberChoices({ ...data, value })}
                                            idMandatoryQuestions={idMandatoryQuestions}
                                            title={data.min_numeric_value ? t("MINIMUM_VALUE") : data.max_numeric_value ? t("MAXIMUM_VALUE") : ""}
                                        />
                                    </Fragment>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <div
                    onClick={handleResetFilter}
                    className="btn btn-cancel"
                >
                    {t("RESET")}
                </div>
                <div
                    className="btn-apply-filter"
                    onClick={handleApplyFilter}
                >
                    {t("APPLY_FILTER")}
                </div>
            </Modal.Footer>
        </div>
    );
}

export default Filter;
