import { FC, useEffect, useState } from 'react'
import { FormInput } from 'shared/component';

import { QuestionsFiltersChoices } from '../../types';

type QuestionsFiltersChoicesProps = 'onChange'
    | 'value'
    | 'numeric_accepted_answer'
    | 'title'
    | 'showMandatoryQuestion';

const QuestionNumericChoice: FC<
    Pick<
        QuestionsFiltersChoices, 
        QuestionsFiltersChoicesProps
    >
> = ({
    onChange,
    value,
    title,
    numeric_accepted_answer,
    showMandatoryQuestion,
}) => {
    const [inputValue, setInputValue] = useState<number | undefined>(0);

    useEffect(() => {
        if (showMandatoryQuestion || !value) {
            setInputValue(Number(numeric_accepted_answer))
            return;
        }
        if (value) setInputValue(Number(value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMandatoryQuestion])

    const handleChange = (v: number | undefined) => {
        onChange(v)
        setInputValue(v)
    }

    return (
        <div className='px-3 pt-3'>
            {title && (
                <span className='question-filter__title'>{title}</span>
            )}
            <div className='pt-3'>
                <FormInput
                    format="currency"
                    value={inputValue}
                    isDoubleIcon={Boolean(inputValue && inputValue > 0)}
                    onCloseIcon={() => {
                        onChange(0)
                        setInputValue(0)
                    }}
                    onCurrencyChange={handleChange}
                />
            </div>
        </div>
    )
}

export default QuestionNumericChoice;
