import React, { useState } from "react";

import { UserData } from "./types";
import { Button } from "shared/component";
import PKLogo from "assets/png/pk-logo.png";
import { useHistory } from "react-router-dom";
import Image from "shared/component/UI/Image";
import { SIGN_UP } from "shared/utils/routeLink";
import useTranslate from "shared/hooks/useTranslate";
import { Typography } from "shared/component/Typography/Typography";
import CardParentSelect from "./components/CardParentSelect/CardParentSelect";
import useSearchParams from "shared/hooks/useSearchParams";

import "./ParentCompanySelect.scss";

const ParentCompanySelector = () => {
	const t = useTranslate();

	const history = useHistory();

	const [selectedId, setSelectedId] = useState(0);

	const { getParam } = useSearchParams();

	const email = getParam("email");

	const handleSelectedParentCompany = (id: number) => () => {
		setSelectedId(id);
	};

	const formRegister: UserData = JSON.parse(localStorage.getItem("form_register") ?? "");
	const businessParentCompany = formRegister.businessParentCompany;

	const handleClickButton = async () => {
		history.push(`${SIGN_UP}?is_sent=true&email=${encodeURIComponent(email ?? "")}`);
	};

	return (
		<div className="parent-company-selector">
			<Image src={PKLogo} className="parent-company-selector__image" />
			<div className="parent-company-selector__title">
				<Typography.Text size="xl" fontWeight="semibold">
					{t("SELECT_PARENT_COMPANY_TITLE")}
				</Typography.Text>
				<Typography.Text size="md">{t("SELECT_PARENT_COMPANY")}</Typography.Text>
			</div>
			<div className="parent-company-selector__lists">
				{businessParentCompany.map((company) => (
					<CardParentSelect
						key={company.id}
						label={company.name}
						checked={company.id === selectedId}
						onClick={handleSelectedParentCompany(company.id)}
					/>
				))}
			</div>
			<Button
				type="primary"
				btnClassName="parent-company-selector__btn"
				disabled={!selectedId}
				onClick={handleClickButton}
			>
				{t("REGISTER_ACCOUNT")}
			</Button>
		</div>
	);
};

export default ParentCompanySelector;
