import { Eye, EyeClosed } from '@phosphor-icons/react';
import React from 'react';

export default function PasswordIcon({
  setshowPassword,
  showPassword,
  width,
  height,
}) {
  return (
    <span
      className="formInputType icon password"
      onClick={() => setshowPassword(!showPassword)}>
      {showPassword ? (
        <EyeClosed height={height ?? 24} width={width ?? 24} />
      ) : (
        <Eye height={height ?? 24} width={width ?? 24} />
      )}
    </span>
  );
}
