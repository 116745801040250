import { FC, useRef } from 'react'
import cx from 'classnames';

import applicationHistory from "../../../../assets/svg/application-history-blue.svg";
import comments from "../../../../assets/svg/comments-blue.svg";
import history from "../../../../assets/svg/history-blue.svg";
import users from "../../../../assets/svg/users-blue.svg";
import applicationHistoryActive from "../../../../assets/svg/application-history-blue-active.svg";
import commentsActive from "../../../../assets/svg/comments-blue-active.svg";
import historyActive from "../../../../assets/svg/history-blue-active.svg";
import usersActive from "../../../../assets/svg/users-blue-active.svg";

import Image from "shared/component/UI/Image";
import useTranslate from 'shared/hooks/useTranslate';

import './DetailNavigation.scss'

type DetailNavigationProps = {
    countComments: number | undefined;
    selectedTab: string;
    onTabClick: (v: string) => void;
}

const DetailNavigation: FC<DetailNavigationProps> = ({
    countComments,
    selectedTab,
    onTabClick
}) => {
    const t = useTranslate();
    const detailNavigationRef = useRef<HTMLDivElement>(null);

    const getWidth = (id: string = 'profile'): number => {
        return detailNavigationRef?.current?.querySelector?.(`#${id}`)?.getBoundingClientRect?.()?.width || 0;
    }

    return (
        <div className='detail-navigation__wrapper' ref={detailNavigationRef}>
            <div className='detail-navigation__sub-wrapper'>
                <div
                    id='profile'
                    className={cx(
                        'detail-navigation__items', {
                        'detail-navigation__items--active': selectedTab === 'profile'
                        })
                    }
                    onClick={() => onTabClick('profile')}
                >
                    <Image
                        className=""
                        src={selectedTab !== 'profile' ? users : usersActive}
                    />
                    <div>
                        <span>{t("PROFILE_DETAILS")}</span>
                    </div>
                </div>
                <div className='detail-navigation__border' />
                <div
                    id='comment'
                    className={cx(
                        'detail-navigation__items', {
                        'detail-navigation__items--active': selectedTab === 'comment'
                        })
                    }
                    onClick={() => onTabClick('comment')}
                >
                    <Image
                        className=""
                        src={selectedTab !== 'comment' ? comments : commentsActive}
                    />
                    <div>
                        <span>{t("COMMENT")}</span> <span>({countComments})</span>
                    </div>
                </div>
                <div className='detail-navigation__border' />
                <div
                    id='history'
                    className={cx(
                        'detail-navigation__items', {
                        'detail-navigation__items--active': selectedTab === 'history'
                        })
                    }
                    onClick={() => onTabClick('history')}
                >
                    <Image
                        className=""
                        src={selectedTab !== 'history' ? history : historyActive}
                    />
                    <div>
                        <span>{t("STATUS_HISTORY")}</span>
                    </div>
                </div>
                <div className='detail-navigation__border' />
                <div
                    id='screen-questions'
                    className={cx(
                        'detail-navigation__items',
                        { 'detail-navigation__items--active': selectedTab === 'screen-questions' }
                    )}
                    onClick={() => onTabClick('screen-questions')}
                >
                    <Image
                        className=""
                        src={selectedTab !== 'screen-questions' ? history : historyActive}
                    />
                    <div>
                        <span>{t("SCREENING_QUESTION")}</span>
                    </div>
                </div>
                <div className='detail-navigation__border' />
                <div
                    id='application-history'
                    className={cx(
                        'detail-navigation__items', {
                        'detail-navigation__items--active': selectedTab === 'application-history'
                        })
                    }
                    onClick={() => onTabClick('application-history')}
                >
                    <Image
                        className=""
                        src={selectedTab !== 'application-history' ? applicationHistory : applicationHistoryActive}
                    />
                    <div>
                        <span>{t("APPLICATION_HISTORY")}</span>
                    </div>
                </div>
            </div>
            <div style={{ width: detailNavigationRef?.current?.getBoundingClientRect?.()?.width }}>
                <div
                    className='detail-navigation__border--active'
                    style={{
                        width: (() => {
                            switch (selectedTab) {
                                case 'profile':
                                    return getWidth('profile')
                                case 'comment':
                                    return getWidth('comment')
                                case 'history':
                                    return getWidth('history')
                                case 'screen-questions':
                                    return getWidth('screen-questions')
                                case 'application-history':
                                    return getWidth('application-history')
                                default:
                                    return 0;
                            }
                        })(),
                        marginLeft: (() => {
                            switch (selectedTab) {
                                case 'profile':
                                    return 0;
                                case 'comment':
                                    return getWidth('profile') + 2;
                                case 'history':
                                    return getWidth('profile') + getWidth('comment') + 4;
                                case 'screen-questions':
                                    return getWidth('profile') + getWidth('comment') + getWidth('history') + 6;
                                case 'application-history':
                                    return getWidth('profile') + getWidth('comment') + getWidth('history') + getWidth('screen-questions') + 8;
                                default:
                                    return 0;
                            }
                        })(),
                    }}
                />
            </div>
        </div>
    )
}

export default DetailNavigation;
