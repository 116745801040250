import { FC } from 'react';
import Image from 'shared/component/UI/Image';
import moment from 'moment';

import useTranslate from 'shared/hooks/useTranslate';
import { UserType } from 'component/JobAdListing/types/types';

import DefaultImg from 'assets/svg/default-profile-pic.svg';

import './StatusHistory.scss';

interface StatusHistoryProps {
    candidateStatus: {
        candidate_status: string;
        created_at: string;
        created_by: UserType;
    }[]
}

const StatusHistory: FC<StatusHistoryProps> = ({ candidateStatus }) => {
    const t = useTranslate();
    return (
        <div className="status-history">
            {candidateStatus.map(({
                candidate_status,
                created_at,
                created_by,
            }, k) => (
                <div className="status-history__items" key={k}>
                    <div className="d-flex status-history__details">
                        <div className="d-flex align-items-center">
                            <Image className="status-history__img" src={DefaultImg} />
                            <div className="d-flex status-history__description">
                                <span>{[created_by.first_name, created_by.last_name].filter(Boolean).join(" ")}</span>
                                <span className='status-history__description--middle-text'>{t("CHANGED_STATUS_BECOME")}</span>
                                <span>{candidate_status}</span>
                            </div>
                        </div>
                        <p className="status-history__time">
                            {`${moment(created_at).format("DD MMM YYYY")} ${new Date(created_at).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default StatusHistory;
