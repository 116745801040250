import React, { useEffect, useState } from 'react';
import * as Variable from '../../../shared/utils/variables';
import Image from '../UI/Image';
import { Oval } from 'react-loader-spinner';
import UploadImage from '../../../assets/svg/upload-image-emtpy-state.svg';
import UploadImageFailed from '../../../assets/svg/upload-image-failed-state.svg';
import PDFIcon from '../../../assets/svg/pdf_file_icon.svg';
import PNGIcon from '../../../assets/svg/jpg_file_icon.svg';
import { CheckCircleIcon, CloseCircleIcon } from '../../../assets/icons/Icons';
import './UploadFile.scss';

const UploadFile = ({
	ENV_NAME,
	isUploading,
	isUploadSuccess,
	isFileExist,
	existFileName,
	acceptedFiles,
	getRootProps,
	getInputProps,
	setSelectedFiles,
	selectedFiles,
}) => {
	const [fileName, setFileName] = useState('');
	const uploadFilesCondition = [
		{
			id: 1,
			condition: isUploadSuccess === null && !isFileExist && !selectedFiles.length,
			src: UploadImage,
			childrenText: (
				<>
					<p className="drop-area__text font-weight-normal">
						{Variable.DRAG_FILE_HERE[ENV_NAME]}{' '}
						<strong>{Variable.CHOOSE_FILE[ENV_NAME]}</strong>
					</p>
					<p className="drop-area__text font-weight-semibold">
						{Variable.FILE_UPLOAD_COMPANY_RULES[ENV_NAME]}
					</p>
				</>
			),
		},
		{
			id: 2,
			condition: isUploadSuccess === false,
			src: UploadImageFailed,
			childrenText: (
				<>
					<p className="drop-area__text text-file-title font-weight-normal mb-0">
						{existFileName || fileName}
						<CloseCircleIcon
							color="#F04438"
							height={17}
							width={16}
							className="drop-area__text__icon"
						/>
					</p>
					<p className="drop-area__text font-weight-bold text-replace-file">
						Ganti Berkas
					</p>
				</>
			),
		},
		{
			id: 4,
			condition: isUploadSuccess === null && !!selectedFiles.length && !isFileExist,
			src: selectedFiles[0]?.type === 'application/pdf' ? PDFIcon : PNGIcon,
			childrenText: (
				<>
					<p className="drop-area__text font-weight-normal text-file-title">
						{existFileName || fileName}
						<CheckCircleIcon
							color="#039855"
							height={16}
							width={16}
							className="drop-area__text__icon"
						/>
					</p>
					<p className="drop-area__text font-weight-bold text-replace-file">
						Ganti Berkas
					</p>
				</>
			),
		},
	];

	useEffect(() => {
		if (acceptedFiles && acceptedFiles.length) {
			setFileName(acceptedFiles[0]?.name);
			setSelectedFiles(acceptedFiles);
		}
	}, [acceptedFiles]);

	return isUploading ? (
		<div className="drop-area">
			<Oval
				height={118}
				width={118}
				color="#475D80"
				wrapperStyle={{
					margin: '2.4rem 0',
				}}
				wrapperClass=""
				visible={true}
				ariaLabel="oval-loading"
				secondaryColor="#EAECF0"
				strokeWidth={4}
				strokeWidthSecondary={4}
			/>
			<p className="drop-area__text font-weight-normal">
				{Variable.UPLOADING_FILE[ENV_NAME]} <strong>{fileName}</strong>
			</p>
		</div>
	) : (
		<>
			<div
				className={`drop-area cursorPointer ${
					!!isFileExist && isUploadSuccess !== false
						? 'drop-area-success'
						: isUploadSuccess === null && !isFileExist
						? ''
						: 'drop-area-failed'
				}`}
				{...getRootProps()}
			>
				<input {...getInputProps()} />
				{uploadFilesCondition.map(
					({ condition, src, childrenText, id }) =>
						condition && (
							<React.Fragment key={id}>
								<Image src={src} className="placeholder-image" key={src} />
								{childrenText}
							</React.Fragment>
						),
				)}
			</div>
		</>
	);
};

export default UploadFile;
