import cookies from "react-cookies";
import usePost from "./usePost";
import { homeUrl } from "shared/utils/apiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT_SUCCEED } from "store/actions/actionTypes";
import useToast from "./useToast";
import { RootState } from "store/types/RootStateTypes";

const useAuth = () => {
	const toast = useToast();
	const { postFunction: logout } = usePost("logout");
	const dispatch = useDispatch();

	const isAuthenticated = !!cookies.load("ptkjauthtoken");

	const handleLogout = async () => {
		if (cookies.load("ptkjrefreshtoken")) {
			const res = await logout({
				body: {
					refresh_token: cookies.load("ptkjrefreshtoken"),
				},
			}).then(async (status) => {
				if (status) {
					cookies.remove("ptkjauthtoken", { path: "/", domain: homeUrl });
					cookies.remove("ptkjrefreshtoken", { path: "/", domain: homeUrl });
					cookies.remove("moduleSelector", { path: "/", domain: homeUrl });
					dispatch({
						type: LOGOUT_SUCCEED,
					});
					localStorage.clear();
				} else {
					toast.error("Something went wrong");
				}
				return status;
			});
			return res;
		}
	};

	return {
		handleLogout,
		isAuthenticated,
	};
};

export default useAuth;
