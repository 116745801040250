import { useState } from "react";
import { Modal } from "react-bootstrap";

import { Button } from "../../../../shared/component";
import { RenderIf } from "../../../../shared/utils/common";
import { FormExact } from "../../../../store/types/interview";
import Form from "./Form";
import FormSecond from "./FormSecond";
import { insertExactSchedule } from "../../../../store/actions/interview";
import moment from "moment";

type ExactTimeProps = {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
};

export default function ExactTime(props: ExactTimeProps) {
	const [form, setform] = useState<FormExact>({
		meeting_type: "",
		schedule_name: "",
		slot_target: "",
		video_call_link: "",
		date_time_schedule_exact: "",
		location: "",
		phone_number: "",
		interview_pic: "",
		message: "",
		job_ad: 1,
		job_applications: [1],
	});

	const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;

		setform((prev: FormExact) => ({
			...prev,
			// when meeting_type is onsite set value for phone and video_call_link is null
			//when meeting_type is phone  set value for location and video_call_link is null
			//when meeting_type is video set value for location and phone is null
			...(name === "meeting_type" && value === "on_site"
				? {
						phone_number: "",
						video_call_link: "",
				  }
				: name === "meeting_type" && value === "phone_call"
				? {
						location: "",
						video_call_link: "",
				  }
				: name === "meeting_type" && value === "video_call"
				? {
						location: "",
						phone_number: "",
				  }
				: {}),

			[name]: value,
		}));
	};

	const [next, setnext] = useState("one");

	const handleButtonNext = () => {
		if (next === "one") {
			setnext("second");
		} else {
			console.log(form);
			handleSendInvitation();
		}
	};

	const handleSendInvitation = async () => {
		// convert form.date_time_schedule_exact to format 2023-06-27T07:51:22.090Z use moment
		let convert = moment(form.date_time_schedule_exact).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

		return await insertExactSchedule({
			...form,
			date_time_schedule_exact: convert,
		})
			.then((res) => {
				console.log(res);
				return res;
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div>
			<Modal
				show={props.showModal}
				fullscreen
				onHide={() => props.setShowModal(false)}
				dialogClassName="right-modal"
				backdrop="static"
				contentClassName="border-0 p-0  tw rounded-l-xl"
				style={{ right: 0 }}
			>
				<Modal.Header
					closeButton
					className="tw rounded-t-xl border-b-2"
					style={{ padding: "2.4rem", backgroundColor: "#F9FAFB" }}
				>
					<Modal.Title className="text-xl">Invitation to candidate</Modal.Title>
				</Modal.Header>

				<Modal.Body className="px-lg py-md">
					<h4 className="fw-medium tw text-black text-md">Select schedule.</h4>
					<p className="text-sm fw-light text-black tw">
						Please select type for interview meeting with candidate
					</p>

					<RenderIf condition={next === "one"}>
						<Form
							form={form}
							handleChangeValue={handleChangeValue}
							typeInterview={typeInterview}
						/>
					</RenderIf>

					<RenderIf condition={next === "second"}>
						<FormSecond form={form} handleChangeValue={handleChangeValue} />
					</RenderIf>
				</Modal.Body>
				<Modal.Footer
					className="d-flex justify-content-end tw border-t-2 rounded-bl-xl box-border bg-gray-50"
					style={{ padding: "1.6rem" }}
				>
					<RenderIf condition={next === "second"}>
						<Button
							onClick={() => setnext("one")}
							size="md"
							type="textGray"
							btnClassName="border tw border-gray-500"
						>
							Back
						</Button>
					</RenderIf>

					{/* disabled is true when date_time_schedule_exact is empty and meeting_type is empty and when meeting_type is phone and phone_number is empty and when meeting_type is video and video_call_link is empty and when meeting_type is onsite and location is empty */}
					<Button
						disabled={
							form.meeting_type === "" ||
							(form.meeting_type === "phone" && form.phone_number === "") ||
							(form.meeting_type === "video" && form.video_call_link === "") ||
							(form.meeting_type === "onsite" && form.location === "")
						}
						onClick={handleButtonNext}
						size="md"
						type="primary"
					>
						{next === "one" ? "Next" : "Send Invitation"}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

const typeInterview = [
	{
		id: "phone_call",
		name: "Phone",
	},
	{
		id: "video_call",
		name: "Video Call",
	},
	{
		id: "on_site",
		name: "On Site",
	},
];
