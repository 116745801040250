import { useRef, useState } from "react";
import cookies from "react-cookies";
import {
	Briefcase,
	CaretDown,
	CaretUp,
	FolderUser,
	Money,
	Question,
	SignOut,
	User,
} from "@phosphor-icons/react";
import { Overlay } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as RoutePath from "shared/utils/routeLink";
import * as ActionTypes from "store/actions/actionTypes";

import Image from "../../component/UI/Image";
import * as Variable from "../../../shared/utils/variables";
import { getImageFromAssets } from "../../utils/common";
import defaultProfileIcon from "../../../assets/svg/default-profile-pic.svg";
import EnIcon from "../../../assets/icons/eng.png";
import IdIcon from "../../../assets/icons/indo.png";
import { homeUrl } from "../../utils/apiEndPoints";
import buildingDefault from "assets/svg/building.svg";

import "./HeaderDashboard.scss";
import { Text } from "shared/component/Typography/Typography";
import useAuth from "shared/hooks/useAuth";
import LabelStatus from "shared/component/LabelStatus/LabelStatus";
import { useEnvironment } from "shared/hooks/useEnvironment";
import usePermission from "shared/hooks/usePermission";
import useToast from "shared/hooks/useToast";
import useUserDetails from "shared/hooks/userDetails";
import { configCookies, setCookies } from "shared/utils/cookies";
import ModalInfo from "shared/component/ModalComponent/ModalInfo";
import ErrorIcon from "assets/icons/alert/alert-error.png";
import { PiUserSwitchFill } from "react-icons/pi";

const NavigationContentWrapper = ({ text, icon, isComingSoon }) => {
	const ENV_NAME = useEnvironment();
	return (
		<div className="header-dashboard__navigation-content-wrapper">
			{icon}
			<Text size="sm">{text}</Text>
			{isComingSoon && (
				<LabelStatus size="xs" type="warning" text={Variable.COMING_SOON[ENV_NAME]} />
			)}
		</div>
	);
};

export default function HeaderDashboard(props) {
	const userDetails = useSelector((state) => state.auth?.userDetails);

	// console.log(company, "babang");

	const toast = useToast();
	const [profileDropdown, setProfileDropdown] = useState(false);
	const [showLangDropdown, setShowLangDropdown] = useState(false);
	const [showModuleSelect, setShowModuleSelect] = useState(false);
	const [modalHRMSRestriction, setModalHRMSRestriction] = useState(false);

	const ENV_NAME = useSelector((state) => state.auth.selectedEnvironment);
	const { personal_info } = useUserDetails();
	const dispatch = useDispatch();
	const history = useHistory();
	const permission = usePermission();

	const { handleLogout: logoutFunc } = useAuth();

	const changeLanguage = (payload) => {
		dispatch({
			type: ActionTypes.UPDATE_LANGUAGE,
			payload: payload,
		});
	};

	const onLogout = () => {
		logoutFunc().then((status) => {
			if (status && status.code === 200) {
				toast.success(Variable.LOGOUT_SUCCEED[ENV_NAME]);
				window.open(`https://jobmarket.${homeUrl}`, "_self");
			}
		});
	};

	const openDropdown = (open = true, type = "language") => {
		if (type === "profile") {
			setProfileDropdown(open);
		} else if (type === "language") {
			setShowLangDropdown(open);
		}
	};

	const changeLang = async (lang) => {
		if (lang !== ENV_NAME) {
			let val = lang;
			await cookies.save("lang", val, {
				domain: homeUrl,
				path: "/",
				expires: new Date("9999-12-31T12:00:00.000Z"),
			});
			await changeLanguage(val);
			// window.location.reload();
		}
	};

	const profileRef = useRef(null);
	const languageRef = useRef(null);

	const handleVisitJobSeeker = () => {
		window.open(`https://jobmarket.${homeUrl}/cari-kerja`, "_self");

		setCookies("moduleSelector", "JobSeeker");
	};

	const handleSwitchModule = () => {
		if (!personal_info?.hrms_type) {
			setModalHRMSRestriction(true);
			return;
		}
		cookies.save("moduleSelector", "HRMS", configCookies);

		window.open(`https://hrms.${homeUrl}`, "_self");
	};

	return (
		<section className={`header-dashboard ${props.isSticky ? "is-sticky-header" : ""}`}>
			<div
				className={`header-margin tw transition-all duration-300 ${
					props.isCollapse ? "" : "collapse-sidebar"
				}`}
			>
				<Link to={RoutePath.ROOT}>
					<Image src={getImageFromAssets("svg/homepage.svg")} className="hn-left-img" />
				</Link>

				<div className="d-flex gap-sm align-items-center">
					<div
						className={`p-sm cursorPointer ${showLangDropdown ? "active" : ""}`}
						onMouseLeave={() => openDropdown(false, "language")}
						onClick={() => openDropdown(true, "language")}
						ref={languageRef}
					>
						<div className="flex-all-center">
							<Image
								className="hn-language me-2"
								src={ENV_NAME === "eng" ? EnIcon : IdIcon}
							/>
							{!!showLangDropdown ? (
								<CaretUp width={20} height={20} weight="bold" />
							) : (
								<CaretDown width={20} height={20} weight="bold" />
							)}
						</div>
						<Overlay
							target={languageRef.current}
							show={showLangDropdown}
							placement="bottom-end"
						>
							{({ placement, arrowProps, show: _show, popper, ...OverlayProps }) => (
								<div
									{...OverlayProps}
									id="hn-dropdown-lang"
									className="hn-dropdown hn-lang"
								>
									<button
										className={`hn-dropdown-item ${
											ENV_NAME === "bhs" ? "active" : ""
										}`}
										onClick={() => changeLang("bhs")}
									>
										<Image
											className="hn-dropdown-item-icon lang-logo"
											src={IdIcon}
											alt="logo"
										/>
										<div className="hn-dropdown-item-label">
											Bahasa Indonesia
										</div>
									</button>
									<button
										className={`hn-dropdown-item ${
											ENV_NAME === "eng" ? "active" : ""
										}`}
										onClick={() => changeLang("eng")}
									>
										<Image
											className="hn-dropdown-item-icon lang-logo"
											src={EnIcon}
											alt="logo"
										/>
										<div className="hn-dropdown-item-label">English</div>
									</button>
								</div>
							)}
						</Overlay>
					</div>
					<div
						className="d-flex align-items-center gap-2 border-end pe-sm"
						style={{
							borderColor: "#667085",
							borderWidth: "0.1rem",
						}}
					>
						<div
							className="d-flex justify-content-center align-items-center"
							style={{
								height: "40px",
								width: "40px",
							}}
						>
							<Image
								style={{
									height: "40px",
									width: "40px",
									// width: "100%",
								}}
								src={userDetails?.company?.company_logo ?? buildingDefault}
								alt="building"
							/>
						</div>
						<span className="company-description">
							<p className="company-description__company-name">
								{userDetails?.company?.name}
							</p>
							<Text
								size="sm"
								className="company-description__company-owner"
								isTruncated
							>
								{Variable.OWNER[ENV_NAME]}:{" "}
								{userDetails?.company?.created_by?.email}
							</Text>
						</span>
					</div>
					<div
						className="d-flex justify-content-center align-items-center header-dashboard__profile-details"
						ref={profileRef}
						onClick={() => openDropdown(!profileDropdown, "profile")}
						onMouseLeave={() => openDropdown(false, "profile")}
					>
						<Image
							src={personal_info?.profile_picture || defaultProfileIcon}
							style={{
								width: "28px",
								height: "28px",
								objectFit: "cover",
								borderRadius: "50rem",
							}}
						/>
						<p className="header-dashboard__profile-details_text">
							{personal_info?.first_name}
						</p>

						{!!profileDropdown ? (
							<CaretUp width={20} height={20} weight="bold" />
						) : (
							<CaretDown width={20} height={20} weight="bold" />
						)}

						<Overlay
							target={profileRef.current}
							show={profileDropdown}
							placement="bottom"
						>
							{({ placement, arrowProps, show: _show, popper, ...OverlayProps }) => (
								<div
									{...OverlayProps}
									id="hn-dropdown-profile"
									className="hn-dropdown header-dashboard__hn-dropdown"
								>
									<div className="header-dashboard__dropdown-topic">
										<Text
											className="header-dashboard__title-dropdown-topic"
											size="md"
											fontWeight="semibold"
										>
											{userDetails?.company?.name}
										</Text>
										{permission && permission.company_profile_management && (
											<Link
												to={RoutePath.COMPANY_MANAGEMENT}
												className="hn-dropdown-item"
											>
												<div className="hn-dropdown-item-label mxw-auto">
													<NavigationContentWrapper
														text={Variable.COMPANY_MANAGEMENT[ENV_NAME]}
														icon={
															<FolderUser
																width={18}
																height={18}
																weight="fill"
																color="#667085"
															/>
														}
													/>
												</div>
											</Link>
										)}
										{permission && permission.billing_management && (
											<button className="hn-dropdown-item">
												<div className="hn-dropdown-item-label mxw-auto">
													<NavigationContentWrapper
														text={
															Variable.BILLING_MANAGEMENT_LBL[
																ENV_NAME
															]
														}
														icon={
															<Money
																width={18}
																height={18}
																weight="fill"
																color="#667085"
															/>
														}
														isComingSoon
													/>
												</div>
											</button>
										)}
									</div>
									<div className="header-dashboard__dropdown-topic">
										<Text
											className="header-dashboard__title-dropdown-topic"
											size="md"
											fontWeight="semibold"
										>
											{personal_info?.email}
										</Text>
										<Link to={RoutePath.PROFILE} className="hn-dropdown-item">
											<div className="hn-dropdown-item-label mxw-auto">
												<NavigationContentWrapper
													text={Variable.PROFILE[ENV_NAME]}
													icon={
														<User
															width={18}
															height={18}
															weight="fill"
															color="#667085"
														/>
													}
												/>
											</div>
										</Link>
										<button
											className="hn-dropdown-item"
											onClick={handleVisitJobSeeker}
										>
											<div className="hn-dropdown-item-label mxw-auto">
												<NavigationContentWrapper
													text={Variable.VISIT_PORTAL_KERJA_LBL[ENV_NAME]}
													icon={
														<Briefcase
															width={18}
															height={18}
															weight="fill"
															color="#667085"
														/>
													}
												/>
											</div>
										</button>
										<button
											className="hn-dropdown-item"
											onClick={handleSwitchModule}
										>
											<div className="hn-dropdown-item-label mxw-auto" st>
												<NavigationContentWrapper
													text={Variable.SWITCH_HRMS_MODULE[ENV_NAME]}
													icon={
														<PiUserSwitchFill
															color="#667085"
															style={{
																width: "18px",
																height: "100%",
															}}
														/>
													}
												/>
											</div>
										</button>
										<button className="hn-dropdown-item">
											<div className="hn-dropdown-item-label mxw-auto">
												<NavigationContentWrapper
													text={Variable.HELP[ENV_NAME]}
													icon={
														<Question
															width={18}
															height={18}
															weight="fill"
															color="#667085"
														/>
													}
													isComingSoon
												/>
											</div>
										</button>
									</div>
									<button
										className="hn-dropdown-item hn-exit-btn"
										onClick={onLogout}
									>
										<div className="hn-dropdown-item-label mxw-auto">
											<NavigationContentWrapper
												text={Variable.SIGN_OUT[ENV_NAME]}
												icon={
													<SignOut
														width={18}
														height={18}
														weight="fill"
														color="#D92D20"
													/>
												}
											/>
										</div>
									</button>
								</div>
							)}
						</Overlay>
					</div>
				</div>
			</div>
			<ModalInfo
				isShow={modalHRMSRestriction}
				onConfirm={() => setModalHRMSRestriction(false)}
				type="other"
				title={Variable.NO_PERMISSION_HRMS_TITLE[ENV_NAME]}
				description={Variable.NO_PERMISSION_HRMS_DESC[ENV_NAME]}
				customIcon={<Image src={ErrorIcon} style={{ width: "56px", height: "56px" }} />}
				confirmButtonType="primary"
				confirmLabel={Variable.OKAY[ENV_NAME]}
				showCancelBtn={false}
			/>
		</section>
	);
}
