import { SetStateAction, useState } from "react";

import Pagination from "shared/component/Pagination/Pagination";

interface PropsTypes {
	page_size: number;
	total_items: number;
	ENV: string;
	onChangePage?: (page: number) => void;
	page: number;
	setPage: React.Dispatch<SetStateAction<number>>;
}

const PaginationWrapper = (props: PropsTypes) => {
	const paginationSize = Math.ceil((props.total_items ?? 1) / props.page_size);

	if (paginationSize <= 1) return null;
	return (
		<div className="mt-5">
			<Pagination
				selectedEnvironment={props.ENV}
				page={props.page}
				paginationSize={paginationSize}
				getRequestedPage={(inputPage: number) => {
					if (inputPage <= 0 || inputPage > paginationSize) return;

					props.setPage(Number(inputPage));
					props.onChangePage?.(Number(inputPage));
				}}
				className="py-0"
			/>
		</div>
	);
};

export default PaginationWrapper;
