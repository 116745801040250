import React from 'react';
import './Label.scss';

export default function Label({
  name,
  children,
  label,
  className,
  isRequired,
}) {
  return (
    <label htmlFor={name} className={className}>
      {children || label}
      {isRequired && <span style={{ color: '#f04438' }}>*</span>}
    </label>
  );
}
