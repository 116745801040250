export const ROOT = "/";
export const COLLEGE = "/kampus";
export const MAJOR = "/major";
export const CAREER = "/karir";
export const SCHOLARSHIP = "/beasiswa";
export const FAVORITE = "/favorit";
export const PROFILE = "/profile";
export const CREATE_ACCOUNT = "/buat-akun";
export const FORGOT_PASSWORD = "/lupa-password";
export const COLLEGE_DETAIL = "/detail-kampus";
export const MAJOR_DETAIL = "/detail-jurusan";
export const CAREER_DETAIL = "/detail-karir";
export const SCHOLARSHIP_DETAIL = "/detail-beasiswa";

export const DASHBOARD = "/dashboard";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_OF_USE = "/terms-of-use";
export const CHANGE_PASSWORD = "/change-password";
export const CHANGE_SYSTEM_PASSWORD = "/change-system-password";
export const COMPANY_MANAGEMENT = "/company-management";

// Job Poster
export const ALL_COMPONENT = "/all-component";
export const VERIFICATION = "/verifikasi";
export const JOB_SEARCH = "/cari-kerja";
export const JOB_DETAIL = "/info-kerja";
export const SIGN_UP = "/daftar";
export const JOB_AD = "/pekerjaan-ad";
export const VERIFY_COMPANY = "/verifikasi-perusahaan";

// Employer
export const EMPLOYER = "/employer";
export const ADD_USER = "/employer/add-user";

//Candidate Management
export const CANDIDATE_MANAGEMENT = "/candidate-management";
export const JOB_AD_LISTING = "/job-ads-list";
export const PREVIEW_JOB_AD = "/job-ads-list/preview";
export const INTERVIEW_SCHEDULE = "/interview-schedule";
export const TIME_SLOT = "/time-slot";
export const INTERVIEW_TYPE = "/interview-type";
export const INTERVIEW_ADD = "/interview-add";

export const SELECT_PARENT_COMPANY = "/select-parent-company";
