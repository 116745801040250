import { ArrowLeft } from '@phosphor-icons/react';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '../../../shared/component';
import LayoutWithNavigation from '../../../shared/layouts/LayoutWithNavigation';

import { useDispatch } from 'react-redux';
import { setInterviewType } from '../../../store/actions/interview';
import './InterviewType.scss';
import ListType from './components/ListType';

export default function InterviewType() {
  const route = useHistory();
  const dispatch = useDispatch();

  const handleClick = (item) => {
    dispatch(setInterviewType(item.name));
    setTimeout(() => {
      route.push('/interview-add');
    }, 200);
  };

  return (
    <LayoutWithNavigation>
      {/* Container */}
      <div className="position-relative d-flex justify-content-center align-items-center p-lg mt-lg">
        {/* Content Container */}
        <div className="bg-white w-75 p-lg tw rounded-xl">
          {/* Head Section */}
          <div className="position-relative d-flex justify-content-center align-items-center">
            <Button
              type="textGray"
              size="sm"
              className="position-absolute start-0"
              onClick={() => route.push('/time-slot')}
              btnClassName="border tw border-gray-500 d-flex gap-2 align-items-center">
              <ArrowLeft weight="bold" height={14} width={14} />
              Back
            </Button>
            <h3 className="text-display-xs heading">Time Slot Type</h3>
            <div></div>
          </div>

          {/* Content Section */}
          <div className="position-relative d-flex flex-column gap-4 mt-xl">
            {type.map((item) => (
              <ListType
                key={item.name}
                desc={item.desc}
                name={item.name}
                onClick={() => handleClick(item)}
              />
            ))}
          </div>
        </div>
      </div>
    </LayoutWithNavigation>
  );
}

const type = [
  {
    name: 'one-on-one',
    desc: 'One host with one invite',
  },
  {
    name: 'group',
    desc: 'One host with group candidate',
  },
];
