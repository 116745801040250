import { useEffect, useState } from "react";
import { PiArrowLeft } from "react-icons/pi";
import { useHistory } from "react-router-dom/";
import queryString from "query-string";

import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import { Button } from "../../shared/component";
import TinyMCE from "./../../shared/component/molecules/TinyMCE/TinyMCE";

import Form from "./Reusable/Form";

import { ENV, Nullable } from "../../types";
import { countCharactersWithoutTags } from "shared/utils/stringUtils";
import { Editor } from "@tinymce/tinymce-react";
import useTranslate from "shared/hooks/useTranslate";
import useToast from "shared/hooks/useToast";

interface Props {
	ENV_NAME: ENV;
	jobAdId: Nullable<number>;
	onNext: () => void;
	onPrev: () => void;
}

interface FormFields {
	job_requirements: string;
	job_description: string;
	job_benefits: string;
}

interface FormError {
	job_requirements: boolean;
	job_description: boolean;
	job_benefits: boolean;
}

const JobDetails = ({ ENV_NAME, jobAdId, onNext, onPrev }: Props) => {
	const t = useTranslate();
	const toast = useToast();
	const EDITORS = [
		{
			id: 1,
			name: "job_requirements",
			label: t("JOB_REQUIREMENT_QUALIFICATION"),
			description: t("JOB_REQUIREMENT_DESC"),
			placeholder: t("INPUT") + " " + t("JOB_REQUIREMENT_PLACEHOLDER").toLowerCase(),
			minLimit: 200,
			maxLimit: 10000,
		},
		{
			id: 2,
			name: "job_description",
			label: t("JOB_DESC"),
			description: t("JOB_DESC_TEXT"),
			placeholder: t("INPUT") + " " + t("JOB_DESC").toLowerCase(),
			minLimit: 200,
			maxLimit: 10000,
		},
		{
			id: 3,
			name: "job_benefits",
			label: t("JOB_BENEFIT_EDITOR_HEADER"),
			description: t("JOB_BENEFIT_DESC"),
			placeholder: t("INPUT") + " " + t("JOB_BENEFIT_EDITOR_HEADER").toLowerCase(),
			minLimit: 5,
			maxLimit: 10000,
		},
	];
	const history = useHistory();
	const jobIdFromUrl = (queryString.parse(history.location.search)?.job_ad ||
		null) as Nullable<string>;

	const [form, setForm] = useState({
		job_requirements: "",
		job_description: "",
		job_benefits: "",
	});
	const [errors, setErrors] = useState({} as FormError);
	const [isEdited, setIsEdited] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	useEffect(() => {
		if (jobIdFromUrl) {
			getJob(jobIdFromUrl);
			setIsEditing(true);
		}
	}, [jobIdFromUrl]);

	const getJob = async (jobId: string) => {
		const res = await makeRequest(generateRequestOptions("getJobAd", { urlParams: jobId }));

		if (res?.code === 200) {
			setForm({
				job_requirements: res.data.job_requirements || undefined,
				job_description: res.data.job_description || undefined,
				job_benefits: res.data.job_benefits || undefined,
			});
		} else toast.error(res.message);
	};

	const handleChange = (value: string, editor: Editor["editor"]) => {
		const name = editor?.targetElm.getAttribute("name") ?? "";
		const currentLength = countCharactersWithoutTags(value);
		const maxLength = EDITORS.find((editor) => editor.name === name)?.maxLimit || 10000;

		if (currentLength <= maxLength) {
			setForm((prev) => ({
				...prev,
				[name]: value,
			}));
			setErrors((prev) => ({ ...prev, [name]: false }));
			setIsEdited(true);
		}
	};

	const handleSave = async () => {
		// Need to save the data after button next is clicked
		// if (isEditing && !isEdited) return onNext();

		const { job_requirements, job_description, job_benefits } = form;

		const errors = {} as FormError;
		if (!job_requirements || countCharactersWithoutTags(job_requirements) < 200)
			errors["job_requirements"] = true;
		if (!job_description || countCharactersWithoutTags(job_description) < 200)
			errors["job_description"] = true;
		if (!job_benefits || countCharactersWithoutTags(job_benefits) < 5)
			errors["job_benefits"] = true;
		const hasError = Object.values(errors).some((err) => err);
		if (hasError) return setErrors(errors);

		const data = {
			job_requirements,
			job_description,
			job_benefits,
		};
		const res = await makeRequest({
			...generateRequestOptions("updateJobAd", { urlParams: jobAdId || jobIdFromUrl }),
			data,
		});
		if (res?.code === 200) {
			onNext();
		} else toast.error(res.message);
	};

	return (
		<div className="form-block">
			<Form title={t("JOB_DETAIL_HEAD")} isRequired>
				{EDITORS.map((item) => (
					<TinyMCE
						key={item.id}
						ENV_NAME={ENV_NAME}
						name={item.name}
						label={item.label}
						placeholder={item.placeholder}
						handleChange={handleChange}
						value={form[item.name as keyof FormFields]}
						description={item.description}
						minLimit={item.minLimit}
						maxLimit={item.maxLimit}
						isError={errors[item.name as keyof FormError]}
					/>
				))}
			</Form>

			<div className="btn-grp">
				<Button
					type="outline"
					title={t("PREVIOUS_LABEL")}
					onClick={onPrev}
					btnClassName="customBtn"
					customIcon={<PiArrowLeft size={18} className="icon icon-prev" />}
				/>
				<Button
					type="primary"
					title={t("SAVE_CONTINUE_LABEL")}
					onClick={handleSave}
					btnClassName="customBtn btn-save"
					disabled={
						!form?.job_requirements || !form?.job_description || !form?.job_benefits
					}
				/>
			</div>
		</div>
	);
};

export default JobDetails;
