import { X } from '@phosphor-icons/react';
import cx from 'classnames';
import { Modal } from 'react-bootstrap';
import useTranslate from 'shared/hooks/useTranslate';

export default function Download({
    setDownloadType,
    handlerCloseModal,
    downloadType,
    bulkDownload,
    candidateCheckboxes,
    exportResume,
    bulkExport
}) {
    const t = useTranslate()
    return (
        <>
        <Modal.Header>
            <span>
                {
                    bulkDownload
                        ? t("DOWNLOAD_TOTAL_CANDIDATE").replace("{{_total_}}", candidateCheckboxes.filter((x) => x === true).length)
                        : t("DOWNLOAD")
                }
            </span>

            <X className={'close'} onClick={handlerCloseModal} />
        </Modal.Header>
        <Modal.Body>
            <div
                onClick={() => setDownloadType(1)}
                className={cx('option-div', { 'selected': downloadType === 1 })}
            >
                <p className="option-heading">{t("DOWNLOAD_RESUME_ONLY")}</p>
                <p className="option-desc">{t("DOWNLOAD_DESCRIPTION_1")}</p>
            </div>
            <div
                onClick={() => setDownloadType(2)}
                className={cx('option-div', { 'selected': downloadType === 2 })}
            >
            <p className="option-heading">{t("DOWNLOAD_ALL_FILES")}</p>
            <p className="option-desc">
                {t("DOWNLOAD_DESCRIPTION_2")}
            </p>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div onClick={handlerCloseModal} className="btn btn-cancel">
                {t("CANCEL_LABEL")}
            </div>
            <div
                onClick={()=>{
                    if (bulkDownload) return bulkExport()
                    return exportResume()
                }}
                className={cx('btn', 'btn-download', { 'disabled': !downloadType })}
            >
                {t("DOWNLOAD")}
            </div>
        </Modal.Footer>
        </>
    );
}
