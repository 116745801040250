export const MoneyOutlineIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '32'}
      height={props.height || '32'}
      viewBox="0 0 32 32"
      style={{ minWidth: props.width || '32', ...(props.style || {}) }}
    >
      <path
        d="M29 8H3C2.44772 8 2 8.44772 2 9V23C2 23.5523 2.44772 24 3 24H29C29.5523 24 30 23.5523 30 23V9C30 8.44772 29.5523 8 29 8Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 8L30 15"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 24L30 17"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 8L2 15"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 24L2 17"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CloseEyeIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '32'}
      height={props.height || '32'}
      viewBox="0 0 32 32"
    >
      <path
        d="M25.1377 15.9121L28.0002 20.8496"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2754 18.6621L20.1629 23.6996"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7127 18.6504L11.8252 23.7004"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.8498 15.9121L3.9873 20.8746"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 13.1133C6.1 15.7133 9.95 19.0008 16 19.0008C22.05 19.0008 25.9 15.7133 28 13.1133"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BuildingOutlineIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '14'}
      height={props.height || '14'}
      viewBox="0 0 14 14"
      style={{ minWidth: props.width || '14', ...(props.style || {}) }}
    >
      <path
        d="M0.875 11.8125H13.125"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.875 11.8125V2.1875C7.875 2.07147 7.82891 1.96019 7.74686 1.87814C7.66481 1.79609 7.55353 1.75 7.4375 1.75H2.1875C2.07147 1.75 1.96019 1.79609 1.87814 1.87814C1.79609 1.96019 1.75 2.07147 1.75 2.1875V11.8125"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 11.8125V5.6875C12.25 5.57147 12.2039 5.46019 12.1219 5.37814C12.0398 5.29609 11.9285 5.25 11.8125 5.25H7.875"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 3.9375H5.25"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 7.4375H6.125"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 9.625H5.25"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.625 9.625H10.5"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.625 7.4375H10.5"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BriefcaseOutlineIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '14'}
      height={props.height || '14'}
      viewBox="0 0 14 14"
      style={{ minWidth: props.width || '14', ...(props.style || {}) }}
    >
      <path
        d="M11.8125 3.9375H2.1875C1.94588 3.9375 1.75 4.13338 1.75 4.375V11.375C1.75 11.6166 1.94588 11.8125 2.1875 11.8125H11.8125C12.0541 11.8125 12.25 11.6166 12.25 11.375V4.375C12.25 4.13338 12.0541 3.9375 11.8125 3.9375Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1875 3.9375V3.0625C9.1875 2.83044 9.09531 2.60788 8.93122 2.44378C8.76712 2.27969 8.54456 2.1875 8.3125 2.1875H5.6875C5.45544 2.1875 5.23288 2.27969 5.06878 2.44378C4.90469 2.60788 4.8125 2.83044 4.8125 3.0625V3.9375"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 6.90698C10.6544 7.82979 8.84326 8.31464 7 8.31245C5.1564 8.31725 3.34462 7.83222 1.75 6.90698"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.34375 6.5625H7.65625"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IdCardIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '14'}
      height={props.height || '14'}
      viewBox="0 0 14 14"
      style={{ minWidth: props.width || '14', ...(props.style || {}) }}
    >
      <path
        d="M7 9.1875C7.9665 9.1875 8.75 8.404 8.75 7.4375C8.75 6.471 7.9665 5.6875 7 5.6875C6.0335 5.6875 5.25 6.471 5.25 7.4375C5.25 8.404 6.0335 9.1875 7 9.1875Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 10.5C4.68064 10.0925 5.07696 9.76172 5.53258 9.53391C5.9882 9.3061 6.4906 9.1875 7 9.1875C7.5094 9.1875 8.0118 9.3061 8.46742 9.53391C8.92304 9.76172 9.31936 10.0925 9.625 10.5"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.375 11.8125V2.1875C11.375 1.94588 11.1791 1.75 10.9375 1.75L3.0625 1.75C2.82087 1.75 2.625 1.94588 2.625 2.1875V11.8125C2.625 12.0541 2.82087 12.25 3.0625 12.25H10.9375C11.1791 12.25 11.375 12.0541 11.375 11.8125Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 3.5H8.75"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BookmarkIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox="0 0 24 24"
      style={{ minWidth: props.width || '24', ...(props.style || {}) }}
    >
      <path
        d="M18 21L12 17.25L6 21V4.5C6 4.30109 6.07902 4.11032 6.21967 3.96967C6.36032 3.82902 6.55109 3.75 6.75 3.75H17.25C17.4489 3.75 17.6397 3.82902 17.7803 3.96967C17.921 4.11032 18 4.30109 18 4.5V21Z"
        stroke={props.stroke || 'black'}
        fill={props.fill || 'transparent'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CaretDown = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox="0 0 24 24"
      style={{ minWidth: props.width || '24', ...(props.style || {}) }}
    >
      <path
        d="M20 9L12.5 16.5L5 9"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '2'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PlusIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '28'}
      height={props.height || '28'}
      viewBox="0 0 28 28"
      style={{ minWidth: props.width || '28', ...(props.style || {}) }}
    >
      <path
        d="M4.375 14H23.625"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 4.375V23.625"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PencilIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '28'}
      height={props.height || '28'}
      viewBox="0 0 28 28"
      style={{ minWidth: props.width || '28', ...(props.style || {}) }}
    >
      <path
        d="M10.1391 23.6249H5.25001C5.01794 23.6249 4.79538 23.5327 4.63129 23.3686C4.46719 23.2045 4.37501 22.982 4.37501 22.7499V17.8608C4.37461 17.7472 4.39662 17.6346 4.43979 17.5295C4.48295 17.4244 4.54643 17.3289 4.62657 17.2483L17.7516 4.12334C17.833 4.04067 17.93 3.97501 18.0371 3.9302C18.1441 3.88538 18.259 3.8623 18.375 3.8623C18.491 3.8623 18.6059 3.88538 18.7129 3.9302C18.82 3.97501 18.917 4.04067 18.9984 4.12334L23.8766 9.00147C23.9592 9.08288 24.0249 9.17993 24.0697 9.28696C24.1145 9.39399 24.1376 9.50887 24.1376 9.6249C24.1376 9.74094 24.1145 9.85581 24.0697 9.96284C24.0249 10.0699 23.9592 10.1669 23.8766 10.2483L10.7516 23.3733C10.671 23.4535 10.5755 23.517 10.4704 23.5601C10.3653 23.6033 10.2527 23.6253 10.1391 23.6249Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.875 7L21 13.125"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TrashIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '28'}
      height={props.height || '28'}
      viewBox="0 0 28 28"
      style={{ minWidth: props.width || '28', ...(props.style || {}) }}
    >
      <path
        d="M23.625 6.125H4.375"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokelinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.375 11.375V18.375"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokelinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.625 11.375V18.375"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokelinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.875 6.125V22.75C21.875 22.9821 21.7828 23.2046 21.6187 23.3687C21.4546 23.5328 21.2321 23.625 21 23.625H7C6.76794 23.625 6.54538 23.5328 6.38128 23.3687C6.21719 23.2046 6.125 22.9821 6.125 22.75V6.125"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokelinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.375 6.125V4.375C18.375 3.91087 18.1906 3.46575 17.8624 3.13756C17.5342 2.80937 17.0891 2.625 16.625 2.625H11.375C10.9109 2.625 10.4658 2.80937 10.1376 3.13756C9.80937 3.46575 9.625 3.91087 9.625 4.375V6.125"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokelinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ArrowLeftIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '28'}
      height={props.height || '28'}
      viewBox="0 0 28 28"
      style={{ minWidth: props.width || '28', ...(props.style || {}) }}
    >
      <path
        d="M23.625 14H4.375"
        stroke={props.stroke}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 6.125L4.375 14L12.25 21.875"
        stroke={props.stroke}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MailIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox="0 0 24 24"
      style={{ minWidth: props.width || '24', ...(props.style || {}) }}
    >
      <path
        d="M3 5.25H21V18C21 18.1989 20.921 18.3897 20.7803 18.5303C20.6397 18.671 20.4489 18.75 20.25 18.75H3.75C3.55109 18.75 3.36032 18.671 3.21967 18.5303C3.07902 18.3897 3 18.1989 3 18V5.25Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 5.25L12 13.5L3 5.25"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FlagIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox="0 0 24 24"
      style={{ minWidth: props.width || '24', ...(props.style || {}) }}
    >
      <path
        d="M3.75 20.25V4.5"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 15.7502C9.75 11.2502 14.25 20.2502 20.25 15.7502V4.50021C14.25 9.00021 9.75 0.000209957 3.75 4.50021"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PhoneIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox="0 0 24 24"
      style={{ minWidth: props.width || '24', ...(props.style || {}) }}
    >
      <path
        d="M8.67187 11.6998C9.44364 13.2935 10.7324 14.579 12.3281 15.3467C12.4458 15.4024 12.576 15.4265 12.7059 15.4167C12.8358 15.4068 12.9608 15.3633 13.0687 15.2904L15.4125 13.7248C15.516 13.6546 15.6357 13.6117 15.7603 13.6002C15.8849 13.5887 16.0104 13.609 16.125 13.6592L20.5125 15.5435C20.6625 15.6059 20.7877 15.7159 20.869 15.8565C20.9504 15.9971 20.9832 16.1606 20.9625 16.3217C20.8234 17.407 20.2937 18.4046 19.4723 19.1276C18.6509 19.8506 17.5943 20.2495 16.5 20.2498C13.1185 20.2498 9.87548 18.9065 7.48439 16.5154C5.0933 14.1243 3.75 10.8813 3.75 7.49979C3.75025 6.40553 4.1492 5.34886 4.87221 4.5275C5.59522 3.70613 6.59274 3.17635 7.67812 3.03729C7.83922 3.01659 8.00266 3.04943 8.14326 3.13074C8.28386 3.21206 8.39384 3.33733 8.45625 3.48729L10.3406 7.88416C10.3896 7.99699 10.4101 8.12013 10.4003 8.24275C10.3905 8.36537 10.3507 8.48369 10.2844 8.58729L8.71875 10.9685C8.64905 11.0762 8.60814 11.2 8.59993 11.328C8.59172 11.4561 8.61649 11.5841 8.67187 11.6998Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BirthCakeIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox="0 0 24 24"
      style={{ minWidth: props.width || '24', ...(props.style || {}) }}
    >
      <path
        d="M12 8.25V6"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6C16.3313 4.5 12 0.75 12 0.75C12 0.75 7.5 4.5 12 6Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1875 11.8125C15.1875 12.6579 14.8517 13.4686 14.2539 14.0664C13.6561 14.6642 12.8454 15 12 15C11.1546 15 10.3439 14.6642 9.7461 14.0664C9.14832 13.4686 8.8125 12.6579 8.8125 11.8125"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.81252 11.8125C8.8127 12.6466 8.4859 13.4476 7.90224 14.0435C7.31859 14.6395 6.5246 14.9828 5.69064 15C3.90002 15.0375 2.43752 13.5375 2.43752 11.7469V10.5C2.43628 10.2042 2.49363 9.91106 2.60626 9.63752C2.7189 9.36397 2.88458 9.11544 3.09376 8.90626C3.30294 8.69708 3.55147 8.5314 3.82502 8.41876C4.09856 8.30613 4.3917 8.24878 4.68752 8.25002H19.3125C19.6083 8.24878 19.9015 8.30613 20.175 8.41876C20.4486 8.5314 20.6971 8.69708 20.9063 8.90626C21.1155 9.11544 21.2811 9.36397 21.3938 9.63752C21.5064 9.91106 21.5638 10.2042 21.5625 10.5V11.7469C21.5625 13.5375 20.1 15.0375 18.3094 15C17.4754 14.9828 16.6814 14.6395 16.0978 14.0435C15.5141 13.4476 15.1873 12.6466 15.1875 11.8125"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.25 14.3711V19.4992C20.25 19.6981 20.171 19.8889 20.0303 20.0295C19.8897 20.1702 19.6989 20.2492 19.5 20.2492H4.5C4.30109 20.2492 4.11032 20.1702 3.96967 20.0295C3.82902 19.8889 3.75 19.6981 3.75 19.4992V14.3711"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PinIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox="0 0 24 24"
      style={{ minWidth: props.width || '24', ...(props.style || {}) }}
    >
      <path
        d="M12 12.75C13.6569 12.75 15 11.4069 15 9.75C15 8.09315 13.6569 6.75 12 6.75C10.3431 6.75 9 8.09315 9 9.75C9 11.4069 10.3431 12.75 12 12.75Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 9.75C19.5 16.5 12 21.75 12 21.75C12 21.75 4.5 16.5 4.5 9.75C4.5 7.76088 5.29018 5.85322 6.6967 4.4467C8.10322 3.04018 10.0109 2.25 12 2.25C13.9891 2.25 15.8968 3.04018 17.3033 4.4467C18.7098 5.85322 19.5 7.76088 19.5 9.75Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FileIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox="0 0 24 24"
      style={{ minWidth: props.width || '24', ...(props.style || {}) }}
    >
      <path
        d="M18.75 21H5.25C5.05109 21 4.86032 20.921 4.71967 20.7803C4.57902 20.6397 4.5 20.4489 4.5 20.25V3.75C4.5 3.55109 4.57902 3.36032 4.71967 3.21967C4.86032 3.07902 5.05109 3 5.25 3H14.25L19.5 8.25V20.25C19.5 20.4489 19.421 20.6397 19.2803 20.7803C19.1397 20.921 18.9489 21 18.75 21Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 3V8.25H19.5"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.75H15"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 15.75H15"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ClipIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox="0 0 24 24"
      style={{ minWidth: props.width || '24', ...(props.style || {}) }}
    >
      <path
        d="M14.9999 7.49912L7.19054 15.4397C6.932 15.7251 6.79309 16.0989 6.80257 16.4838C6.81205 16.8688 6.96919 17.2353 7.24146 17.5076C7.51372 17.7799 7.88027 17.937 8.2652 17.9465C8.65012 17.9559 9.02396 17.817 9.30929 17.5585L18.6187 8.11787C19.1357 7.5472 19.4136 6.79953 19.3946 6.02968C19.3757 5.25982 19.0614 4.52673 18.5168 3.9822C17.9723 3.43766 17.2392 3.12338 16.4694 3.10443C15.6995 3.08547 14.9518 3.36329 14.3812 3.88037L5.07179 13.321C4.2289 14.1639 3.75537 15.3071 3.75537 16.4991C3.75537 17.6911 4.2289 18.8344 5.07179 19.6772C5.91468 20.5201 7.05789 20.9937 8.24992 20.9937C9.44195 20.9937 10.5852 20.5201 11.428 19.6772L19.1249 11.9991"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MaleIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '32'}
      height={props.height || '32'}
      viewBox="0 0 32 32"
      style={{ minWidth: props.width || '32', ...(props.style || {}) }}
    >
      <path
        d="M13.0424 28C18.013 28 22.0424 23.9706 22.0424 19C22.0424 14.0294 18.013 10 13.0424 10C8.07189 10 4.04245 14.0294 4.04245 19C4.04245 23.9706 8.07189 28 13.0424 28Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.405 12.6375L27.0425 5"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0424 5H27.0424V11"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FemaleIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '32'}
      height={props.height || '32'}
      viewBox="0 0 32 32"
      style={{ minWidth: props.width || '32', ...(props.style || {}) }}
    >
      <path
        d="M16.0424 21C21.013 21 25.0424 16.9706 25.0424 12C25.0424 7.02944 21.013 3 16.0424 3C11.0719 3 7.04245 7.02944 7.04245 12C7.04245 16.9706 11.0719 21 16.0424 21Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0424 21V30"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0424 26H21.0424"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PreviewResumeIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '28'}
      height={props.height || '28'}
      viewBox="0 0 28 28"
      style={{ minWidth: props.width || '28', ...(props.style || {}) }}
    >
      <path
        d="M21.875 24.5H6.125C5.89294 24.5 5.67038 24.4078 5.50628 24.2437C5.34219 24.0796 5.25 23.8571 5.25 23.625V4.375C5.25 4.14294 5.34219 3.92038 5.50628 3.75628C5.67038 3.59219 5.89294 3.5 6.125 3.5H16.625L22.75 9.625V23.625C22.75 23.8571 22.6578 24.0796 22.4937 24.2437C22.3296 24.4078 22.1071 24.5 21.875 24.5Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.625 3.5V9.625H22.75"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7283 18.3525L17.4783 20.1025"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5625 19.25C15.2539 19.25 16.625 17.8789 16.625 16.1875C16.625 14.4961 15.2539 13.125 13.5625 13.125C11.8711 13.125 10.5 14.4961 10.5 16.1875C10.5 17.8789 11.8711 19.25 13.5625 19.25Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DownloadIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '28'}
      height={props.height || '28'}
      viewBox="0 0 28 28"
      style={{ minWidth: props.width || '28', ...(props.style || {}) }}
    >
      <path
        d="M14 3.5V20.125"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.125 12.25L14 20.125L21.875 12.25"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 23.625H23.625"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth={props.strokeWidth || '1.75'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const OpenEyeIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '32'}
      height={props.height || '32'}
      viewBox="0 0 32 32"
      style={{ minWidth: props.width || '32', ...(props.style || {}) }}
    >
      <path
        d="M16 7C6 7 2 16 2 16C2 16 6 25 16 25C26 25 30 16 30 16C30 16 26 7 16 7Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const RepeatIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '16'}
      height={props.height || '16'}
      viewBox="0 0 16 16"
      style={{ minWidth: props.width || '16', ...(props.style || {}) }}
    >
      <path
        d="M12.5 5.5L14 4L12.5 2.5"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8C2.00165 6.93964 2.42361 5.92319 3.1734 5.1734C3.92319 4.42361 4.93964 4.00165 6 4H14"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 10.5L2 12L3.5 13.5"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 8C13.9983 9.06036 13.5764 10.0768 12.8266 10.8266C12.0768 11.5764 11.0604 11.9983 10 12H2"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const KebabIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '32'}
      height={props.height || '32'}
      viewBox="0 0 32 32"
      style={{ minWidth: props.width || '32', ...(props.style || {}) }}
    >
      <path
        d="M16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M16 9C17.6569 9 19 7.65685 19 6C19 4.34315 17.6569 3 16 3C14.3431 3 13 4.34315 13 6C13 7.65685 14.3431 9 16 9Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M16 29C17.6569 29 19 27.6569 19 26C19 24.3431 17.6569 23 16 23C14.3431 23 13 24.3431 13 26C13 27.6569 14.3431 29 16 29Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
export const RedCrossIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '32'}
      height={props.height || '32'}
      viewBox="0 0 32 32"
      style={{ minWidth: props.width || '32', ...(props.style || {}) }}
    >
      <path
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M20 12L12 20"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20L12 12"
        stroke={props.stroke || 'black'}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const IconPortalSekolah = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '96'}
      height={props.height || '96'}
      viewBox="0 0 96 96"
    >
      <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)">
        <path
          d="M195 755 c-19 -10 -31 -12 -33 -6 -10 30 -82 1 -113 -45 -17 -26 -19 -48 -19 -252 0 -242 3 -260 56 -301 15 -12 40 -21 55 -21 l29 0 0 134 0 135 35 -15 c77 -32 167 -1 220 74 26 38 30 53 30 110 0 77 -19 118 -76 164 -46 37 -137 49 -184 23z m97 -122 c26 -23 25 -96 -2 -123 -28 -28 -84 -27 -104 2 -21 30 -20 96 1 120 20 23 80 24 105 1z"
          fill={props.fill || 'black'}
        />
        <path
          d="M607 756 c-78 -28 -112 -81 -105 -164 4 -46 10 -60 35 -82 31 -28 83 -51 160 -70 97 -24 110 -82 20 -88 -38 -3 -49 1 -64 19 -56 71 -94 92 -149 81 -24 -4 -26 -8 -21 -39 14 -81 90 -154 182 -173 96 -20 209 14 249 75 22 33 22 137 -1 171 -20 31 -84 64 -180 93 -39 11 -73 27 -76 34 -17 48 66 66 101 22 18 -23 27 -25 92 -25 l73 0 -7 33 c-8 41 -71 102 -122 116 -53 15 -141 13 -187 -3z"
          fill={props.fill || 'black'}
        />
      </g>
    </svg>
  );
};
export const IconCollegeDirectory = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width || '116'}
      height={props.height || '116'}
      viewBox="0 0 116 116"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.47715 0 0 4.47715 0 10V106C0 111.523 4.47715 116 10 116H106C111.523 116 116 111.523 116 106V10C116 4.47715 111.523 0 106 0H10ZM52.9026 94.6771H35.9021C30.3793 94.6771 25.9021 90.2 25.9021 84.6771V31.3229C25.9021 25.8 30.3793 21.3229 35.9021 21.3229H52.9026C73.2974 21.3229 90.0979 37.377 90.0979 58.0026C90.0979 78.623 73.1994 94.6771 52.9026 94.6771Z"
        fill="url(#paint0_linear_243_2593)"
      />
      <path
        d="M65.7944 73.5081C66.8445 73.0479 67.8581 72.5085 68.8266 71.8945V79.2789C68.8266 79.6817 68.6669 80.0679 68.3825 80.3527C68.0982 80.6375 67.7126 80.7975 67.3105 80.7975C66.9084 80.7975 66.5228 80.6375 66.2385 80.3527C65.9542 80.0679 65.7944 79.6817 65.7944 79.2789V73.5081ZM54.5945 51.2221C54.4108 51.581 54.3734 51.9972 54.4901 52.3832C54.6067 52.7691 54.8683 53.0947 55.2199 53.2913L64.0889 58.018L67.3105 56.2906L56.6601 50.5957C56.3019 50.4117 55.8863 50.3742 55.501 50.4911C55.1157 50.6079 54.7907 50.87 54.5945 51.2221ZM79.4012 50.5957L56.6601 38.4466C56.4365 38.3346 56.19 38.2764 55.94 38.2764C55.69 38.2764 55.4435 38.3346 55.2199 38.4466L32.4788 50.5957C32.2377 50.7283 32.0366 50.9233 31.8966 51.1604C31.7565 51.3975 31.6826 51.668 31.6826 51.9435C31.6826 52.219 31.7565 52.4894 31.8966 52.7265C32.0366 52.9636 32.2377 53.1586 32.4788 53.2913L36.9891 55.6831V65.1366C36.9863 65.7884 37.1996 66.4227 37.5955 66.94C39.0927 68.9522 44.8537 75.4823 55.94 75.4823C59.3273 75.5106 62.6835 74.8318 65.7944 73.4891V58.9292L64.0889 58.018L55.94 62.3651L39.2253 53.4431L36.4206 51.9435L55.94 41.5218L75.4594 51.9435L72.6547 53.4431L67.3105 56.2906L68.0306 56.6702C68.287 56.8185 68.497 57.0354 68.6371 57.2967C68.7631 57.516 68.8285 57.765 68.8266 58.018V71.8945C70.9207 70.5749 72.7679 68.898 74.2844 66.94C74.6804 66.4227 74.8937 65.7884 74.8909 65.1366V55.6831L79.4012 53.2913C79.6423 53.1586 79.8433 52.9636 79.9834 52.7265C80.1235 52.4894 80.1974 52.219 80.1974 51.9435C80.1974 51.668 80.1235 51.3975 79.9834 51.1604C79.8433 50.9233 79.6423 50.7283 79.4012 50.5957Z"
        fill="url(#paint1_linear_243_2593)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_243_2593"
          x1="116"
          y1="4.60568e-05"
          x2="-8.00839"
          y2="9.30824"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E63BE" />
          <stop offset="1" stopColor="#4A8DE5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_243_2593"
          x1="80.1974"
          y1="38.2764"
          x2="28.4208"
          y2="42.7106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E63BE" />
          <stop offset="1" stopColor="#4A8DE5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
