import { FC } from 'react';
import NoCandidate from 'assets/svg/no-candidate.svg';
import Image from 'shared/component/UI/Image';
import useTranslate from 'shared/hooks/useTranslate';

interface CardNotSelectedProps {
	description: string;
	children?: any;
}

const CardNotSelected: FC<CardNotSelectedProps> = ({ description, children = '' }) => {
	const t = useTranslate();
	return (
		<div className="no-data-div">
			<Image src={NoCandidate} />
			<p className="para1">{t("NO_CANDIDATE")}</p>
			<p className="para2">{description}</p>
			{children}
		</div>
	);
}

export default CardNotSelected;