import React from "react";
import "./CardJobList.scss";
import TagComponent from "../../../../shared/component/TagComponent/TagComponent";
import * as Variable from "../../../../shared/utils/variables";
import { useSelector } from "react-redux";
import moment from "moment";
import { RootState } from "store/types/RootStateTypes";
import LabelStatus from "shared/component/LabelStatus/LabelStatus";
import useTranslate from "shared/hooks/useTranslate";
import { CalendarBlank } from "@phosphor-icons/react/dist/ssr";

const CardJobList = ({
	data,
	activeTab,
	handleClick,
	handleClickCollaborate,
	handleClickView,
	handleClickRepost,
	handleClickDeactivate,
	handleClickEdit,
	handleClickDelete,
}) => {
	const ENV_NAME = useSelector((state: RootState) => state.auth.selectedEnvironment);
	const t = useTranslate();
	const STATUS_TYPE = {
		active: Variable.ACTIVE[ENV_NAME],
		inactive: Variable.INACTIVE[ENV_NAME],
		draft: Variable.DRAFT[ENV_NAME],
	};

	const buttonGroups = {
		draft: [
			{
				label: Variable.EDIT[ENV_NAME],
				onClick: handleClickEdit,
			},
			{
				label: Variable.DELETE_LABEL[ENV_NAME],
				onClick: handleClickDelete,
			},
		],
		active: [
			{
				label: Variable.VIEW[ENV_NAME],
				onClick: handleClickView,
			},
			{
				label: Variable.EDIT[ENV_NAME],
				onClick: handleClickEdit,
			},
			{
				label: Variable.DEACTIVATE[ENV_NAME],
				onClick: handleClickDeactivate,
			},
			{
				label: Variable.COLLABORATE[ENV_NAME],
				onClick: handleClickCollaborate,
			},
		],
		inactive: [
			{
				label: Variable.VIEW[ENV_NAME],
				onClick: handleClickView,
			},
			{
				label: Variable.REPOST[ENV_NAME],
				onClick: handleClickRepost,
			},
			{
				label: Variable.COLLABORATE[ENV_NAME],
				onClick: handleClickCollaborate,
			},
		],
	};

	const isScheduledJob = data.published_at && moment(data.published_at).isAfter(new Date());

	return (
		<div
			className="wrapper-job-listing__card-job-list"
			key={data.id}
			onClick={() => {
				if (activeTab === "draft") return;
				handleClick(data.id);
			}}
		>
			<div className="d-flex justify-content-between align-items-center">
				<div>
					<h5 className="wrapper-job-listing__card-job-list__title">{data.job_title}</h5>
					<div className="wrapper-job-listing__card-job-list__creator">
						{activeTab !== "draft"
							? Variable.POSTED_BY[ENV_NAME]
							: Variable.DRAFT_BY[ENV_NAME]}{" "}
						{data.created_by.first_name} {data.created_by.last_name}{" "}
						{Variable.FROM[ENV_NAME]} {data.company.name}
						{data.expired_at && (
							<>
								<div className="wrapper-job-listing__card-job-list__creator__line" />
								{Variable.POST_ENDS_ON[ENV_NAME]}{" "}
								{moment(data.expired_at).format("DD MMMM YYYY")}
							</>
						)}
						{data.updated_at && data.status === "draft" && (
							<>
								<div className="wrapper-job-listing__card-job-list__creator__line" />
								{t("LAST_UPDATED_ON")}{" "}
								{moment(data.updated_at).format("DD MMMM YYYY")}
							</>
						)}
					</div>
				</div>
				<LabelStatus
					text={STATUS_TYPE[data.status]}
					type={data.status}
					fontWeight="bold"
					size="sm"
				/>
			</div>
			{activeTab !== "draft" && (
				<div className="wrapper-job-listing__card-job-list__stats">
					<p className="wrapper-job-listing__card-job-list__stats-candidates">
						<span>{data.viewers_counter}</span>
						{Variable.VIEWS[ENV_NAME]}
					</p>
					<div className="wrapper-job-listing__card-job-list__stats-line" />
					<p className="wrapper-job-listing__card-job-list__stats-candidates">
						<span>{data.applies_counter}</span>
						{Variable.APPLIED[[ENV_NAME]]}
					</p>
					<div className="wrapper-job-listing__card-job-list__stats-line" />
					<p className="wrapper-job-listing__card-job-list__stats-candidates">
						<span>{data.unrated_counter}</span>
						{Variable.UNRATED[ENV_NAME]}
					</p>
					<div className="wrapper-job-listing__card-job-list__stats-line" />
					<div className="wrapper-job-listing__card-job-list__wrapper-tag">
						<TagComponent
							value={data.good_fit_counter}
							text={Variable.GOOD_FIT[ENV_NAME]}
							type="success"
						/>
						<TagComponent
							value={data.perhaps_counter}
							text={Variable.PERHAPS[ENV_NAME]}
							type="info"
						/>
						<TagComponent
							value={data.unfit_counter}
							text={Variable.UNFIT[ENV_NAME]}
							type="error"
						/>
					</div>
				</div>
			)}
			<div className="wrapper-job-listing__card-job-list__border-bottom" />
			<div className="d-flex justify-content-between align-items-center">
				<div className="wrapper-job-listing__card-job-list__button-actions">
					{buttonGroups[activeTab].map((button, index: number) => (
						<p
							key={`button-${index}`}
							className="wrapper-job-listing__card-job-list__button-actions_text"
							onClick={button.onClick}
							id={data.id}
						>
							{button.label}
						</p>
					))}
				</div>
				{data.status === "active" && isScheduledJob && (
					<LabelStatus
						text={`${t("SCHEDULED_ON")} ${moment(data.published_at).format(
							"DD MMMM YYYY hh:mm",
						)}`}
						type="orange"
						size="sm"
						icon={<CalendarBlank width={16} height={16} color="#FFF" />}
					/>
				)}
			</div>
		</div>
	);
};

export default CardJobList;
