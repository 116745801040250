import React, { FC, useEffect, useState } from "react";import Image from "shared/component/UI/Image";
import carretLeft from "../../../../assets/svg/caret-left-blue.svg";
import carretLeftDisable from "../../../../assets/svg/caret-left-disable.svg";
import carretRight from "../../../../assets/svg/caret-right-blue.svg";
import carretRightDisable from "../../../../assets/svg/caret-right-disable.svg";

import "./SmallPagination.scss";
import Tooltip from "shared/component/atoms/Tooltips/Tooltip";
import useTranslate from "shared/hooks/useTranslate";

interface SmallPaginationProps {
    onMovePage: (v: string | null) => void;
    page?: number;
    totalItems?: number;
    pageSize?: number;
    next?: string | null;
    previous?: string | null;
}

const SmallPagination: FC<SmallPaginationProps> = ({
    onMovePage,
    page = 1, // TECHDEBT: need to ask BE team to include the "page" attribute
    totalItems = 0,
    pageSize = 0,
    next = '', // TECHDEBT: next and previous still use http instead of https, get CORS issues
    previous = '',
}) => {
    const t = useTranslate();
    const [disablePosition, setDisablePosition] = useState("")

    const determineDisablePosition = () => {
        if (previous && next) {
            return "";
        } else if (previous !== null) {
            return "left";
        } else if (next !== null) {
            return "right";
        } else {
            return "both";
        }
    }

    useEffect(() => {
        setDisablePosition(determineDisablePosition())
    }, []);

    const generateItemRangeText = (page, pageSize, totalItems) => {
        const startIndex = page === 1 ? 1 : (pageSize * (page - 1)) + 1;
        const endIndex = Math.min(totalItems, pageSize * page);
        
        return `${startIndex} - ${endIndex} ${t("FROM")} ${totalItems}`;
    }


    return (
        <div className="small-pagination__wrapper">
            <div className="small-pagination__text">
                <span>{generateItemRangeText(page, pageSize, totalItems)}</span>
            </div>
            <div className="small-pagination__arrow-wrapper">
                {["right", "both"].includes(disablePosition) ? (
                    <Image
                        src={carretLeftDisable}
                        className="tw cursor-pointer disabled"
                    />
                ) : (
                    <Tooltip description={t("PREVIOUS")} placement="bottom">
                        <Image
                            src={carretLeft}
                            onClick={() => onMovePage(previous)}
                            className="tw cursor-pointer"
                        />
                    </Tooltip>
                )}
                {["left", "both"].includes(disablePosition) ? (
                    <Image
                        src={carretRightDisable}
                        className="tw cursor-pointer disabled"
                    />
                ) : (
                    <Tooltip description={t("NEXT")} placement="bottom">
                        <Image
                            src={carretRight}
                            onClick={() => onMovePage(next)}
                            className="tw cursor-pointer"
                        />
                    </Tooltip>
                )}
            </div>
        </div>
    )
}

export default SmallPagination;
