import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Empty from '../../../assets/svg/empty_time_slot.svg';
import Button from '../../../shared/component/ButtonComponent/Button';
import Image from '../../../shared/component/UI/Image';
import LayoutWithNavigation from '../../../shared/layouts/LayoutWithNavigation';
import './TimeSlot.scss';
import CardTimeSlot from './CardTimeSlot';
import { fetchListTimeSlot } from '../../../store/actions/interview';
import { RenderIf } from '../../../shared/utils/common';

export default function TimeSlots() {
  const route = useHistory();
  const [listTemp, setlistTemp] = useState([]);

  const handleFetchListTimeSlot = async () => {
    return await fetchListTimeSlot().then((res) => {
      setlistTemp(res.data);
    });
  };

  useEffect(() => {
    handleFetchListTimeSlot();
  }, []);

  return (
    <LayoutWithNavigation className={'time-slot'}>
      <div className="position-relative mx-auto container bg-white w-75 tw rounded-lg p-lg mt-lg">
        {/* Content */}
        <div className="positon-relative">
          {/* Breadcumb Section */}
          <nav className="breadcumb-section" aria-label="breadcrumb">
            <ol className="breadcrumb text-sm">
              <li
                className="breadcrumb-item fw-light active tw cursor-pointer"
                onClick={() => route.push('/candidate-management')}>
                Dashboard
              </li>
              <li
                className="breadcrumb-item fw-light active tw cursor-pointer"
                onClick={() => route.push('/interview-schedule')}>
                Interview Schedule
              </li>
              <li className="breadcrumb-item fw-semibold" aria-current="page">
                Time Slot List
              </li>
            </ol>
          </nav>

          {/* Head Section */}
          <div className="head-section">
            <h2>Time slot list</h2>
            <Button
              type="primary"
              size="sm"
              onClick={() => route.push('/interview-type')}>
              Create Schedule
            </Button>
          </div>

          {/* List of Interview Schedule */}
          <RenderIf condition={listTemp.length > 0}>
            <div className="position-relative mt-xl d-flex flex-wrap gap-4">
              {listTemp.map((item, index) => (
                <CardTimeSlot
                  color={item.schedule_color}
                  location={item.meeting_type}
                  title={item.schedule_name}
                  type={item.slot_target}
                  key={index}
                />
              ))}
            </div>
          </RenderIf>

          {/* Condition when data is null */}
          <RenderIf condition={listTemp.length === 0}>
            <div className="content-null">
              <Image src={Empty} alt="empty" />

              <div className="description">
                <h4 className="text-center">
                  You don’t have schedule interview
                </h4>
                <p className="text-center">
                  Buat schedule terlebih dahulu agar dapat mengundang untuk
                  interview
                </p>
              </div>
            </div>
          </RenderIf>
        </div>
      </div>
    </LayoutWithNavigation>
  );
}
