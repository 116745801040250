import { Check, X } from "@phosphor-icons/react";
import cx from "classnames";
import moment from 'moment/moment';
import Checkbox from 'shared/component/atoms/Checkbox';

import useTranslate from "shared/hooks/useTranslate";
import Tooltip from "shared/component/atoms/Tooltips/Tooltip";
import QuestionsIcon from "shared/component/svg/QuestionsIcon";

const Candidate = ({
	header,
	candidateCheckboxes,
	getCandidateDetails,
	setCandidateCheckboxes,
	candidate,
	idx,
	markStatus,
	allCandidates,
	setCandidate,
	candidateData,
}) => {
	const t = useTranslate()
	return (
		<div className="candidate-outer-div">
			<Checkbox
				className="mt-2"
				checked={candidateCheckboxes[idx]}
				onChange={() => {
					let temp = [...candidateCheckboxes];
					temp[idx] = !temp[idx];
					setCandidateCheckboxes(temp);
				}}
			/>
			<div
				onClick={() => {
					getCandidateDetails(candidate?.id);
					setCandidate(
						allCandidates.map((c) => {
							return c.id === candidate?.id ? { ...c, is_viewed: true } : { ...c };
						}),
					);
				}}
				className={cx("candidate-div", {
					"unseen": !candidate?.is_viewed,
					"selected": candidateData?.id === candidate?.id
				})}
			>
				<div className="d-flex justify-content-between">
					<p className="candidate-name">
						{[candidate.created_by.first_name, candidate.created_by.last_name].join(" ")}
					</p>
					<div className="d-flex align-items-center gap-2">
						<div className="quick-move-status">
							<Tooltip
								description={t("GOOD_FIT")}
								style={{ backgroundColor: header === "good_fit" ? "#32D583" : "#FFFFFF", borderRadius: "7px 0 0 7px" }}
								onClick={(e) => {
									e.preventDefault();
									markStatus(candidate.id, "good_fit");
								}}
							>
								<Check
									height={20}
									width={20}
									color={header === "good_fit" ? "#FFFFFF" : "#000000"}
									weight="bold"
									className="rounded-circle p-1 pt-0"
								/>
							</Tooltip>
							<Tooltip
								description={t("PERHAPS")}
								style={{ backgroundColor: header === "perhaps" ? "#FC9607" : "#FFFFFF", borderRadius: 0 }}
								onClick={(e) => {
									e.preventDefault();
									markStatus(candidate.id, "perhaps");
								}}
							>
								<QuestionsIcon
									height={24}
									width={24}
									color={header === "perhaps" ? "#FFFFFF" : "#000000"}
									weight="bold"
									className="rounded-circle p-1"
								/>
							</Tooltip>
							<Tooltip
								description={t("UNFIT")}
								style={{ backgroundColor: header === "unfit" ? "#F04438" : "#FFFFFF", borderRadius: "0 7px 7px 0" }}
								onClick={(e) => {
									e.preventDefault();
									markStatus(candidate.id, "unfit");
								}}
							>
								<X
									height={20}
									width={20}
									color={header === "unfit" ? "#FFFFFF" : "#000000"}
									weight="bold"
									className="rounded-circle p-1"
								/>
							</Tooltip>
						</div>
					</div>
				</div>
				<p className="candidate-role">{candidate.current_job_title}</p>
				<p className="candidate-company">{candidate.current_company}</p>
				<div className="d-flex justify-content-between">
					<p className="candidate-date-applied">
						{t("DATE_APPLIED_TIME").replace(
							"{{_time_}}", 
							candidate.applied_at ? moment(candidate.applied_at).format("DD MMM YYYY") : "-"
						)}
					</p>
					{/**
					 * TECHDEBT: there is a different comparation score between candidate list and candidate details
					 * Need to ensure which one is correct
					 */}
					<p className="roles-available">
						{candidate.user_pre_screening_score}/
						{candidate.expected_pre_screening_score}
					</p>
				</div>
			</div>
		</div>
	);
}

export default Candidate;
