import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as Variable from "../../utils/variables";
import { classHelper } from "../../utils/stringUtils";

import "./SelectComponent.scss";
import Image from "../UI/Image";
import arrowTop from "../../../assets/svg/arrow_top.svg";
import arrowDown from "../../../assets/svg/arrow_down.svg";

const toVal = (v, key = "id") => (typeof v === "object" ? v?.[key] : v);

const Select = ({
	items,
	label,
	name,
	required,
	placeholder,
	id,
	disabled,
	onClick,
	selected,
	value,
	defaultValue,
	hidden,
	noDefault,
	defaultOpt = {},
	searchable,
	autoComplete,
	suggestion,
	ENV_NAME,
	description,
	isError,
	...props
}) => {
	const [isOpen, setIsOpen] = useState(false);

	let itemsDict =
		(Array.isArray(items) &&
			items.reduce(
				(obj, item) =>
					Object.assign(
						obj,
						typeof item === "object" ? { [item?.id]: item } : { [item]: item },
					),
				{},
			)) ||
		{};

	const onChange = (e) => {
		if (typeof props.onChange === "function") props.onChange(e);
		if (typeof props.onChangeItem === "function")
			props.onChangeItem(itemsDict[e?.target?.value], e);
	};

	const toggleOpenState = () => setIsOpen((p) => !p);

	return (
		<div
			className={classHelper([`J-select`, props.className].join(" "))}
			onClick={toggleOpenState}
		>
			<div className="J-select-content">
				{label && (
					<label>
						{label ?? Variable.LABEL_NAME[ENV_NAME]}
						{required && <span className="text-danger text-sm">*</span>}
					</label>
				)}
				{description?.length > 0 && (
					<span
						className="text-sm fw-light"
						style={{ marginTop: "-0.8rem", marginBottom: "0.6rem" }}
					>
						{description}
					</span>
				)}

				{searchable === false ? (
					<div className="positionRelative">
						<select
							ref={props.innerRef}
							name={name}
							className={classHelper(
								`JSelect ${isError ? "J-error" : ""}`,
								props.selectClassName,
							)}
							placeholder={placeholder ?? Variable.SELECT_LABEL[ENV_NAME]}
							id={id}
							onChange={onChange}
							disabled={disabled}
							onClick={onClick}
							value={selected ? toVal(selected) : toVal(value)}
							defaultValue={toVal(defaultValue)}
							hidden={hidden}
						>
							{noDefault && (
								<option
									value={
										toVal(defaultOpt) !== null &&
										toVal(defaultOpt) !== undefined
											? toVal(defaultValue)
											: ""
									}
									disabled={defaultOpt?.disabled}
									hidden={defaultOpt?.hidden}
								>
									{toVal(defaultOpt, "name") ?? Variable.SELECT_LABEL[ENV_NAME]}
								</option>
							)}
							{Array.isArray(items) &&
								items?.map((item, index) => (
									<option
										key={toVal(item) ?? index}
										value={toVal(item)}
										disabled={typeof item === "object" ? item.disabled : false}
									>
										{toVal(item, "name")}
									</option>
								))}
						</select>
						<Image className="JArrow" src={isOpen ? arrowTop : arrowDown} />
					</div>
				) : (
					<div className="positionRelative">
						<input
							type="text"
							className={classHelper(`JInput`, props.inputClassName)}
							disabled={disabled}
							placeholder={placeholder ?? Variable.SEARCH_LABEL[ENV_NAME]}
							name={name}
							autoComplete={autoComplete}
							value={toVal(value)}
							onChange={(e) => onChange(e)}
						/>
						<Image
							className="JArrow"
							src={arrowDown}
							// src={
							//   toVal(value?.target?.value)?.length >= 3 ? arrowDown : arrowTop
							// }
						/>
						{toVal(value?.target?.value)?.length >= 3 && (
							<div className="JInput-boxBelow">
								{suggestion ?? Variable.RESULT_LABEL[ENV_NAME]}
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(Select));
