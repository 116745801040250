// Import from external link
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// Import from internal
import * as Variable from "shared/utils/variables";
import * as RoutePath from "shared/utils/routeLink";
import * as ActionTypes from "store/actions/actionTypes";

import Button from "shared/component/ButtonComponent/Button";
import HeaderComponent from "container/HeaderComponent/HeaderComponent";
import FooterComponent from "container/FooterComponent/FooterComponent";

import { useEnvironment } from "shared/hooks/useEnvironment";
import { ReactComponent as HPIllustration } from "assets/svg/HP_Illustration_2.svg";

// Import from same folder
import "./HomeComponent.scss";

const StepComponent = ({ number, title, desc }) => (
	<div className="step-item-container">
		<h4 className="step-number">{number}</h4>
		<p className="step-title">{title}</p>
		<span className="step-desc">{desc}</span>
	</div>
);

const HomeComponent = () => {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const ENV_NAME = useEnvironment();
	const history = useHistory();
	const dispatch = useDispatch();

	const Steps = [
		{
			number: 1,
			title: Variable.HC_STEP_1_TITLE[ENV_NAME],
			desc: Variable.HC_STEP_1_DESC[ENV_NAME],
		},
		{
			number: 2,
			title: Variable.HC_STEP_2_TITLE[ENV_NAME],
			desc: Variable.HC_STEP_2_DESC[ENV_NAME],
		},
		{
			number: 3,
			title: Variable.HC_STEP_3_TITLE[ENV_NAME],
			desc: Variable.HC_STEP_3_DESC[ENV_NAME],
		},
	];

	const goToJobAd = () => {
		history.push(RoutePath.JOB_AD);
	};

	const goToJobAdListing = () => {
		history.push(RoutePath.JOB_AD_LISTING);
	};

	const toggleLoginModal = (open = true) => {
		if (!isAuthenticated) {
			dispatch({
				type: open ? ActionTypes.OPEN_LOGIN_MODAL : ActionTypes.CLOSE_LOGIN_MODAL,
			});
			return;
		}
		goToJobAd();
	};

	return (
		<>
			<HeaderComponent />
			<div className="hc mx-auto">
				<div className="hero-container">
					<div className="hero-left-container">
						<h1 className="hero-title-text">{Variable.HC_HERO_TITLE[ENV_NAME]}</h1>
						<h3 className="hero-info-text">{Variable.HC_HERO_INFO[ENV_NAME]}</h3>
						<Button
							type="primary"
							title={Variable.HC_HERO_BTN[ENV_NAME]}
							size="sm"
							btnClassName="hero-button"
							onClick={toggleLoginModal}
						/>
					</div>
					<HPIllustration />
				</div>
				<div className="step">
					{Steps.map((v) => (
						<StepComponent key={v.number} {...v} />
					))}
				</div>
			</div>
			<FooterComponent />
		</>
	);
};

export default HomeComponent;
