import defaultImage from "../../../assets/svg/building.svg";
const Image = ({ src, ...props }) => {
	return (
		<img
			src={src ? src : defaultImage}
			alt={props.alt ? props.alt : "Portal Sekolah"}
			className={props.className}
			{...props}
		/>
	);
};
export default Image;
