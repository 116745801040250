import { FC, useRef, useState } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { Copy } from '@phosphor-icons/react';

import { useOutsideClick } from 'shared/hooks/outsideClick';

import './ButtonDetailsCandidate.scss';
import useTranslate from 'shared/hooks/useTranslate';
import useToast from 'shared/hooks/useToast';

interface ButtonDetailsCandidateProps {
    onShowDownloadModal: () => void;
    phoneNumber1: string;
    phoneNumber2: string;
    streetAddress: string;
}

const ButtonDetailsCandidate: FC<ButtonDetailsCandidateProps> = ({
    onShowDownloadModal,
    phoneNumber1,
    phoneNumber2,
    streetAddress,
}) => {
    const t = useTranslate();
    const toast = useToast();
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [showContactDetail, setShowContactDetail] = useState<boolean>(false)
    
    useOutsideClick(tooltipRef, () => setShowContactDetail(false));

    const handleCopyClicked = (text: string) => {
        navigator.clipboard.writeText(text);
        toast.success('Successfully copied');
        setShowContactDetail(false)
    }

    return (
        <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
                <div className="download-details-btn tooltip-info-container">
                    <span onClick={() => setShowContactDetail(true)}>{t("CONTACT_DETAILS")}</span>
                    <div className={cx('tooltip-info', { 'hide-tooltip-info': !showContactDetail })} ref={tooltipRef}>
                        <div className='tooltip-info-wrapper'>
                            <span>{t("LOCATION")} : </span>
                            {streetAddress ? (
                                <span>
                                    {`${streetAddress} `}
                                    <Copy
                                    size={18}
                                    className="tw cursor-pointer"
                                    color="#ffffff"
                                    onClick={() => handleCopyClicked(streetAddress)}
                                    />
                                </span>
                            ) : "-"}
                        </div>
                        {phoneNumber1 && (
                            <div className='tooltip-info-wrapper'>
                                <span>{t("PHONE_1")}</span>
                                <span>{phoneNumber1}</span>
                                <Copy
                                    size={18}
                                    className="tw cursor-pointer"
                                    color="#ffffff"
                                    onClick={() => handleCopyClicked(phoneNumber1)}
                                />
                            </div>
                        )}
                        {phoneNumber2 && (
                            <div className='tooltip-info-wrapper'>
                                <span>{t("PHONE_2")}</span>
                                <span>{phoneNumber2}</span>
                                <Copy
                                    size={18}
                                    className="tw cursor-pointer"
                                    color="#ffffff"
                                    onClick={() => handleCopyClicked(phoneNumber2)}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div onClick={onShowDownloadModal} className="download-file-btn">
                    <span>{t("DOWNLOAD_FILE")}</span>
                </div>
            </div>
        </div>
    );
}

export default ButtonDetailsCandidate;
