import React from "react";
import clx from "classnames";

import * as Variable from "shared/utils/variables";
import { Button, WarningTriangle } from "shared/component";
import { Text } from "shared/component/Typography/Typography";
import { useEnvironment } from "shared/hooks/useEnvironment";

import "./CardVerifyCompany.scss";
import { Clock, WarningCircle } from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";
import { COMPANY_MANAGEMENT } from "shared/utils/routeLink";

type CardVerifyCompanyProps = {
	type: "secondary" | "primary" | "error";
};

type ContentCardVerifyCompanyType = {
	title: string;
	description: string;
	icon: React.ReactElement;
	buttonText?: string;
	handleClick?: () => void;
};

const CardVerifyCompany = ({ type }: CardVerifyCompanyProps) => {
	const ENV_NAME = useEnvironment();
	const history = useHistory();

	const contentCardVerifyCompany: Record<
		CardVerifyCompanyProps["type"],
		ContentCardVerifyCompanyType
	> = {
		secondary: {
			title: Variable.VERIFY_YOUR_COMPANY[ENV_NAME],
			description: Variable.VERIFY_YOUR_COMPANY_DESC[ENV_NAME],
			icon: <WarningTriangle className={clx("icon", type)} width={16} height={16} />,
			buttonText: Variable.UPLOAD[ENV_NAME],
			handleClick: () => history.push(COMPANY_MANAGEMENT),
		},
		primary: {
			title: Variable.WAITING_VERIFY[ENV_NAME],
			description: Variable.WAITING_VERIFY_DESC[ENV_NAME],
			icon: <Clock className={clx("icon", type)} width={16} height={16} />,
			buttonText: Variable.CHECK[ENV_NAME],
			handleClick: () => history.push(COMPANY_MANAGEMENT),
		},
		error: {
			title: Variable.REJECTED[ENV_NAME],
			description: Variable.REJECTED_DESC[ENV_NAME],
			icon: <WarningCircle className={clx("icon", type)} width={16} height={16} />,
			buttonText: Variable.UPLOAD_AGAIN[ENV_NAME],
			handleClick: () => history.push(COMPANY_MANAGEMENT),
		},
	};

	return (
		<div className={clx("job-ads-list__card-verify-company", type)}>
			<div className="job-ads-list__card-verify-company__left">
				<div className={clx("wrapper-icon", type)}>
					{contentCardVerifyCompany[type]?.icon}
				</div>
				<div>
					<Text
						size="md"
						fontWeight="semibold"
						className={clx("job-ads-list__card-verify-company__text", type)}
					>
						{contentCardVerifyCompany[type]?.title}
					</Text>
					<Text
						size="sm"
						className={clx("job-ads-list__card-verify-company__text mt-2", type)}
					>
						{contentCardVerifyCompany[type]?.description}
					</Text>
				</div>
			</div>
			{contentCardVerifyCompany[type].buttonText && (
				<Button type={type} size="md" onClick={contentCardVerifyCompany[type].handleClick}>
					<Text size="sm" fontWeight="semibold">
						{contentCardVerifyCompany[type].buttonText}
					</Text>
				</Button>
			)}
		</div>
	);
};

export default CardVerifyCompany;
