export const getIfArray = (v) => (Array.isArray(v) ? v : []);
export const RenderIf = ({ condition, children }) =>
  condition ? children : null;

export const getImageFromAssets = (pathname) => {
  return require(`../../assets/${pathname}`);
};

export const handlerAnswerQuestionPreload = ({
  data,
  uniqueId,
  e,
  dataForm,
  jobCreation,
  category,
  isMultiple,
  jobCreationId,
}) => {
  const { name, value, checked } = e.target;

  // if dataForm is undefined then create new object with id and category and data
  if (!dataForm) {
    const createObject = {
      id: uniqueId,
      category: category,
      data: {
        id: data?.id,
        job_ad: jobCreationId,
        mandatory_choices: [value],
        is_mandatory: name === 'is_mandatory' && checked,
      },
    };

    return [...(jobCreation?.preloadQuestionAnswers || []), createObject];
  } else {
    // if dataForm is exist then update data
    const updateData = jobCreation?.preloadQuestionAnswers?.map((item) => {
      if (item.id === uniqueId) {
        return {
          ...item,
          data: {
            ...item.data,
            job_ad: jobCreationId,
            [name === category ? 'mandatory_choices' : name]:
              name === category
                ? // check is multiple or not
                  isMultiple
                  ? [...item.data.mandatory_choices, value]
                  : [value]
                : checked,
          },
        };
      } else {
        return item;
      }
    });

    return updateData;
    // pushDataToStore(updateData);
  }
};

export const handlerChangeValueCustomQuestion = ({
  uniqueId,
  e,
  dataForm,
  category,
  jobCreation,
  jobCreationId,
}) => {
  const { name, value, checked } = e.target;

  // if dataForm is undefined then create new object with id and category and data
  if (!dataForm) {
    const createObject = {
      id: uniqueId,
      category: category,
      data: {
        job_ad: jobCreationId,
        is_active: true,
        is_mandatory: false,
        question_category: 'other',
        max_numeric_value: 0,
        min_numeric_value: 0,
        [name]: value,
      },

      // if dataForm is exist then update data
    };
    return [...(jobCreation?.preloadQuestionAnswers || []), createObject];
  } else {
    const updateData = jobCreation?.preloadQuestionAnswers?.map((item) => {
      if (item.id === uniqueId) {
        // when name is question_type
        if (name === 'question_type') {
          return handlerRemove(item, name, value);
        }

        return handlerChangeRest(item, name, value, checked);
      } else {
        return item;
      }
    });

    return updateData;
  }
};

/**
 * function handlerRemove
 * this function will remove all value except question_type, is_active, is_mandatory, job_ad
 * @param {object} item => object from redux
 * @param {string} name => name from event
 * @param {string} value => value from event
 * @returns {object} data
 */
const handlerRemove = (item, name, value) => {
  return {
    ...item,
    data: {
      ...item.data,
      [name]: value,
      mcq_choices: [],
      max_numeric_value: 0,
      min_numeric_value: 0,
      mandatory_choices: [],
      question_text: '',
      fill_in_the_blanks_options: [],
      limit_type: '',
    },
  };
};

/**
 * function handlerChangeRest
 * this function will change value from event except constant value like is_active, is_mandatory, job_ad
 * @param {object} item => object from redux
 * @param {string} name => name from event
 * @param {string} value => value from event
 * @param {boolean} checked => checked from event
 * @returns {object} data
 * */
const handlerChangeRest = (item, name, value) => {
  return {
    ...item,
    data: {
      ...item?.data,
      [name]:
        name === 'min_numeric_value' || name === 'max_numeric_value'
          ? Number(value)
          : value,
    },
  };
};

/**
 * function handlerAnswerMultipleChoice
 * this function will update data mandatory_choices and mcq_choices only
 * @param {integer} uniqueId
 * @param {object} dataTemp => this value is generate by function covnertValue
 * @param {object} jobCreation => this value is from redux
 * @returns {object} data
 */
export const handlerAnswerMultipleChoice = ({
  uniqueId,
  dataTemp,
  jobCreation,
}) => {
  const updateData = jobCreation?.preloadQuestionAnswers?.map((item) => {
    if (item.id === uniqueId) {
      return {
        ...item,
        data: {
          ...item.data,
          mandatory_choices: dataTemp?.mandatory_choices,
          mcq_choices: dataTemp?.mcq_choices,
        },
      };
    } else {
      return item;
    }
  });
  return updateData;
};

export const getGMTOffset = (timezone) => {
  var now = new Date();
  var formatter = new Intl.DateTimeFormat('en', {
    timeZone: timezone,
    timeZoneName: 'short',
  });
  var formatted = formatter.formatToParts(now);

  var gmtOffsetPart = formatted.find((part) => part.type === 'timeZoneName');
  var gmtOffset = gmtOffsetPart?.value.trim().replace('GMT', '');

  return gmtOffset;
};
