import { FC, useEffect, useState } from 'react';
import Image from 'shared/component/UI/Image';
import checklistIcon from '../../../../../assets/svg/checklist.svg';
import xCircle from '../../../../../assets/svg/x-circle.svg';
import { ScreeningAnswer } from '../../Filters/types';

import './ScreenQuestions.scss';
import { isMatchWithIdealAnswer, screenQuestionAnswers } from 'shared/utils/helper';
import useTranslate from 'shared/hooks/useTranslate';


interface ScreenQuestionsProps {
    candidateQuestions: ScreeningAnswer[];
}

const ScreenQuestions: FC<ScreenQuestionsProps> = ({
    candidateQuestions
}) => {
    const t = useTranslate();
    const [countMatch, setCountMatch] = useState<number>(0);

    const updateCountMatch = () => {
        let count = 0;
        candidateQuestions.forEach((v) => {
            if (isMatchWithIdealAnswer(v)) {
                count++;
            }
        });
        setCountMatch(count);
    }

    useEffect(() => {
        updateCountMatch();
    }, [])
    
    return (
        <div className='screen-questions__container'>
            <p className='screen-questions__title'>
                <span>{t("SCREENING_QUESTION")}</span>
                {/**
                 * TECHDEBT: there is a different comparation score between candidate list and candidate details
                 * Need to ensure which one is correct
                 */}
                <span>{countMatch}/{candidateQuestions.length}</span>
            </p>
            {candidateQuestions.map((v, k) => (
                <div key={k} className='screen-questions__items'>
                    <div className='screen-questions__icon-wrapper'>
                        {v.question.question_type !== 'short_answer' && (
                            <Image src={isMatchWithIdealAnswer(v) ? checklistIcon : xCircle} />
                        )}
                    </div>
                    <div className='screen-questions__question-wrapper'>
                        <div className='screen-questions__question'>{v.question.question_text}</div>
                        <div className='screen-questions__answer'>{screenQuestionAnswers(v)}</div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ScreenQuestions;
