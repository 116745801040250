import { FC } from 'react'
import { IconProps } from './IconTypes'

const ChecklistIcon: FC<IconProps> = ({
    width = "14",
    height = "14",
    color = "#12B76A"
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4967 6.27711 13.8108 4.62573 12.5925 3.40746C11.3743 2.18918 9.72289 1.5033 8 1.5ZM11.0938 6.8625L7.43125 10.3625C7.33657 10.4516 7.21125 10.5008 7.08125 10.5C7.01771 10.5009 6.95463 10.4892 6.89563 10.4656C6.83663 10.442 6.78289 10.407 6.7375 10.3625L4.90625 8.6125C4.85546 8.56819 4.81415 8.51406 4.78481 8.45337C4.75547 8.39269 4.73871 8.3267 4.73552 8.25937C4.73233 8.19204 4.7428 8.12476 4.76628 8.06157C4.78975 7.99839 4.82577 7.9406 4.87215 7.89169C4.91853 7.84278 4.97432 7.80375 5.03617 7.77695C5.09802 7.75015 5.16465 7.73614 5.23206 7.73574C5.29946 7.73535 5.36625 7.7486 5.42841 7.77467C5.49056 7.80075 5.54681 7.83913 5.59375 7.8875L7.08125 9.30625L10.4063 6.1375C10.5035 6.05268 10.6297 6.00872 10.7586 6.01482C10.8875 6.02092 11.009 6.0766 11.0978 6.17022C11.1866 6.26384 11.2357 6.38815 11.235 6.51717C11.2342 6.64618 11.1836 6.76992 11.0938 6.8625Z"
                fill={color}
            />
        </svg>

    )
}

export default ChecklistIcon