import { FC, useEffect, useState } from 'react'

import useTranslate from 'shared/hooks/useTranslate';
import Checkbox from 'shared/component/atoms/Checkbox';

import { QuestionsFiltersChoices } from '../../types';

type QuestionsFiltersChoicesProps = 'onChange' | 'mcq_choices' | 'candidateJobStatus';

const QuestionMultipleChoice: FC<
    Pick<
        QuestionsFiltersChoices, 
        QuestionsFiltersChoicesProps
    >
> = ({
    onChange,
    mcq_choices = {},
    candidateJobStatus = []
}) => {
    const t = useTranslate();

    const [selectedMultipleChoice, setSelectedMultipleChoice] = useState<string[]>([]);

    useEffect(() => {
        setSelectedMultipleChoice(candidateJobStatus);
    }, [candidateJobStatus])

    return (
        <div className='mt-3'>
          <div className='mt-2 ms-3'>
                {Object.keys(mcq_choices).map((v, k) => {
                    return(
                        <div className='d-flex align-items-center py-2' key={k}>
                            <Checkbox
                                id={`multiple_choices__${v}`}
                                label={t(v.toUpperCase() as any)}
                                checked={selectedMultipleChoice.includes(v)}
                                onChange={() => {
                                    if(selectedMultipleChoice.includes(v)) {
                                        onChange(selectedMultipleChoice.filter(x => x !== v));
                                        setSelectedMultipleChoice(selectedMultipleChoice.filter(x => x !== v))
                                        return;
                                    }
                                    onChange([...selectedMultipleChoice, v]);
                                    setSelectedMultipleChoice([...selectedMultipleChoice, v])
                                    return;
                                }}
                            />
                        </div>
                    );
                })}
          </div>
        </div>
    )
}

export default QuestionMultipleChoice;
