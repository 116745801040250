import { FC, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { useEnvironment } from 'shared/hooks/useEnvironment';
import { EMPLOYEE_HIRED_JOB_AD } from 'shared/utils/variables';

import './TabNavigation.scss';
import { useScroll } from 'shared/hooks/useScroll';
import { IStatus } from 'component/CandidateManagement/types';
import useTranslate from 'shared/hooks/useTranslate';

interface TabNavigationProps {
	header: string;
	setHeader: (v: string) => void;
	headerData: IStatus | null;
	hiredEmployees: string;
}

const TAB_LISTS = ['unrated', 'good_fit', 'perhaps', 'unfit'];

const TabNavigation: FC<TabNavigationProps> = ({
	header,
	setHeader,
	headerData,
	hiredEmployees
}) => {
	const t = useTranslate();
	const TAB_LISTS_VALUE: string[] = [t("UNRATED"), t("GOOD_FIT"), t("PERHAPS"), t("UNFIT")];

	const { setScrollDirection } = useScroll()
	const tabNavigationRef = useRef<HTMLDivElement>(null)
	const [selectedTab, setSelectedTab] = useState(TAB_LISTS[0]);

	useEffect(() => {
		setSelectedTab(header)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [header]);

    const getWidth = (id: string = 'profile'): number => 
		tabNavigationRef?.current?.querySelector?.(`#${id}`)?.getBoundingClientRect?.()?.width || 0;

	const countTotalWidth = (n: number) => {
		let c = 0
		for (let i = 0; i < n; i++) {
			c += getWidth(TAB_LISTS[i])
		}
		/**
		 * 12 is padding/margin for each items.
		 */
		return c + (12 * n); 
	};

	return (
		<div className='tab-navigation__container'>
			<div className='tab-navigation__wrapper' ref={tabNavigationRef}>
				<div className='tab-navigation__sub-wrapper'>
					{TAB_LISTS.map((v, k) => (
						<div
							id={v}
							key={k}
							onClick={() => {
								if (v !== header) setHeader(v);
								setSelectedTab(v)
								setScrollDirection("up")
							}}
							className={cx(
								'tab-navigation__items tw capitalize', 
								{ 'tab-navigation__items--active': selectedTab === v }
							)}
						>
							{/* <span>{v.split('_').join(' ')}</span> */}
							<span>{TAB_LISTS_VALUE[k]}</span>
							<span className='tab-navigation__items--count'>{headerData?.[v] || 0}</span>
						</div>
					))}
				</div>
				<div
					className="tab-navigation__border--active"
					style={{
						width: (() => {
							switch (selectedTab) {
								case TAB_LISTS[0]: return getWidth(TAB_LISTS[0])
								case TAB_LISTS[1]: return getWidth(TAB_LISTS[1])
								case TAB_LISTS[2]: return getWidth(TAB_LISTS[2])
								case TAB_LISTS[3]: return getWidth(TAB_LISTS[3])
								default: return 0;
							}
						})(),
						marginLeft: (() => {
							switch (selectedTab) {
								case TAB_LISTS[0]: return 0;
								case TAB_LISTS[1]: return countTotalWidth(1);
								case TAB_LISTS[2]: return countTotalWidth(2);
								case TAB_LISTS[3]: return countTotalWidth(3);
								default: return 0;
							}
						})(),
					}}
				/>
			</div>
			<span className='tab-navigation__employee-hired-count'>
				{t("EMPLOYEE_HIRED_JOB_AD").replace('{{_1_}}', hiredEmployees)}
			</span>

		</div>
	);
}

export default TabNavigation;
