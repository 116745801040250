import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/";
import queryString from "query-string";
import { PiArrowLeft } from "react-icons/pi";
import moment, { Moment } from "moment";
import { IoCalendarNumberOutline } from "react-icons/io5";
import DateTimePicker from "react-datetime";

import makeRequest from "shared/utils/request";
import { generateRequestOptions } from "shared/utils/apiEndPoints";
import { Button, Input } from "../../shared/component";

import Form from "./Reusable/Form";

import { ENV, Nullable } from "../../types";

import "react-datetime/css/react-datetime.css";
import useTranslate from "shared/hooks/useTranslate";
import useToast from "shared/hooks/useToast";

interface Props {
	ENV_NAME: ENV;
	jobAdId: Nullable<number>;
	publishDate: Nullable<string>;
	onNext: () => void;
	onPrev: () => void;
	updatePublishDate: (item: string) => void;
}

interface FormFields {
	schedule: "now" | "later" | string;
	published_at: string;
}

interface FormError {
	dateError: boolean;
	timeError: boolean;
}

const Schedule = ({ jobAdId, publishDate, onNext, onPrev, updatePublishDate }: Props) => {
	const t = useTranslate();
	const toast = useToast();
	const history = useHistory();
	const jobIdFromUrl = (queryString.parse(history.location.search)?.job_ad ||
		null) as Nullable<string>;
	const status = (queryString.parse(history.location.search)?.status || null) as Nullable<string>;

	const [form, setForm] = useState<FormFields>({
		schedule: "now",
		published_at: "",
	});
	const [errors, setErrors] = useState({} as FormError);
	const [isEdited, setIsEdited] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	useEffect(() => {
		if (jobIdFromUrl) {
			getJob(jobIdFromUrl);
			setIsEditing(true);
		}
	}, [jobIdFromUrl]);

	const getJob = async (jobId: string) => {
		const res = await makeRequest(
			generateRequestOptions("getJobAd", { urlParams: jobAdId || jobId }),
		);

		if (res?.code === 200) {
			setForm({
				schedule: !res?.data?.published_at && !publishDate ? "now" : "later",
				published_at: publishDate || res?.data?.published_at || "",
			});
		} else toast.error(res?.message);
	};

	const handleSchedule = (type: string) => {
		setForm({
			schedule: type,
			published_at: "",
		});
		setIsEdited(true);
	};

	const handleDateChange = (e: string | Moment) => {
		setErrors((p) => ({ ...p, timeError: false }));
		setForm((p) => ({
			...p,
			published_at: moment(e).toISOString(),
		}));
	};

	const handleTimeChange = (e: string | Moment) => {
		const selectedDate = moment(form?.published_at);
		const currentDate = moment();
		const selectedTime = moment(e, "HH:mm");
		const currentTime = moment(new Date(), "HH:mm");

		if (
			selectedDate.isSame(currentDate, "year") &&
			selectedDate.isSame(currentDate, "month") &&
			selectedDate.isSame(currentDate, "day") &&
			selectedTime.hour() <= currentTime.hour() &&
			selectedTime.minute() <= currentTime.minute()
		)
			setErrors((p) => ({ ...p, timeError: true }));
		else setErrors((p) => ({ ...p, timeError: false }));

		setForm((prev) => ({
			...prev,
			published_at: moment(prev.published_at)
				.set({
					hour: moment(e, "HH:mm").hour(),
					minute: moment(e, "HH:mm").minute(),
				})
				.toISOString(),
		}));
	};

	const handleSave = async () => {
		const { schedule, published_at } = form;

		if (errors["timeError"]) return;

		updatePublishDate(published_at);
		resetForm();
		onNext();
	};

	const resetForm = () => setForm({ schedule: "", published_at: "" });

	return (
		<div className="form-block schedule">
			<Form title={t("SCHEDULE")}>
				<div>
					<p>{t("SELECT_SCHEDULE_POST")}</p>

					{status !== "active" && (
						<div>
							<Input
								choiceLabel={t("NOW")}
								inputType="radio"
								id="now"
								name="schedule"
								value={form?.schedule}
								onChange={() => handleSchedule("now")}
								checked={form?.schedule === "now"}
								isError={errors["schedule"]}
							/>
							<Input
								choiceLabel={t("SELECT_SCHEDULE")}
								inputType="radio"
								id="later"
								name="schedule"
								value={form?.schedule}
								onChange={() => handleSchedule("later")}
								checked={form?.schedule === "later"}
								isError={errors["schedule"]}
							/>
						</div>
					)}
					{(form?.schedule === "later" || status === "active") && (
						<div className="date-time-container">
							<div className="form-row">
								<div className="date">
									<p className="isRequired">{t("DATE")}</p>
									<DateTimePicker
										value={
											form?.published_at
												? moment(form?.published_at).format("DD/MM/YYYY")
												: ""
										}
										onChange={handleDateChange}
										dateFormat={"DD/MM/YYYY"}
										timeFormat={false}
										closeOnSelect
										isValidDate={(current) =>
											current.isSameOrAfter(moment().subtract(1, "day"))
										}
										renderInput={(props) => {
											return (
												<div
													className={`J-inputComp J-inputComp-input schedule-date ${
														status === "active" &&
														"schedule-date-disabled"
													} ${errors["dateError"] && "border-red"}`}
												>
													<div className="icon-div">
														<IoCalendarNumberOutline size={24} />
													</div>
													<input
														{...props}
														value={
															form?.published_at
																? moment(form?.published_at).format(
																		"DD/MM/YYYY",
																  )
																: ""
														}
														placeholder="dd/mm/yyyy"
														readOnly
														disabled={status === "active"}
													/>
												</div>
											);
										}}
									/>
								</div>
								<div className="date">
									<p className="isRequired">{t("TIME_TO_POST")}</p>
									<DateTimePicker
										value={
											form?.published_at
												? moment(form?.published_at).format("HH:mm")
												: ""
										}
										onChange={handleTimeChange}
										dateFormat={false}
										timeFormat={"HH:mm"}
										closeOnSelect
										renderInput={(props) => {
											return (
												<div
													className={`J-inputComp J-inputComp-input schedule-date ${
														status === "active" &&
														"schedule-date-disabled"
													} ${errors["timeError"] && "border-red"}`}
												>
													<input
														{...props}
														value={
															form?.published_at
																? moment(form?.published_at).format(
																		"HH:mm",
																  )
																: ""
														}
														placeholder="hh:mm"
														readOnly
														disabled={status === "active"}
													/>
												</div>
											);
										}}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</Form>
			<div className="btn-grp">
				<Button
					type="outline"
					title={t("PREVIOUS_LABEL")}
					onClick={onPrev}
					btnClassName="customBtn"
					customIcon={<PiArrowLeft size={18} className="icon icon-prev" />}
				/>
				<Button
					type="primary"
					title={t("POST_AND_PREVIEW_JOB")}
					onClick={handleSave}
					btnClassName="customBtn btn-save"
					disabled={
						(!isEdited && !isEditing) ||
						(form?.schedule === "later" && !form?.published_at)
					}
				/>
			</div>
		</div>
	);
};

export default Schedule;
