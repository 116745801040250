import { FC } from 'react';
import cx from "classnames";
import WhatsappIcon from 'assets/icons/WhatsappIcon';

import "./WhatsappStickyBtn.scss";
import useTranslate from 'shared/hooks/useTranslate';

const PHONE_NUMBER = "6281110020977"
const WA_TEXT = `https://wa.me/${PHONE_NUMBER}/?text=Saya%20ingin%20tahu%20lebih%20banyak%20tentang%20Portal%20Kerja.%20Berikut%20ini%20detail%20saya%3A%0ANama%3A%20%0AJabatan%3A%20%0APerusahaan%3A%20%0ANomor%20kontak%3A%20%0AEmail%3A%20%0APesan%2FPertanyaan%3A`;

interface IProps {
    className: string;
}

const WhatsappStickyBtn: FC<IProps> = ({ className = '' }) => {
    const t = useTranslate()
    const handleWaLink = () => {
        window.open(WA_TEXT, "_blank");
    }

    return (
        <div className={cx('whatsapp-sticky-wrapper', className)}>
            <div className='whatsapp-sticky-btn' onClick={handleWaLink}>
                <WhatsappIcon width="50" height="80" />
                <div className='hover-text'>{t("ASK_MORE")}</div>
            </div>
        </div>
    );
}

export default WhatsappStickyBtn;
