import { CaretDown, X } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Label, Textarea } from '../../../shared/component';
import ModalCustom from '../../../shared/component/molecules/ModalCustom/ModalCustom';
import { RenderIf } from '../../../shared/utils/common';

type ModalRescheduleProps = {
  showModal: boolean;
  setshowModal: (value: boolean) => void;
};

export default function ModalReschedule(props: ModalRescheduleProps) {
  const [show, setShow] = useState(false);
  const [selectedCandidate, setselectedCandidate] = useState<any>([]);

  // handler select and unselect candidate
  const handleSelectCandidate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (name === 'all') {
      if (checked) {
        setselectedCandidate(listName);
      } else {
        setselectedCandidate([]);
      }
    }

    if (checked && name !== 'all') {
      setselectedCandidate((prev: any) => [...prev, name]);
    } else {
      setselectedCandidate((prev: any) =>
        prev.filter((item: any) => item !== name),
      );
    }
  };

  // handle remove candidate from list selected
  const handleRemoveCandidate = (name: string) => {
    setselectedCandidate((prev: any) =>
      prev.filter((item: any) => item !== name),
    );
  };

  // make const list of random name
  const listName = [
    'Leonardo',
    'Donatello',
    'Raphael',
    'Michelangelo',
    'Splinter',
    'April',
    'Casey',
    'Shredder',
  ];

  return (
    <ModalCustom
      showCloseButton
      isCentered
      title="Reschedule"
      size="lg"
      isShow={props.showModal}
      setShow={props.setshowModal}
      useChildren
      childrenButtonConfirm={'Reschedule'}>
      <section className="p-md">
        <div className="position-relative mb-med">
          <Label
            children={null}
            className={''}
            isRequired={false}
            name={''}
            label={'Select candidates'}
          />
          <div
            onClick={
              //   when candidateselected is not empty this click can't trigger anything
              //   but when candidateselected is empty this click will trigger show list candidate
              selectedCandidate.length > 0 ? () => {} : () => setShow(!show)
            }
            className={[
              'position-relative border d-flex flex-wrap tw transition-all duration-300 px-md rounded-xs bg-transparent gap-2 align-items-center',
              selectedCandidate.length > 0 ? 'py-med' : 'py-md',
            ].join(' ')}>
            {/* list candidate selected */}

            {selectedCandidate.map((item: any, index: any) => (
              <div
                key={index}
                className="d-flex align-items-center gap-1 text-sm tw bg-app-primary-50 px-xs rounded-xxs"
                style={{
                  width: 'fit-content',
                }}>
                <p className="tw text-gray-500 mb-0 pb-0 fw-normal">{item}</p>
                <X
                  onClick={() => handleRemoveCandidate(item)}
                  className="tw text-gray-500 cursor-pointer"
                />
              </div>
            ))}

            <CaretDown
              height={20}
              width={20}
              onClick={() => setShow(!show)}
              className={[
                'position-absolute tw text-gray-500 cursor-pointer transition-all duration-300',
                show ? 'rotate-180' : '',
              ].join(' ')}
              weight="bold"
              style={{
                right: '1.5rem',
              }}
            />
          </div>
          <RenderIf condition={show}>
            <div
              className="border mt-2 rounded-xs position-absolute w-100 p-4 bg-white tw overflow-auto"
              style={{ maxHeight: '20rem' }}>
              <Form.Check
                onChange={(e) => handleSelectCandidate(e)}
                type="checkbox"
                checked={selectedCandidate.length === listName.length}
                name="all"
                label={'Select all'}
              />
              {listName.map((item, index) => (
                <div key={index}>
                  <Form.Check
                    onChange={(e) => handleSelectCandidate(e)}
                    type="checkbox"
                    name={item}
                    checked={selectedCandidate.includes(item)}
                    value={item}
                    label={item}
                  />
                </div>
              ))}
            </div>
          </RenderIf>
        </div>

        <Textarea
          children={null}
          classNameLabel={''}
          cols={10}
          onChange={() => {}}
          value={''}
          isError={false}
          required={false}
          rows={5}
          titleLabel="Input message for candidate"
          characterLimit={200}
          name="message"
          placeholder={`Dear Leonardo,

Thank you for your interest in joining Visi Prima. We appreciate the time you've taken to apply for this position of Android Developer.
      `}
        />
      </section>
    </ModalCustom>
  );
}
