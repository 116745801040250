const qs = require("qs");
const getURL = (protocol = "https") => {
	let url = window.location.href.split("/")[2];
	let splitUrl = url.split(".");
	if (splitUrl[1] === "portalkerja") {
		return `${protocol}://backend.portalkerja.co.id`;
	} else if (splitUrl[1] === "powerplant") {
		return `${protocol}://backend.powerplant.id`;
	} else {
		return `${protocol}://backend.accel.id`;
	}
};

export const baseURL = (() => {
	return getURL();
})();

export const socketBaseURL = (() => {
	return getURL("wss");
})();

export const homeUrl = (() => {
	let url = window.location.href.split("/")[2];
	let splitUrl = url.split(".");
	if (splitUrl[1] === "portalkerja") {
		return "portalkerja.co.id";
	} else if (splitUrl[1] === "powerplant") {
		return `powerplant.id`;
	} else if (splitUrl[1] === "accel") {
		return `accel.id`;
	} else {
		return `localhost`;
	}
})();

export const EPStore = {
	getNewAccessToken: {
		url: "user/refresh-token/",
		method: "POST",
	},
	getUserViaToken: {
		url: "user/basicUserDetail/",
		method: "GET",
	},
	signUp: {
		url: "api/auth/userRegistration/",
		method: "POST",
	},
	logIn: { url: "user/login/", method: "POST" },
	logout: { url: "user/logout/", method: "POST" },
	listQuestion: {
		url: "job_ad/list-preloaded-prescreening-question/",
		method: "GET",
	},

	getProfile: {
		url: "user/show-profile/",
		method: "GET",
	},
	editProfile: {
		url: "user/profile/edit/",
		method: "PATCH",
	},
	getListIndustry: {
		url: "company/industry/",
		method: "GET",
	},
	// City and District
	fetchCity: {
		url: "user/location/list",
		method: "GET",
	},

	// Job Creation
	addJobCreation: {
		url: "job_ad/create/",
		method: "POST",
	},
	fetchJobCreation: {
		url: "job_ad/get/",
		method: "GET",
	},
	updateJobCreation: {
		url: "job_ad/update/",
		method: "PATCH",
	},
	carryOverJobAd: {
		url: "job_ad/carry-over/",
		method: "PATCH",
	},
	postJobCreation: {
		url: "job_ad/publish/",
		method: "PATCH",
	},

	// Job Creation Preload Copy
	copyPreloadQuestion: {
		method: "POST",
		url: "job_ad/copy-preloaded-prescreening-question/",
	},
	fetchAnswerQuestion: {
		method: "GET",
		url: "job_ad/list-prescreening-answer/",
	},
	updateAnswerQuestion: {
		url: "job_ad/RetrieveUpdatePreScreeningQuestion/",
		method: "PATCH",
	},
	addCustomQuestion: {
		url: "job_ad/create-prescreening-question/",
		method: "POST",
	},
	deleteAnswerQuestion: {
		method: "DELETE",
		url: "job_ad/RetrieveUpdatePreScreeningQuestion/",
	},
	// detail job poster on interview page
	getDetailJobPoster: {
		url: "job_ad/get/short-detail/",
		method: "GET",
	},
	// Interview Method
	updateInterviewMethod: {
		method: "PATCH",
		url: "job_ad/add-interview-type/",
	},
	// insert interview type
	insertInterviewType: {
		method: "POST",
		url: "job_application/interview/slot/create/",
	},
	// add slot interview
	addSlotInterview: {
		method: "POST",
		url: "job_application/interview/slot/add-slots/",
	},
	// add exact interview
	addExactInterview: {
		method: "POST",
		url: "job_application/interview/exact/create/",
	},
	//list interview schdeule (temporary)
	listInterviewSchedule: {
		method: "GET",
		url: "job_application/interview/schedule/",
	},
	//list interview slot (temporary)
	listInterviewSlot: {
		method: "GET",
		url: "job_application/interview/list/",
	},
	detailInterviewSchedule: {
		method: "GET",
		url: "job_application/interview/retrieve/",
	},

	getJobAds: {
		url: "job_ad/list/",
		method: "GET",
	},
	getListApplicants: {
		url: "job_application/candidate-management/list-applicants/",
		method: "GET",
	},
	getCandidateDetails: {
		url: "job_application/candidate-management/detail-applicant/",
		method: "GET",
	},
	markStatus: {
		url: "job_application/candidate-management/mark-status/",
		method: "POST",
	},
	employerAssist: {
		url: "job_ad/update/",
		method: "PATCH",
	},
	quickMovetoUnfit: {
		url: "job_application/candidate-management/employer-assist/quick-move-to-unfit/",
		method: "PATCH",
	},
	quickMovetoReject: {
		url: "job_application/candidate-management/employer-assist/quick-rejection/",
		method: "PATCH",
	},
	markCategory: {
		url: "job_application/candidate-management/set-candidate-category/",
		method: "PATCH",
	},
	exportResume: {
		url: "user/resume-export/",
		method: "GET",
	},
	exportAllCandidateFiles: {
		url: "user/zip-docs-for-single-job-seeker/",
		method: "GET",
	},
	listComments: {
		url: "job_application/comments/list/",
		method: "GET",
	},
	postComment: {
		url: "job_application/comments/create/",
		method: "POST",
	},
	applicationHistory: {
		url: "job_application/candidate-management/application-history/",
		method: "GET",
	},
	listStaff: {
		url: "user/company-user/",
		method: "GET",
	},
	setBulkCandidateCategory: {
		url: "job_application/candidate-management/set-bulk-candidate-category/",
		method: "POST",
	},
	getOfferletterPreview: {
		url: "job_application/offering-letter/preview/",
		method: "POST",
	},
	sendOfferingLetter: {
		url: "job_application/offering-letter/send/",
		method: "POST",
	},
	exportBulkCandidateResume: {
		url: "user/bulk-zip-resumes-for-bulk-job-seekers/",
		method: "GET",
	},
	exportBulkCandidateFiles: {
		url: "user/zip-all-docs-for-bulk-job-seekers/",
		method: "GET",
	},
	sendCandidateLink: {
		url: "job_application/candidate-management/send-candidate-link/",
		method: "POST",
	},
	candidateNameSearch: {
		url: "job_application/candidate-management/list-applicants-slim/",
		method: "GET",
	},
	getCompanyDetail: {
		url: "company/get-company/",
		method: "GET",
	},
	getCompanyVerification: {
		url: "company/verification/get/",
		method: "GET",
	},
	addTaxCardCompany: {
		url: "company/verification/add-tax-card/",
		method: "POST",
	},
	addBusinessLicenceCompany: {
		url: "company/verification/add-business-license/",
		method: "POST",
	},
	updateVerificationData: {
		url: "company/verification/update/",
		method: "PATCH",
	},
	jobPosterSignUp: {
		url: "user/job-poster/sign-up/",
		method: "POST",
	},
	resendVerificationEmail: {
		url: "user/job-poster/resend-registration-email/",
		method: "POST",
	},
	getCompanyStaff: {
		url: "user/company-staff/",
		method: "GET",
	},
	viewCollaborators: {
		url: "job_ad/view-collaborators/",
		method: "GET",
	},
	addCollaborators: {
		url: "job_ad/add-collaborators/",
		method: "PATCH",
	},
	getMiniDetailCompany: {
		url: "company/mini-detail/",
		method: "GET",
	},
	jobAdDeactivate: {
		url: "job_ad/deactivate/",
		method: "PATCH",
	},
	jobAdDelete: {
		url: "job_ad/delete/",
		method: "DELETE",
	},

	// Job Ad Creation
	getLocation: {
		url: "user/location/list",
		method: "GET",
	},
	createJobAd: {
		url: "job_ad/create/",
		method: "POST",
	},
	getJobAd: {
		url: "job_ad/get/",
		method: "GET",
	},
	updateJobAd: {
		url: "job_ad/update/",
		method: "PATCH",
	},
	publishJob: {
		url: "job_ad/publish/",
		method: "PATCH",
	},
	listPrescreeningCategories: {
		url: "job_ad/list-prescreening-question-category/",
		method: "GET",
	},
	listPreloadedPreScreenQuestions: {
		url: "job_ad/list-preloaded-prescreening-question/",
		method: "GET",
	},
	createPreScreenQuestions: {
		url: "job_ad/create-prescreening-question/",
		method: "POST",
	},
	listPreScreenQuestions: {
		url: "job_ad/list-prescreening-question/",
		method: "GET",
	},
	updatePrescreeningQuestion: {
		url: "job_ad/RetrieveUpdatePreScreeningQuestion/",
		method: "PATCH",
	},
	deletePrescreenQuestion: {
		url: "job_ad/RetrieveUpdatePreScreeningQuestion/",
		method: "DELETE",
	},
	fetchCompanyUsers: {
		url: "user/company-user/",
		method: "GET",
	},
	deactivateStaffUser: {
		url: "user/staff-user/deactivate-reactivate-account/profile/",
		method: "PATCH",
	},
	addUserStaff: {
		url: "user/add-staff/",
		method: "POST",
	},
	changePassword: {
		url: "user/change-password/",
		method: "PATCH",
	},
	getCompany: {
		url: "company/get-company/",
		method: "GET",
	},
	companyUpdate: {
		url: "company/update/",
		method: "PATCH",
	},
	updateUserStaff: {
		url: "user/staff-user/update/profile/",
		method: "PATCH",
	},
	updateFileCompanyVerification: {
		url: "company/verification/update/",
		method: "PATCH",
	},
	getRegisteredBusiness: {
		url: "company/get-list-company/",
		method: "GET",
	},
	getListParentCompany: {
		url: "company/get-list-parent-company",
		method: "GET",
	},
};

export const generateRequestOptions = (key, options = {}) => {
	if (!key) {
		return null;
	}

	if (options && options.hasOwnProperty("queryParams")) {
		return {
			...EPStore[key],
			url: EPStore[key].hasOwnProperty("baseURL")
				? `${EPStore[key].baseURL}/${EPStore[key].url}${
						options.urlParams || ""
				  }?${qs.stringify(options.queryParams)}`
				: `${baseURL}/${EPStore[key].url}${options.urlParams || ""}?${qs.stringify(
						options.queryParams,
						options.queryOptions,
				  )}`,
		};
	}

	if (options && options.hasOwnProperty("queryParamsNoArrIdx")) {
		let qsString = qs.stringify(
			options.queryParamsNoArrIdx,
			!EPStore[key].hasOwnProperty("baseURL") ? options.queryOptions : undefined,
		);
		qsString = qsString.replace(/%5B[0-9]%5D=/g, "=");

		return {
			...EPStore[key],
			url: EPStore[key].hasOwnProperty("baseURL")
				? `${EPStore[key].baseURL}/${EPStore[key].url}${
						options.urlParams || ""
				  }?${qsString}`
				: `${baseURL}/${EPStore[key].url}${options.urlParams || ""}?${qsString}`,
		};
	}

	if (options && options.hasOwnProperty("urlParams")) {
		return {
			...EPStore[key],
			url: EPStore[key].hasOwnProperty("baseURL")
				? `${EPStore[key].baseURL}/${EPStore[key].url}${options.urlParams}`
				: `${baseURL}/${EPStore[key].url}${options.urlParams}`,
		};
	}

	if (options && options.hasOwnProperty("innerUrlParams")) {
		let url = Object.keys(options.innerUrlParams).map((k) => {
			return EPStore[key].url.replace(`{${k}}`, options.innerUrlParams[k]);
		})[Object.keys(options.innerUrlParams).length - 1];
		return {
			...EPStore[key],
			url: EPStore[key].hasOwnProperty("baseURL")
				? `${EPStore[key].baseURL}/${url}`
				: `${baseURL}/${url}`,
		};
	}
	return {
		...EPStore[key],
		url: EPStore[key].hasOwnProperty("baseURL")
			? `${EPStore[key].baseURL}/${EPStore[key].url}`
			: `${baseURL}/${EPStore[key].url}`,
	};
};
export const generateURL = (key) => {
	return `${homeUrl}/${EPStore[key].url}`;
};
