import { FC, useEffect, useState } from 'react'
import Checkbox from 'shared/component/atoms/Checkbox';
import cx from 'classnames';
import { firstLetterUpperCase } from 'shared/utils/helper';
import { QuestionsFiltersChoices } from '../../types';
import { getCookies } from 'shared/utils/cookies';

type QuestionSingleChoiceProps = 'id'
    | 'onChange'
    | 'showMandatoryQuestion'
    | 'mcq_choices'
    | 'idMandatoryQuestions'
    | 'mandatory_choices';

const QuestionSingleChoice: FC<
    Pick<
        QuestionsFiltersChoices, 
        QuestionSingleChoiceProps
    >
> = ({
    id,
    onChange,
    showMandatoryQuestion,
    mcq_choices = {},
    idMandatoryQuestions,
    mandatory_choices = [],
    ...props
}) => {
    const defaultMandatoryQuestions = getCookies("defaultMandatoryQuestions");
    const defaultQuestions = defaultMandatoryQuestions.filter(v => v.id === id)[0];

    const [selectedSingleChoice, setSelectedSingleChoice] = useState<string[]>([]);

    useEffect(() => {
        setSelectedSingleChoice(mandatory_choices);
    }, [mandatory_choices])

    const handleDefaultMandatoryQuestions = () => {
        setSelectedSingleChoice(mandatory_choices);
        onChange(mandatory_choices);
    }

    useEffect(() => {
        handleDefaultMandatoryQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMandatoryQuestion]);

    return (
        <div className='pt-3'>
            <div className='mt-2 ms-3'>
                {Object.keys(mcq_choices).map(status => {
                    return (
                        <div
                            key={status}
                            className={cx(
                                "d-flex align-items-center py-2",
                                {
                                    "question-filter__mandatory-mark": defaultQuestions?.mandatory_choices?.includes?.(status)
                                }
                            )}
                        >
                            <Checkbox
                                id={`single_choices__${status}`}
                                label={firstLetterUpperCase(status)}
                                checked={selectedSingleChoice.includes(status)}
                                onChange={() => {
                                    if(selectedSingleChoice.includes(status)) {
                                        setSelectedSingleChoice(selectedSingleChoice.filter(x => x !== status))
                                        onChange(selectedSingleChoice.filter(x => x !== status));
                                        return;
                                    }
                                    setSelectedSingleChoice([ ...selectedSingleChoice, status ])
                                    onChange([ ...selectedSingleChoice, status ])
                                    return;
                                }}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default QuestionSingleChoice;
