import { FC, useEffect, useState } from "react"
import cx from "classnames";
import InputRadio from "shared/component/atoms/InputRadio"

import { QuestionsFiltersChoices } from "../../types";
import useTranslate from "shared/hooks/useTranslate";

import "../../Filter.scss"

type QuestionsYesNoChoiceProps = "onChange" | "value" | "boolean_accepted_answer";

const QuestionsYesNoChoice: FC<
    Pick<
        QuestionsFiltersChoices, 
        QuestionsYesNoChoiceProps
    >
> = ({
    onChange,
    value,
    boolean_accepted_answer,
}) => {
    const t = useTranslate();
    const [inputValue, setInputValue] = useState<string>("");

    useEffect(() => {
        if (value) setInputValue(value as string)
    }, [value])

    useEffect(() => {
        setInputValue(boolean_accepted_answer ? "true" : "false")
    }, [])

    const handleChange = e => {
        onChange(e.target.value)
        setInputValue(e.target.value)
    }

    return (
        <div className="pt-3">
            <InputRadio
                onChange={handleChange}
                className="tw cursor-pointer"
                label={t("YES")}
                value="true"
                checked={inputValue === "true"}
                classNameLabel={cx({ "question-filter__mandatory-mark":  boolean_accepted_answer })}
                name="idealAnswer"
            />
            <InputRadio
                onChange={handleChange}
                className="tw cursor-pointer"
                label={t("NO")}
                value="false"
                checked={inputValue === "false"}
                classNameLabel={cx({ "question-filter__mandatory-mark":  !boolean_accepted_answer })}
                name="idealAnswer"
            />
        </div>
    )
}

export default QuestionsYesNoChoice