import { useSelector } from "react-redux";
import { RootState } from "store/types/RootStateTypes";

type LANG = {
	eng: string;
	bhs: string;
};

export const useEnvironment = () => {
	return useSelector((state: RootState) => state.auth.selectedEnvironment) as keyof LANG;
};
