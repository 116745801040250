import { Modal } from 'react-bootstrap';
import 'react-calendar/dist/Calendar.css';
import { Button } from '../../../../shared/component';
import DateCalendar from '../../../../shared/component/molecules/DateCalendar/DateCalendar';

type CopyModalProps = {
  showModal: boolean;
  setshowModal: (data: boolean) => void;
  loopDateRange: any;
};

export default function CopyModal({
  showModal,
  setshowModal,
  loopDateRange,
}: CopyModalProps) {
  const handleSelectDate = (date: any) => {
    if (!date.includes(null)) {
      loopDateRange(date.map((item: any) => new Date(item)));
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setshowModal(false)}
      backdrop="static"
      contentClassName="border-0 p-0 tw rounded-xl"
      centered>
      <Modal.Header className="tw rounded-t-xl border-b-2 p-md ">
        <Modal.Title className="mx-auto">
          Select the date(s) you want to copy time
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center py-sm">
          <DateCalendar
            onChange={handleSelectDate}
            minDate={new Date()}
            selectRange={true}
            showNeighboringMonth={false}
            disableWeekends={true}
          />
        </div>
      </Modal.Body>

      <Modal.Footer className="d-flex gap-3 tw border-t-2 rounded-b-xl box-border bg-gray-50">
        <Button
          onClick={() => setshowModal(false)}
          type="textGray"
          btnClassName="tw border-2 w-100"
          className="col"
          size="sm">
          Cancel
        </Button>
        <Button
          onClick={() => setshowModal(false)}
          type="primary"
          btnClassName="border w-100"
          className="col"
          size="sm">
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
