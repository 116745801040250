import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as Variable from "../../utils/variables";
// import { classHelper } from "../../utils/stringUtils";

import "./ToggleComponent.scss";
import { classHelper } from "../../utils/stringUtils";

// type = [switch, textOnly]

const Toggle = ({
	type,
	leftText,
	rightText,
	checked,
	onChange,
	name,
	active,
	disabled = false,
	ENV_NAME,
	...props
}) => {
	const [mode, setMode] = useState("subscribe");

	return (
		<div className={classHelper("tComp", props.className)}>
			{type === "switch" ? (
				<div className="tComp-content mb-0">
					{leftText && (
						<label
							className={`tComp-text px-2 ${checked ? "checked" : ""}`}
							htmlFor={`${name ? name : "switch"}`}
						>
							{leftText || "Left"}
						</label>
					)}
					<label className="px-2 tComp-switch">
						<input
							className="togle-switch modal-toggle testing"
							type="checkbox"
							id={`${name ? name : "switch"}`}
							disabled={disabled}
							onChange={onChange}
							checked={checked}
							name={name}
						/>
						<label
							className="toggle-switch"
							htmlFor={`${name ? name : "switch"}`}
						></label>
					</label>
					{rightText && (
						<label
							className={`tComp-text px-2 ${checked ? "checked" : ""}`}
							htmlFor={`${name ? name : "switch"}`}
						>
							{rightText || "Right"}
						</label>
					)}
				</div>
			) : type === "textOnly" ? (
				<div className="tComp-textOnly">
					<label
						className={`${mode === "subscribe" ? "active" : ""}`}
						onClick={() => setMode("subscribe")}
					>
						{Variable.SUBSCRIBE_LABEL[ENV_NAME]}
					</label>
					<label
						className={`${mode === "unsubscribe" ? "onActive" : ""}`}
						onClick={() => setMode("unsubscribe")}
					>
						{Variable.UNSUBSCRIBE_LABEL[ENV_NAME]}
					</label>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(Toggle));
