import { Modal } from "react-bootstrap";
import { Button, Input } from "../../../shared/component";
import * as Variable from "../../../shared/utils/variables";

import { Question } from "../PreScreening";

import { ENV, Nullable } from "../../../types";

import "./CustomModal.scss";

import { ProgressBar } from "react-step-progress-bar";

interface Props {
	ENV_NAME: ENV;
	show: boolean;
	question: Nullable<Question> | undefined;
	companyName: string;
	onHide: () => void;
}

const QUESTION_TYPE = [
	"mcq", // checbox
	"fill_in_the_blanks", // radio
	"short_answer", // textarea
	"true_false", // radio
	"numeric", // numeric
	"progressive", //radio
	"single_select", //radio
];

const CustomPreviewModal = ({ ENV_NAME, show, question, companyName, onHide }: Props) => {
	const YES_NO_OPTIONS = [
		{ id: Variable.YES[ENV_NAME], value: true, name: Variable.YES[ENV_NAME] },
		{ id: Variable.NO[ENV_NAME], value: false, name: Variable.NO[ENV_NAME] },
	];

	return (
		<Modal
			show={show}
			onHide={onHide}
			backdrop="static"
			contentClassName="border-0 p-0 tw custom-preview"
			centered
		>
			<Modal.Header className="tw border-b-2" closeButton>
				<h3>{`${Variable.APPLY_TO[ENV_NAME]} ${companyName}`}</h3>
			</Modal.Header>
			<Modal.Body>
				<div className="content-wrapper">
					<div className="progress-bar-default">
						<ProgressBar percent={30} height={10} filledBackground="#193560" />
						<h4>{Variable.ADDITIONAL_QUESTIONS[ENV_NAME]}</h4>
					</div>

					<div className="question">
						<p className={`text ${question?.is_mandatory ? "isRequired" : ""}`}>
							{question?.question_text}
						</p>

						{/* mcq */}
						{question?.question_type === QUESTION_TYPE[0] && (
							<>
								{question?.mcq_choices?.map((opt, opt_idx) => (
									<Input
										key={opt_idx + 1}
										choiceLabel={opt}
										inputType="checkbox"
										id={`${question?.id}_${opt_idx + 1}`}
										name={`answer_${question?.id}`}
										value={opt}
										onChange={() => {}}
									/>
								))}
							</>
						)}

						{/* short answer */}
						{question?.question_type === QUESTION_TYPE[2] && (
							<div className="question">
								<Input
									value={""}
									name={`answer_${question?.id}`}
									onChange={() => {}}
									inputType="textarea"
									placeholder={""}
								/>
							</div>
						)}

						{/* Yes/No */}
						{question?.question_type === QUESTION_TYPE[3] && (
							<>
								{YES_NO_OPTIONS?.map((opt, opt_idx) => (
									<Input
										key={opt_idx + 1}
										choiceLabel={opt?.id}
										inputType="radio"
										id={`${question?.id}_${opt_idx + 1}`}
										name={`answer_${question?.id}`}
										value={opt?.value}
										onChange={() => {}}
									/>
								))}
							</>
						)}

						{/* numeric */}
						{question?.question_type === QUESTION_TYPE[4] && (
							<div className="question">
								<Input
									placeholder={""}
									value={""}
									name={`answer_${question?.id}`}
									onChange={() => {}}
									inputType="label"
									className="w-100"
								/>
							</div>
						)}

						{/* single_select */}
						{[QUESTION_TYPE[1], QUESTION_TYPE[5], QUESTION_TYPE[6]].includes(
							question?.question_type || "",
						) && (
							<>
								{question?.mcq_choices.map((option, opt_idx) => (
									<Input
										key={opt_idx + 1}
										choiceLabel={option}
										inputType="radio"
										id={`${question?.id}_${opt_idx + 1}`}
										name={`answer_${question?.id}`}
										value={option}
										onChange={() => {}}
									/>
								))}
							</>
						)}
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<div className="btn-grp">
					<Button type="outline" btnClassName="w-100" disabled>
						{Variable.BACK[ENV_NAME]}
					</Button>
					<Button type="primary" disabled>
						{Variable.NEXT_LABEL[ENV_NAME]}
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default CustomPreviewModal;
