import { Users, UsersThree } from '@phosphor-icons/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '../../../shared/component';
import LayoutWithNavigation from '../../../shared/layouts/LayoutWithNavigation';
import { RenderIf } from '../../../shared/utils/common';
import FormSchedule from './SlotTime/FormSchedule';
import FormTime from './SlotTime/FormTime';
import {
  insertTimeSlotSchedule,
  setInterviewType,
} from '../../../store/actions/interview';
import useToast from 'shared/hooks/useToast';

export default function InterviewAdd() {
  const toast = useToast();
  const route = useHistory();
  const INTERVIEW = useSelector((state) => state.interview);
  const dispatch = useDispatch();
  const [form, setform] = useState({
    meeting_type: '',
    schedule_name: '',
    slot_target: INTERVIEW.typeInterview,
    video_call_link: '',
    date_time_schedule_exact: null,
    location: '',
    phone_number: '',
    interview_pic: '',
    message: '',
    max_capacity_in_slots: 0,
    job_ad: 1,
    allow_interview_reschedule: true,
    max_date_to_interview: null,
    no_of_reschedule_allowed: 0,
    slot: [],
    schedule_color: '',
  });

  const [step, setstep] = useState('form');

  const handleCancel = () => {
    if (step === 'time') {
      setstep('form');
    }

    if (step === 'form') {
      route.goBack();
    }
  };

  const handlerSubmit = async (event) => {
    event.preventDefault();
    if (step === 'form') {
      setstep('time');
    }

    if (step === 'time') {
      // if value from form contains null or '' then delete key from form
      for (const key in form) {
        if (form[key] === null || form[key] === '') {
          delete form[key];
        }
      }

      return await insertTimeSlotSchedule(form)
        .then((res) => {
          if (res.status === 200) {
            toast.success('Create Schedule Interview Success');
            dispatch(setInterviewType(''));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <LayoutWithNavigation>
      <form onSubmit={handlerSubmit}>
        {/* Section Form Interview */}
        <RenderIf condition={step === 'form'}>
          <section className="position-relative bg-white mt-xl p-lg rounded-xl tw container w-75">
            {/* Section Head */}
            <section className="position-relative d-flex gap-3 align-items-center">
              <RenderIf condition={INTERVIEW?.typeInterview === 'group'}>
                <UsersThree
                  width={36}
                  height={36}
                  weight="fill"
                  color="#FC9607"
                />
              </RenderIf>
              <RenderIf condition={INTERVIEW?.typeInterview === 'one-on-one'}>
                <Users width={36} height={36} weight="fill" color="#FC9607" />
              </RenderIf>

              <h3 className="text-display-xs fw-semibold mt-1 tw text-black tw capitalize">
                Create {INTERVIEW?.typeInterview} Interview
              </h3>
            </section>

            {/* Content */}
            <section className="mt-5">
              <FormSchedule
                form={form}
                setform={setform}
                INTERVIEW={INTERVIEW}
              />
            </section>
          </section>
        </RenderIf>

        {/* Section Form Time */}
        <RenderIf condition={step === 'time'}>
          <FormTime form={form} setForm={setform} />
        </RenderIf>

        {/* Button Cancel and Save */}
        <section className="position-relative p-lg rounded-xl tw container w-75">
          <div className="position-relative d-flex justify-content-end gap-4 ">
            <Button
              typeButton="button"
              onClick={handleCancel}
              type="textGray"
              btnClassName="border"
              size="sm">
              Cancel
            </Button>
            <Button
              typeButton={'submit'}
              type="primary"
              disabled={
                step === 'form'
                  ? !form.meeting_type ||
                    !form.schedule_name ||
                    !form.interview_pic ||
                    !form.message
                  : false
              }
              size="sm">
              Next
            </Button>
          </div>
        </section>
      </form>
    </LayoutWithNavigation>
  );
}
