import React from 'react';
import './TagComponent.scss';

type TagComponentType = {
	value?: string;
	text?: string;
	type?: 'success' | 'info' | 'error';
	className?: string;
};

const TagComponent = ({ value, text, className, type = 'success', ...props }: TagComponentType) => {
	return (
		<div className={`tag ${className} tag-${type}`} {...props}>
			<p className="tag__tag-value">{value}</p>
			<p className="tag__tag-description">{text}</p>
		</div>
	);
};

export default TagComponent;
