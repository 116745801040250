import * as type from '../types/interview';

const initialState: type.InterviewReducerInitial = {
  methodInterview: '',
  typeInterview: '',
  formInterview: {},
  jobInterview: {} as type.InterviewJobPosterDetail,
};

// eslint-disable-next-line import/no-anonymous-default-export
const reducer = (state = initialState, action: type.ListInterviewTypes) => {
  switch (action.type) {
    case type.INTERVIEW_METHOD:
      return {
        ...state,
        methodInterview: action.payload,
      };

    case type.INTERVIEW_TYPE:
      return {
        ...state,
        typeInterview: action.payload,
      };

    case type.INTERVIEW_FORM:
      return {
        ...state,
        formInterview: action.payload,
      };

    case type.INTERVIEW_JOB_POSTER:
      return {
        ...state,
        jobInterview: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
