import React from "react";
import cx from "classnames";
import { Buildings } from "@phosphor-icons/react";

import { Typography } from "shared/component/Typography/Typography";

import "./CardParentSelect.scss";

const Checkbox = ({ label, checked, className, ...props }) => {
	return (
		<label className={`checkbox-parent-select ${className}`} {...props}>
			<div className="card-parent-company__icon">
				<Buildings color="#475467" width={20} height={20} weight="fill" />
				<Typography.Text size="md" fontWeight="medium" color="#1D2939">
					{label}
				</Typography.Text>
			</div>
			<input type="checkbox" checked={checked} disabled />
			<span className="checkbox-parent-select__checkmark"></span>
		</label>
	);
};

const CardParentSelect = ({ label, checked, onClick }) => {
	return (
		<div className={cx("card-parent-company", { checked: checked })} onClick={onClick}>
			<Checkbox label={label} checked={checked} className="checkbox-parent-company" />
		</div>
	);
};

export default CardParentSelect;
