import cookies from "react-cookies";
import { call, put, select } from "redux-saga/effects";
import { homeUrl } from "../../shared/utils/apiEndPoints";
import * as actionTypes from "../actions/actionTypes";
import * as Variable from "../../shared/utils/variables";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import makeRequest from "../../shared/utils/request";
import useToast from "shared/hooks/useToast";

export function* loginSucceed(action) {
	yield put({ type: actionTypes.LOGIN_SUCCEED_REDUX, payload: action.payload });
}
export function* Logout() {
	const toast = useToast();
	const state = yield select();
	if (cookies.load("ptkjrefreshtoken")) {
		let res = yield makeRequest({
			...generateRequestOptions("logout"),
			headers: {
				Accept: "application/json",
				"content-type": "application/json",
			},
			body: {
				revoke_token: false,
				refresh_token: cookies.load("ptkjrefreshtoken"),
			},
			json: true,
		});
		if (res?.code === 200) {
			yield call(toast.success, Variable.LOGGED_OUT[state.auth.selectedEnvironment || "bhs"]);
		}
	}

	cookies.remove("ptkjauthtoken", { path: "/", domain: homeUrl });
	cookies.remove("ptkjrefreshtoken", { path: "/", domain: homeUrl });
	cookies.remove("moduleSelector", { path: "/", domain: homeUrl });
	localStorage.clear();
	window.open(`https://jobmarket.${homeUrl}`, "_self");

	yield put({
		type: actionTypes.LOGOUT_SUCCEED,
	});
}
