import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as RoutePath from '../../shared/utils/routeLink';
import * as Variable from '../../shared/utils/variables.js';
import * as ActionTypes from '../../store/actions/actionTypes';

import arrowLeft from '../../assets/svg/arrow_left.svg';
import Button from '../../shared/component/ButtonComponent/Button';
import Icon from '../../shared/component/IconComponent/Icon';
import Input from '../../shared/component/InputComponent/Input';
import ModalInfo from '../../shared/component/ModalComponent/ModalInfo';
import './ForgetPassComponent.scss';

const ForgetPassComponent = ({ setLoginModalShow, ENV_NAME, ...props }) => {
  const [success, setSuccess] = useState(false);
  const [renew, setRenew] = useState(false);
  const [passResetComp, setPassResetComp] = useState(false);

  const goToHomepage = () => {
    props.history.push(RoutePath.ROOT);
  };

  return (
    <>
      <div className="fpc">
        <div className="container d-flex justify-content-center">
          <div className="fpc-main">
            <Icon
              iconType={`${passResetComp ? 'success' : 'reset'}`}
              borderType="outline"
            />
            <h1>{`${
              renew
                ? Variable.SET_NEW_PASSWORD[ENV_NAME]
                : passResetComp
                ? Variable.PASSWORD_RESET[ENV_NAME]
                : Variable.FORGOT_PASSWORD[ENV_NAME]
            }`}</h1>
            <h3>{`${
              renew
                ? Variable.SET_NEW_PASSWORD_TITLE[ENV_NAME]
                : passResetComp
                ? Variable.PASSWORD_RESET_TITLE[ENV_NAME]
                : Variable.HELP_TEXT_FORGOT[ENV_NAME]
            }`}</h3>
            {renew ? (
              <div className="fpc-main-input">
                <Input
                  className="padBot-24"
                  label={Variable.PASSWORD[ENV_NAME]}
                  inputType="password"
                />
                <Input
                  label={Variable.CONFIRM_PASSWORD[ENV_NAME]}
                  inputType="password"
                />
              </div>
            ) : passResetComp ? (
              <></>
            ) : (
              <div className="fpc-main-input">
                <Input
                  inputType="email"
                  placeholder={Variable.ENTER_YOUR_EMAIL[ENV_NAME]}
                  label={Variable.EMAIL[ENV_NAME]}
                />
              </div>
            )}
            <Button
              type="primary"
              title={`${
                passResetComp
                  ? Variable.CONTINUE_LABEL[ENV_NAME]
                  : Variable.RESET_PASSWORD[ENV_NAME]
              }`}
              size="md"
              className={`d-flex flex-column w-100 ${
                passResetComp ? 'padTop-32' : ''
              }`}
              onClick={() => {
                if (renew) {
                  setPassResetComp(true);
                  setRenew(false);
                  setSuccess(false);
                } else if (passResetComp) {
                  goToHomepage();
                } else {
                  setSuccess(true);
                  setRenew(true);
                  setPassResetComp(false);
                }
              }}
            />
            {!passResetComp && (
              <Button
                type="textGray"
                leftIcon={arrowLeft}
                title={Variable.BACK_TO_LOGIN[ENV_NAME]}
                size="md"
                className="fpc-main-button"
                onClick={() => {
                  goToHomepage();
                  setLoginModalShow(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {success && (
        <ModalInfo
          isShow={success}
          isHide={() => {
            setSuccess(false);
          }}
          type="verification"
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  ENV_NAME: state.auth.selectedEnvironment || 'bhs',
});

const mapStateToDispatch = (dispatch) => {
  return {
    setLoginModalShow: (payload = true) => {
      if (payload) {
        dispatch({
          type: ActionTypes.OPEN_LOGIN_MODAL,
        });
      } else {
        dispatch({
          type: ActionTypes.CLOSE_LOGIN_MODAL,
        });
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch,
)(withRouter(ForgetPassComponent));
