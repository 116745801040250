import moment from 'moment';
import ListScheduleTime from './ListScheduleTime';

type ListScheduleProps = {
  dataSchedule: any;
};

export default function ListSchedule(props: ListScheduleProps) {
  return (
    <div className="position-relative tw border rounded-t-lg w-100 overflow-auto">
      {/* Head */}
      <div className="position-relative tw rounded-t-lg p-std tw bg-app-primary-50 text-app-primary">
        {moment(props.dataSchedule.date).format('dddd, DD MMMM YYYY')}
      </div>

      {/* Body */}
      <div>
        {props.dataSchedule.data.map((item: any, index: number) => (
          <ListScheduleTime dataTime={item} key={index} />
        ))}
      </div>
    </div>
  );
}
