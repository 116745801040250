import { Copy, ShareFat, WhatsappLogo } from "@phosphor-icons/react";
import TooltipCustom from "shared/component/atoms/Tooltips/Tooltip";
import useToast from "shared/hooks/useToast";
import useTranslate from "shared/hooks/useTranslate";

export default function NameContact({ candidateData, handlerModalForward }) {
    const t = useTranslate();
    const toast = useToast();
    return (
        <div>
            <p id="candidate-detail-name" className="candidate-name">
                {[candidateData?.created_by?.personal_info?.first_name, candidateData?.created_by?.personal_info?.last_name].filter(Boolean).join(" ")}
            </p>
            <div className="d-flex align-items-center mar-top-10 gap-3">
                <a href={"mailto:" + candidateData?.created_by?.personal_info?.email} className="candidate-gmail">
                    {candidateData?.created_by?.personal_info?.email}
                </a>
                
                <TooltipCustom description={t("COPY")} placement="bottom">
                    <Copy
                        className="tw cursor-pointer"
                        color="#667085"
                        onClick={() => {
                            navigator.clipboard.writeText(candidateData?.created_by?.personal_info?.email);
                            setTimeout(() => {
                                document.getElementById("candidate-detail-name").click()
                                toast.success(t("EMAIL_COPIED"))
                            }, 100);
                        }}
                    />
                </TooltipCustom>
                <div className="vertical-line" />
                <TooltipCustom
                    description="Whatsapp"
                >
                    <WhatsappLogo
                        className="tw cursor-pointer"
                        color="#32D583"
                        onClick={() => window.open("https://wa.me/" + candidateData?.created_by?.personal_info?.mobile_phone_number)}
                    />
                </TooltipCustom>
                <TooltipCustom
                    description={t("FORWARD")}
                >
                    <ShareFat
                        className="tw cursor-pointer"
                        color="#667085"
                        onClick={handlerModalForward}
                    />
                </TooltipCustom>
            </div>
        </div>
    );
}
