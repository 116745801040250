import { FC } from 'react'

interface IconProps {
    height: number;
    width: number;
    color: string;
    weight: string;
    className: string | undefined
    onClick?: (e: any) => void,
}

const QuestionsIcon: FC<IconProps> = ({
    height = 15,
    width = 15,
    color = "#eaeaea",
    weight = "bold",
    className = "",
    onClick,
}) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            onClick={onClick}
            viewBox="0 0 15 14"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            {...(weight && { weight })}
        >
            <path
                d="M8.09154 10.65C8.09154 10.8181 8.04168 10.9825 7.94829 11.1222C7.85489 11.262 7.72213 11.371 7.56682 11.4353C7.4115 11.4996 7.24059 11.5165 7.07571 11.4837C6.91083 11.4509 6.75937 11.3699 6.6405 11.251C6.52162 11.1322 6.44067 10.9807 6.40787 10.8158C6.37507 10.6509 6.3919 10.48 6.45624 10.3247C6.52057 10.1694 6.62952 10.0366 6.7693 9.94325C6.90908 9.84985 7.07342 9.8 7.24154 9.8C7.46697 9.8 7.68317 9.88955 7.84258 10.049C8.00198 10.2084 8.09154 10.4246 8.09154 10.65ZM7.24154 3C5.67895 3 4.4082 4.14396 4.4082 5.55V5.83333C4.4082 5.98362 4.46791 6.12776 4.57418 6.23403C4.68045 6.3403 4.82458 6.4 4.97487 6.4C5.12516 6.4 5.26929 6.3403 5.37556 6.23403C5.48183 6.12776 5.54154 5.98362 5.54154 5.83333V5.55C5.54154 4.77083 6.30441 4.13333 7.24154 4.13333C8.17866 4.13333 8.94154 4.77083 8.94154 5.55C8.94154 6.32917 8.17866 6.96667 7.24154 6.96667C7.09125 6.96667 6.94711 7.02637 6.84084 7.13264C6.73457 7.23891 6.67487 7.38304 6.67487 7.53333V8.1C6.67487 8.25029 6.73457 8.39442 6.84084 8.50069C6.94711 8.60696 7.09125 8.66667 7.24154 8.66667C7.39183 8.66667 7.53596 8.60696 7.64223 8.50069C7.7485 8.39442 7.8082 8.25029 7.8082 8.1V8.049C9.1002 7.81171 10.0749 6.78108 10.0749 5.55C10.0749 4.14396 8.80412 3 7.24154 3Z"
                fill={color}
            />
        </svg>

    )
}

export default QuestionsIcon