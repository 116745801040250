import { Modal } from "react-bootstrap";
import { Button, Input } from "../../../shared/component";
import {
	CLOSE_LABEL,
	CUSTOM,
	DAYS_2,
	EXAMPLE_ABBV,
	INPUT_2,
	SAVE_LABEL,
} from "../../../shared/utils/variables";
import { ENV } from "../../../types";

import "./CustomModal.scss";
import { ChangeEvent, useEffect, useState } from "react";
import { isValueAllowed } from "shared/utils/numberUtils";

interface Props {
	ENV_NAME: ENV;
	show: boolean;
	value: string;
	isError?: boolean;
	onHide: () => void;
	onSave: (item: string) => void;
}

const CustomDayModal = ({ ENV_NAME, show, value, onHide, onSave }: Props) => {
	const [customDays, setCustomDays] = useState(value);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (show && value) setCustomDays(value);
	}, [show, value]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setCustomDays((prev) => (isValueAllowed(value, "numberWithoutDecimal") ? value : prev));
		setError(false);
	};

	const handleSave = () => {
		if (!customDays || +customDays < 1 || +customDays > 99) return setError(true);

		onSave(customDays);
		onHide();
		setCustomDays("");
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			backdrop="static"
			contentClassName="border-0 p-0 tw"
			centered
		>
			<Modal.Header className="tw border-b-2">
				<h3>{`${CUSTOM[ENV_NAME]} ${DAYS_2[ENV_NAME]}`}</h3>
			</Modal.Header>
			<Modal.Body>
				<div className="content-wrapper">
					<p>{`${INPUT_2[ENV_NAME]} ${DAYS_2[ENV_NAME]}`}</p>
					<div className="input-row">
						<Input
							value={customDays}
							placeholder={EXAMPLE_ABBV[ENV_NAME] + " 5"}
							name="custom_days"
							onChange={handleChange}
							inputType="label"
							className="day-input"
							isError={error}
						/>
						<span>{DAYS_2[ENV_NAME]}</span>
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer className="d-flex gap-3 tw border-t-2 rounded-b-xl box-border bg-gray-50">
				<Button
					onClick={() => {
						onHide();
						setCustomDays("");
						setError(false);
					}}
					type="textGray"
					btnClassName="tw border-2 w-100"
					className="col"
				>
					{CLOSE_LABEL[ENV_NAME]}
				</Button>
				<Button
					onClick={handleSave}
					type="primary"
					btnClassName="border w-100"
					className="col"
				>
					{SAVE_LABEL[ENV_NAME]}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default CustomDayModal;
