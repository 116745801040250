import { Form } from 'react-bootstrap';
import { FormInput, Select, Textarea } from '../../../../shared/component';
import { RenderIf } from '../../../../shared/utils/common';
import { FormTimeSlot } from '../../../../store/types/interview';
import FormColor from './FormColor';

type FormScheduleProps = {
  INTERVIEW: any;
  form: FormTimeSlot;
  setform: (value: any) => void;
};

export default function FormSchedule(props: FormScheduleProps) {
  const handleChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    props.setform((prev: FormTimeSlot) => ({
      ...prev,
      // when meeting_type is onsite set value for phone and video_call_link is null
      //when meeting_type is phone  set value for location and video_call_link is null
      //when meeting_type is video set value for location and phone is null
      ...(name === 'meeting_type' && value === 'onsite'
        ? {
            phone_number: null,
            video_call_link: null,
          }
        : name === 'meeting_type' && value === 'phone'
        ? {
            location: null,
            video_call_link: null,
          }
        : name === 'meeting_type' && value === 'video'
        ? {
            location: null,
            phone_number: null,
          }
        : {}),
      [name]: value,
    }));
  };

  const handleChangeColor = (color: string) => {
    props.setform((prev: FormTimeSlot) => ({
      ...prev,
      schedule_color: color,
    }));
  };

  return (
    <section className="position-relative d-flex flex-column gap-4">
      <FormInput
        required
        titleLabel={'Schedule Name'}
        name={'schedule_name'}
        value={props.form.schedule_name}
        onChange={handleChangeValue}
      />
      <FormInput
        required
        titleLabel={'Interview PIC'}
        name={'interview_pic'}
        value={props.form.interview_pic}
        onChange={handleChangeValue}
      />
      <Select
        name="meeting_type"
        label="Type Interview Meeting"
        noDefault
        required
        onChange={handleChangeValue}
        value={props.form.meeting_type}
        items={typeInterview}
        placeholder="Type meeting"
        searchable={false}
      />
      <RenderIf condition={props.form.meeting_type === 'phone'}>
        <FormInput
          required
          titleLabel={'Contact Person'}
          name={'phone_number'}
          value={props.form.phone_number}
          onChange={handleChangeValue}
        />
      </RenderIf>

      <RenderIf condition={props.form.meeting_type === 'video'}>
        <FormInput
          titleLabel={'Video Call Link'}
          name={'video_call_link'}
          value={props.form.video_call_link}
          onChange={handleChangeValue}
        />
      </RenderIf>

      <RenderIf condition={props.form.meeting_type === 'onsite'}>
        <Textarea
          children={null}
          classNameLabel=""
          cols={10}
          isError={false}
          required={false}
          rows={3}
          titleLabel="Address Location"
          characterLimit={200}
          name="location"
          onChange={handleChangeValue}
          value={props.form.location}
          placeholder={`Please input complete address, sample:Street, Building number, District, City`}
        />
      </RenderIf>

      {/* When slot is group */}
      <RenderIf condition={props.INTERVIEW?.typeInterview === 'group'}>
        <FormInput
          required
          type={'number'}
          min={0}
          titleLabel={'Max Capacity'}
          name={'max_capacity_in_slots'}
          value={Number(props.form.max_capacity_in_slots)}
          onChange={handleChangeValue}
        />
        <Form.Check type="checkbox" label="Display Capacity" />
      </RenderIf>

      <Textarea
        children={null}
        classNameLabel=""
        cols={10}
        isError={false}
        required={false}
        rows={5}
        value={props.form.message}
        name={'message'}
        onChange={handleChangeValue}
        titleLabel={'Input message for candidate'}
        characterLimit={5000}
        placeholder={`Dear Candidate, 

Thank you for your interest in joining Visi Prima. We appreciate the time you've taken to apply for this position of Android Developer.`}
      />

      <FormColor
        onClick={handleChangeColor}
        value={props.form.schedule_color!}
      />
    </section>
  );
}

const typeInterview = [
  {
    id: 'phone',
    name: 'Phone',
  },
  {
    id: 'video',
    name: 'Video Call',
  },
  {
    id: 'onsite',
    name: 'On Site',
  },
];
