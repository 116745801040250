import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { classHelper } from '../../utils/stringUtils';
import * as Variable from '../../utils/variables';

import privateImg from '../../../assets/svg/private_icon.svg';
import publicImg from '../../../assets/svg/public_icon.svg';
import tokpedIcon from '../../../assets/svg/tokped.svg';
import Button from '../ButtonComponent/Button';
import Icon from '../IconComponent/Icon';
import Image from '../UI/Image';
import './CardComponent.scss';
import { RenderIf } from '../../utils/common';

// type = [job, public, private]

const Card = (props) => {
  const {
    imgCompany,
    title,
    type,
    isPrivate,
    company,
    region,
    salary,
    postedAt,
    onClick,
    ENV_NAME,
    description,
    children,
    ...rest
  } = props;
  return (
    <>
      <RenderIf condition={type === 'job'}>
        <div className={classHelper('J-cardComp', rest.className)}>
          <div className="J-cardComp-icon">
            <Image src={imgCompany || tokpedIcon} />
          </div>
          <div className="J-cardComp-title">
            {company || '(Live Host) Social Media Specialist' || title}
            <p>{'Tokopedia'}</p>
          </div>
          <div className="J-cardComp-region">
            {region || 'Malang, Jawa Timur'}
            <br />
            {salary || 'IDR 3,5 jt - 6 jt per bulan'}
          </div>
          <div className="J-cardComp-postedAt">
            {postedAt || 'Posted 1 day ago'}
          </div>
          <div className="J-cardComp-btn">
            <Button
              type="primary"
              size="md"
              title="Apply"
              className="w-100"
              btnClassName="widthCard"
            />
            <Icon
              iconType="bookmark"
              borderType="outline"
              className="px-8rem cursorPointer"
            />
          </div>
        </div>
      </RenderIf>

      <RenderIf condition={type === 'private' || type === 'public'}>
        <div
          className={classHelper(
            `J-pubPriv cursorPointer ${rest.active ? 'active' : ''}`,
            rest.className,
          )}
          onClick={onClick}>
          <div className="ppLeft">
            <Image
              src={type === 'public' ? publicImg : privateImg}
              width="32rem"
              height="32rem"
            />
          </div>
          <div className="ppRight">
            <h1>
              {type === 'public'
                ? Variable.PUBLIC[ENV_NAME]
                : Variable.PRIVATE[ENV_NAME]}
            </h1>
            <h4>
              {type === 'public'
                ? Variable.PUBLIC_DESCRIPTION[ENV_NAME]
                : Variable.PRIVATE_DESCRIPTION[ENV_NAME]}
            </h4>
          </div>
        </div>
      </RenderIf>

      <RenderIf condition={type === 'custom'}>
        <div
          className={[
            'J-pubPriv cursorPointer',
            rest.active ? 'active' : '',
            rest.className,
          ].join(' ')}
          onClick={onClick}>
          {children}
        </div>
      </RenderIf>

      <RenderIf condition={!type}>
        <div
          className={[
            'J-pubPriv cursorPointer',
            rest.active ? 'active' : '',
            rest.className,
          ].join(' ')}
          onClick={onClick}>
          <div className="ppRight">
            <h1>{title}</h1>
            <h4>{description}</h4>
          </div>
        </div>
      </RenderIf>
    </>
  );
};

const mapStateToProps = (state) => ({
  ENV_NAME: state.auth.selectedEnvironment || 'bhs',
});

const mapStateToDispatch = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(Card));
