import Label from '../Label/Label';
import './Textarea.scss';

export default function Textarea({
  rows,
  cols,
  characterLimit = 200,
  onChange,
  value,
  placeholder,
  isError,
  name,
  titleLabel,
  classNameLabel,
  required,
  children,
}) {
  return (
    <div className="m-0 p-0">
      {/* Show label if withLabel is true */}
      {titleLabel?.length > 0 && (
        <Label
          name={name}
          className={classNameLabel}
          label={titleLabel}
          isRequired={required}>
          {children}
        </Label>
      )}
      <textarea
        name={name}
        rows={rows ?? '4'}
        cols={cols ?? '50'}
        maxLength={characterLimit}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className={`J-inputComp-input txarea ${isError ? 'borError' : ''}`}
      />

      <p className={`J-inputComp-below float-end ${isError ? 'error' : ''}`}>
        {value?.length} / {characterLimit}
      </p>
    </div>
  );
}
