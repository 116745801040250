import { FC } from 'react';
import Image from 'shared/component/UI/Image';
import useTranslate from 'shared/hooks/useTranslate';

type CardEmptyProps = {
	imageSource: string;
};

const CardEmpty: FC<CardEmptyProps> = ({ imageSource }) => {
	const t = useTranslate();
	return (
		<div className="content-null">
			<Image className="" src={imageSource} alt="titleEmpty" />
			<div className="description">
				<h4 className="text-center">{t("NO_UPCOMING_SCHEDULE")}</h4>
				<p className="text-center">
					{t("PLEASE_CREATE_SCHEDULE_DESC")}
				</p>
			</div>
		</div>
	);
}

export default CardEmpty;
