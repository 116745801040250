import React from "react";

type CheckboxProps = {
	checked: boolean;
	disabled: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	id?: string;
};

const Checkbox = ({ checked, disabled, onChange, id }: CheckboxProps) => {
	return (
		<label className="container-checkbox">
			<input
				type="checkbox"
				id={id}
				checked={checked}
				onChange={onChange}
				disabled={disabled}
			/>
			<span className="checkmark"></span>
		</label>
	);
};

export default Checkbox;
