import { ChangeEvent, useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { BsExclamationCircle } from "react-icons/bs";
import { PiPencilSimpleFill, PiPlus, PiTrash, PiWarningBold } from "react-icons/pi";

import { Button, Input, Select } from "../../shared/component";
import { capitalize } from "../../shared/utils/stringUtils";
import { RenderIf } from "../../shared/utils/common";

import { Question, QuestionError } from "./PreScreening";
import Banner from "./Reusable/Banner";

import { ENV } from "../../types";
import useTranslate from "shared/hooks/useTranslate";

interface Props {
	ENV_NAME: ENV;
	question: Question;
	isError?: boolean;
	isDisabled?: boolean;
	isEditingQuestion?: boolean;
	onRemove?: () => void;
	onChange: (e: ChangeEvent<HTMLFormElement>, id: number, type?: string, name?: string) => void;
	onUpdateChoices: (
		id: number,
		choices: string[],
		type?: ("mcq_choices" | "mandatory_choices") | null,
	) => void;
	onEditQuestion: (event: React.MouseEvent) => void;
	onShowPreview: () => void;
	onUpdateErrors: () => void;
}

interface Option {
	id: number;
	name: string;
}

const QUESTION_TYPE = [
	"mcq", // checbox
	"fill_in_the_blanks", // radio
	"short_answer", // textarea
	"true_false", // radio
	"numeric", // numeric
	"progressive", //radio
	"single_select", //radio
];

const PreScreenQuestion = ({
	ENV_NAME,
	question,
	isError,
	isDisabled,
	isEditingQuestion,
	onRemove,
	onChange,
	onUpdateChoices,
	onEditQuestion,
	onShowPreview,
	onUpdateErrors,
}: Props) => {
	const t = useTranslate();
	const QUESTION_TYPE_HEADINGS = {
		mcq: t("MULTI_SELECTION"), // checbox
		fill_in_the_blanks: t("FILL_IN_THE_BLANK"), // radio
		short_answer: t("FREE_INPUT"), // textarea
		true_false: t("TRUE_FALSE"), // radio
		numeric: t("NUMERICAL"), // numeric
		progressive: t("PROGRESSIVE_CHOICE"), //radio
		single_select: t("SINGLE_SELECTION"), //radio
	};
	const YES_NO_OPTIONS = [
		{ id: t("YES"), value: true, name: t("YES") },
		{ id: t("NO"), value: false, name: t("NO") },
	];
	const MAX_MIN_OPTIONS = [
		{ id: "max", name: t("MAXIMUM") },
		{ id: "min", name: t("MINIMUM") },
	];
	const QUESTIONS_CATEGORY = [
		{ id: QUESTION_TYPE[3], name: t("YES_NO") },
		{ id: QUESTION_TYPE[4], name: t("NUMERICAL") },
		{ id: QUESTION_TYPE[5], name: t("PROGRESSIVE_CHOICE") },
		{ id: QUESTION_TYPE[6], name: t("SINGLE_SELECTION") },
		{ id: QUESTION_TYPE[0], name: t("MULTI_SELECTION") },
		{ id: QUESTION_TYPE[2], name: t("FREE_INPUT") },
	];

	const [options, setOptions] = useState<Option[]>([{ id: 1, name: "" }]);

	useEffect(() => {
		if (isEditingQuestion) {
			if (
				[QUESTION_TYPE[0], QUESTION_TYPE[5], QUESTION_TYPE[6]].includes(
					question.question_type,
				)
			) {
				const optionsArr: Option[] = question?.mcq_choices.map((opt, idx) => ({
					id: idx + 1,
					name: opt,
				}));
				setOptions(optionsArr);
			}

			if (question?.question_type === QUESTION_TYPE[3]) {
			}
		}
	}, [isEditingQuestion]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>, id: number, type: string) => {
		const { value } = e.target;
		const optionsClone = [...options];

		switch (type) {
			case "radio":
				const option = optionsClone.find((opt) => opt?.id === id);
				if (option) {
					option.name = value;
					setOptions(optionsClone);
				}
				onUpdateChoices(
					question?.id,
					options.map((opt) => opt.name),
					"mcq_choices",
				);
				break;
			case "checkbox":
				break;
			default:
				return;
		}
	};

	const handleMandatoryChoices = (id: number, type?: string) => {
		const option = options.find((opt) => opt?.id === id);
		switch (type) {
			case "single_select":
				if (option) onUpdateChoices(question?.id, [option.name], "mandatory_choices");
				break;
			default:
				if (option)
					onUpdateChoices(
						question?.id,
						[
							...(question?.mandatory_choices.includes(option?.name)
								? question.mandatory_choices.filter((opt) => opt !== option?.name)
								: [...question?.mandatory_choices, option?.name]),
						],
						"mandatory_choices",
					);
				break;
		}

		onUpdateErrors();
	};

	const handleAddOption = () => {
		setOptions((prev) => [...prev, { id: Date.now(), name: "" }]);
	};

	const handleRemoveOption = (id: number) => {
		const filteredOptions = options.filter((opt) => opt.id !== id);
		onUpdateChoices(
			question?.id,
			filteredOptions.map((opt) => opt.name),
			"mcq_choices",
		);
		setOptions(filteredOptions);
	};

	return (
		<div>
			<div className={`question ${isError ? "error" : ""}`}>
				<div className="question-header">
					<div>
						<h3>
							{question?.is_custom
								? t("CUSTOM_QUESTION")
								: capitalize(question?.question_category)}
						</h3>
						{!question?.is_custom && (
							<p>{QUESTION_TYPE_HEADINGS[question?.question_type]}</p>
						)}
					</div>
					<div className="question-header-icons">
						{!isDisabled && !question?.is_custom && !question?.is_preloaded && (
							<PiPencilSimpleFill
								size={20}
								color="#667085"
								className="cursorPointer"
								onClick={(e: React.MouseEvent) => onEditQuestion(e)}
							/>
						)}
						{!isDisabled && (
							<IoCloseOutline
								size={24}
								color="#667085"
								className="cursorPointer"
								onClick={onRemove}
							/>
						)}
					</div>
				</div>

				<div className="question-content">
					<div className="text mb-2_p_4">
						<p className="mb-0">
							{question?.is_custom
								? t("WRITE_CUSTOM_QUESTION")
								: question?.question_text}
						</p>
						<Input
							choiceLabel={t("MUST_HAVE")}
							inputType="checkbox"
							id={`is_mandatory_${question?.id}`}
							name="is_mandatory"
							value={question?.is_mandatory || ""}
							onChange={(e: ChangeEvent<HTMLFormElement>) =>
								onChange(e, question?.id, "checkbox")
							}
							checked={question?.is_mandatory}
							disabled={
								isDisabled ||
								(!question?.is_custom &&
									!question?.is_preloaded &&
									!isEditingQuestion)
							}
						/>
					</div>
					<RenderIf condition={question?.is_custom || isEditingQuestion}>
						<div className="custom-question-content">
							<p className="mb-1_p_6 fs-14">{t("SELECT_QUESTION_TYPE")}</p>
							<div className="form-row">
								<Select
									name="question_type"
									label={t("TYPE_QUESTION")}
									className="m-0 job-type-input"
									onChange={(e: ChangeEvent<HTMLFormElement>) =>
										onChange(e, question.id)
									}
									value={question?.question_type}
									items={QUESTIONS_CATEGORY}
									searchable={false}
									noDefault
									required
								/>

								{/* True/False */}
								{question?.question_type === QUESTION_TYPE[3] && (
									<Select
										name="boolean_accepted_answer"
										label={t("IDEAL_ANSWER")}
										className="m-0 job-type-input"
										onChange={(e: ChangeEvent<HTMLFormElement>) =>
											onChange(e, question.id, "yes_no")
										}
										value={
											typeof JSON.parse(
												(question?.boolean_accepted_answer ?? 0).toString(),
											) === "boolean"
												? question?.boolean_accepted_answer === true
													? t("YES")
													: t("NO")
												: ""
										}
										items={YES_NO_OPTIONS}
										searchable={false}
										noDefault
										required
									/>
								)}
								{/* Numerical */}
								{question?.question_type === QUESTION_TYPE[4] && (
									<>
										<Select
											name="limit_type"
											label={t("LIMIT_TYPE")}
											className="m-0 job-type-input"
											onChange={(e: ChangeEvent<HTMLFormElement>) =>
												onChange(e, question.id)
											}
											value={question?.limit_type}
											items={MAX_MIN_OPTIONS}
											searchable={false}
											noDefault
											required
										/>
										<Input
											label={t("IDEAL_ANSWER")}
											placeholder={""}
											value={question?.numeric_accepted_answer || ""}
											name="numeric_accepted_answer"
											onChange={(e: ChangeEvent<HTMLFormElement>) =>
												onChange(e, question?.id, "numberWithoutDecimal")
											}
											inputType="label"
											required
										/>
									</>
								)}
							</div>

							{question?.question_type === QUESTION_TYPE[3] ? (
								<Banner className="field-info" text={t("YES_NO_INFO")} />
							) : question?.question_type === QUESTION_TYPE[4] ? (
								<Banner className="field-info" text={t("NUMERICAL_INFO")} />
							) : question?.question_type === QUESTION_TYPE[5] ? (
								<Banner className="field-info" text={t("PROGRESSIVE_INFO")} />
							) : question?.question_type === QUESTION_TYPE[6] ? (
								<Banner className="field-info" text={t("SINGLE_SELECT_INFO")} />
							) : question?.question_type === QUESTION_TYPE[0] ? (
								<Banner className="field-info" text={t("MULTI_SELECT_INFO")} />
							) : (
								question?.question_type === QUESTION_TYPE[2] && (
									<Banner className="field-info" text={t("FREE_INPUT_INFO")} />
								)
							)}

							<RenderIf condition={question?.question_type}>
								<Input
									placeholder={
										question?.question_type === QUESTION_TYPE[3] ||
										question?.question_type === QUESTION_TYPE[5]
											? t("SAMPLE_QUESTION_YES_NO")
											: question?.question_type === QUESTION_TYPE[4]
											? t("SAMPLE_QUESTION_NUMERICAL")
											: question?.question_type === QUESTION_TYPE[6]
											? t("SAMPLE_QUESTION_SINGLE_SELECT")
											: question?.question_type === QUESTION_TYPE[0]
											? t("SAMPLE_QUESTION_MULTI_SELECT")
											: question?.question_type === QUESTION_TYPE[2]
											? t("SAMPLE_QUESTION_FREE_INPUT")
											: ""
									}
									value={question?.question_text || ""}
									name="question_text"
									onChange={(e: ChangeEvent<HTMLFormElement>) =>
										onChange(e, question?.id, "textarea", "question_text")
									}
									inputType="textarea"
									rows={2}
									className="w-100 custom-question-input"
									characterLimit={
										question?.question_type === QUESTION_TYPE[2] && 2000
									}
								/>
							</RenderIf>

							<RenderIf
								condition={[
									QUESTION_TYPE[0],
									QUESTION_TYPE[5],
									QUESTION_TYPE[6],
								].includes(question?.question_type)}
							>
								<div className="custom-options mt-2_p_4 mb-1">
									<h4>{t("SELECTION_OPTIONS")}</h4>

									<div className="custom-options-container">
										{options.map((option) => (
											<div className="option" key={option?.id}>
												<div className="inp-container">
													{/* Multi Select */}
													{question?.question_type ===
														QUESTION_TYPE[0] && (
														<Input
															inputType="checkbox"
															onChange={() =>
																handleMandatoryChoices(option?.id)
															}
															checked={question?.mandatory_choices.includes(
																option.name,
															)}
															className="custom-check"
														/>
													)}
													{/* Single Select */}
													{[QUESTION_TYPE[5], QUESTION_TYPE[6]].includes(
														question?.question_type,
													) && (
														<Input
															inputType="radio"
															onChange={() =>
																handleMandatoryChoices(
																	option?.id,
																	"single_select",
																)
															}
															checked={question?.mandatory_choices.includes(
																option.name,
															)}
															className="custom-check"
														/>
													)}

													<Input
														placeholder={""}
														value={option?.name}
														name="question_text"
														onChange={(
															e: ChangeEvent<HTMLInputElement>,
														) => handleChange(e, option?.id, "radio")}
														inputType="label"
														className="w-100 custom-option-input"
													/>
												</div>

												<div className="icon">
													<PiTrash
														size={22}
														color="#667085"
														className="icon-trash"
														onClick={() =>
															handleRemoveOption(option?.id)
														}
													/>
												</div>
											</div>
										))}
									</div>

									<Button
										type="textGray"
										title={t("ADD_OPTION")}
										onClick={handleAddOption}
										customIcon={
											<PiPlus size={18} color="#667085" className="mr-1" />
										}
										className="add-option-btn"
										disabled={options.some((option) => !option?.name)}
									/>
								</div>
							</RenderIf>
						</div>

						<RenderIf condition={question?.question_type === QUESTION_TYPE[2]}>
							<Banner
								className="field-warning"
								icon={<PiWarningBold size={18} />}
								text={t("FREE_INPUT_WARN")}
							/>
						</RenderIf>

						<Button
							type="outline"
							size="sm"
							title={t("SHOW_PREVIEW")}
							onClick={() => {
								if (typeof onShowPreview === "function") onShowPreview();
								else return;
							}}
							className="preview-btn mt-2_p_4"
							disabled={!question?.question_text}
						/>
					</RenderIf>

					<RenderIf condition={!question?.is_custom && !isEditingQuestion}>
						<div className="answer">
							{/* mcq */}
							{question?.question_type === QUESTION_TYPE[0] && (
								<div className="mt-3">
									{question?.mcq_choices?.map((opt: string, opt_idx: number) => (
										<Input
											key={opt_idx + 1}
											choiceLabel={opt}
											inputType="checkbox"
											id={`${question?.id}_${opt_idx + 1}`}
											name={`answer_${question?.id}`}
											value={opt}
											onChange={(e: ChangeEvent<HTMLFormElement>) =>
												onChange(e, question?.id, "mcq")
											}
											checked={question?.mandatory_choices?.includes(opt)}
											disabled={
												isDisabled ||
												(!question?.is_custom &&
													!question?.is_preloaded &&
													!isEditingQuestion)
											}
										/>
									))}
								</div>
							)}

							{/* Yes/No */}
							{question?.question_type === QUESTION_TYPE[3] && (
								<div className="mt-3">
									{YES_NO_OPTIONS?.map((opt, opt_idx) => (
										<Input
											key={opt_idx + 1}
											choiceLabel={opt?.id}
											inputType="radio"
											id={`${question?.id}_${opt_idx + 1}`}
											name={`answer_${question?.id}`}
											value={opt?.value}
											onChange={(e: ChangeEvent<HTMLFormElement>) =>
												onChange(e, question?.id, "yes_no")
											}
											checked={
												question?.boolean_accepted_answer === opt.value
											}
											disabled={
												isDisabled ||
												(!question?.is_custom &&
													!question?.is_preloaded &&
													!isEditingQuestion)
											}
										/>
									))}
								</div>
							)}

							{/* numeric */}
							{question?.question_type === QUESTION_TYPE[4] && (
								<div className="mt-3">
									<Input
										placeholder={""}
										value={question?.numeric_accepted_answer || ""}
										name={`numeric_accepted_answer`}
										onChange={(e: ChangeEvent<HTMLFormElement>) =>
											onChange(e, question?.id, "numberWithDecimal")
										}
										inputType="label"
										className="w-100"
										disabled={isDisabled}
									/>
								</div>
							)}

							{/* single_select */}
							{[QUESTION_TYPE[1], QUESTION_TYPE[5], QUESTION_TYPE[6]].includes(
								question?.question_type || "",
							) && (
								<div className="mt-3">
									{question?.mcq_choices.map((option, opt_idx) => (
										<Input
											key={opt_idx + 1}
											choiceLabel={option}
											inputType="radio"
											id={`${question?.id}_${opt_idx + 1}`}
											name={`answer_${question?.id}`}
											value={option}
											onChange={(e: ChangeEvent<HTMLFormElement>) => {
												onChange(e, question?.id, "single_select");
											}}
											checked={question?.mandatory_choices?.includes(option)}
											disabled={
												isDisabled ||
												(!question?.is_custom &&
													!question?.is_preloaded &&
													!isEditingQuestion)
											}
										/>
									))}
								</div>
							)}

							{/* short answer */}
							{question?.question_type === QUESTION_TYPE[2] && (
								<div className="mt-3">
									<Input
										placeholder={""}
										value={question?.numeric_accepted_answer || ""}
										name={`short_answer`}
										onChange={() => {}}
										inputType="textarea"
										className="w-100"
										disabled={isDisabled}
									/>
								</div>
							)}
						</div>
					</RenderIf>
				</div>
			</div>
			{isError && (
				<Banner
					className="field-error mt-3"
					icon={<BsExclamationCircle size={14} />}
					text={t("BLANK_FIELD_ERR")}
				/>
			)}
		</div>
	);
};

export default PreScreenQuestion;
