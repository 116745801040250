/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from "react";
import { PiArrowLeft, PiPencilSimple } from "react-icons/pi";
import { useHistory } from "react-router-dom/";
import queryString from "query-string";

import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import { Button, Toggle } from "../../shared/component";

import Form from "./Reusable/Form";
import CustomDayModal from "./Modal/CustomDayModal";

import { ENV, Nullable } from "../../types";
import useTranslate from "shared/hooks/useTranslate";
import useToast from "shared/hooks/useToast";

interface Props {
	ENV_NAME: ENV;
	jobAdId: Nullable<number>;
	onNext: () => void;
	onPrev: () => void;
}

interface FormFields {
	employer_assist: boolean;
	auto_decline_days?: string;
	custom_days?: string;
}

interface FormError {
	employer_assist: boolean;
	auto_decline_days: boolean;
	custom_days: boolean;
}

const EmpAssist = ({ ENV_NAME, jobAdId, onNext, onPrev }: Props) => {
	const t = useTranslate();
	const toast = useToast();
	const DECLINE_DAYS = ["3", "7", "10", "14", t("CUSTOM")];
	const history = useHistory();
	const jobIdFromUrl = (queryString.parse(history.location.search)?.job_ad ||
		null) as Nullable<string>;

	const [form, setForm] = useState<FormFields>({
		employer_assist: false,
		auto_decline_days: "",
		custom_days: "",
	});
	// const [errors, setErrors] = useState({} as FormError);
	const [isEdited, setIsEdited] = useState(false);
	const [showCustomModal, setShowCustomModal] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	useEffect(() => {
		if (jobIdFromUrl) {
			getJob(jobIdFromUrl);
			setIsEditing(true);
		}
	}, [jobIdFromUrl]);

	const getJob = async (jobId: string) => {
		const res = await makeRequest(generateRequestOptions("getJobAd", { urlParams: jobId }));

		if (res?.code === 200) {
			const formClone = {
				employer_assist: res?.data?.employer_assist,
			};

			if (res.data?.auto_decline_days) {
				if (DECLINE_DAYS.includes(res.data?.auto_decline_days?.toString())) {
					formClone["auto_decline_days"] = res.data?.auto_decline_days?.toString();
					formClone["custom_days"] = "";
				} else {
					formClone["auto_decline_days"] = t("CUSTOM");
					formClone["custom_days"] = res.data?.auto_decline_days?.toString();
				}
			} else {
				formClone["auto_decline_days"] = "";
				formClone["custom_days"] = "";
			}

			setForm(formClone);
		} else toast.error(res.message);
	};

	const onChange = (e: ChangeEvent<HTMLInputElement>, type: Nullable<string> = null) => {
		const { name, value } = e.target;
		switch (type) {
			case "toggle":
				setForm((prev) => ({
					...prev,
					employer_assist: e.target.checked,
					auto_decline_days: e.target.checked ? "10" : "",
					custom_days: "",
				}));
				break;
			default:
				setForm((prev) => ({
					...prev,
					[name]: value,
				}));
		}

		// setErrors((prev) => ({ ...prev, [name]: false }));
		setIsEdited(true);
	};

	const handleDaySelect = (day: string) => {
		if (day === t("CUSTOM")) {
			setForm((prev) => ({
				...prev,
				auto_decline_days: t("CUSTOM"),
			}));
			setShowCustomModal(true);
			setIsEdited(true);
			return;
		}
		setForm((prev) => ({
			...prev,
			auto_decline_days: day,
			custom_days: "",
		}));

		setIsEdited(true);
	};

	const handleSave = async () => {
		if (isEditing && !isEdited) return onNext();

		const { employer_assist, auto_decline_days, custom_days } = form;

		const errors = {} as FormError;
		if (employer_assist && !auto_decline_days) errors["auto_decline_days"] = true;
		// if (auto_decline_days === t("CUSTOM") && !custom_days)
		// 	errors["custom_days"] = true;
		const hasError = Object.values(errors).some((err) => err);
		if (hasError) return toast.error(t("CUSTOM_DAYS_ERR"));

		const data = {
			employer_assist,
			...(auto_decline_days === t("CUSTOM")
				? { auto_decline_days: custom_days }
				: { auto_decline_days }),
		};

		if (!data.employer_assist) data.auto_decline_days = "0";

		const res = await makeRequest({
			...generateRequestOptions("updateJobAd", { urlParams: jobAdId || jobIdFromUrl }),
			data,
		});
		if (res?.code === 200) {
			resetForm();
			onNext();
		} else toast.error(res.message);
	};

	const resetForm = () =>
		setForm({ employer_assist: false, auto_decline_days: "", custom_days: "" });

	return (
		<div className="form-block emp-assist">
			<Form>
				<Toggle
					type="switch"
					leftText={t("EMP_ASSIST")}
					name="employer_assist"
					className="emp-assist-toggle"
					checked={form?.employer_assist}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						onChange(e, "toggle");
					}}
				/>

				<p className="emp-assist-desc">{t("EMP_ASSIST_DSEC")}</p>

				{form?.employer_assist && (
					<div className="emp-decline-days">
						<p className="no-interest-text">{t("NO_INTEREST_TEXT")}</p>
						<div className="days-container">
							{DECLINE_DAYS.map((day, idx) => (
								<div
									key={idx + 1}
									className={`day-card ${
										form?.auto_decline_days === day ? "active" : ""
									}`}
									style={{
										pointerEvents: form?.employer_assist ? "all" : "none",
										cursor: form?.employer_assist ? "pointer" : "not-allowed",
									}}
									onClick={() => handleDaySelect(day)}
								>
									{day === t("CUSTOM") && form?.custom_days && (
										<PiPencilSimple size={20} className="icon-pencil" />
									)}
									<p className="day">
										{day === t("CUSTOM") && form?.custom_days
											? form?.custom_days
											: day}
									</p>
									<p className="text">{t("DAYS_2").toUpperCase()}</p>
								</div>
							))}
						</div>
					</div>
				)}
			</Form>
			<div className="btn-grp">
				<Button
					type="outline"
					title={t("PREVIOUS_LABEL")}
					onClick={onPrev}
					btnClassName="customBtn"
					customIcon={<PiArrowLeft size={18} className="icon icon-prev" />}
				/>
				<Button
					type="primary"
					title={t("SAVE_CONTINUE_LABEL")}
					onClick={handleSave}
					btnClassName="customBtn btn-save"
					// disabled={!isEdited && !isEditing}
				/>
			</div>

			<CustomDayModal
				ENV_NAME={ENV_NAME}
				show={showCustomModal}
				value={form?.custom_days || ""}
				onHide={() => setShowCustomModal(false)}
				onSave={(value: string) => {
					setForm((prev) => ({
						...prev,
						auto_decline_days: t("CUSTOM"),
						custom_days: value,
					}));
				}}
			/>
		</div>
	);
};

export default EmpAssist;
