import FormInput from '../../../../shared/component/molecules/FormInput/FormInput';

export default function InputTime() {
  return (
    <div className="row gap-4">
      <FormInput
        titleLabel="Date"
        type="date"
        className="col"
        name="date"
        // value={item.date}
        required={true}
      />
      <div className="row col">
        <FormInput
          titleLabel={'Start time'}
          name={'start_time'}
          // value={time.start_time}
          required
          className="col"
          type={'time'}
        />
        <p className="pb-3 col">__</p>
        <FormInput
          className="col"
          name={'end_time'}
          // value={time.end_time}
          titleLabel={'End time'}
          required
          type={'time'}
        />
      </div>
    </div>
  );
}
