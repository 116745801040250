import React from "react";
import { Text } from "shared/component/Typography/Typography";
import cx from "classnames";

type CardRepostMethodPropsType = {
	selectedMethod: string;
	method: {
		id: string;
		title: string;
		description: string;
		onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
	};
};

const CardRepostMethod = ({ method, selectedMethod }: CardRepostMethodPropsType) => {
	return (
		<div
			id={method.id}
			className={cx("card-repost-method cursorPointer", {
				selected: selectedMethod === method.id,
			})}
			onClick={method.onClick}
		>
			<Text size="xl" fontWeight="medium">
				{method.title}
			</Text>
			<Text size="sm" className="mt-2">
				{method.description}
			</Text>
		</div>
	);
};

export default CardRepostMethod;
