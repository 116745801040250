import cookies from "react-cookies";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useAuth from "shared/hooks/useAuth";
import { homeUrl } from "shared/utils/apiEndPoints";
import { JOB_AD_LISTING, ROOT } from "shared/utils/routeLink";

const ModuleChecker = ({ children }: React.PropsWithChildren) => {
	const history = useHistory();

	const { isAuthenticated } = useAuth();

	const handleRedirect = () => {
		const moduleSelector = cookies.load("moduleSelector");
		switch (moduleSelector) {
			case "JobSeeker":
				window.open(`https://jobmarket.${homeUrl}`, "_self");
				break;
			case "HRMS":
				window.open(`https://hrms.${homeUrl}`, "_self");
				break;
			default:
				break;
		}
	};

	if (!!isAuthenticated && !cookies.load("moduleSelector")) {
		window.open(`https://jobmarket.${homeUrl}`, "_self");
		return;
	}

	if (
		!!isAuthenticated &&
		!!cookies.load("moduleSelector") &&
		cookies.load("moduleSelector") !== "JobPoster"
	) {
		handleRedirect();
		return;
	}

	if (!!isAuthenticated && history.location.pathname === ROOT) {
		history.push(JOB_AD_LISTING);
	}

	return <>{children}</>;
};

export default ModuleChecker;
