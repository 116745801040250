//configureStore.js
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';

const persistConfig = {
  key: 'ptkj',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
let enhancer;
if (process.env.NODE_ENV === 'development') {
  let composeWithEnhancer =
    typeof window === 'object' && window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
      ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({ trace: true })
      : compose;
  enhancer = composeWithEnhancer(applyMiddleware(sagaMiddleware));
} else enhancer = applyMiddleware(sagaMiddleware);

let store = createStore(persistedReducer, enhancer);

// let store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
let persistor = persistStore(store);

export { store, persistor, sagaMiddleware };
