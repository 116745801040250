import { USER_TYPES } from "constants/constants";
import { useSelector } from "react-redux";
import { RootState } from "store/types/RootStateTypes";

type PermissionListType = {
	manage_candidate: {
		all_jobs: boolean;
		specific_jobs: boolean;
	};
	job_posting: boolean;
	talent_search_utilization: boolean;
	company_profile_management: boolean;
	user_management: boolean;
	billing_management: boolean;
};

type PermissionEnum =
	| "all_jobs"
	| "specific_jobs"
	| "talent_search_utilization"
	| "company_profile_management"
	| "user_management"
	| "billing_management"
	| "job_posting";

const usePermission = () => {
	const userDetails = useSelector((state: RootState) => state.auth.userDetails);

	const permissionList = {};

	if (userDetails && userDetails.staff_user_permissions) {
		userDetails.staff_user_permissions.forEach((permission: PermissionEnum) => {
			if (permission === "all_jobs" || permission === "specific_jobs") {
				permissionList["manage_candidate"] = {};
				permissionList["manage_candidate"][permission] = true;
			}
			permissionList[permission] = true;
		});
	}

	return userDetails && userDetails.user_type !== USER_TYPES.JOB_MARKET_SUPER_ADMIN
		? (permissionList as PermissionListType)
		: {
				manage_candidate: {
					all_jobs: true,
				},
				job_posting: true,
				talent_search_utilization: true,
				company_profile_management: true,
				user_management: true,
				billing_management: true,
		  };
};

export default usePermission;
