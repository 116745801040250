import React from 'react';

type TabNavigatioInterviewnProps = {
  menuActive: string;
  setmenuActive: (value: string) => void;
};

export default function TabNavigatioInterview(
  props: TabNavigatioInterviewnProps,
) {
  const tabNavigation = ['upcoming', 'past'];

  return (
    <ul className="tab-navigation">
      {tabNavigation.map((item) => {
        return (
          <li
            key={item}
            onClick={() => props.setmenuActive(item)}
            className={[
              'tab-navigation__item tw cursor-pointer',
              props.menuActive === item ? 'active' : '',
            ].join(' ')}>
            {item}
          </li>
        );
      })}
    </ul>
  );
}
