export const t = (key, env, text) => {
	if (key == null || typeof key !== "object") {
		key == null && console.error("translation not found", { key, env, text });
		return text || "--";
	}
	return key[env];
};

export const CURRENCY_LIST = [
	{ id: "idr", name: "IDR", locale: "id-ID" },
	{ id: "usd", name: "USD", locale: "en-US" },
	{ id: "sgd", name: "SGD", locale: "en-SG" },
	{ id: "myr", name: "MYR", locale: "en-MY" },
	{ id: "inr", name: "INR", locale: "en-IN" },
	{ id: "php", name: "PHP", locale: "en-PH" },
	{ id: "aud", name: "AUD", locale: "en-AU" },
	{ id: "thb", name: "THB", locale: "th-TH" },
	{ id: "hkd", name: "HKD", locale: "zh-HK" },
	{ id: "eur", name: "EUR", locale: "de-DE" },
	{ id: "cny", name: "CNY", locale: "zh-CN" },
	{ id: "jpy", name: "JPY", locale: "ja-JP" },
	{ id: "gbp", name: "GBP", locale: "en-GB" },
	{ id: "krw", name: "KRW", locale: "ko-KR" },
	{ id: "vnd", name: "VND", locale: "vi-VN" },
	{ id: "bdt", name: "BDT", locale: "bn-BD" },
	{ id: "nzd", name: "NZD", locale: "en-NZ" },
];

// Log Status Handler
export const TOAST_SESSION_EXPIRED = {
	eng: "Session Expired",
	bhs: "Sesi berakhir",
};
export const TOAST_USER_NOT_VALID = {
	eng: "User Not Valid",
	bhs: "Pengguna Tidak Valid",
};

// Loading Component
export const LOADING_LABEL = { eng: "Loading...", bhs: "Memuat..." };

// No content Component
export const NO_CONTENT_DEFAULT_LABEL = {
	eng: "No Content",
	bhs: "Tidak ada konten",
};
export const NO_CONTENT_DEFAULT_DESCRIPTION = {
	eng: "No Content Available",
	bhs: "Tidak ada konten tersedia",
};

// Header
export const SIGN_UP_FREE = {
	eng: "Sign up FREE Now",
	bhs: "Daftar GRATIS sekarang",
};
export const LOGIN_PORTAL_KERJA = {
	eng: "Log in to Portal Kerja",
	bhs: "Masuk ke Portal Kerja",
};
export const POST_JOB_LABEL = {
	eng: "Post Job",
	bhs: "Pasang Iklan",
};
export const POST_A_JOB_LABEL = {
	eng: "Post A Job",
	bhs: "Pasang Iklan",
};
export const JOB_ADS_LABEL = {
	eng: "Job Ads",
	bhs: "Job Ads",
};
export const TALENT_SEARCH_LABEL = {
	eng: "Talent Search",
	bhs: "Pencarian Talent",
};
export const COLLAPSE = {
	eng: "Collapse",
	bhs: "Tutup",
};
export const PACKAGES_LABEL = {
	eng: "Packages",
	bhs: "Paket",
};
export const HEADER_SRC_PLACEHOLDER = {
	eng: "Search for Major, College",
	bhs: "Cari Jurusan, Kampus",
};
export const REGISTER = {
	eng: "Sign up",
	bhs: "Daftar",
};
export const SIGN_IN = {
	eng: "Log in",
	bhs: "Masuk",
};
export const FAVORITE = {
	eng: "Favorite",
	bhs: "Favorit",
};
export const DASHBOARD = {
	eng: "Dashboard",
	bhs: "Dashboard",
};
export const LOGOUT = {
	eng: "Log out",
	bhs: "Keluar",
};
export const SIGN_OUT = {
	eng: "Sign out",
	bhs: "Keluar",
};
export const LOGGED_OUT = {
	eng: "Logged out",
	bhs: "Keluar Akun",
};
export const VIEW_PROFILE = {
	eng: "View Profile",
	bhs: "Lihat Profil",
};
export const ACCOUNT_SETTINGS = {
	eng: "Account Settings",
	bhs: "Pengaturan Akun",
};
export const MY_JOBS = {
	eng: "My Jobs",
	bhs: "Pekerjaan Saya",
};
export const HELP = {
	eng: "Help",
	bhs: "Bantuan",
};
export const FOR_CANDIDATES = {
	eng: "For Candidates",
	bhs: "Untuk Kandidat",
};

// Footer
export const CONTACT_US = {
	eng: "Contact Us",
	bhs: "Hubungi Kami",
};
export const PRIVACY_POLICY = {
	eng: "Privacy Policy",
	bhs: "Kebijakan Privasi",
};
export const TNC = {
	eng: "Terms & Conditions",
	bhs: "Syarat dan Ketentuan",
};
export const MAIL_TO_SUBJECT = {
	eng: "Inquiry / Complaint",
	bhs: "Pertanyaan / Keluhan",
};

// Home Component
export const COLLEGE = {
	eng: "College",
	bhs: "Kampus",
};
export const MAJOR = {
	eng: "Major",
	bhs: "Jurusan",
};
export const CAREER = {
	eng: "Career",
	bhs: "Karier",
};
export const SCHOLARSHIP = {
	eng: "Scholarship",
	bhs: "Beasiswa",
};
export const SEARCH_COLLEGE_CITY = {
	eng: "Search colleges located in {0}",
	bhs: "Cari kampus yang terletak di {0}",
};
export const NO_ITEM_FOUND_QUERY = {
	eng: "No item found for {0}",
	bhs: "Tidak ada {0} ditemukan",
};
export const CITY = {
	eng: "City",
	bhs: "Kota",
};
export const RECENTLY_VIEW_COLLEGE = {
	eng: "Recently Viewed College",
	bhs: "Kampus yang Terakhir Dilihat",
};
export const RECENTLY_VIEW_MAJOR = {
	eng: "Recently Viewed Major",
	bhs: "Jurusan yang Terakhir Dilihat",
};
export const OUR_COLLEGE_RECOMMENDATION = {
	eng: "Our Recommended College",
	bhs: "Kampus Rekomendasi Kami",
};
export const OUR_MAJOR_RECOMMENDATION = {
	eng: "Our Recommended Major",
	bhs: "Jurusan Rekomendasi Kami",
};
export const VIEW_ALL = {
	eng: "View All",
	bhs: "Lihat Semua",
};
export const LOGOUT_SUCCEED = {
	eng: "User logged out",
	bhs: "Pengguna berhasil keluar",
};
export const ACCREDITATION = {
	eng: "Accreditation",
	bhs: "Akreditasi",
};
export const ASIAN_RANKING = {
	eng: "QS Asia Ranking",
	bhs: "QS Asia Ranking",
};
export const WORLD_RANKING = {
	eng: "QS World Ranking",
	bhs: "QS World Ranking",
};
export const START = {
	eng: "Start",
	bhs: "Mulai dari",
};
export const SMT = {
	eng: "smt",
	bhs: "smt",
};
export const HC_STEP_1_TITLE = {
	eng: "Create free account",
	bhs: "Buat akun gratis",
};
export const HC_STEP_1_DESC = {
	eng: "Sign up to Portal Kerja to start building job post",
	bhs: "Daftar di Portal Kerja untuk memulai membuat iklan lowongan",
};
export const HC_STEP_2_TITLE = {
	eng: "Post your job",
	bhs: "Pasang iklan Anda",
};
export const HC_STEP_2_DESC = {
	eng: "We will attract candidates to your job post",
	bhs: "Kami akan menarik banyak kandidat ke iklan lowongan Anda",
};
export const HC_STEP_3_TITLE = {
	eng: "Start recruiting",
	bhs: "Mulai merekrut",
};
export const HC_STEP_3_DESC = {
	eng: "Manage the candidates that applied easily",
	bhs: "Kelola kandidat yang melamar dengan mudah",
};
export const HC_HERO_TITLE = {
	eng: "Unlocking Potential, Hiring Excellence",
	bhs: "Membuka Potensi, Unggul dalam Perekrutan",
};
export const HC_HERO_INFO = {
	eng: "Find Limitless great talents on Portal Kerja",
	bhs: "Temukan banyak talenta hebat di Portal Kerja",
};
export const HC_HERO_BTN = {
	eng: "Post Job",
	bhs: "Pasang Iklan",
};

// PS Login Modal
export const EMAIL_USERNAME = {
	eng: "Email/Username",
	bhs: "Email/Username",
};
export const PASSWORD = {
	eng: "Password",
	bhs: "Password/Kata Sandi",
};
export const FORGOT_PASSWORD = {
	eng: "Forgot Password",
	bhs: "Lupa Kata Sandi",
};
export const FILL_CORRECT_PASSWORD = {
	eng: "Password Empty",
	bhs: "Kata Sandi kosong",
};
export const PASSWORD_VALIDATION_ERR_MSG = {
	eng: "Password must be at least 8 characters, with 1 number, 1 upper case, and 1 lower case",
	bhs: "Kata sandi harus setidaknya 8 karakter terdiri dari 1 angka, 1 huruf besar, dan 1 huruf kecil",
};
export const FILL_CORRECT_USER_ID = {
	eng: "Email/Username Empty",
	bhs: "Email/Nama Pengguna kosong",
};
export const FILL_CORRECT_USER_ID_PASSWORD = {
	eng: "User Name & Password Empty",
	bhs: "Nama Pengguna & Kata Sandi kosong",
};

// Login Modal
export const LOGIN = {
	eng: "Login",
	bhs: "Masuk",
};
export const OR_FILL_FORM_BELOW = {
	eng: "Or fill the form below",
	bhs: "Atau isi form di bawah",
};
export const NEW_USER = {
	eng: "New User",
	bhs: "Pengguna Baru",
};
export const CLICK_HERE_CREATE_ACCOUNT = {
	eng: "Click here to create an account!",
	bhs: "Klik di sini untuk buat akun!",
};
export const LOGIN_SUCCESS = {
	eng: "Login success!",
	bhs: "Berhasil masuk!",
};
export const ERROR_INVALID_LOGIN = {
	eng: "Login failed, please check your username and/or password!",
	bhs: "Gagal untuk masuk, silakan periksa username dan/atau password kamu!",
};
export const DELETE_LABEL = {
	eng: "Delete",
	bhs: "Hapus",
};
export const DELETE_MEDIA_LABEL = {
	eng: "Delete Media",
	bhs: "Hapus Media",
};
export const DELETE_TITLE = {
	eng: "Do you want delete this item ?",
	bhs: "Apakah Anda ingin menghapus item ini?",
};

// Create Account
export const LETS_CREATE_ACCOUNT = {
	eng: "Let's create a Direktori Kampus account",
	bhs: "Ayo buat akun Direktori Kampus",
};
export const SIGNUP_WITH_GOOGLE = {
	eng: "Sign up with Google",
	bhs: "Daftar dengan Google",
};
export const ENTER = {
	eng: "Enter",
	bhs: "Masukkan",
};
export const FIRST_NAME = {
	eng: "First Name",
	bhs: "Nama Depan",
};
export const LAST_NAME = {
	eng: "Last Name",
	bhs: "Nama Belakang",
};
export const EMAIL = {
	eng: "Email",
	bhs: "Email",
};
export const USERNAME = {
	eng: "Username",
	bhs: "Username/Nama Pengguna",
};
export const ALREADY_HAVE_ACCOUNT = {
	eng: "Already have an account?",
	bhs: "Sudah punya akun?",
};
export const CLICK_HERE_LOGIN = {
	eng: "Click here to log in",
	bhs: "Klik di sini untuk masuk",
};
export const CONFIRM_PASSWORD = {
	eng: "Confirm Password",
	bhs: "Konfirmasi Kata Sandi",
};
export const PASSWORD_NOT_MATCHED = {
	eng: "The passwords entered do not match",
	bhs: "Kata Sandi dan konfirmasi Kata Sandi yang dimasukkan tidak cocok",
};
export const ACCEPT_PRIVACY_POLICY = {
	eng: "I accept with the privacy policy of",
	bhs: "Saya setuju dengan kebijakan privasi",
};
export const PARENT_UNDERAGE_CONSENT = {
	eng: "This agreement needs to be given to your parent if you're still under legal age.",
	bhs: "Persetujuan ini harus diberikan oleh orang tua Anda jika Anda masih di bawah umur.",
};
export const REGISTER_SUCCESS = {
	eng: "Successfully creating a new account",
	bhs: "Berhasil membuat akun baru",
};
export const EMAIL_INVALID = {
	eng: "Invalid email!",
	bhs: "Email tidak valid!",
};
export const USERNAME_ACCEPT = {
	eng: "Username can only accept letters, digits, @, ., +, -, and _",
	bhs: "Username/Nama Pengguna hanya menerima huruf, angka, @, ., +, -, dan _",
};

// Forgot Password
export const FORGOT_PASSWORD_MESSAGE = {
	eng: "Please enter your username",
	bhs: "Silakan masukkan nama pengguna anda",
};
export const FORGOT_PASSWORD_MAIL_SENT = {
	eng: "We have sent a confirmation email. please check your email to reset your password",
	bhs: "Kami telah mengirimkan email konfirmasi. silakan cek email Anda untuk mengatur ulang kata sandi Anda",
};
export const FORGOT_PASSWORD_MAIL_SENT_SHORT = {
	eng: "Confirmation Email Sent!",
	bhs: "Email konfirmasi terkirim!",
};
export const SEND = {
	eng: "Send",
	bhs: "Kirim",
};
export const RESEND = {
	eng: "Resend",
	bhs: "Kirim Ulang",
};
export const BACK_TO_HOME = {
	eng: "Back to the Homepage",
	bhs: "Kembali ke Halaman Utama",
};
export const HAVENT_GOT_EMAIL = {
	eng: "Haven't got e-mail?",
	bhs: "Belum mendapatkan e-mail?",
};
export const PASSWORD_RESET_TITLE = {
	eng: "Your password has been succesfully reset.",
	bhs: "Kata Sandi/Password sudah diatur ulang",
};
export const SET_NEW_PASSWORD_TITLE = {
	eng: "Set your new password",
	bhs: "Atur kata sandi baru anda",
};
export const PASSWORD_RESET = {
	eng: "Password Reset",
	bhs: "Kata Sandi/Password diatur ulang",
};
export const SET_NEW_PASSWORD = {
	eng: "Set New Password",
	bhs: "Atur Kata Sandi Baru",
};

// Profile Detail
export const EDIT_PROFILE = {
	eng: "Edit Profile",
	bhs: "Ubah Profil",
};
export const CHANGE_PASSWORD = {
	eng: "Change Password",
	bhs: "Ubah Kata Sandi",
};
export const CURRENT_PASSWORD = {
	eng: "Current Password",
	bhs: "Kata Sandi Saat Ini",
};
export const CURRENT_PASSWORD_2 = {
	eng: "Current Password",
	bhs: "Kata Sandi Sekarang",
};
export const NEW_PASSWORD = {
	eng: "New Password",
	bhs: "Kata Sandi Baru",
};
export const SAVE_PROFILE = {
	eng: "Save Profile",
	bhs: "Simpan Profil",
};
export const PLEASE_FILL_NECESSARY_FORM = {
	eng: "Please fill all necessary form",
	bhs: "Mohon mengisi bagian yang wajib diisi",
};
export const PROFILE_DETAIL_CHANGED = {
	eng: "Profile detail changed!",
	bhs: "Detil profil diubah!",
};
export const PASSWORD_CHANGED = {
	eng: "Password changed!",
	bhs: "Kata Sandi/Password diubah!",
};
export const PROFILE = {
	eng: "Profile",
	bhs: "Profil",
};

// Change Password
export const NEW_PASSWORD_2 = {
	eng: "Enter New Password",
	bhs: "Kata Sandi Baru",
};
export const INCORRECT_PASSWORD = {
	eng: "The password you entered is incorrect",
	bhs: "Kata Sandi yang dimasukkan salah",
};
export const PASS_CHANGE_SUCCESS = {
	eng: "Your password has been changed successfully",
	bhs: "Kata Sandi Anda berhasil diubah",
};
export const PASS_CHANGE_SUCCESS_DESC = {
	eng: "Because of security reasons you need to relogin.",
	bhs: "Untuk alasan keamanan Anda perlu masuk kembali.",
};

export const KEEP_SAFE_TEXT = {
	eng: "Keep your password safe from scammers",
	bhs: "Jaga kata sandi Anda dari scammer",
};
export const KEEP_SAFE_DESC = {
	eng: "Never tell anyone the password to your account. Your new password should only be known to those within the organization",
	bhs: "Jangan berikan kata sandi akun Anda kepada siapapun. Kata sandi baru Anda hanya boleh diketahui oleh pihak di dalam organisasi yang sama",
};
export const GOTO_JOB_ADS = {
	eng: "Go to Job Ads",
	bhs: "Ke halaman Job Ads",
};
// Listing
export const SHOWING = {
	eng: "Showing",
	bhs: "Menampilkan",
};
export const COLLEGES = {
	eng: "College(s)",
	bhs: "Kampus",
};
export const MAJORS = {
	eng: "Major(s)",
	bhs: "Jurusan",
};
export const CAREERS = {
	eng: "Careers(s)",
	bhs: "Karier",
};
export const SCHOLARSHIPS = {
	eng: "Scholarships(s)",
	bhs: "Beasiswa",
};
export const VIEW_MORE = {
	eng: "View More",
	bhs: "Tampilkan Lebih Banyak",
};
export const FILTERS = {
	eng: "Filters",
	bhs: "Filter",
};

// Filter Box
export const SRC_PLACEHOLDER = {
	eng: "Search...",
	bhs: "Cari...",
};
export const SRC_BY_PLACEHOLDER = {
	eng: "Search {0} Name",
	bhs: "Cari Nama {0}",
};
export const RESET = {
	eng: "Reset",
	bhs: "Atur Ulang",
};
export const APPLY = {
	eng: "Apply",
	bhs: "Terapkan",
};

// College Listing
export const LOCATION = {
	eng: "Location",
	bhs: "Lokasi",
};
export const SRC_LOCATION = {
	eng: "Search Location",
	bhs: "Cari Lokasi",
};
export const SRC_ACCREDITATION = {
	eng: "Search Accreditation",
	bhs: "Cari Akreditasi",
};
export const DEGREE = {
	eng: "Degree",
	bhs: "Level Studi",
};
export const SRC_MAJOR = {
	eng: "Search Major",
	bhs: "Cari Jurusan",
};
export const ALL_MAJORS = {
	eng: "All majors",
	bhs: "Semua jurusan",
};
export const DIPLOMA_1 = {
	eng: "Diploma 1",
	bhs: "Diploma 1",
};
export const DIPLOMA_2 = {
	eng: "Diploma 2",
	bhs: "Diploma 2",
};
export const DIPLOMA_3 = {
	eng: "Diploma 3",
	bhs: "Diploma 3",
};
export const DIPLOMA_4 = {
	eng: "Diploma 4",
	bhs: "Diploma 4",
};
export const UNDERGRAD = {
	eng: "Undergraduate",
	bhs: "Sarjana",
};
export const POSTGRAD = {
	eng: "Postgraduate",
	bhs: "Pasca Sarjana",
};
export const DOCTORAL = {
	eng: "Doctoral",
	bhs: "Doktoral",
};
export const SOMETHING_WENT_WRONG_TRY_AGAIN = {
	eng: "Something went wrong please try again after some time!",
	bhs: "Ada yang tidak beres, coba lagi setelah beberapa saat!",
};
export const STATE_UNIVERSITY_ABBV = {
	eng: "State",
	bhs: "Negeri",
};
export const PRIVATE_UNIVERSITY_ABBV = {
	eng: "Private",
	bhs: "Swasta",
};
export const SHOW_ALL = {
	eng: "Show All",
	bhs: "Lihat Semua",
};
export const YES = {
	eng: "Yes",
	bhs: "Ya",
};
export const NO = {
	eng: "No",
	bhs: "Tidak",
};
export const NO_ACCREDITATION = {
	eng: "No Accreditation",
	bhs: "Tidak Terakreditasi",
};
export const OTHER_ACCREDITATION = {
	eng: "Other Accreditation",
	bhs: "Akreditasi Lainnya",
};
export const SELECTED = {
	eng: "{0} selected",
	bhs: "{0} terpilih",
};

// Major Listing
export const FACULTY_NAME = {
	eng: "Faculty Name",
	bhs: "Bidang Studi",
};
export const TUITION_FEE_SMT = {
	eng: "Tuition Fee per semester",
	bhs: "Biaya Per Semester",
};
export const ALL_FACULTY = {
	eng: "All Faculties",
	bhs: "Semua bidang studi",
};
export const ALL_TUITION = {
	eng: "All Tuition",
	bhs: "Semua biaya",
};
export const SRC_FACULTY = {
	eng: "Search Faculty",
	bhs: "Cari Fakultas",
};

// Career Listing
export const SECTOR = {
	eng: "Sector",
	bhs: "Karier",
};
export const ALL_SECTOR = {
	eng: "All Sectors",
	bhs: "Semua Karier",
};

// Scholarship Listing
export const SCHOLARSHIP_GIVER = {
	eng: "Scholarship giver",
	bhs: "Jenis",
};
export const ALL_SCHOLARSHIP_GIVER = {
	eng: "All Scholarship Giver",
	bhs: "Semua Jenis",
};
export const GOVERNMENT = {
	eng: "Government",
	bhs: "Pemerintah",
};
export const ORGANIZATION = {
	eng: "Organization",
	bhs: "Organisasi",
};
export const INTERNATIONAL = {
	eng: "International",
	bhs: "Internasional",
};
export const DESTINATION = {
	eng: "Destination",
	bhs: "Tujuan",
};
export const ALL_DESTINATION = {
	eng: "All Destinations",
	bhs: "Semua Tujuan",
};
export const DOMESTIC = {
	eng: "Domestic",
	bhs: "Dalam Negeri",
};
export const ABROAD = {
	eng: "Abroad",
	bhs: "Luar Negeri",
};
export const INTERNATIONAL_DESTINATION = {
	eng: "International",
	bhs: "Luar Negeri",
};
export const UNTIL = {
	eng: "Until",
	bhs: "Hingga",
};

// College Details
export const GENERAL_INFORMATION = {
	eng: "General Information",
	bhs: "Informasi Umum",
};
export const COLLEGE_NOT_FOUND = {
	eng: "Selected college not found!",
	bhs: "Tidak dapat menemukan kampus terpilih",
};
export const RANKING = {
	eng: "Ranking",
	bhs: "Ranking",
};
export const BASED_ON_WEBOMETRIC_INDO = {
	eng: "Based on Webometrics Country Rank in Indonesia",
	bhs: "Berdasarkan Webometrics Country Rank di Indonesia",
};
export const STATE_UNIVERSITY = {
	eng: "State University",
	bhs: "Universitas Negeri",
};
export const PRIVATE_UNIVERSITY = {
	eng: "Private University",
	bhs: "Universitas Swasta",
};
export const ADD_TO_FAVORITES = {
	eng: "Add to Favorites",
	bhs: "Tambahkan ke Favorit",
};
export const DOWNLOAD_BROCHURE = {
	eng: "Download Brochure",
	bhs: "Download Brosur",
};
export const REMOVE_FROM_FAVORITES = {
	eng: "Remove from Favorites",
	bhs: "Hapus dari Favorit",
};
export const STUDENT = {
	eng: "Student",
	bhs: "Mahasiswa",
};
export const LECTURER = {
	eng: "Lecturer",
	bhs: "Dosen",
};
export const WEBSITE = {
	eng: "Website",
	bhs: "Website",
};
export const PHONE = {
	eng: "Phone",
	bhs: "Nomor Telepon",
};
export const WHATSAPP = {
	eng: "WhatsApp",
	bhs: "WhatsApp",
};
export const DATA_NOT_AVAILABLE = {
	eng: "Data is not available",
	bhs: "Data tidak tersedia",
};
export const COLLEGE_DESCRIPTION = {
	eng: "College Description",
	bhs: "Deskripsi Kampus",
};
export const SRC_MAJOR_PLACEHOLDER = {
	eng: "Search Major",
	bhs: "Cari Jurusan",
};
export const FACILITIES = {
	eng: "Facilities",
	bhs: "Fasilitas",
};
export const FACULTIES_AND_MAJORS = {
	eng: "Faculties and Majors",
	bhs: "Fakultas dan Jurusan",
};
export const GENERAL_TUITION_FEES = {
	eng: "General Tuition Fees",
	bhs: "Biaya Kuliah",
};
export const SCHOLARSHIP_INFORMATION = {
	eng: "Scholarship Information",
	bhs: "Informasi Beasiswa",
};
export const MORE_INFO = {
	eng: "More Info",
	bhs: "Informasi Lebih Banyak",
};
export const TESTIMONY = {
	eng: "Testimony",
	bhs: "Testimoni",
};
export const LOCATIONS = {
	eng: "Location(s)",
	bhs: "Lokasi",
};
export const OPEN_IN_MAP = {
	eng: "Open in Map",
	bhs: "Lihat di Peta",
};
export const CAMPUS_NUM = {
	eng: "Campus {0}",
	bhs: "Kampus {0}",
};

// Major Details
export const MAJOR_NOT_FOUND = {
	eng: "Selected major not found!",
	bhs: "Tidak dapat menemukan jurusan terpilih",
};
export const FACULTY = {
	eng: "Faculty",
	bhs: "Fakultas",
};
export const MAJOR_DESCRIPTION = {
	eng: "Major Description",
	bhs: "Deskripsi Jurusan",
};
export const REGISTRATION_FEE = {
	eng: "Registration Fee",
	bhs: "Biaya Pendaftaran",
};
export const STUDY_TIME = {
	eng: "Study Time (Years)",
	bhs: "Lama Studi (Tahun)",
};
export const SKS = {
	eng: "SKS",
	bhs: "SKS",
};
export const NUMBER_STUDENT = {
	eng: "Number of Student",
	bhs: "Jumlah Siswa",
};
export const NUMBER_LECTURER = {
	eng: "Number of Lecturer",
	bhs: "Jumlah Dosen",
};
export const MAJOR_WEBSITE = {
	eng: "Major Website",
	bhs: "Website Jurusan",
};
export const MAJOR_EMAIL = {
	eng: "Major Email",
	bhs: "Email Jurusan",
};
export const MAJOR_PHONE = {
	eng: "Major Phone",
	bhs: "Telepon Jurusan",
};
export const REGISTRATION_INFO = {
	eng: "Registration Info",
	bhs: "Info Registrasi",
};
export const STUDY_FEES = {
	eng: "Study Fees",
	bhs: "Biaya Kuliah",
};
export const CURRICULUM = {
	eng: "Curriculum",
	bhs: "Kurikulum",
};
export const ACHIEVEMENTS = {
	eng: "Achievements",
	bhs: "Prestasi",
};
export const CONCENTRATION = {
	eng: "Concentration",
	bhs: "Konsentrasi",
};
export const CARRER_PATH = {
	eng: "Career Path",
	bhs: "Karier Terkait",
};
export const CARRER_PATH_DESC = {
	eng: "If you study in this major, you can work as:",
	bhs: "Jika kamu kuliah di jurusan ini, maka kamu dapat bekerja sebagai:",
};

// Career Details
export const CAREER_NOT_FOUND = {
	eng: "Selected career not found!",
	bhs: "Tidak dapat menemukan karier terpilih",
};
export const START_FROM = {
	eng: "Starts from",
	bhs: "Dimulai dari",
};
export const SALARY = {
	eng: "Salary",
	bhs: "Gaji",
};
export const MIN_DEGREE = {
	eng: "Minimum Degree",
	bhs: "Minimal Pendidikan",
};
export const CERTIFICATION = {
	eng: "Certification",
	bhs: "Sertifikasi",
};
export const CAREER_DESCRIPTION = {
	eng: "Career Description",
	bhs: "Deskripsi Karier",
};
export const FACT_STATS = {
	eng: "Fact Stats",
	bhs: "Fakta dan Statistik",
};
export const ROLE_RESPONSIBILITIES = {
	eng: "Role and Responsibilities",
	bhs: "Peran dan Tanggung Jawab",
};
export const REQUIRED_SKILLS = {
	eng: "Knowledge and Skills Required",
	bhs: "Pengetahuan dan Keahlian",
};
export const CAREER_PATH = {
	eng: "Career Path",
	bhs: "Jenjang Karier",
};
export const RELATED_MAJOR = {
	eng: "Faculty/Major Related",
	bhs: "Fakultas/Jurusan Terkait",
};

// Scholarship Details
export const SCHOLARSHIP_NOT_FOUND = {
	eng: "Selected scholarship not found!",
	bhs: "Tidak dapat menemukan beasiswa terpilih",
};
export const REGISTRATION_PERIOD = {
	eng: "Registration Period",
	bhs: "Pendaftaran",
};
export const SCHOLARSHIP_DESCRIPTION = {
	eng: "Scholarship Description",
	bhs: "Deskripsi Beasiswa",
};
export const REGISTRATION_TIMELINE = {
	eng: "Registration Timeline",
	bhs: "Info Proses Pendaftaran",
};
export const IMPORTANT_INFO = {
	eng: "Important Info",
	bhs: "Info Penting",
};
export const DOCUMENTS_REQUIRED = {
	eng: "Documents Required",
	bhs: "Berkas yang dibutuhkan",
};
export const SPECIAL_REQS = {
	eng: "Special Requirements",
	bhs: "Syarat khusus yang perlu diperhatikan",
};
export const LEVEL_OF_COMPS = {
	eng: "Level of Competition",
	bhs: "Tingkat persaingan untuk beasiswa",
};
export const SELECTION_PERIOD = {
	eng: "Selection Period",
	bhs: "Periode seleksi beasiswa",
};
export const CHECKING_ANNOUNCEMENT = {
	eng: "Checking Announcement",
	bhs: "Bagaimana cara mengetahui saya diterima atau tidak?",
};
export const OTHER_EXPLANATION = {
	eng: "Other Explanation",
	bhs: "Penjelasan lain-lain",
};
export const WHAT_BENEFIT = {
	eng: "What benefit you will get?",
	bhs: "Benefit apa saja yang didapat?",
};

// Favorite page
export const HOME = {
	eng: "Home",
	bhs: "Home",
};
export const NO_FAVORITE_TITLE = {
	eng: "You haven't save any favorite {0}",
	bhs: "Anda belum menyimpan {0} favorit Anda",
};
export const NO_FAVORITE_DESC = {
	eng: "Go to the {0} page here and mark as favorite",
	bhs: "Lihat halaman {0} di sini dan tandai sebagai favorit",
};
export const GENERAL_PAGE = {
	eng: "{0} Page",
	bhs: "Halaman {0}",
};
export const FAILED_FETCH_FAVORITES = {
	eng: "Failed to fetch favorites",
	bhs: "Gagal mendapatkan data favorit",
};
export const SUCCESS_ADD_FAVORITES = {
	eng: "Successfully adding this {0} to the favorite",
	bhs: "Berhasil menambahkan {0} ini ke favorit",
};
export const SUCCESS_REMOVE_FAVORITES = {
	eng: "Successfully removing this {0} from the favorite",
	bhs: "Berhasil menghapus {0} ini dari favorit",
};
export const FAILED_ADD_FAVORITES = {
	eng: "Failed to add this {0} to the favorite",
	bhs: "Gagal menambahkan {0} ini ke favorit",
};
export const FAILED_REMOVE_FAVORITES = {
	eng: "Failed to remove this {0} from the favorite",
	bhs: "Gagal menghapus {0} ini dari favorit",
};
export const CANNOT_GET_DATA = {
	eng: "Cannot get the sufficient data to do this operation",
	bhs: "Tidak dapat mendapatkan data untuk melakukan operasi ini",
};
export const SCROLL_TO_TOP = {
	eng: "Scroll to Top",
	bhs: "Scroll ke atas",
};

// Component
export const BUTTON_CTA = {
	eng: "Button CTA",
	bhs: "Button CTA",
};
export const LOCATION_LABEL = {
	eng: "Location",
	bhs: "Lokasi",
};
export const SEARCH_LABEL = {
	eng: "Search",
	bhs: "Pencarian",
};
export const COLLEGE_LABEL = {
	eng: "College",
	bhs: "Universitas",
};
export const LABEL_NAME = {
	eng: "Label",
	bhs: "Label",
};
export const DATE_LABEL = {
	eng: "Date Post",
	bhs: "Tanggal Unggah",
};
export const JOB_TYPE_LABEL = {
	eng: "Job Type",
	bhs: "Tipe Pekerjaan",
};
export const WORK_MODE_LABEL = {
	eng: "Work Mode",
	bhs: "Mode Kerja",
};
export const EXPERIENCE_LEVEL_LABEL = {
	eng: "Experience Level",
	bhs: "Tingkat Pengalaman",
};
export const INDUSTRY_LABEL = {
	eng: "Industry",
	bhs: "Industri",
};
export const INDUSTRY_INPUT_PLACEHOLDER = {
	eng: "Input Industry",
	bhs: "Ketik Industri",
};
export const UNDER_TEN_APP_LABEL = {
	eng: "Under 10 Application",
	bhs: "Dibawah 10 pendaftar",
};
export const ANY_TIME_LABEL = {
	eng: "Any time",
	bhs: "Semua Waktu",
};
export const LAST_24_HOURS = {
	eng: "Last 24 hours",
	bhs: "24 Jam Lalu",
};
export const LAST_3_DAYS = {
	eng: "Last 3 days",
	bhs: "3 Hari Lalu",
};
export const LAST_7_DAYS = {
	eng: "Last 7 days",
	bhs: "7 Hari Lalu",
};
export const LAST_14_DAYS = {
	eng: "Last 14 days",
	bhs: "14 Hari Lalu",
};
export const LAST_30_DAYS = {
	eng: "Last 30 days",
	bhs: "30 Hari Lalu",
};
export const ONSITE_LABEL = {
	eng: "On site",
	bhs: "Di tempat",
};
export const HYBRID_LABEL = {
	eng: "Hybrid",
	bhs: "Hibrid",
};
export const REMOTE_LABEL = {
	eng: "Remote",
	bhs: "Remote",
};
export const FULL_TIME_LABEL = {
	eng: "Full Time",
	bhs: "Penuh Waktu",
};
export const PART_TIME_LABEL = {
	eng: "Part Time",
	bhs: "Paruh Waktu",
};
export const FRELANCE_LABEL = {
	eng: "Freelance",
	bhs: "Pekerja Lepas",
};
export const CONTRACT_LABEL = {
	eng: "Contract",
	bhs: "Kontrak",
};
export const INTERNSHIP_LABEL = {
	eng: "Internship",
	bhs: "Magang",
};
export const TEMPORARY_LABEL = {
	eng: "Temporary",
	bhs: "Temporer",
};
export const ENTRY_LEVELLABEL = {
	eng: "Entry Level",
	bhs: "Pemula",
};
export const ASSOCIATE_LABEL = {
	eng: "Associate",
	bhs: "Junior",
};
export const INTERMEDIATE_SENIOR_LABEL = {
	eng: "Intermediate - Senior",
	bhs: "Menengah - Senior",
};
export const DIRECTOR_LABEL = {
	eng: "Director",
	bhs: "Direktur",
};
export const EXECUTIVE_LABEL = {
	eng: "Executive",
	bhs: "Eksekutif",
};
export const INPUT_PLACEHOLDER = {
	eng: "Input your text",
	bhs: "Masukkan tulisan anda",
};
export const INPUT_YOUR_NAME = {
	eng: "Input your name",
	bhs: "Masukkan nama anda",
};
export const EMAIL_PLACEHOLDER = {
	eng: "olivia@portalkerja.com",
	bhs: "olivia@portalkerja.com",
};
export const SALARY_PLACEHOLDER = {
	eng: "5.000",
	bhs: "5.000",
};
export const PHONE_PLACEHOLDER = {
	eng: "8777752364",
	bhs: "8777752364",
};
export const HINT_MESSAGE = {
	eng: "This is a hint text to help user",
	bhs: "Ini adalah bantuan untuk membimbing pengguna",
};
export const ERROR_HINT_MESSAGE = {
	eng: "This is an error message",
	bhs: "Ini adalah pesan error",
};
export const TEXTAREA = {
	eng: "Description",
	bhs: "Deskripsi",
};

// Pagination
export const OF_LABEL = {
	eng: "of",
	bhs: "dari",
};
export const PAGE_LABEL = {
	eng: "Page",
	bhs: "Halaman",
};

// Home Component
export const ENTER_YOUR_EMAIL = {
	eng: "Enter Your Email",
	bhs: "Masukkan Email anda",
};
export const SUBSCRIBE_LABEL = {
	eng: "Subscribe",
	bhs: "Subscribe",
};
export const UNSUBSCRIBE_LABEL = {
	eng: "Unsubscribed",
	bhs: "Unsubscribed",
};

// Job Searching
export const FIND_JOB = {
	eng: "Find Job",
	bhs: "Cari Pekerjaan",
};
export const JOB_SEARCH_PLACEHOLDER = {
	eng: "Job tittle, Keywords, Company ",
	bhs: "Job tittle, Keywords, Company ",
};
export const LOCATION_PLACEHOLDER = {
	eng: "Area, city",
	bhs: "Area, city",
};
export const JOB_MATCHES = {
	eng: "{0}-{1} of {2} job matches",
	bhs: "{0}-{1} dari {2} pekerjaan ditemukan",
};
export const PER_MONTH = {
	eng: "per month",
	bhs: "per bulan",
};
export const POSTED_AGO = {
	eng: "Posted {0}",
	bhs: "Ditayangkan {0}",
};
export const URGENCY = {
	eng: "Urgency: {0}",
	bhs: "Urgensi: {0}",
};
export const APPLICANTS = {
	eng: "{0} applicants",
	bhs: "{0} pelamar",
};
export const OPEN_IN_NEW_TAB = {
	eng: "Open in new tab",
	bhs: "Buka di tab baru",
};
export const APPLY_NOW = {
	eng: "Apply Now",
	bhs: "Lamar Sekarang",
};
export const ADD_TO_COMPARE = {
	eng: "Add to Compare",
	bhs: "Tambahkan ke Perbandingan",
};
export const FULL_JOB_DESCRIPTION = {
	eng: "Full Job Description",
	bhs: "Deskripsi Pekerjaan",
};
export const QUALIFICATIONS = {
	eng: "Qualifications:",
	bhs: "Kualifikasi:",
};
export const RESPONSIBILITIES = {
	eng: "Responsibilities:",
	bhs: "Tanggung Jawab:",
};
export const JOB_DETAILS = {
	eng: "Job Details",
	bhs: "Rincian Pekerjaan",
};
export const MORE_JOB_SEARCH = {
	eng: "More Job Search",
	bhs: "Cari lebih banyak pekerjaan",
};
export const REPORT = {
	eng: "Report",
	bhs: "Laporkan",
};

// Verification
export const VERIFICATION_TITLE = {
	eng: "Verify your account",
	bhs: "Verifikasi akun anda",
};
export const UPLOAD_NPWP = {
	eng: "Upload NPWP",
	bhs: "Unggah NPWP",
};
export const UPLOAD_PHOTO = {
	eng: "Upload photo",
	bhs: "Unggah photo",
};
export const UPLOAD_LOGO = {
	eng: "Upload Logo",
	bhs: "Unggah Logo",
};
export const DELETE_FILE = {
	eng: "Delete file",
	bhs: "Hapus file",
};
export const STEP_LABEL = {
	eng: "Step",
	bhs: "Langkah",
};
export const SKIP_LABEL = {
	eng: "Skip",
	bhs: "Lewati",
};
export const NEXT_STEP_LABEL = {
	eng: "Next Step",
	bhs: "Langkah Selanjutnya",
};
export const DRAG_FILE_LABEL = {
	eng: "Drag file here to upload or",
	bhs: "Seret file ke sini untuk mengunggah atau",
};
export const CHOOSE_FILE = {
	eng: "Choose file",
	bhs: "Pilih Berkas",
};
export const MAXIMUM_FILE = {
	eng: "PNG or PDF format. Maximum 20 MB",
	bhs: "Format PNG atau PDF. Maksimum 20 MB",
};
export const UPLOAD_FAILED = {
	eng: "Upload Failed",
	bhs: "Gagal Mengunggah",
};
export const FORMAT_NOT_SUPPORTED = {
	eng: "The file format is not supported",
	bhs: "Format file tidak didukung",
};
export const MODAL_VERIFICATION_TITLE = {
	eng: "Check your email",
	bhs: "Periksa email Anda",
};
export const REPORT_SUCCESS_TEXT = {
	eng: "We take report Seriously and after a throught review, our support team will get back to you.",
	bhs: "Kami menanggapi laporan dengan serius dan setelah tinjauan menyeluruh, tim pendukung kami akan menghubungi Anda kembali.",
};
export const RESET_PASSWORD_VERIFICATION = {
	eng: "We have sent a email for verification to ",
	bhs: "Kami telah mengirimkan email untuk verifikasi ke ",
};
export const REPORT_SUCCESS_TITLE = {
	eng: "Thank you for submitting a report",
	bhs: "Terima kasih sudah memberikan laporan",
};
export const UPLOADING_FILE = {
	eng: "Upload file",
	bhs: "Unggah berkas",
};
export const CANCEL_LABEL = {
	eng: "Cancel",
	bhs: "Batal",
};
export const CANCEL_BTN_LABEL = {
	eng: "Cancel",
	bhs: "Batalkan",
};
export const CLOSE_LABEL = {
	eng: "Close",
	bhs: "Tutup",
};
export const REPLACE_LABEL = {
	eng: "Replace",
	bhs: "Ubah",
};
export const COMPANY = {
	eng: "Company",
	bhs: "Perusahaan",
};
export const SELECT_LABEL = {
	eng: "Select",
	bhs: "Pilih",
};
export const RESULT_LABEL = {
	eng: "Result",
	bhs: "Hasil",
};
export const PUBLIC = {
	eng: "Public",
	bhs: "Publik",
};
export const PRIVATE = {
	eng: "Private",
	bhs: "Pribadi",
};
export const PUBLIC_DESCRIPTION = {
	eng: "Your resume and profile information can be found through Portal Kerja by Job Posters looking for candidates",
	bhs: "Resume dan informasi profil Anda dapat ditemukan melalui Portal Kerja oleh Poster Pekerjaan yang mencari kandidat.",
};
export const PRIVATE_DESCRIPTION = {
	eng: "Employers cannot find your resume in a search on Portal Kerja. This does not affect previous applications or prevent employers you responded to from contacting you.",
	bhs: "Pemberi kerja tidak dapat menemukan resume Anda dalam pencarian di Portal Kerja. Ini tidak memengaruhi aplikasi sebelumnya atau mencegah perusahaan yang Anda tanggapi untuk menghubungi Anda.",
};

// Sign Up
export const EMAIL_USERNAME_PLACEHOLDER = {
	eng: "john@gmail.com",
	bhs: "john@gmail.com",
};
export const EG_REGISTERED_BUSINESS_NAME = {
	eng: "eg : PT Ceria",
	bhs: "eg : PT Ceria",
};
export const LAST_NAME_OPTIONAL = {
	eng: "Last Name (Optional)",
	bhs: "Nama Belakang (Opsional)",
};
export const REGISTERED_BUSINESS_NAME = {
	eng: "Registered business name",
	bhs: "Nama bisnis terdaftar",
};
export const SIGN_UP_TITLE = {
	eng: "Please Confirm the validity of your email address",
	bhs: "Harap Konfirmasi validitas alamat email Anda",
};
export const HELP_TITLE = {
	eng: "Once you sign up you're agreeing to out privacy policy",
	bhs: "Setelah Anda mendaftar, Anda menyetujui kebijakan privasi",
};
export const BACK_TO_LOGIN = {
	eng: "Back to login",
	bhs: "Kembali ke login",
};
export const CONTINUE_LABEL = {
	eng: "Continue",
	bhs: "Selanjutnya",
};
export const NEXT_LABEL = {
	eng: "Next",
	bhs: "Selanjutnya",
};
export const REGISTER_ACCOUNT = {
	eng: "Register Account",
	bhs: "Daftarkan Akun",
};
export const SEND_EMAIL = {
	eng: "Send email",
	bhs: "Kirim email",
};
export const HELP_TEXT_FORGOT = {
	eng: "No worries, we’ll send you reset instruction.",
	bhs: "Jangan khawatir, kami akan mengirimkan instruksi reset.",
};
export const RESET_PASSWORD = {
	eng: "Reset Password",
	bhs: "Ubah Kata Sandi",
};

// Employer
export const ACTIVE_JOBS = {
	eng: "Active Jobs",
	bhs: "Pekerjaan Aktif",
};
export const USERS = {
	eng: "Users",
	bhs: "Pengguna",
};
export const ADD_USER = {
	eng: "Add User",
	bhs: "Tambahkan Pengguna",
};
export const EDIT_USER = {
	eng: "Edit User",
	bhs: "Ubah Pengguna",
};
export const USER_MANAGEMENT = {
	eng: "User Management",
	bhs: "Manajemen Pengguna",
};
export const FULL_NAME = {
	eng: "Full Name",
	bhs: "Nama Lengkap",
};
export const DESIGNATION = {
	eng: "Designation",
	bhs: "Jabatan",
};
export const DEPARTMENT = {
	eng: "Department",
	bhs: "Departemen",
};
export const EMAIL_ADDRESS = {
	eng: "Email address",
	bhs: "Alamat email",
};
export const MOBILE_NUMBER = {
	eng: "Mobile Number",
	bhs: "Nomor HP",
};
export const PLACEHOLDER = {
	eng: "Type your {0} here",
	bhs: "Ketik {0} di sini",
};
export const PROFILE_PHOTO = {
	eng: "Profile Photo",
	bhs: "Foto Profil",
};
export const CHANGE_PHOTO = {
	eng: "Change Picture",
	bhs: "Ubah Foto Profil",
};
export const PIC_SIZE_ERR = {
	eng: "The size of picture must be 5 MB or lower",
	bhs: "Ukuran foto profil harus di bawah 5 MB",
};
export const UNSUPPORTED_FILE = {
	eng: "File format is not supported",
	bhs: "File format tidak didukung",
};
export const PROFILE_UPDATE_SUCCESS = {
	eng: "Your profile was updated",
	bhs: "Profil Anda telah diperbarui",
};
export const PROFILE_IMG_UPDATE_SUCCESS = {
	eng: "Your profile image was updated",
	bhs: "Foto profil Anda telah diperbarui",
};
export const PROFILE_IMG_REMOVED = {
	eng: "Your profile image was removed",
	bhs: "Profil foto Anda telah dihapus",
};
export const DELETE_PIC_DESC = {
	eng: "Delete your profile picture",
	bhs: "Hapus foto profil Anda",
};
export const FILE_SIZE_FORMAT = {
	eng: "{0} or {1} format, maximum {2} MB.",
	bhs: "{0} or {1} format, maximum {2} MB.",
};
export const LOGIN_EMAIL = {
	eng: "Login email",
	bhs: "Email masuk",
};
export const ACTIVE = {
	eng: "Active",
	bhs: "Aktif",
};
export const INACTIVE = {
	eng: "Inactive",
	bhs: "Tidak Aktif",
};
export const DRAFT = {
	eng: "Draft",
	bhs: "Draf",
};
export const SUPER_ADMIN = {
	eng: "Super Admin",
	bhs: "Admin Super",
};
export const USER_INFORMATION = {
	eng: "User Information",
	bhs: "Informasi Pengguna",
};
export const ACCESS_MANAGEMENT = {
	eng: "Access Management",
	bhs: "Manajemen Akses",
};
export const ADMIN = {
	eng: "Admin",
	bhs: "Admin",
};
export const CUSTOM = {
	eng: "Custom",
	bhs: "Kustom",
};
export const MANAGE_CANDIDATES = {
	eng: "Manage Candidates",
	bhs: "Kelola Kandidat",
};
export const MANAGE_CANDIDATES_DESC = {
	eng: "Users are able to manage candidates",
	bhs: "Pengguna dapat mengelola kandidat",
};
export const ALL_JOBS = {
	eng: "All Jobs",
	bhs: "Semua Pekerjaan",
};
export const SPECIFIC_JOBS = {
	eng: "Specific Jobs",
	bhs: "Pekerjaan Spesifik",
};
export const JOB_POSTING = {
	eng: "Job Posting",
	bhs: "Pasang Iklan",
};
export const JOB_POSTING_DESC = {
	eng: "User can create/edit/view job ad or edit/delete the draft",
	bhs: "Pengguna dapat membuat/mengubah/melihat iklan lowongan kerja atau mengubah/menghapus draft",
};
export const TALENT_SEARCH_UTILIZATION = {
	eng: "Talent Search Utilization",
	bhs: "Penggunaan Pencarian Talent",
};
export const TALENT_SEARCH_UTILIZATION_DESC = {
	eng: "User can use 'talent search' fucntionality and retrieve resume",
	bhs: "Pengguna dapat menggunakan 'pencarian talent' dan mengumpulkan resume",
};
export const USER_MANAGEMENT_DESC = {
	eng: "User can manage other user within the account",
	bhs: "Pengguna dapat mengelola pengguna lain di dalam akun",
};
export const COMPANY_PROFILE_MANAGEMENT = {
	eng: "Company Profile Management",
	bhs: "Manajemen Profil Perusahaan",
};
export const COMPANY_PROFILE_MANAGEMENT_DESC = {
	eng: "User can manage company profile within the account",
	bhs: "Pengguna dapat mengelola profil perusahaan di dalam akun",
};
export const COMPANY_PROFILE = {
	eng: "Company Profile",
	bhs: "Profil Perusahaan",
};
export const COMPANY_PROFILE_DESC = {
	eng: "Will be redirected to company profile dedicated page (Coming soon)",
	bhs: "Akan dialihkan ke halaman khusus profil perusahaan (Segera hadir)",
};
export const NPWP_UPLOADED = {
	eng: "NPWP Uploaded Successfully",
	bhs: "NPWP Berhasil Diupload",
};
export const TDP_UPLOADED = {
	eng: "TDP Uploaded Successfully",
	bhs: "TDP Berhasil Diupload",
};
export const WAITING_CONFIRMATION = {
	eng: "Waiting confirmation",
	bhs: "Menunggu konfirmasi",
};
export const BILLING_MANAGEMENT = {
	eng: "Billing Management",
	bhs: "Manajemen Tagihan",
};
export const BILLING_MANAGEMENT_DESC = {
	eng: "Users can manage order, payment, and billing details within the account",
	bhs: "Pengguna dapat mengatur rincian pemesanan, pembayaran, dan tagihan di dalam akun",
};
export const BILLING_MANAGEMENT_LBL = {
	eng: "Billing Management",
	bhs: "Manajemen Penagihan",
};
export const SAVE_LABEL = {
	eng: "Save",
	bhs: "Simpan",
};
export const CONFIRM_NEW_PASSWORD = {
	eng: "Confirm New Password",
	bhs: "Konfirmasi Kata Sandi",
};
export const ENTER_CURRENT_PASSWORD = {
	eng: "Enter Current Password",
	bhs: "Masukkan Kata Sandi Saat Ini",
};
export const CURRENT_PASSWORD_WRONG = {
	eng: "The password you entered is incorrect",
	bhs: "Kata sandi yang Anda masukkan salah",
};
export const CURRENT_PASSWORD_SAME = {
	eng: "Old password is same as new password",
	bhs: "Kata sandi lama sama dengan kata sandi baru",
};
export const MUST_SAME = {
	eng: "The passwords entered do not match",
	bhs: "Kata sandi yang dimasukkan tidak cocok",
};
export const PASSWORD_CHANGED_SUCCESS = {
	eng: "Password Changed Successfully",
	bhs: "Kata Sandi Berhasil Diubah",
};
export const COMPANY_ACCOUNT_STATUS = {
	eng: "Company Account status",
	bhs: "Status Akun Perusahaan",
};
export const NPWP_TDP_WARNING = {
	eng: "We need both NPWP and TDP/SIUP/NIB of your company to verify your company.",
	bhs: "Kami memerlukan NPWP dan TDP/SIUP/NIB perusahaan Anda untuk memverifikasi perusahaan Anda.",
};
export const DRAG_HERE = {
	eng: "Drag file here to upload or",
	bhs: "Seret file ke sini untuk mengunggah atau",
};
export const FILE_ALLOWED = {
	eng: "File allowed only with type of DOC, DOCX, TXT, RTF, PDF, PNG, JPG, or JPEG format.",
	bhs: "File hanya diperbolehkan dengan jenis format DOC, DOCX, TXT, RTF, PDF, PNG, JPG, atau JPEG.",
};
export const MAX_20MB = {
	eng: "Maximum 20 MB.",
	bhs: "Maksimum 20 MB.",
};
export const REQUEST_VERIFICATION = {
	eng: "Request Verification",
	bhs: "Minta Verifikasi",
};
export const FILE_UPLOAD_INVALID = {
	eng: "File type must be .doc, .docx, .txt, .rtf, .pdf, .png, .jpg, or .jpeg",
	bhs: "Tipe berkas harus .doc, .docx, .txt, .rtf, .pdf, .png, .jpg, atau .jpeg,",
};
export const REPLACE_FILE = {
	eng: "Replace file",
	bhs: "Ganti file",
};
export const FILE_UPLOAD_TOO_LARGE = {
	eng: "File is larger than 20 MBs",
	bhs: "Ukuran berkas lebih besar dari 20 MB",
};
export const LOGO_COMPANY = {
	eng: "Logo Company",
	bhs: "Logo Perusahaan",
};
export const PASSWORD_CHANGED_MSG = {
	eng: "Because of security reasons you need to relogin.",
	bhs: "Karena alasan keamanan, Anda perlu masuk kembali.",
};
export const PASSWORD_VALID = {
	eng: "Password must be at least 8 characters, with 1 number, 1 upper case, 1 lower case, and special characters.",
	bhs: "Kata sandi minimal harus 8 karakter, dengan 1 angka, 1 huruf besar, 1 huruf kecil, dan karakter khusus.",
};
export const REQUIRED_FIELD = {
	eng: "This field is required",
	bhs: "Field ini harus di isi",
};
export const RESOURCE_NOT_FOUND = {
	eng: "Resource Not Found",
	bhs: "Sumber Tidak Ditemukan",
};
export const ACCOUNT_ADDED = {
	eng: "Account has been added.",
	bhs: "Akun ditambahkan.",
};
export const ACCOUNT_UPDATED = {
	eng: "Account has been updated",
	bhs: "Akun telah diperbarui",
};
export const ACCOUNT_DELETE_DESC = {
	eng: "Do you really want to delete this account? This process cannot be undone",
	bhs: "Apakah Anda yakin untuk menghapus akun ini? Proses ini tidak dapat dibatalkan kembali",
};
export const ACCOUNT_DELETE_SUCCESS = {
	eng: "Account has been deleted.",
	bhs: "Akun dihapus.",
};
export const DEACTIVATE_LABEL = {
	eng: "Deactivate",
	bhs: "Nonaktifkan",
};
export const ACCOUNT_DEACTIVATE = {
	eng: "Deactivate Account",
	bhs: "Nonaktifkan Akun",
};
export const ACCOUNT_ACTIVATE = {
	eng: "Activate Account",
	bhs: "Nonaktifkan Akun",
};
export const ACCOUNT_DEACTIVATE_DESC = {
	eng: "Are you sure want to deactivate this account? This user will no longer have access to Portal Kerja job recruitment.",
	bhs: "Apakah Anda yakin ingin menonaktifkan akun ini? Pengguna ini tidak lagi memiliki akses ke Portal Kerja.",
};
export const ACCOUNT_ACTIVATE_SUCCESS = {
	eng: "Account has been activated",
	bhs: "Akun telah diaktifkan",
};
export const ACCOUNT_DEACTIVATE_SUCCESS = {
	eng: "Account has been deactivated",
	bhs: "Akun telah dinonaktifkan",
};

// Dialog
export const DIALOG_CONFIRM_TITLE = {
	eng: "Are You Sure?",
	bhs: "Apakah Anda yakin?",
};
export const DIALOG_CONFIRM_BODY = {
	eng: "Are you sure you want to delete this item?",
	bhs: "Anda yakin ingin menghapus ini?",
};

// Candidate Management

// Interview Schedule
export const MODAL_COPY_TIME = {
	eng: "Select the date(s) you want to copy time",
	bhs: "Pilih tanggal yang ingin Anda salin waktu-nya.",
};

// Job Poster Signup
export const DRAG_FILE_HERE = {
	eng: "Drag file here to upload or",
	bhs: "Tarik berkas ke sini untuk unggah atau",
};
export const FILE_UPLOAD_COMPANY_RULES = {
	eng: "PNG or PDF format. Maximum 20mb",
	bhs: "format PNG atau PDF. Maksimum 20 MB",
};
export const NEXT_STEP = {
	eng: "Next Step",
	bhs: "Selanjutnya",
};
export const BACK = {
	eng: "Back",
	bhs: "Kembali",
};
export const SKIP = {
	eng: "Skip",
	bhs: "Selanjutnya",
};
export const VERIFY_YOUR_ACCOUNT = {
	eng: "Verify your account",
	bhs: "Verifikasi akun Anda",
};
export const UPLOAD_TDP_SIUP = {
	eng: "Upload TDP/SIUP",
	bhs: "Unggah TDP/SIUP",
};
export const CANCEL_UPLOADING = {
	eng: "Cancel",
	bhs: "Batalkan",
};
export const FILE_FORMAT_NOT_SUPPORTED = {
	eng: "The file format is not supported",
	bhs: "Format berkas tidak didukung",
};
export const FILE_TOO_LARGE = {
	eng: "File is too large. Maximum file size is 20MB",
	bhs: "File terlalu besar. Ukuran file maksimum adalah 20MB",
};
export const FILE_ONLY_5MB = {
	eng: "File is too large. Maximum file size is 5MB",
	bhs: "File terlalu besar. Ukuran file maksimum adalah 5MB",
};
export const UPLOAD = {
	eng: "Upload",
	bhs: "Unggah",
};
export const FILE_UPLOADED_SUCCESS = {
	eng: "File uploaded succesfully",
	bhs: "Berkas berhasil diunggah",
};
export const FILE_DELETED_SUCCESS = {
	eng: "File deleted succesfully",
	bhs: "Berkas berhasil dihapus",
};

export const SIGN_UP = {
	eng: "Sign Up",
	bhs: "Mendaftar",
};
export const ONBOARDING_1 = {
	eng: "Get Inspired",
	bhs: "Terinspirasi",
};
export const ONBOARDING_1_DESC = {
	eng: "Receive career advice, tips, announcements, and other commercial emails from Portal Kerja",
	bhs: "Menerima saran karier, tips, pengumuman, dan email komersial lainnya dari Portal Kerja",
};
export const ONBOARDING_2 = {
	eng: "Connect the best candidates and employers",
	bhs: "Hubungkan kandidat dan majikan terbaik",
};
export const ONBOARDING_2_DESC = {
	eng: "Increase opportunity to find your dream job with Portal Kerja",
	bhs: "Tingkatkan kesempatan untuk menemukan pekerjaan impian Anda dengan Portal Kerja",
};
export const ONBOARDING_3 = {
	eng: "One profile, million opportunities",
	bhs: "Satu profil, juta peluang",
};
export const ONBOARDING_3_DESC = {
	eng: "Complete profile easily to be noticed by employers",
	bhs: "Profil lengkap dengan mudah diperhatikan oleh majikan",
};
export const CHECK_YOUR_EMAIL = {
	eng: "Check your email",
	bhs: "Periksa email Anda",
};
export const RESEND_IN = {
	eng: "Resend in",
	bhs: "Ulang",
};
export const SIGNUP_EMAIL_SENT_1 = {
	eng: "We have sent an email to ",
	bhs: "Kami telah mengirim email ke ",
};
export const SIGNUP_EMAIL_SENT_2 = {
	eng: " verify your account",
	bhs: " verifikasi akun anda",
};
export const JOBS_LABEL = {
	eng: "Jobs",
	bhs: "Pekerjaan",
};
// Reset Password
export const HELP_TEXT_NP = {
	eng: "Set your new password",
	bhs: "Setel Kata Sandi Baru Anda",
};
export const PASSWORD_RESET_DESC = {
	eng: "Your password has been successfully reset",
	bhs: "Kata sandi Anda telah berhasil diatur ulang",
};
export const CONTINUE = {
	eng: "Continue",
	bhs: "Melanjutkan",
};
export const RESET_PASSWORD_FAILED_1 = {
	eng: "Password / Confirm Password are mandatory fields",
	bhs: "Kata Sandi / Konfirmasi Kata Sandi adalah bidang wajib",
};
export const RESET_PASSWORD_FAILED_2 = {
	eng: "Password / Confirm Password do not match",
	bhs: "Kata Sandi / Konfirmasi Kata sandi tidak cocok",
};
export const INPUT = {
	eng: "Input",
	bhs: "Ketik",
};
export const INPUT_2 = {
	eng: "Input",
	bhs: "Masukkan",
};
export const NAME = {
	eng: "Name",
	bhs: "Nama",
};
export const OPTIONAL = {
	eng: "Optional",
	bhs: "Opsional",
};
export const REGISTERED_BUSINESS_NAME_PLACEHOLDER = {
	eng: "Example: PT Ceria",
	bhs: "Contoh: PT Ceria",
};
export const POSTED_BY = {
	eng: "Posted by",
	bhs: "Dipost oleh",
};
export const DRAFT_BY = {
	eng: "Drafted by",
	bhs: "Dibuat oleh",
};
export const FROM = {
	eng: "from",
	bhs: "dari",
};
export const POST_ENDS_ON = {
	eng: "Post ends on",
	bhs: "Posting berakhir pada",
};
export const LAST_UPDATED_ON = {
	eng: "Last updated on",
	bhs: "Terakhir diperbarui pada",
};
export const VIEWS = {
	eng: "Views",
	bhs: "Melihat",
};
export const APPLIED = {
	eng: "Applies",
	bhs: "Melamar",
};
export const UNRATED = {
	eng: "Unrated",
	bhs: "Belum dinilai",
};
export const GOOD_FIT = {
	eng: "Good Fit",
	bhs: "Cocok",
};
export const PERHAPS = {
	eng: "Perhaps",
	bhs: "Mungkin",
};
export const UNFIT = {
	eng: "Unfit",
	bhs: "Tidak cocok",
};
export const VIEW = {
	eng: "View",
	bhs: "Lihat",
};
export const EDIT = {
	eng: "Edit",
	bhs: "Ubah",
};
export const DEACTIVATE = {
	eng: "Deactivate",
	bhs: "Nonaktifkan",
};
export const DEACTIVATE_BTN_LABEL = {
	eng: "Yes, deactivate",
	bhs: "Ya, nonaktifkan",
};
export const DELETE_BTN_LABEL = {
	eng: "Delete",
	bhs: "Ya, hapus",
};
export const DEACTIVATE_SUCCESS_MSG = {
	eng: "Deactivated job ad successed",
	bhs: "Iklan pekerjaan berhasil dinonaktifkan",
};
export const SCHEDULED_ON = {
	eng: "Scheduled on",
	bhs: "Dijadwalkan pada",
};
export const DELETE_JOB_AD_SUCCESS_MSG = {
	eng: "Deleted job ad successed",
	bhs: "Iklan pekerjaan berhasil dihapus",
};
export const DELETE_JOB_AD_DESCRIPTION = {
	eng: "You will lose all of the data in this Job Ad draft",
	bhs: "Anda akan kehilangan semua data di draf iklan lowongan ini",
};
export const COLLABORATE = {
	eng: "Collaborate",
	bhs: "Kolaborasi",
};
export const SEARCH_JOB_AD_PLACEHOLDER = {
	eng: "Search job ad",
	bhs: "Cari iklan lowongan kerja",
};
export const SORT_BY = {
	eng: "Sort By",
	bhs: "Urutkan berdasarkan",
};
export const SORT_BY_JOB_TITLE = {
	eng: "Sort By Job title",
	bhs: "Urutkan berdasarkan jabatan",
};
export const POSTING_DATE = {
	eng: "Posting Date",
	bhs: "Tanggal penayangan",
};
export const EXPIRY_DATE = {
	eng: "Expiry date",
	bhs: "Tanggal kadaluarasa",
};
export const OLDEST_TO_NEWEST = {
	eng: "Oldest to Newest",
	bhs: "Terlama ke Terbaru",
};
export const NEWEST_TO_OLDEST = {
	eng: "Newest to Oldest",
	bhs: "Terbaru ke Terlama",
};
export const A_TO_Z = {
	eng: "A to Z",
	bhs: "A ke Z",
};
export const Z_TO_A = {
	eng: "Z to A",
	bhs: "Z ke A",
};
export const REPOST = {
	eng: "Repost",
	bhs: "Tayangkan Ulang",
};
export const EMPTY_JOB_ADS_TITLE = {
	eng: "You don't have any job post yet",
	bhs: "Anda belum memiliki iklan pekerjaan",
};
export const NO_PERMISSION_JOB_AD_TITLE = {
	eng: "No Permission",
	bhs: "Anda tidak memiliki izin",
};
export const NO_PERMISSION_JOB_AD_DESC = {
	eng: "You don't have permission to access this menu.",
	bhs: "Anda tidak memiliki izin untuk mengakses menu ini.",
};
export const EMPTY_JOB_ADS_DESCRIPTION = {
	eng: 'Click "Post Job" button to start create a new Job Ad',
	bhs: 'Klik tombol "Pasang Iklan" untuk memulai membuat Iklan Pekerjaan',
};
export const BACK_TO_JOB_AD = {
	eng: "Back to job ad",
	bhs: "Kembali ke Job Ad",
};
export const NO_CANDIDATE = {
	eng: "No candidate",
	bhs: "Belum Ada Kandidat",
};
export const NO_CANDIDATE_DESC = {
	eng: "No candidate apply for this job ad.",
	bhs: "Belum ada kandidat yang melamar untuk iklan pekerjaan ini.",
};
export const NO_CANDIDATE_DESC_1 = {
	eng: "Select a candidate on the candidate list.",
	bhs: "Pilih kandidat pada daftar kandidat.",
};
export const SEARCH_STAFF_NAME = {
	eng: "Search staff name",
	bhs: "Cari nama staff",
};
export const STAFF_LIST = {
	eng: "Staff list",
	bhs: "Daftar staff",
};
export const ADD_COLLABORATOR_SUCCESS = {
	eng: "Collaborators have been updated",
	bhs: "Kolaborator berhasil diperbarui",
};
export const EMPTY_STATE_STAFF = {
	eng: "There is no staff.",
	bhs: "Belum ada staff untuk saat ini",
};
export const OWNER = {
	eng: "Owner",
	bhs: "Pemilik",
};
export const ARE_YOU_SURE = {
	eng: "Are you sure",
	bhs: "Apakah Anda yakin",
};
export const DEACTIVATE_DESCRIPTION = {
	eng: "Deactivate the Job Ad means Job Seeker won't be able to see and search this Job Ad, and the remaining days of the Job Ad will be gone.",
	bhs: "Menonaktifkan iklan lowongan berarti pencari kerja tidak dapat melihat dan mencari iklan lowongan ini, dan sisa hari iklan lowongan tersebut akan hilang.",
};
export const NOTIFICATION = {
	eng: "Notification",
	bhs: "Notifikasi",
};
export const DUPLICATE = {
	eng: "Duplicate",
	bhs: "Duplikasi",
};
export const DUPLICATE_DESCRIPTION = {
	eng: "Duplicate Job Ad means that the Job Ad will be duplicated as a new Job Ad draft. All of the Job Ad data can still be edited. Candidates who applied to the previous Job Ad will not be carried over to the new Job Ad.",
	bhs: "Duplikasi Iklan Lowongan Kerja artinya Iklan tersebut akan diduplikasi sebagai draf Iklan Lowongan baru. Semua data iklan lowongan tetap bisa diubah. Kandidat yang  sudah melamar ke Iklan lowongan sebelumnya tidak akan terbawa ke Iklan Lowongan yang baru.",
};
export const CARRY_OVER = {
	eng: "Carry Over",
	bhs: "Carry Over",
};
export const CARRY_OVER_DESCRIPTION = {
	eng: "Carry Over means that the previous  Job Ad will be continued to be published as a new Job Ad; however, candidates who applied to the previous Job Ad will be carried over to the new Job Ad.",
	bhs: "Carry Over artinya Iklan Lowongan sebelumnya akan dilanjutkan untuk dipublikasikan sebagai Iklan Lowongan baru, tetapi kandidat yang sudah melamar ke Iklan Lowongan sebelumnya akan dibawa ke Iklan Lowongan yang baru.",
};
export const REPOST_JOB_AD_MSG_SUCCESSFULLY = {
	eng: "Job ad reposted successfully",
	bhs: "Iklan lowongan pekerjaan berhasil ditayangkan kembali",
};

// Job Ad Creation
export const JOB_TITLE_HEAD = {
	eng: "What's the job title?",
	bhs: "Apa nama jabatan pekerjaan?",
};
export const TYPE = {
	eng: "Type",
	bhs: "Tipe",
};
export const TYPE_2 = {
	eng: "Type",
	bhs: "Ketik",
};
export const RETYPE = {
	eng: "Retype",
	bhs: "Ketik Ulang",
};
export const JOB_TITLE = {
	eng: "Job Title",
	bhs: "Jabatan",
};
export const INPUT_JOB_TITLE = {
	eng: "Type job title",
	bhs: "Ketik nama jabatan",
};
export const JOB_CITY_HEAD = {
	eng: "Where is your new employee going to work?",
	bhs: "Di mana karyawan baru Anda akan bekerja?",
};
export const INPUT_CITY = {
	eng: "Type city",
	bhs: "Ketik nama kota",
};
export const DISTRICT = {
	eng: "District",
	bhs: "Kecamatan",
};
export const INPUT_DISTRICT = {
	eng: "Type district",
	bhs: "Ketik nama kecamatan",
};
export const NO_LOCATION_TEXT = {
	eng: "We don't recognize this location. Review and try again.",
	bhs: "Kami tidak mengenali lokasi ini. Mohon periksa dan coba lagi",
};
export const SAVE_CONTINUE_LABEL = {
	eng: "Save & Continue",
	bhs: "Simpan & Lanjut",
};
export const PREVIOUS_LABEL = {
	eng: "Previous",
	bhs: "Kembali",
};
export const WORK_MODEL_HEAD = {
	eng: "What is the work mode & experience for candidate?",
	bhs: "Apa mode kerja & pengalaman yang disyaratkan untuk kandidat?",
};
export const YEAR = {
	eng: "Year",
	bhs: "Tahun",
};
export const YEARS = {
	eng: "Years",
	bhs: "Tahun",
};
export const INTERMEDIATE = {
	eng: "Intermediate",
	bhs: "Staff",
};
export const SENIOR = {
	eng: "Senior",
	bhs: "Senior",
};
export const JOB_TYPE_HEAD = {
	eng: "What is the job type?",
	bhs: "Apa tipe pekerjaannya?",
};
export const WORKING_HOURS_PER_WEEK = {
	eng: "Working hours per week",
	bhs: "Jam kerja per minggu",
};
export const WORKING_HOUR_HINT_TEXT = {
	eng: "Enter a valid number (maximum of 168)",
	bhs: "Masukkan nomor yang valid (maksimal 168 karakter)",
};
export const CONTRACT_DURATION_TEXT = {
	eng: "How long is the contract duration?",
	bhs: "Berapa lama durasi kontrak yang ditawarkan?",
};
export const AMOUNT = {
	eng: "Amount",
	bhs: "Jumlah",
};
export const TYPE_AMOUNT = {
	eng: "Type number",
	bhs: "Tulis angka",
};
export const PERIOD = {
	eng: "Period",
	bhs: "Periode",
};
export const DAYS = {
	eng: "Day(s)",
	bhs: "Hari",
};
export const DAYS_2 = {
	eng: "Days",
	bhs: "Hari",
};
export const WEEKS = {
	eng: "Week(s)",
	bhs: "minggu",
};
export const WEEKS_2 = {
	eng: "Weeks",
	bhs: "minggu",
};
export const MONTHS = {
	eng: "Month(s)",
	bhs: "Bulan",
};
export const SALARY_HEAD = {
	eng: "What is the salary for candidate?",
	bhs: "Berapa gaji yang ditawarkan untuk kandidat?",
};
export const DISPLAY_SALARY_TEXT = {
	eng: "Display salary to job seeker",
	bhs: "Munculkan gaji untuk pencari kerja",
};
export const TO = {
	eng: "to",
	bhs: "ke",
};
export const SHOW_SALARY_BY = {
	eng: "Show salary by",
	bhs: "Munculkan gaji dalam",
};
export const CURRENCY = {
	eng: "Currency",
	bhs: "Mata uang",
};
export const RANGE = {
	eng: "Range",
	bhs: "Rentang",
};
export const STARTING_AMOUNT = {
	eng: "Starting amount",
	bhs: "Jumlah minimal",
};
export const MAX_AMOUNT = {
	eng: "Maximum amount",
	bhs: "Jumlah maksimal",
};
export const EXACT_AMOUNT = {
	eng: "Exact amount",
	bhs: "Jumlah pasti",
};
export const PER_HOUR = {
	eng: "Per hour",
	bhs: "Per jam",
};
export const PER_DAY = {
	eng: "Per day",
	bhs: "Per hari",
};
export const PER_WEEK = {
	eng: "Per week",
	bhs: "Per minggu",
};
export const PER_MONTH_2 = {
	eng: "Per month",
	bhs: "Per bulan",
};
export const PER_YEAR = {
	eng: "Per year",
	bhs: "Per tahun",
};
export const SALARY_DISPLAY_WARNING = {
	eng: "Salary is not displayed to job seeker",
	bhs: "Gaji tidak ditampilkan ke pencari kerja",
};
export const JOB_DETAIL_HEAD = {
	eng: "What are the job details?",
	bhs: "Apa rincian pekerjaannya?",
};
export const JOB_REQUIREMENT_QUALIFICATION = {
	eng: "Job Requirements/Qualification",
	bhs: "Persyaratan Kerja/Kualifikasi",
};
export const JOB_REQUIREMENT_DESC = {
	eng: "Describe the requirements or qualification to be fulfilled by the candidates for this job",
	bhs: "Deskripsikan persyaratan atau kualifikasi yang perlu dipenuhi kandidat untuk pekerjaan ini",
};
export const JOB_REQUIREMENT_PLACEHOLDER = {
	eng: "Job Qualification",
	bhs: "Kualifikasi Pekerjaan",
};
export const JOB_DESC = {
	eng: "Job Description",
	bhs: "Deskripsi Pekerjaan",
};
export const JOB_DESC_TEXT = {
	eng: "Describe the responsibility of this job, required work expereince, skills, or education",
	bhs: "Deskripsikan tanggung jawab dari pekerjaan ini, pengalaman, keahlian, atau pendidikan  yang dibutuhkan",
};
export const JOB_DETAIL_ERR = {
	eng: "Add {0} with a minimum of {1} characters",
	bhs: "Tambahkan {0} dengan minimal {1} karakter",
};
export const JOB_BENEFIT = {
	eng: "Benefit:",
	bhs: "Benefit:",
};
export const JOB_BENEFIT_EDITOR_HEADER = {
	eng: "Job Benefit",
	bhs: "Benefit Pekerjaan",
};
export const JOB_BENEFIT_DESC = {
	eng: "Describe the job benefit",
	bhs: "Deskripsikan benefit pekerjaan",
};
export const URGENCY_LABEL = {
	eng: "Urgency",
	bhs: "Urgensi",
};
export const ESTIMATION = {
	eng: "Estimation",
	bhs: "Estimasi",
};
export const URGENCY_DESC = {
	eng: "How quickly do you want to hire?",
	bhs: "Seberapa cepat Anda ingin merekrut?",
};
export const MORE_THAN_4_WEEKS = {
	eng: "More than 4 weeks",
	bhs: "Lebih dari 4 minggu",
};
export const HIRES = {
	eng: "Hires",
	bhs: "Jumlah Perekrutan",
};
export const HIRES_DESC_1 = {
	eng: "How many people do you want to hire for this opening?",
	bhs: "Berapa orang yang ingin Anda rekrut?",
};
export const HIRES_DESC_2 = {
	eng: "The more opening you have, the more applications you'll need",
	bhs: "Makin banyak pembukaan rekrutmen yang dilakukan, makin banyak lamaran yang dibutuhkan",
};
export const NUMBER = {
	eng: "Number",
	bhs: "Nomor",
};
export const PRESCREENING_HEAD = {
	eng: "Easy Pre-screening",
	bhs: "Easy Pre-screening",
};
export const SCREENING_QUESTION = {
	eng: "Screening Question",
	bhs: "Pertanyaan screening",
};
export const QUESTION_RECOMMEND_TEXT = {
	eng: "We recommend adding 3 or more questions. Applicants must answer each question",
	bhs: "Kami merekomendasikan menambahkan 3 pertanyaan atau lebih. Pelamar harus menjawab setiap pertanyaan.",
};
export const ADD_SCREENING_QUESTION = {
	eng: "Add screening question:",
	bhs: "Tambah pertanyaan screening:",
};
export const EDUCATION = {
	eng: "Education",
	bhs: "Pendidikan",
};
export const INTERVIEW_AVAILABILITY = {
	eng: "Interview Availability",
	bhs: "Ketersediaan wawancara",
};
export const Language = {
	eng: "Language",
	bhs: "Bahasa",
};
export const TRAVEL_WILLINGNESS = {
	eng: "Willingness to travel",
	bhs: "Kesediaan untuk bepergian",
};
export const SHIFT_AVAILABILITY = {
	eng: "Shift Availability",
	bhs: "Ketersediaan shift",
};
export const LICENSE_CERT = {
	eng: "License/certification",
	bhs: "Lisensi/Sertifikasi",
};
export const CREATE_CUSTOM_QUES = {
	eng: "Create custom question",
	bhs: "Buat pertanyaan kustom",
};
export const CUSTOM_QUESTION = {
	eng: "Custom Question",
	bhs: "Pertanyaan Kustom",
};
export const WRITE_CUSTOM_QUESTION = {
	eng: "Write a custom screening question.",
	bhs: "Buat pertanyaan screening kustom",
};
export const SELECT_QUESTION_TYPE = {
	eng: "Please select one of the question types provided.",
	bhs: "Silakan pilih satu tipe pertanyaan yang disediakan di bawah ini.",
};
export const TYPE_QUESTION = {
	eng: "Type of question",
	bhs: "Tipe pertanyaan",
};
export const YES_NO = {
	eng: "Yes/No",
	bhs: "Ya Tidak",
};
export const NUMERICAL = {
	eng: "Numerical",
	bhs: "Numerik",
};
export const PROGRESSIVE_CHOICE = {
	eng: "Progressive Choice",
	bhs: "Pilihan Bertingkat",
};
export const SINGLE_SELECTION = {
	eng: "Single Selection",
	bhs: "Pilihan tunggal",
};
export const MULTI_SELECTION = {
	eng: "Multi Selection",
	bhs: "Pilihan banyak",
};
export const FREE_INPUT = {
	eng: "Free Input",
	bhs: "Teks bebas",
};
export const FILL_IN_THE_BLANK = {
	eng: "Fill in the blank",
	bhs: "Isian singkat",
};
export const TRUE_FALSE = {
	eng: "True/False",
	bhs: "Benar/Salah",
};
export const SAMPLE_QUESTION_YES_NO = {
	eng: "e.g. How good is your understanding of journal in Accounting concept?",
	bhs: "contoh: Seberapa baik pemahaman Anda mengenai jurnal pada konsep akunting?",
};
export const SAMPLE_QUESTION_NUMERICAL = {
	eng: "e.g. How many years of experience do you have with Figma?",
	bhs: "contoh: Berapa tahun pengalaman Anda menggunakan Figma?",
};
export const SAMPLE_QUESTION_SINGLE_SELECT = {
	eng: "e.g. What is your personality type?",
	bhs: "contoh: Apa tipe kepribadian Anda?",
};
export const SAMPLE_QUESTION_MULTI_SELECT = {
	eng: "e.g What kind of programming language do you familiar with?",
	bhs: "contoh: Apa jenis bahasa pemrograman yang familiar dengan kamu?",
};
export const SAMPLE_QUESTION_FREE_INPUT = {
	eng: "e.g Why do you want to resign from your current job? ",
	bhs: "contoh: Mengapa Anda ingin keluar dari pekerjaan Anda saat ini?",
};
export const IDEAL_ANSWER = {
	eng: "Ideal answer",
	bhs: "Jawaban ideal",
};
export const YES_NO_INFO = {
	eng: "Yes/No question is a type of question which ask the job seeker to answer Yes or No only.",
	bhs: "Pertanyaan Ya/Tidak adalah tipe pertanyaan yang meminta kandidat untuk menjawab Ya atau Tidak.",
};
export const NUMERICAL_INFO = {
	eng: "Numerical question is a type of question which ask the job seeker to input a specific number.",
	bhs: "Pertanyaan Numerik adalah tipe pertanyaan yang meminta kandidat untuk memasukkan angka spesifik.",
};
export const PROGRESSIVE_INFO = {
	eng: "Progressive Choice is a set of choice that starts from the lowest hierarchy to the highest hierarchy. For example: 25%, 50%, 75%, 100% OR education level (from elementary, middle, high school, bachelor, master, doctorate",
	bhs: "Pilihan bertingkat adalah pilihan yang dimulai dari hierarki terendah ke tertinggi. Contohnya: 25%, 50%, 75%, 100% atau jenjang pendidikan (dari SD, SMP, SMA, sarjana, magister, dan doktoral).",
};
export const SINGLE_SELECT_INFO = {
	eng: "Single selection is a type of question which ask the job seeker to select only one option from the given multiple options",
	bhs: "Pilihan tunggal adalah tipe pertanyaan yang meminta kandidat untuk memilih hanya satu pilihan dari beragam pilihan yang diberikan.",
};
export const MULTI_SELECT_INFO = {
	eng: "Multi selection is a type of question which ask the job seeker to select any option (more than 1 is allowed) they may feel fit from the given options",
	bhs: "Pilihan banyak adalah tipe pertanyaan yang meminta kandidat untuk memilih pilihan apa saja (lebih dari 1 pilihan diperbolehkan) yang mereka rasa cocok.",
};
export const FREE_INPUT_INFO = {
	eng: "Free input is a type of question which ask the job seeker to type free text based on the given question",
	bhs: "Teks bebas adalah tipe pertanyaan yang meminta kandidat untuk memasukkan teks bebas berdasarkan pertanyaan yang diberikan.",
};
export const FREE_INPUT_WARN = {
	eng: "Free Input question won't be visible in the filter part",
	bhs: "Pertanyaan Teks bebas tidak akan muncul di bagian filter",
};
export const SELECTION_OPTIONS = {
	eng: "Options",
	bhs: "Pilihan",
};
export const ADD_OPTION = {
	eng: "Add Option",
	bhs: "Tambah Pilihan",
};
export const SHOW_PREVIEW = {
	eng: "Show Preview",
	bhs: "Tampilkan pratinjau",
};
export const LIMIT_TYPE = {
	eng: "Limit type",
	bhs: "Tipe batasan",
};
export const MAXIMUM = {
	eng: "Maximum",
	bhs: "Maksimal",
};
export const MINIMUM = {
	eng: "Minimum",
	bhs: "Minimal",
};
export const EMP_ASSIST = {
	eng: "Employer Assist",
	bhs: "Employer Assist",
};
export const EMP_ASSIST_DSEC = {
	eng: "Portal Kerja will decline candidates you're not interested in on your behalf",
	bhs: "Portal Kerja akan menolak kandidat yang tidak Anda minati",
};
export const NO_INTEREST_TEXT = {
	eng: "If I don't show interest, automatically decline each candidate after:",
	bhs: "Jika saya tidak menunjukkan ketertarikan, tolak kandidat otomatis setelah:",
};
export const EXAMPLE_ABBV = {
	eng: "Ex:",
	bhs: "Contoh:",
};
export const CUSTOM_DAYS_ERR = {
	eng: "Please provide custom days",
	bhs: "Harap berikan hari khusus",
};
export const SCHEDULE = {
	eng: "Set Post Schedule",
	bhs: "Atur Jadwal Tayang",
};
export const SELECT_SCHEDULE_POST = {
	eng: "Select whether you want to post it now or set specific time to publish it automatically in future",
	bhs: "Pilih antara apakah Anda ingin lowongan ini ditayangkan sekarang atau atur jadwal untuk ditayangkan di waktu yang akan datang secara otomatis",
};
export const NOW = {
	eng: "Now",
	bhs: "Sekarang",
};
export const SELECT_SCHEDULE = {
	eng: "Select Schedule",
	bhs: "Pilih Jadwal",
};
export const DATE = {
	eng: "Date",
	bhs: "Tanggal ditayangkan",
};
export const TIME_TO_POST = {
	eng: "Time to post",
	bhs: "Jam ditayangkan",
};
export const POST_AND_PREVIEW_JOB = {
	eng: "Preview & Post Job",
	bhs: "Pratinjau & Tayangkan Pekerjaan",
};
export const PREVIEW = {
	eng: "Preview",
	bhs: "Pratinjau",
};
export const SAVE_JOB = {
	eng: "Save Job",
	bhs: "Simpan Pekerjaan",
};
export const MUST_HAVE = {
	eng: "Must Have",
	bhs: "Wajib Dimiliki",
};
export const BLANK_FIELD_ERR = {
	eng: "This cannot be blank",
	bhs: "Tidak boleh kosong",
};
export const APPLY_TO = {
	eng: "Apply to",
	bhs: "Lamar ke",
};
export const ADDITIONAL_QUESTIONS = {
	eng: "Additional Questions",
	bhs: "Pertanyaan tambahan",
};
export const SUCCESS = {
	eng: "Success",
	bhs: "Berhasil",
};
export const WARNING = {
	eng: "Warning",
	bhs: "Peringatan",
};
export const ERROR = {
	eng: "Error",
	bhs: "Kesalahan",
};
export const POSTED_DAY_AGO = {
	eng: "1 day ago",
	bhs: "1 hari lalu",
};
export const COMPANY_MANAGEMENT = {
	eng: "Company Management",
	bhs: "Manajemen Perusahaan",
};
export const VISIT_PORTAL_KERJA_LBL = {
	eng: "Switch to job seeker",
	bhs: "Ganti sebagai kandidat",
};
export const SWITCH_HRMS_MODULE = {
	eng: "Switch HRMS Module",
	bhs: "Ganti sebagai HRMS",
};
export const JOB_TITLE_FILTER_LBL = {
	eng: "Job Title",
	bhs: "Jabatan",
};
export const COMING_SOON = {
	eng: "Coming soon",
	bhs: "Segera hadir",
};
export const SOON = {
	eng: "Soon",
	bhs: "Segera",
};
export const APPLY_FILTER = {
	eng: "Apply filter",
	bhs: "Terapkan Filter",
};
export const SUCCESS_MOVE_TO_UNFIT = {
	eng: "Status moved to unfit successfully",
	bhs: "Status berhasil dipindahkan ke tidak cocok",
};
export const STATUS_SUCCESSFULLY_CHANGED = {
	eng: "Status successfully changed",
	bhs: "Status berhasil diubah",
};
export const SUCCESS_MOVE_TO_GOODFIT = {
	eng: "Status moved to good fit successfully",
	bhs: "Status berhasil dipindahkan ke cocok",
};
export const SUCCESS_MOVE_TO_PERHAPS = {
	eng: "Status moved to perhaps successfully",
	bhs: "Status berhasil dipindahkan ke mungkin",
};
export const SUCCESS_MOVE_TO_REJECT = {
	eng: "Successfully moved to reject",
	bhs: "Berhasil dipindahkan ke reject",
};
export const LAST_UPDATED_RESUME = {
	eng: "Last update resume",
	bhs: "Resume terakhir diperbarui",
};
export const SALARY_FILTER = {
	eng: '<span class="question-filter-title__bold">Expected</span> <span>salary filter</span>',
	bhs: 'Filter gaji <span class="question-filter-title__bold">yang diharapkan</span>',
};
export const MAXIMUM_VALUE = {
	eng: "Maximum Value",
	bhs: "Nilai Maksimum",
};
export const MINIMUM_VALUE = {
	eng: "Minimum Value",
	bhs: "Nilai Minimum",
};
export const MUST_HAVE_FILTERS = {
	eng: "Must have filters",
	bhs: "Filter wajib dimiliki",
};
export const NUMBER_MONTHS = {
	eng: "{{number}} months",
	bhs: "{{number}} bulan",
};
export const NUMBER_YEARS = {
	eng: "{{number}} years",
	bhs: "{{number}} tahun",
};
export const YEARS_NUMBER_MONTHS = {
	eng: "{{_year_}} years {{_month_}} months",
	bhs: "{{_year_}} tahun {{_month_}} bulan",
};
export const RANGE_SALARY_ERR = {
	eng: "Minimum salary must be lower than salary maximum amount",
	bhs: "Gaji minimal harus lebih kecil dari gaji maksimal",
};
export const CANDIDATE_INFO_TOOLTIP = {
	eng: "This will filter the candidates who updated their profile within the time specified in the dropdown below. You can select smaller number for recent updated candidates only",
	bhs: "Ini akan menyaring kandidat-kandidat yang memperbarui profil mereka dalam waktu yang ditentukan pada dropdown di bawah. Anda dapat memilih jumlah yang lebih kecil untuk kandidat yang diperbarui baru-baru ini saja",
};
export const PROFILE_DETAILS = {
	eng: "Profile detail",
	bhs: "Detail profil",
};
export const COMMENT = {
	eng: "Comment",
	bhs: "Komentar",
};
export const STATUS_HISTORY = {
	eng: "Status history",
	bhs: "Riwayat Status",
};
export const APPLICATION_HISTORY = {
	eng: "Application history",
	bhs: "Riwayat lamaran",
};
export const EMPLOYEE_HIRED_JOB_AD = {
	eng: "Employee Hired for this Job Ad: {{_1_}}",
	bhs: "Karyawan yang Direkrut untuk lowongan Pekerjaan ini: {{_1_}}",
};
export const VERIFY_YOUR_COMPANY = {
	eng: "Your company has not been verified yet",
	bhs: "Perusahaan Anda belum terverifikasi",
};
export const VERIFY_YOUR_COMPANY_DESC = {
	eng: "You will be able to Post Job Ad after your company is verified.",
	bhs: "Anda dapat memasang iklan pekerjaan setelah perusahaan Anda terverifikasi.",
};
export const UPLOAD_AGAIN = {
	eng: "Upload Again",
	bhs: "Unggah lagi",
};
export const WAITING_VERIFY = {
	eng: "Waiting Confirmation",
	bhs: "Menunggu Konfirmasi",
};
export const WAITING_VERIFY_DESC = {
	eng: "Your documents are being checked.",
	bhs: "Dokumen Anda sedang diperiksa",
};
export const REJECTED = {
	eng: "Rejected",
	bhs: "Ditolak",
};
export const REJECTED_DESC = {
	eng: "Your documents verification is failed.",
	bhs: "Verifikasi dokumen Anda gagal.",
};
export const SET_STATUS = {
	eng: "Set status",
	bhs: "Atur status",
};
export const UNDO = {
	eng: "Undo",
	bhs: "Batalkan",
};
export const PHONE_1 = {
	eng: "Phone 1 :",
	bhs: "Telepon 1 :",
};
export const PHONE_2 = {
	eng: "Phone 2 :",
	bhs: "Telepon 2 :",
};
export const DOWNLOAD_FILE = {
	eng: "Download File",
	bhs: "Unduh Berkas",
};
export const CONTACT_DETAILS = {
	eng: "Contact Details",
	bhs: "Detail Kontak",
};
export const MOVE = {
	eng: "Move",
	bhs: "Pindah",
};
export const FORWARD = {
	eng: "Forward",
	bhs: "Teruskan",
};
export const LEAVE_A_COMMENT = {
	eng: "Leave a comment ...",
	bhs: "Tinggalkan komentar",
};
export const DATE_APPLIED_TIME = {
	eng: "Date applied : {{_time_}}",
	bhs: "Tanggal Dilamar : {{_time_}}",
};
export const EMAIL_COPIED = {
	eng: "Email copied",
	bhs: "Email disalin",
};
export const COPY = {
	eng: "Copy",
	bhs: "Salin",
};
export const DOWNLOAD = {
	eng: "Download",
	bhs: "Unduh",
};
export const NEXT = {
	eng: "Next",
	bhs: "Selanjutnya",
};
export const PREVIOUS = {
	eng: "Previous",
	bhs: "Sebelumnya",
};
export const EMP_ASSIT_EXPLANATION_1 = {
	eng: "Turn the toggle Off will reset the countdown timer that have been running so far.",
	bhs: "Mematikan tombol akan mengatur ulang penghitung waktu mundur yang telah berjalan selama ini.",
};
export const EMP_ASSIT_EXPLANATION_2 = {
	eng: "Turn the toggle On will start the countdown timer together for all candidates that doesn't meet all of the criteria.",
	bhs: "Mengakifkan tombol akan memulai penghitungan waktu mundur secara bersamaan untuk semua kandidat yang tidak sesuai dengan kriteria.",
};
export const QUICK_MOVE_TO_REJECT = {
	eng: "Quick set as Reject",
	bhs: "Tolak secara cepat",
};
export const QUICK_MOVE_TO_UNFIT = {
	eng: "Quick move to unfit",
	bhs: "Pindah ke tidak cocok",
};
export const WARNING_MESSAGE_1 = {
	eng: "The interview method is applied for this Job Ad only and one time setup, once you confirm the method you will not able to change it anymore.",
	bhs: "Sebelumnya",
};
export const CONFIRM = {
	eng: "Confirm",
	bhs: "Konfirmasi",
};
export const MOVE_TOTAL_CANDIDATE = {
	eng: "Move {{_total_}} Candidate",
	bhs: "Pindahkan {{_total_}} Kandidat",
};
export const DOWNLOAD_TOTAL_CANDIDATE = {
	eng: "Download {{_total_}} Candidate",
	bhs: "Unduh {{_total_}} Kandidat",
};
export const FORWARD_TOTAL_CANDIDATE = {
	eng: "Forward {{_total_}} Candidate",
	bhs: "Teruskan {{_total_}} Kandidat",
};
export const PROSPECT = {
	eng: "Prospect",
	bhs: "Prospek",
};
export const SHORTLIST = {
	eng: "Shortlist",
	bhs: "Terpilih",
};
export const INTERVIEW = {
	eng: "Interview",
	bhs: "Wawancara",
};
export const OFFERED = {
	eng: "Offered",
	bhs: "Ditawarkan",
};
export const HIRED = {
	eng: "Hired",
	bhs: "Direkrut",
};
export const INTERVIEW_SCHEDULE_METHOD = {
	eng: "Interview Schedule Method",
	bhs: "Metode Penjadwalan Wawancara",
};
export const PLEASE_SELECT_INTERVIEW_SCHEDULE_METHOD = {
	eng: "Please select the interview schedule method",
	bhs: "Silakan pilih metode penjadwalan wawancara",
};
export const YES_IM_SURE = {
	eng: "Yes, I'm sure",
	bhs: "Ya, saya yakin",
};
export const DOWNLOAD_RESUME_ONLY = {
	eng: "Download resume only",
	bhs: "Unduh resume",
};
export const DOWNLOAD_DESCRIPTION_1 = {
	eng: "The file that will be downloaded is just resume file.",
	bhs: "Berkas yang akan diunduh hanya resume.",
};
export const DOWNLOAD_ALL_FILES = {
	eng: "Download all files",
	bhs: "Unduh semua berkas",
};
export const DOWNLOAD_DESCRIPTION_2 = {
	eng: "The files to be downloaded are all document files for each candidate.",
	bhs: "The files to be downloaded are all document files for each candidate.",
};
export const SEARCH_CANDIDATE_NAME = {
	eng: "Search candidate name",
	bhs: "Cari nama kandidat",
};
export const CANDIDATE_MOVE_SUCCESSFULLY = {
	eng: "Candidates moved to desired category successfully",
	bhs: "Kandidat berhasil berpindah ke kategori yang diinginkan",
};
export const SEND_CANDIDATE_LINK_SUCCESS = {
	eng: "Send candidate link was success.",
	bhs: "Kirim link kandidat berhasil.",
};
export const POSTED = {
	eng: "Posted",
	bhs: "Tayang",
};
export const URGENCYY = {
	eng: "Urgency",
	bhs: "Urgensi",
};
export const ONE_WEEK = {
	eng: "1 Week",
	bhs: "1 Minggu",
};
export const APPLICANT = {
	eng: "Applicants",
	bhs: "Pelamar",
};
export const EXPERIENCE_ENTRY = {
	eng: "Entry Level",
	bhs: "Tingkat Masuk",
};
export const EXPERIENCE_ASSOCIATE = {
	eng: "Associate",
	bhs: "Kolega",
};
export const EXPERIENCE_INTERMEDIATE = {
	eng: "Intermediate",
	bhs: "Menengah",
};
export const EXPERIENCE_SENIOR = {
	eng: "Senior",
	bhs: "Atasan",
};
export const EXPERIENCE_DIRECTOR = {
	eng: "Director",
	bhs: "Direktur",
};
export const EXPERIENCE_EXECUTIVE = {
	eng: "Executive",
	bhs: "Pelaksana",
};
export const JOB_TYPE_FT = {
	eng: "Full Time",
	bhs: "Penuh Waktu",
};
export const JOB_TYPE_PT = {
	eng: "Part Time",
	bhs: "Paruh Waktu",
};
export const JOB_TYPE_FREEL = {
	eng: "Freelance",
	bhs: "Lepas",
};
export const JOB_TYPE_CONTRACT = {
	eng: "Contract",
	bhs: "Kontrak",
};
export const JOB_TYPE_INTERNSHIP = {
	eng: "Internship",
	bhs: "Magang",
};
export const JOB_TYPE_TEMPORARY = {
	eng: "Temporary",
	bhs: "Sementara",
};
export const URGENCY_1_TO_3 = {
	eng: "1 to 3 days",
	bhs: "1 sampai 3 hari",
};
export const URGENCY_3_TO_7 = {
	eng: "3 to 7 days",
	bhs: "3 sampai 7 hari",
};
export const URGENCY_1_TO_2 = {
	eng: "1 to 2 weeks",
	bhs: "1 sampai 2 minggu",
};
export const URGENCY_2_TO_4 = {
	eng: "2 to 4 weeks",
	bhs: "2 sampai 4 minggu",
};
export const URGENCY_MORE_4 = {
	eng: "More than 4 weeks",
	bhs: "Lebih dari 4 minggu",
};
export const WORK_MODEL_HYBRID = {
	eng: "Hybrid",
	bhs: "Hibrida",
};
export const WORK_MODEL_ONSITE = {
	eng: "Onsite",
	bhs: "Ditempat",
};
export const WORK_MODEL_REMOTE = {
	eng: "Remote",
	bhs: "Jarak Jauh",
};
export const SAVED = {
	eng: "Saved",
	bhs: "Disimpan",
};
export const RECOMMENDATION = {
	eng: "Recommendation",
	bhs: "Rekomendasi",
};
export const WORK_MODEL = {
	eng: "Work Model",
	bhs: "Model Kerja",
};
export const SEND_OFFER_LETTER = {
	eng: "Send offer letter",
	bhs: "Kirim surat penawaran",
};
export const USE_PK_OFFER_LETTER_TEMPLATE = {
	eng: "Use Portal Kerja Offer Letter template",
	bhs: "Gunakan Templat Surat Penawaran dari Portal Kerja",
};
export const SET_NAME_STATUS_ONLY = {
	eng: "Set {{_status_}} status only",
	bhs: "Tetapkan status {{_status_}}",
};
export const POSITION_TITLE = {
	eng: "Position title",
	bhs: "Posisi",
};
export const AGREED_SALARY = {
	eng: "Agreed salary",
	bhs: "Gaji yang disepakati",
};
export const OFFER_LETTER_DATE = {
	eng: "Offer letter date",
	bhs: "Tanggal surat penawaran",
};
export const START_JOINING_DATE = {
	eng: "Start joining date",
	bhs: "Tanggal mulai bergabung",
};
export const TYPE_OF_EMPLOYMENT = {
	eng: "Type of employment",
	bhs: "Tipe kepegawaian",
};
export const LENGTH_OF_PROBATION_PERIOD = {
	eng: "Length of probation period",
	bhs: "Periode masa percobaan",
};
export const SEND_TO_CANDIDATE = {
	eng: "Send to candidate",
	bhs: "Kirim ke kandidat",
};
export const FULL_PREVIEW = {
	eng: "Full preview",
	bhs: "Pratinjau",
};
export const OFFER_LETTER_PAGES = {
	eng: "Offer letter {{_candidateName_}} - {{_numPages_}} Pages",
	bhs: "Surat Penawaran Halaman {{_candidateName_}} - {{_numPages_}}",
};
export const CANDIDATE_MOVE_TO_STATUS = {
	eng: "Candidate moved to {{_status_}}",
	bhs: "Kandidat dipindahkan ke {{_status_}}",
};
export const NO_UPCOMING_SCHEDULE = {
	eng: "No upcoming schedule",
	bhs: "Tidak ada jadwal mendatang",
};
export const PLEASE_CREATE_SCHEDULE_DESC = {
	eng: "Please create schedule on time slot list before invite candidate to interview",
	bhs: "Silakan buat jadwal pada daftar slot waktu sebelum mengundang kandidat untuk interview",
};
export const REQUEST_VERIFICATION_SUCCESS = {
	eng: "Request Verification Successfully",
	bhs: "Permintaan Verifikasi Berhasil",
};
export const DOCUMENT_CHECKED = {
	eng: "Your documents are being checked.",
	bhs: "Dokumen Anda sedang diperiksa.",
};
export const APPROVED = {
	eng: "Approved",
	bhs: "Disetujui",
};
export const IMAGE_MAXIMUM_FIVE_MB = {
	eng: "PNG or JPG format, maximum 5 MB",
	bhs: "Format PNG atau JPG, maksimal 5 MB",
};
export const ASK_MORE = {
	eng: "Ask more",
	bhs: "Tanya lebih lanjut",
};
export const CHECK = {
	eng: "Check",
	bhs: "Periksa",
};
export const FILTER_SUCCESSFULLY_RESET = {
	eng: "Filter Successfully reset",
	bhs: "Filter berhasil diatur ulang",
};
export const NO_FILE_CHANGED = {
	eng: "No data changed",
	bhs: "Tidak ada data yang berubah",
};
export const LAST_REVIEWED_BY_NAME = {
	eng: "Last reviewed by {{_name_}}",
	bhs: "Last reviewed by {{_name_}}",
};
export const EXPERIENCE = {
	eng: "Experience",
	bhs: "Pengalaman",
};
export const PRESENT = {
	eng: "Present",
	bhs: "Sekarang",
};
export const MONTH_SALARY = {
	eng: "Month Salary",
	bhs: "Gaji Bulanan",
};
export const CHANGED_STATUS_BECOME = {
	eng: "Changed status",
	bhs: "Mengganti status menjadi",
};
export const STATUS = {
	eng: "Status",
	bhs: "Status",
};
export const NO_RESULT_SEARCH = {
	eng: "No result found",
	bhs: "Data tidak ditemukan",
};
export const ERROR_ADMIN_EXIST = {
	eng: "Admin user already exist for this company",
	bhs: "Pengguna admin sudah ada untuk perusahaan ini",
};
export const ERROR_USER_EXIST = {
	eng: "User already exist",
	bhs: "Pengguna sudah terdaftar",
};
export const SELECT_PARENT_COMPANY_TITLE = {
	eng: "Registered business name already has parent company",
	bhs: "Nama bisnis terdaftar sudah memiliki perusahaan induk",
};
export const SELECT_PARENT_COMPANY = {
	eng: "Select parent company",
	bhs: "Pilih perusahaan induk",
};
export const NO_PERMISSION_HRMS_TITLE = {
	eng: "You do not have permission to this module",
	bhs: "Anda tidak memiliki akses ke modul ini",
};
export const NO_PERMISSION_HRMS_DESC = {
	eng: "You haven't been added to any companies",
	bhs: "Anda belum ditambahkan ke suatu perusahaan",
};
export const OKAY = {
	eng: "Okay",
	bhs: "Oke",
};
