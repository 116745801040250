import * as Variable from "shared/utils/variables";
import useTranslate from "shared/hooks/useTranslate";
import { useFlags } from "shared/hooks/useFlags";

const ListStatus = ({ onClick, sethandlerModal }) => {
    const t = useTranslate();
    const { flag } = useFlags();

    let TEMP = [
        "Prospect",
        "Shortlist",
        (flag("CandidateManagement.statusInterview") && "Interview"),
        "Offered",
        "Hired",
        "Rejected"
    ].filter(Boolean);

    return (
        <>
            {TEMP.map((item, index) => {
                const handleClick = ()=>{
                    if(item === "Offered") {
                        sethandlerModal({ isShow: true, name: "offerletter" });
                        return;
                    };
                    onClick(item)
                }
                return (
                    <button
                        key={index}
                        onClick={handleClick}
                        className="hn-dropdown-item status-dropdown-item"
                    >
                        <div className="hn-dropdown-item-label">{t(item.toLocaleUpperCase() as keyof typeof Variable )}</div>
                    </button>
                )
            })}
        </>
    );
};

export default ListStatus;
