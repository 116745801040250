import React, { createContext, useContext, useEffect, useState, ReactNode, useRef } from 'react';

interface ScrollContextProps {
  scrollDirection: 'up' | 'down' | null;
  scrollPosition: number;
  setScrollDirection: (e: 'up' | 'down' | null) => void;
}

const ScrollContext = createContext<ScrollContextProps | undefined>(undefined);

export const useScroll = (): ScrollContextProps => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
};

interface ScrollProviderProps {
  children: ReactNode;
}

export const ScrollProvider: React.FC<ScrollProviderProps> = ({ children }) => {
    const [scrollDirection, setScrollDirection] = useState<ScrollContextProps['scrollDirection']>("up");
    const [scrollPosition, setScrollPosition] = useState<number>(0);

    const handleScroll = (e) => {
        const { scrollTop } = e.target;
        setScrollPosition(prev => {
            const direction = prev > scrollTop ? "up" : "down"
            setScrollDirection(direction);
            return scrollTop;
        });
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, []);

    return (
        <ScrollContext.Provider value={{ scrollDirection, scrollPosition, setScrollDirection }}>
            {children}
        </ScrollContext.Provider>
    );
};
