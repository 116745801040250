import React from "react";
import { FormInput, Select, Textarea } from "shared/component";

import InputTime from "../components/InputTime";
import { RenderIf } from "../../../../shared/utils/common";
import { FormExact } from "../../../../store/types/interview";

type FormProps = {
	form: FormExact;
	handleChangeValue: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	typeInterview: {
		id: string | number;
		name: string;
		hidden?: boolean;
	}[];
};

export default function Form(props: FormProps) {
	return (
		<div className="row gap-4 mt-xl">
			<InputTime />

			<Select
				name="meeting_type"
				label="Type Interview Meeting"
				noDefault
				required
				className="m-0 p-0"
				onChange={props.handleChangeValue}
				value={props.form.meeting_type}
				items={props.typeInterview}
				placeholder="Type meeting"
				searchable={false}
			/>
			<RenderIf condition={props.form.meeting_type === "phone_call"}>
				<FormInput
					required
					titleLabel={"Contact Person"}
					name={"phone_number"}
					value={props.form.phone_number}
					onChange={props.handleChangeValue}
				/>
			</RenderIf>

			<RenderIf condition={props.form.meeting_type === "video_call"}>
				<FormInput
					titleLabel={"Video Call Link"}
					name={"video_call_link"}
					value={props.form.video_call_link}
					onChange={props.handleChangeValue}
				/>
			</RenderIf>

			<RenderIf condition={props.form.meeting_type === "on_site"}>
				<Textarea
					children={null}
					classNameLabel=""
					cols={10}
					isError={false}
					required={false}
					rows={5}
					titleLabel={"Address Location"}
					characterLimit={200}
					name="location"
					onChange={props.handleChangeValue}
					value={props.form.location}
					placeholder={`Please input complete address, sample:Street, Building number, District, City`}
				/>
			</RenderIf>
		</div>
	);
}
