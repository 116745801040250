import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/";
import { PiArrowLeft } from "react-icons/pi";
import queryString from "query-string";
import { capitalize, pick } from "lodash";

import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import { Button, Input, Select } from "../../shared/component";

import Form from "./Reusable/Form";
import Badge from "./Reusable/Badge";

import { ENV, Nullable } from "../../types";
import useTranslate from "shared/hooks/useTranslate";
import useToast from "shared/hooks/useToast";

type Work_Model = "onsite" | "hybrid" | "remote";
type Experience_Level =
	| "entry_level"
	| "associate"
	| "intermediate"
	| "senior"
	| "director"
	| "executive";
type Job_Type = "full_time" | "part_time" | "freelance" | "contract" | "internship" | "temporary";
type Period_Unit = "day(s)" | "week(s)" | "month(s)";

interface Props {
	ENV_NAME: ENV;
	jobAdId: Nullable<number>;
	onNext: () => void;
	onPrev: () => void;
}

interface FormFields {
	work_model: Work_Model;
	experience_level: Experience_Level;
	job_type: Job_Type[];
	hours_per_week?: number;
	contract_period?: number;
	contract_period_unit?: Period_Unit;
	internship_amount?: number;
	internship_period_unit?: Period_Unit;
	temporary_amount?: number;
	temporary_period_unit?: Period_Unit;
}

interface FormError {
	work_model: boolean;
	experience_level: boolean;
	job_type: boolean;
	hours_per_week: boolean;
	contract_period: boolean;
	contract_period_unit: boolean;
}

type Model_Type<T> = {
	id: T;
	name: string;
};

const WorkModelAndJob = ({ ENV_NAME, jobAdId, onNext, onPrev }: Props) => {
	const t = useTranslate();
	const toast = useToast();
	const WORK_MODEL: Model_Type<Work_Model>[] = [
		{ id: "onsite", name: t("ONSITE_LABEL") },
		{ id: "hybrid", name: t("HYBRID_LABEL") },
		{ id: "remote", name: t("REMOTE_LABEL") },
	];
	const EXPERIENCE_LEVEL: Model_Type<Experience_Level>[] = [
		{
			id: "entry_level",
			name: `${t("ENTRY_LEVELLABEL")} (0-1 ${t("YEAR").toLowerCase()})`,
		},
		{
			id: "associate",
			name: `${t("ASSOCIATE_LABEL")} (1-2 ${t("YEARS").toLowerCase()})`,
		},
		{
			id: "intermediate",
			name: `${t("INTERMEDIATE")} (2-4 ${t("YEARS").toLowerCase()})`,
		},
		{
			id: "senior",
			name: `${t("SENIOR")} (>4 ${t("YEARS").toLowerCase()})`,
		},
		{
			id: "director",
			name: t("DIRECTOR_LABEL"),
		},
		{
			id: "executive",
			name: t("EXECUTIVE_LABEL"),
		},
	];
	const JOB_TYPE: Model_Type<Job_Type>[] = [
		{ id: "full_time", name: t("FULL_TIME_LABEL") },
		{ id: "part_time", name: t("PART_TIME_LABEL") },
		{ id: "freelance", name: t("FRELANCE_LABEL") },
		{ id: "contract", name: t("CONTRACT_LABEL") },
		{ id: "internship", name: t("INTERNSHIP_LABEL") },
		{ id: "temporary", name: t("TEMPORARY_LABEL") },
	];
	const PERIOD_UNIT: Model_Type<Period_Unit>[] = [
		{ id: "day(s)", name: t("DAYS") },
		{ id: "week(s)", name: capitalize(t("WEEKS")) },
		{ id: "month(s)", name: t("MONTHS") },
	];
	const MAX_HOURS_PER_WEEK = 168;

	const history = useHistory();
	const jobIdFromUrl = (queryString.parse(history.location.search)?.job_ad ||
		null) as Nullable<string>;

	const [form, setForm] = useState({} as FormFields);
	const [errors, setErrors] = useState({} as FormError);
	const [isEdited, setIsEdited] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	useEffect(() => {
		if (jobIdFromUrl) {
			getJob(jobIdFromUrl);
			setIsEditing(true);
		}
	}, [jobIdFromUrl]);

	const getJob = async (jobId: string) => {
		const res = await makeRequest(generateRequestOptions("getJobAd", { urlParams: jobId }));

		if (res?.code === 200) {
			const pickedFields = pick(res.data, [
				"work_model",
				"experience_level",
				"job_type",
				"hours_per_week",
				"contract_period",
				"contract_period_unit",
			]);

			setForm(pickedFields as FormFields);
		} else toast.error(res.message);
	};

	const onChange = (e: ChangeEvent<HTMLFormElement>, type: Nullable<string> = null) => {
		const { name, value } = e.target;
		switch (type) {
			case "numberWithDecimal":
				setForm((prev) => ({
					...prev,
					[name]: /^(\d*(\.\d*)?|\.\d+)$/.test(value)
						? value
						: prev[name as keyof FormFields],
				}));
				break;
			case "numberWithoutDecimal":
				setForm((prev) => ({
					...prev,
					[name]: /^(\d*)$/.test(value) ? value : prev[name as keyof FormFields],
				}));
				break;
			default:
				setForm((prev) => ({
					...prev,
					[name]: value,
				}));
		}
		setErrors((prev) => ({ ...prev, [name]: false }));
		setIsEdited(true);
	};

	const onJobTypeChange = (jobType: Job_Type) => {
		const formClone = { ...form };

		if (formClone?.job_type?.includes(jobType))
			formClone.job_type = formClone?.job_type?.filter((job) => job !== jobType);
		else formClone.job_type = [...(formClone?.job_type || []), jobType];

		setForm(formClone);
		setIsEdited(true);
	};

	const handleSave = async () => {
		if (isEditing && !isEdited) return onNext();

		const {
			work_model,
			experience_level,
			job_type,
			hours_per_week,
			contract_period,
			contract_period_unit,
		} = form;

		const errors = {} as FormError;
		if (!work_model) errors["work_model"] = true;
		if (!experience_level) errors["experience_level"] = true;
		if (!job_type.length) errors["job_type"] = true;
		if (
			job_type.includes("part_time") &&
			(!hours_per_week || hours_per_week > MAX_HOURS_PER_WEEK)
		)
			errors["hours_per_week"] = true;

		if (
			job_type.includes("contract") ||
			job_type.includes("internship") ||
			job_type.includes("temporary")
		) {
			if (!contract_period) errors["contract_period"] = true;
			if (!contract_period_unit) errors["contract_period_unit"] = true;
		}

		const hasError = Object.values(errors).some((err) => err);
		if (hasError) return setErrors(errors);

		const data: FormFields = {
			work_model,
			experience_level,
			job_type,
			hours_per_week,
		};

		if (
			data.job_type.includes("contract") ||
			data.job_type.includes("internship") ||
			data.job_type.includes("temporary")
		) {
			data.contract_period = contract_period;
			data.contract_period_unit = contract_period_unit;
		}

		const res = await makeRequest({
			...generateRequestOptions("updateJobAd", { urlParams: jobAdId || jobIdFromUrl }),
			data,
		});
		if (res?.code === 200) {
			resetForm();
			onNext();
		} else toast.error(res.message);
	};

	const resetForm = () => setForm({} as FormFields);

	return (
		<div className="form-block">
			<Form title={t("WORK_MODEL_HEAD")} isRequired>
				<Select
					name="work_model"
					label={t("WORK_MODE_LABEL")}
					className="m-0 p-0"
					onChange={onChange}
					value={form.work_model}
					items={WORK_MODEL}
					searchable={false}
					noDefault
					isError={errors["work_model"]}
				/>
				<Select
					name="experience_level"
					label={t("EXPERIENCE_LEVEL_LABEL")}
					className="m-0 p-0"
					onChange={onChange}
					value={form.experience_level}
					items={EXPERIENCE_LEVEL}
					searchable={false}
					noDefault
					isError={errors["experience_level"]}
				/>
			</Form>
			<Form title={t("JOB_TYPE_HEAD")} isRequired>
				<div className="job-type-container">
					{JOB_TYPE.map((job) => (
						<Badge
							key={job?.id}
							label={job?.name}
							isSelected={form?.job_type?.includes(job?.id)}
							onClick={() => onJobTypeChange(job?.id)}
						/>
					))}
				</div>
				{form?.job_type?.includes("part_time") && (
					<Input
						label={t("WORKING_HOURS_PER_WEEK")}
						placeholder={"15.000"}
						value={form?.hours_per_week || ""}
						name="hours_per_week"
						onChange={(e: ChangeEvent<HTMLFormElement>) =>
							onChange(e, "numberWithDecimal")
						}
						inputType="label"
						className="job-type-input"
						isError={errors["hours_per_week"]}
						noHint={
							errors["hours_per_week"] &&
							form?.hours_per_week &&
							+form?.hours_per_week > MAX_HOURS_PER_WEEK
						}
						hintText={t("WORKING_HOUR_HINT_TEXT")}
					/>
				)}

				{/* Contract/Internship/Temporary */}
				{(form?.job_type?.includes("contract") ||
					form?.job_type?.includes("internship") ||
					form?.job_type?.includes("temporary")) && (
					<div>
						<h3>{`${t("CONTRACT_DURATION_TEXT")}`}</h3>

						<div className="form-row">
							<Input
								label={t("AMOUNT")}
								placeholder={t("TYPE_AMOUNT")}
								value={form?.contract_period || ""}
								name="contract_period"
								onChange={(e: ChangeEvent<HTMLFormElement>) =>
									onChange(e, "numberWithoutDecimal")
								}
								inputType="label"
								className="job-type-input"
								isError={errors["contract_period"]}
							/>
							<Select
								label={t("PERIOD")}
								name="contract_period_unit"
								className="m-0 p-0 job-type-input"
								onChange={onChange}
								value={form.contract_period_unit}
								items={PERIOD_UNIT}
								searchable={false}
								noDefault
								isError={errors["contract_period_unit"]}
							/>
						</div>
					</div>
				)}
			</Form>
			<div className="btn-grp">
				<Button
					type="outline"
					title={t("PREVIOUS_LABEL")}
					onClick={onPrev}
					btnClassName="customBtn"
					customIcon={<PiArrowLeft size={18} className="icon icon-prev" />}
				/>
				<Button
					type="primary"
					title={t("SAVE_CONTINUE_LABEL")}
					onClick={handleSave}
					btnClassName="customBtn btn-save"
					disabled={
						!form?.work_model ||
						!form?.experience_level ||
						!form?.job_type ||
						!form?.job_type.length
					}
				/>
			</div>
		</div>
	);
};

export default WorkModelAndJob;
