import React from "react";
import Image from "shared/component/UI/Image";
import AvatarImg from "assets/image/profiledefaultImage.png";
import Checkbox from "./Checkbox";
import { UserType } from "component/JobAdListing/types/types";

type CardStaffListProps = {
	data: UserType;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	checked: boolean;
	disabled: boolean;
};

const CardStaffList = ({ data, handleChange, checked, disabled }: CardStaffListProps) => {
	return (
		<div className="modal-collaborate__modal-body__card-staff-list">
			<div className="modal-collaborate__modal-body__card-staff-list__left">
				<Image
					src={AvatarImg}
					className="modal-collaborate__modal-body__card-staff-list__left_staff-avatar"
				/>
				<div className="modal-collaborate__modal-body__card-staff-list__left_staff">
					<p className="modal-collaborate__modal-body__card-staff-list__left_staff-name">
						{data.first_name} {data.last_name}
					</p>
					<p className="modal-collaborate__modal-body__card-staff-list__left_staff-position">
						{data.position}
					</p>
				</div>
			</div>
			<div className="modal-collaborate__modal-body__card-staff-list__right">
				<Checkbox
					id={data?.id?.toString()}
					onChange={handleChange}
					checked={checked}
					disabled={disabled}
				/>
			</div>
		</div>
	);
};

export default CardStaffList;
