import { useEffect, useRef, useState } from "react";

import axios from "axios";
import cx from "classnames";
import downloadjs from "downloadjs";
import cookies from "react-cookies";

import { X } from "@phosphor-icons/react";
import { withRouter } from "react-router";
import { Modal, Overlay } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { Button } from "shared/component";
import { RenderIf } from "shared/utils/common";
import { b64toBlob } from "shared/utils/b64toBlob";
import { JOB_AD_LISTING } from "shared/utils/routeLink";
import { firstLetterUpperCase } from "shared/utils/helper";
import { getCookies, setCookies } from "shared/utils/cookies";
import { fetchDetailJobPoster } from "store/actions/interview";
import { generateRequestOptions } from "shared/utils/apiEndPoints";
import { ScrollProvider, useScroll } from "shared/hooks/useScroll";

import makeRequest from "shared/utils/request";
import useTranslate from "shared/hooks/useTranslate";
import Checkbox from "shared/component/atoms/Checkbox";
import LoadingSpinner from "shared/component/atoms/LoadingSpinner";
import SkeletonLoader from "shared/component/atoms/SkeletonLoader";
import LayoutWithNavigation from "shared/layouts/LayoutWithNavigation";

import Filter from "./components/Filters/Filter";
import SectionHead from "./components/SectionHead";
import Interview from "./components/modals/Interview";
import BulkStatus from "./components/modals/BulkStatus";
import Offerletter from "./components/modals/Offerletter";
import ExactTime from "./InterviewAdd/ExactTime/ExactTime";
import SmallPagination from "./components/SmallPagination";
import DetailsNavigation from "./components/DetailsNavigation";
import ScreenQuestions from "./components/sections/ScreenQuestions";
import StatusHistory from "./components/sections/StatusHistory/StatusHistory";

import {
	ApplicantHistory,
	ButtonDetailsCandidate,
	Candidate,
	CardNotSelected,
	Comment,
	Download,
	EmployerAssist,
	Experiences,
	Forward,
	ListButtonActionCandidates,
	ListStatus,
	Move,
	NameContact,
	TabNavigation,
	Warning,
} from "./components";
import { CandidateManagementRes, IModal, IStatus } from "./types";

import "./CandidateManagement.scss";
import useToast from "shared/hooks/useToast";

const DEFAULT_MODAL = {
	isShow: false,
	name: "",
	data: "",
	customData: "",
};

const CandidateManagement = ({ history }) => {
	const t = useTranslate();
	const toast = useToast();
	const router = useHistory();
	const statusRef = useRef(null);
	const quickmoveRef = useRef(null);
	const smartSearchRef = useRef(null);
	const { scrollDirection } = useScroll();

	const [isLoading, setLoading] = useState<boolean>(false);
	const [showBotNotif, setShowBotNotif] = useState<boolean>(false);
	const [bulkDownload, setBulkDownload] = useState<boolean>(false);
	const [employerAssist, setEmployerAssist] = useState<boolean>(false);
	const [modalExactTime, setmodalExactTime] = useState<boolean>(false);
	const [isLoadingSpinner, setLoadingSpinner] = useState<boolean>(false);
	const [showStatusDropdown, setShowStatusDropdown] = useState<boolean>(false);
	const [showQuickmoveDropdown, setShowQuickmoveDropdown] = useState<boolean>(false);
	const [lastUpdateResumeChecked, setLastUpdateResumeChecked] = useState<boolean>(false);
	const [showSmartSearchDropdown, setShowSmartSearchDropdown] = useState<boolean>(false);

	const [moveto, setMoveto] = useState<number | null>(null);
	const [candidateData, setCandidateData] = useState<any>(null);
	const [previousStatus, setPriviousStatus] = useState<any>(null);
	const [headerData, setHeaderData] = useState<IStatus | null>(null);
	const [downloadType, setDownloadType] = useState<number | null>(null);
	const [listApplicantsData, setListApplicantsData] = useState<CandidateManagementRes | null>(
		null,
	);

	const [jobId, setJobId] = useState<string>("");
	const [search, setSearch] = useState<string>("");
	const [comment, setComment] = useState<string>("");
	const [header, setHeader] = useState<string>("unrated");
	const [selectedStatus, setSelectedStatus] = useState<string>("");
	const [selectedDetailTab, setSelectedDetailTab] = useState<string>("profile");

	const [staff, setStaff] = useState<any>([]);
	const [candidate, setCandidate] = useState<any>([]);
	const [salaryJobs, setSalaryJobs] = useState<any>({});
	const [statusFilters, setStatusFilters] = useState<any>([]);
	const [candidateComments, setCandidateComments] = useState<any>([]);
	const [candidateCheckboxes, setCandidateCheckboxes] = useState<any>([]);
	const [smartsearchDropdownData, setSmartsearchDropdownData] = useState<any>([]);
	const [candidateApplicationHistory, setCandidateApplicationHistory] = useState<any>([]);

	const [resumeDuration, setResumeDuration] = useState<number>(6);
	const [handlerModal, sethandlerModal] = useState<IModal>(DEFAULT_MODAL);

	const handleFetchDetailJobPoster = async (id) => {
		return fetchDetailJobPoster(id)
			.then((res) => res)
			.catch((err) => console.error(err));
	};

	useEffect(() => {
		const paramId = router.location.pathname.split("/")[2];
		setJobId(paramId);
	}, [router.location.pathname]);

	useEffect(() => {
		listComments();
		getApplicationHistory();
		setSelectedDetailTab("profile");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [candidateData]);

	const handlerToogleAssist = () => {
		if (employerAssist) sethandlerModal({ isShow: true, name: "assist" });
		else {
			setEmployerAssist(true);
			setEmpAssist(true);
		}
	};

	const handlerShowModal = (name, data = "", customData = "") => {
		if (name) {
			sethandlerModal({
				isShow: true,
				name: name,
				data: data,
				customData: customData,
			});
		}
	};

	const undoStatus = () => {
		setShowBotNotif(false);
		markStatus(previousStatus, false);
	};

	const handlerHideModal = (name, data) => {
		if (name === "assist") {
			// type condition
			// sethandlerModal({ isShow: false, name: "", data: "" });
		}

		if (name === "move") {
			// type condition
			setMoveto(null);
			setBulkDownload(false);
			// sethandlerModal({ isShow: false, name: "", data: "" });
		}

		if (name === "download") {
			// type condition
			setDownloadType(null);
			setBulkDownload(false);
		}

		if (name === "forward") {
			// type condition
			setBulkDownload(false);
			// sethandlerModal({ isShow: false, name: "", data: "" });
		}

		if (name === "interview") {
			// sethandlerModal({ isShow: true, name: "warning", data: "" });
		}

		if (name === "warning") {
			// sethandlerModal({ isShow: false, name: "", data: "" });
		}

		sethandlerModal({ isShow: false, name: "", data: "", customData: "" });
	};

	useEffect(() => {
		listStaff();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (jobId) {
			handleFetchDetailJobPoster(jobId);
		}
	}, [header, jobId]);

	const bulkExport = async (obj: any) => {
		let temp = generateRequestOptions(
			downloadType === 1 ? "exportBulkCandidateResume" : "exportBulkCandidateFiles",
		);
		const callAgain = async (id) => {
			// const body = {
			//   "task_id": id
			// }
			let res_load = await makeRequest({
				...generateRequestOptions(
					downloadType === 1 ? "exportBulkCandidateResume" : "exportBulkCandidateFiles",
					{
						// urlParams: candidateData?.id+"/",
						queryParams: {
							task_id: id,
						},
					},
				),
			});
			if (res_load.data?.message?.task_id) {
				setTimeout(() => {
					callAgain(res_load.data?.message?.task_id);
				}, 4000);
			}
		};
		let qp = new URLSearchParams();
		candidateCheckboxes.forEach((isChecked, idx) => {
			if (isChecked) qp.append("job_seekers_id", candidate[idx].id);
		});
		let request = {
			params: qp,
			headers: {
				Authorization: `Bearer ${cookies.load("ptkjauthtoken")}`,
				"Accept-Language": `${cookies.load("lang") === "eng" ? "en-us" : "id"}`,
			},
		};
		axios
			.get(temp.url + `${jobId}/`, request)
			.then((res) => {
				if (res.data?.data?.message?.task_id) {
					setTimeout(() => {
						callAgain(res.data?.data?.message?.task_id);
					}, 4000);
				}
				downloadjs(b64toBlob(res.data.data.file_data), res.data.data.file_name);
			})
			.catch(() => {
				toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
			})
			.finally(() => {
				setDownloadType(null);
				setBulkDownload(false);
			});
	};

	const getJobs = async (obj) => {
		let showMandatoryQuestion = {};
		let minMaxValue: { min?: number; max?: number } = {};

		const customQuestionFilterCookies = getCookies("customQuestionFilter");

		customQuestionFilterCookies &&
			Object.keys(customQuestionFilterCookies).map((data) => {
				switch (customQuestionFilterCookies[data]?.question_type) {
					case "single_select":
					case "mcq":
					case "progressive":
						showMandatoryQuestion[String(customQuestionFilterCookies[data].id)] =
							Object.keys(customQuestionFilterCookies[data].mcq_choices)
								.map(
									(val) =>
										customQuestionFilterCookies[data].mcq_choices[val] === 1 &&
										val,
								)
								.filter(Boolean);
						break;
					case "numeric":
						if (
							customQuestionFilterCookies[data]?.max_numeric_value &&
							customQuestionFilterCookies[data]?.min_numeric_value
						) {
							minMaxValue["min"] = customQuestionFilterCookies[data]?.value?.min;
							minMaxValue["max"] = customQuestionFilterCookies[data]?.value?.max;
							break;
						}

						showMandatoryQuestion[String(customQuestionFilterCookies[data].id)] =
							Number(customQuestionFilterCookies[data].value);
						break;
					case "salary":
						minMaxValue["min"] = customQuestionFilterCookies[data]?.value?.min;
						minMaxValue["max"] = customQuestionFilterCookies[data]?.value?.max;
						break;
					default:
						break;
				}
			});

		let temp = generateRequestOptions("getListApplicants", {
			urlParams: jobId,
		});

		let qp = new URLSearchParams();

		if (search !== "" && !obj?.candidate_name) qp.append("candidate_name", search);
		if (lastUpdateResumeChecked) qp.append("last_updated_resume", String(resumeDuration));
		if (minMaxValue.max) qp.append("range_salary_max", String(minMaxValue.max));
		if (minMaxValue.min) qp.append("range_salary_min", String(minMaxValue.min));

		statusFilters.forEach((sf) => qp.append("candidate_status", sf));

		if (Object.keys(showMandatoryQuestion).length > 0) {
			Object.keys(showMandatoryQuestion).forEach((sf) =>
				qp.append(`question_filter[${sf}]`, JSON.stringify(showMandatoryQuestion[sf])),
			);
		}

		let request = {
			params: qp,
			headers: {
				Authorization: `Bearer ${cookies.load("ptkjauthtoken")}`,
				"Accept-Language": `${cookies.load("lang") === "eng" ? "en-us" : "id"}`,
			},
		};
		setLoading(true);
		axios
			.get(obj?.NextPrevUrl || temp.url, request)
			.then((res) => {
				if (res.data.detail === "Given token not valid for any token type") {
					history.push("/");
					return;
				}
				setHeaderData(res.data.applications_distribution_by_job_poster_feedback);

				if (header) qp.append("job_poster_feedback", header);
				axios
					.get(obj?.NextPrevUrl || temp.url, request)
					.then((res) => {
						setLoading(false);
						setCandidate(res.data.data);
						setListApplicantsData(res.data);
						setEmployerAssist(res.data.employer_assist);
						setCookies("defaultMandatoryQuestions", res.data?.must_have_questions);
						setSalaryJobs({
							salary_amount: res.data?.salary_amount,
							salary_currency: res.data?.salary_currency,
							salary_maximum_amount: res.data?.salary_maximum_amount,
							salary_rate: res.data?.salary_rate,
							salary_starting_amount: res.data?.salary_starting_amount,
							salary_type: res.data?.salary_type,
						});
						setCandidateCheckboxes(new Array(res.data.data.length).fill(false));
					})
					.catch((res) => {
						setLoading(false);
						toast.error(res.message);
					});
			})
			.catch((res) => {
				setLoading(false);
				toast.error(res.message);
			});
	};

	const handleDefaultCheckbox = () => {
		const filteredCandidate = listApplicantsData?.data?.filter(
			({ job_poster_feedback }) => job_poster_feedback === header,
		);

		setCandidateCheckboxes(new Array(filteredCandidate?.length).fill(false));
	};

	const getCandidateDetails = async (id) => {
		try {
			setLoadingSpinner(true);
			let res = await makeRequest({
				...generateRequestOptions("getCandidateDetails", {
					urlParams: id,
				}),
			});
			setCandidateData(res.data);
		} catch (error) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		} finally {
			setLoadingSpinner(false);
		}
	};

	const markStatus = async (status, botStatus = true) => {
		try {
			let res = await makeRequest({
				...generateRequestOptions("markStatus"),
				body: {
					candidate_status: status.toLowerCase(),
					job_application: candidateData?.id,
				},
			});
			if (res.code === 200) {
				setPriviousStatus(
					candidateData?.candidate_status?.[candidateData?.candidate_status?.length - 1]
						?.candidate_status,
				);
				setCandidateData({
					...candidateData,
					candidate_status: [...candidateData?.candidate_status, res.data],
				});
				if (botStatus) {
					setShowBotNotif(true);
					setTimeout(() => {
						setShowBotNotif(false);
					}, 5000);
				}
				if (status === "Rejected") {
					setHeader("unfit");
					markCategory(candidateData?.id, "unfit");
				}
				if (status === "Hired") {
					setHeader("goodfit");
					markCategory(candidateData?.id, "good_fit");
				}
				setSelectedStatus(status);
				toast.success(t("STATUS_SUCCESSFULLY_CHANGED"));
			} else {
				toast.error(res.message);
			}
		} catch (error) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		}
	};
	const setEmpAssist = async (val) => {
		try {
			await makeRequest({
				...generateRequestOptions("employerAssist", {
					urlParams: jobId,
				}),
				body: {
					employer_assist: val,
				},
			});
		} catch (error) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		}
	};

	const quickMovetoUnfit = async () => {
		try {
			const res = await makeRequest(
				generateRequestOptions("quickMovetoUnfit", {
					urlParams: jobId,
				}),
			);
			sethandlerModal({
				isShow: false,
				name: "warning",
				customData: "unfit",
			});
			if (res.code === 200) {
				toast.success(t("SUCCESS_MOVE_TO_UNFIT"));
				setHeader("unfit");
			} else {
				toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
			}
		} catch (error) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		} finally {
			sethandlerModal({
				isShow: false,
				name: "warning",
				customData: "unfit",
			});
		}
	};
	const quickMovetoReject = async () => {
		try {
			const res = await makeRequest(
				generateRequestOptions("quickMovetoReject", {
					urlParams: jobId,
				}),
			);
			setCandidateData(null);
			if (res.code === 200) return toast.success(t("SUCCESS_MOVE_TO_REJECT"));
			return toast.success(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		} catch (error) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		} finally {
			sethandlerModal({
				isShow: false,
				name: "warning",
				customData: "reject",
			});
			setShowQuickmoveDropdown(false);
		}
	};
	const markCategory = async (id: number | string, cat: string, showToast?: boolean) => {
		try {
			await makeRequest({
				...generateRequestOptions("markCategory", {
					urlParams: id,
				}),
				body: {
					job_poster_feedback: cat,
				},
			});

			setHeader(cat);
			setCandidateData(null);

			if (showToast) {
				if (cat === "unfit") toast.success(t("SUCCESS_MOVE_TO_UNFIT"));
				if (cat === "good_fit") toast.success(t("SUCCESS_MOVE_TO_GOODFIT"));
				if (cat === "perhaps") toast.success(t("SUCCESS_MOVE_TO_PERHAPS"));
			}
		} catch (error) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		}
	};

	const exportResume = async () => {
		try {
			const callAgain = async (id) => {
				let res_load = await makeRequest({
					...generateRequestOptions("exportAllCandidateFiles", {
						queryParams: {
							task_id: id,
						},
					}),
				});
				if (res_load.data?.message?.task_id) {
					setTimeout(() => {
						callAgain(res.data?.message?.task_id);
					}, 4000);
				}
			};
			let objectToUse = {};
			if (downloadType === 1) {
				objectToUse = {
					queryParams: {
						candidate_id: candidateData?.id,
					},
				};
			} else {
				objectToUse = {
					urlParams: candidateData?.id + "/",
				};
			}
			let res = await makeRequest({
				...generateRequestOptions(
					downloadType === 1 ? "exportResume" : "exportAllCandidateFiles",
					{ ...objectToUse },
				),
			});
			if (res.data?.message?.task_id) {
				setTimeout(() => {
					callAgain(res.data?.message?.task_id);
				}, 4000);
			}
			downloadjs(b64toBlob(res.data.file_data), res.data.file_name);
		} catch (err) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		} finally {
			setDownloadType(null);
			setBulkDownload(false);
		}
	};

	const listComments = async () => {
		try {
			if (!candidateData) return;

			let res = await makeRequest({
				...generateRequestOptions("listComments", {
					queryParams: {
						job_application_id: candidateData?.id,
					},
				}),
			});

			setCandidateComments(res.data.reverse());
		} catch (error) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		}
	};

	const postComments = async () => {
		try {
			await makeRequest({
				...generateRequestOptions("postComment", {}),
				body: {
					job_application: candidateData?.id,
					comment: comment,
				},
			});
			setComment("");
			listComments();
		} catch (error) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		}
	};

	const getApplicationHistory = async () => {
		try {
			if (!candidateData) return;

			let res = await makeRequest({
				...generateRequestOptions("applicationHistory", {
					urlParams: candidateData?.id,
				}),
			});

			setCandidateApplicationHistory(res.data);
		} catch (error) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		}
	};

	const listStaff = async () => {
		try {
			let res = await makeRequest({ ...generateRequestOptions("listStaff") });
			if (res.code === 200) {
				setStaff(
					res.data.map((data) => {
						return { ...data, checked: false };
					}),
				);
			}
		} catch (error) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		}
	};

	const getCandidateStatus: any = () => {
		const length = candidateData?.candidate_status?.length - 1;
		const temp = candidateData?.candidate_status?.[length > -1 ? length : 0]?.candidate_status;
		if (!temp) return "Select status";
		return firstLetterUpperCase(temp);
	};

	const handlerConfirmInterview = async (method) => {
		sethandlerModal({ isShow: false, name: "", data: "" });
		setTimeout(() => {
			sethandlerModal({
				isShow: true,
				name: "warning",
				data: method,
				customData: "interview",
			});
		}, 200);
	};

	const sendCandidateLink = async (ids, bulkDownload) => {
		try {
			let recipients = [];
			if (bulkDownload)
				candidateCheckboxes.forEach((isChecked, idx) => {
					if (isChecked) recipients.push(candidate?.[idx]?.id as never);
				});
			else recipients.push(candidateData?.id as never);
			const res = await makeRequest({
				...generateRequestOptions("sendCandidateLink", {}),
				body: {
					recipients: recipients,
					job_application_id: ids,
				},
			});
			if (res.code === 200) {
				setComment("");
				listComments();
				toast.success(t("SEND_CANDIDATE_LINK_SUCCESS"));
			} else {
				toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
			}
		} catch (error) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		}
	};

	const getSmartSearchList = async (name) => {
		try {
			let res = await makeRequest({
				...generateRequestOptions("candidateNameSearch", {
					urlParams: jobId,
					queryParams: {
						candidate_name: name,
					},
				}),
				body: {},
			});
			if (res.data.length > 0) setShowSmartSearchDropdown(true);
			setSmartsearchDropdownData(res.data);
		} catch (error) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		}
	};

	useEffect(() => {
		if (jobId) getJobs({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [header, resumeDuration, lastUpdateResumeChecked, statusFilters, jobId, search]);

	const bulkSetCandidateCategory = async () => {
		try {
			let category = moveto === 1 ? "good_fit" : moveto === 2 ? "perhaps" : "unfit";
			let body: any = [];
			candidateCheckboxes.forEach((isChecked: boolean, idx: number) => {
				if (isChecked) body.push({ id: candidate[idx].id, job_poster_feedback: category });
			});
			const res = await makeRequest({
				...generateRequestOptions("setBulkCandidateCategory"),
				body,
			});
			if (res.code === 200) {
				handlerHideModal(handlerModal.name, null);
				setHeader(category);
				toast.success(t("CANDIDATE_MOVE_SUCCESSFULLY"));
			} else {
				toast.error(res.message);
			}
		} catch (error) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		}
	};

	const bulkSetCandidateStatus = async () => {
		try {
			let status: string;
			switch (moveto) {
				case 1:
					status = "prospect";
					break;
				case 2:
					status = "shortlist";
					break;
				case 3:
					status = "interview";
					break;
				case 4:
					status = "offered";
					break;
				case 5:
					status = "hired";
					break;
				default:
					status = "rejected";
			}

			let body: any = [];
			candidateCheckboxes.forEach((isChecked: boolean, idx: number) => {
				if (isChecked)
					body.push({ candidate_status: status, job_application: candidate[idx].id });
			});

			let res = await makeRequest({
				...generateRequestOptions("markStatus"),
				body,
			});

			if (res.code === 200) {
				const updatePromises = body.map(({ job_application, candidate_status }) => {
					if (candidate_status && job_application) {
						if (status === "rejected") {
							return markCategory(job_application, "unfit", false);
						}
						if (status === "hired") {
							return markCategory(job_application, "good_fit", false);
						}
					}
					return null;
				});

				Promise.all(updatePromises)
					.then(() => {
						if (status === "rejected") setHeader("unfit");
						if (status === "hired") setHeader("good_fit");

						if (candidateData) getCandidateDetails(candidateData?.id);

						toast.success(t("STATUS_SUCCESSFULLY_CHANGED"));
					})
					.catch((err) => {
						console.error(err);
						toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
					});
			} else {
				toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
			}
			handlerHideModal(handlerModal.name, null);
		} catch (error) {
			toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"));
		} finally {
			handleDefaultCheckbox();
		}
	};

	return (
		<LayoutWithNavigation>
			<div className="candidate-management h-100">
				<div className="white-background-div">
					{/* Section Heading  */}
					<SectionHead
						employerAssist={employerAssist}
						// setEmployerAssist={setEmployerAssist}
						getJobs={getJobs}
						job_title={listApplicantsData?.job_title}
						handlerShowModal={handlerShowModal}
						handlerToogleAssist={handlerToogleAssist}
						quickMovetoReject={quickMovetoReject}
						quickMovetoUnfit={quickMovetoUnfit}
						quickmoveRef={quickmoveRef}
						setSearch={setSearch}
						setShowQuickmoveDropdown={setShowQuickmoveDropdown}
						setmodalExactTime={setmodalExactTime}
						showQuickmoveDropdown={showQuickmoveDropdown}
						router={router}
						smartSearchRef={smartSearchRef}
						showSmartSearchDropdown={showSmartSearchDropdown}
						setShowSmartSearchDropdown={setShowSmartSearchDropdown}
						getSmartSearchList={getSmartSearchList}
						smartsearchDropdownData={smartsearchDropdownData}
						sethandlerModal={sethandlerModal}
						handlerModal={handlerModal}
					/>
					{/* End Section Heading  */}

					{/* Tab Navigation  */}
					<TabNavigation
						header={header}
						setHeader={(v) => {
							setHeader(v);
							setCandidateData(null);
						}}
						headerData={headerData}
						hiredEmployees={[
							listApplicantsData?.reserved_quota || 0,
							listApplicantsData?.hire_quota || 0,
						].join("/")}
					/>
					{/* End Tab Navigation  */}

					{/* Empty State No Candidates */}
					<div className="row mtop-30">
						<RenderIf condition={candidate.length < 1}>
							<div className="empty-state-candidate">
								<CardNotSelected description={t("NO_CANDIDATE_DESC")}>
									<Button
										type="primary"
										size="sm"
										className="mt-4"
										onClick={() => {
											router.push(JOB_AD_LISTING);
										}}
									>
										{t("BACK_TO_JOB_AD")}
									</Button>
								</CardNotSelected>
							</div>
						</RenderIf>
						<RenderIf condition={candidate.length > 0}>
							<div
								className={cx("candidates-div col-5 pb-4", {
									"candidate-management__list-scroll-height":
										!handlerModal.isShow && scrollDirection === "down",
								})}
							>
								<div>
									<div className="d-flex align-items-center bulk-options-div">
										{candidate.length > 0 && (
											<Checkbox
												checked={candidateCheckboxes.some(Boolean)}
												onChange={() => {
													setCandidateCheckboxes(
														new Array(candidate.length).fill(
															!candidateCheckboxes.every(Boolean),
														),
													);
												}}
												checkedType={
													candidateCheckboxes.every(Boolean)
														? "full"
														: "half"
												}
											/>
										)}
										{candidateCheckboxes.some(Boolean) && (
											<ListButtonActionCandidates
												setBulkDownload={setBulkDownload}
												handlerShowModal={handlerShowModal}
											/>
										)}
									</div>
									<div>
										<SmallPagination
											onMovePage={(v) => getJobs({ NextPrevUrl: v })}
											totalItems={listApplicantsData?.total_items}
											page={listApplicantsData?.current_page}
											pageSize={listApplicantsData?.page_size}
											next={listApplicantsData?.next}
											previous={listApplicantsData?.previous}
										/>
									</div>
								</div>
								{candidate?.map((c, idx) => {
									if (isLoading) {
										return (
											<div className="candidate-loader">
												<SkeletonLoader
													width="20px"
													height="20px"
													style={{ marginTop: "6px" }}
												/>
												<SkeletonLoader
													width="100%"
													height="80px"
													style={{ marginTop: "6px" }}
												/>
											</div>
										);
									}
									return (
										<Candidate
											header={header}
											candidateCheckboxes={candidateCheckboxes}
											getCandidateDetails={getCandidateDetails}
											setCandidateCheckboxes={setCandidateCheckboxes}
											candidateData={candidateData}
											candidate={c}
											idx={idx}
											key={idx}
											markStatus={markCategory}
											allCandidates={candidate}
											setCandidate={setCandidate}
										/>
									);
								})}
							</div>
							<div
								className={cx("col-7 candidate-data-div", {
									"candidate-management__details-scroll-height":
										!handlerModal.isShow && scrollDirection === "down",
								})}
							>
								<RenderIf condition={candidateData && !isLoadingSpinner}>
									<div>
										<div className="candidate-data-upper-div">
											<div className="d-flex justify-content-between">
												<NameContact
													candidateData={candidateData}
													handlerModalForward={() =>
														handlerShowModal("forward")
													}
												/>
												<div>
													<label className="status-label">
														{t("SET_STATUS")}
													</label>
													<div
														onClick={() =>
															setShowStatusDropdown(
																!showStatusDropdown,
															)
														}
														ref={statusRef}
													>
														<div className="status-dropdown">
															<span
																className={cx({
																	"color-black":
																		getCandidateStatus() ===
																		"Select status",
																})}
															>
																{t(
																	getCandidateStatus().toLocaleUpperCase(),
																)}
															</span>
															<i className="fa fa-solid fa-angle-down"></i>
														</div>
														<Overlay
															target={statusRef.current}
															show={showStatusDropdown}
															placement="bottom-start"
															rootClose
															onHide={() =>
																setShowStatusDropdown(false)
															}
														>
															{({
																placement,
																arrowProps,
																show: _show,
																popper,
																...OverlayProps
															}) => (
																<div
																	{...OverlayProps}
																	id="hn-dropdown-lang"
																	className="hn-dropdown hn-lang"
																>
																	<ListStatus
																		onClick={markStatus}
																		sethandlerModal={
																			sethandlerModal
																		}
																	/>
																</div>
															)}
														</Overlay>
													</div>
												</div>
											</div>

											<ButtonDetailsCandidate
												onShowDownloadModal={() =>
													handlerShowModal("download")
												}
												phoneNumber1={
													candidateData?.created_by?.personal_info
														?.mobile_phone_number
												}
												phoneNumber2={
													candidateData?.created_by?.personal_info
														?.mobile_phone_number_2
												}
												streetAddress={
													candidateData?.created_by?.personal_info
														?.street_address
												}
											/>
										</div>

										<div>
											<DetailsNavigation
												countComments={candidateComments.length}
												selectedTab={selectedDetailTab}
												onTabClick={(v) => setSelectedDetailTab(v)}
											/>
										</div>
										<div className="candidate-data-lower-div">
											{selectedDetailTab === "profile" && (
												<Experiences candidateData={candidateData} />
											)}
											{selectedDetailTab === "comment" && (
												<Comment
													comment={comment}
													setComment={setComment}
													postComments={postComments}
													candidateComments={candidateComments}
												/>
											)}
											{selectedDetailTab === "screen-questions" && (
												<ScreenQuestions
													candidateQuestions={
														candidateData?.screening_answer
													}
												/>
											)}
											{selectedDetailTab === "history" && (
												<StatusHistory
													candidateStatus={
														candidateData?.candidate_status
													}
												/>
											)}
											{selectedDetailTab === "application-history" && (
												<ApplicantHistory
													candidateApplicationHistory={
														candidateApplicationHistory
													}
												/>
											)}
										</div>
									</div>
								</RenderIf>
								<RenderIf condition={!candidateData && !isLoadingSpinner}>
									<CardNotSelected description={t("NO_CANDIDATE_DESC_1")} />
								</RenderIf>
								<RenderIf condition={isLoadingSpinner}>
									<LoadingSpinner />
								</RenderIf>
							</div>
						</RenderIf>
					</div>
				</div>
				{selectedStatus && showBotNotif && (
					<div className="bottom-floating-notification">
						{t("CANDIDATE_MOVE_TO_STATUS").replace(
							"{{_status_}}",
							// candidateData?.candidate_status?.[candidateData?.candidate_status?.length - 1]?.candidate_status
							selectedStatus,
						)}
						<span onClick={undoStatus}>{t("UNDO")}</span>
						<X style={{ cursor: "pointer" }} />
					</div>
				)}

				<Modal
					show={handlerModal.isShow}
					contentClassName="tw rounded-sm border-0 p-0"
					dialogClassName={cx("modal-dialog-centered", {
						"modal-md": ["assist", "warning"].includes(handlerModal.name as string),
						"modal-lg": ![
							"assist",
							"warning",
							"download",
							"move",
							"status",
							"offerletter",
						].includes(handlerModal.name as string),
						"modal-download": ["download", "move", "status", "offerletter"].includes(
							handlerModal.name as string,
						),
						"modal-forward": handlerModal.name === "forward",
					})}
					onHide={() => handlerHideModal(handlerModal.name, null)}
				>
					<RenderIf condition={handlerModal?.name === "assist"}>
						<EmployerAssist
							handlerClose={() => handlerHideModal(handlerModal.name, null)}
							setEmployerAssist={setEmployerAssist}
							setEmpAssist={setEmpAssist}
						/>
					</RenderIf>
					<RenderIf condition={handlerModal?.name === "download"}>
						<Download
							handlerCloseModal={() => handlerHideModal(handlerModal.name, null)}
							setDownloadType={setDownloadType}
							downloadType={downloadType}
							bulkDownload={bulkDownload}
							candidateCheckboxes={candidateCheckboxes}
							exportResume={exportResume}
							bulkExport={bulkExport}
						/>
					</RenderIf>
					<RenderIf condition={handlerModal?.name === "move"}>
						<Move
							handlerCloseModal={() => handlerHideModal(handlerModal.name, null)}
							bulkDownload={bulkDownload}
							candidateCheckboxes={candidateCheckboxes}
							moveto={moveto}
							setMoveto={setMoveto}
							bulkSetCandidateCategory={bulkSetCandidateCategory}
						/>
					</RenderIf>
					<RenderIf condition={handlerModal?.name === "status"}>
						<BulkStatus
							handlerCloseModal={() => handlerHideModal(handlerModal.name, null)}
							moveto={moveto}
							handleMoveTo={(v) => setMoveto(v)}
							bulkSetCandidateCategory={bulkSetCandidateStatus}
						/>
					</RenderIf>
					<RenderIf condition={handlerModal?.name === "forward"}>
						<Forward
							handlerCloseModal={() => handlerHideModal(handlerModal.name, null)}
							bulkDownload={bulkDownload}
							candidateCheckboxes={candidateCheckboxes}
							staff={staff}
							sendCandidateLink={sendCandidateLink}
						/>
					</RenderIf>
					<RenderIf condition={handlerModal?.name === "interview"}>
						<Interview
							handlerCloseModal={() => handlerHideModal(handlerModal.name, null)}
							handlerConfirm={handlerConfirmInterview}
						/>
					</RenderIf>
					<RenderIf condition={handlerModal?.name === "filter"}>
						<Filter
							handlerCloseModal={() => handlerHideModal(handlerModal.name, null)}
							checked={lastUpdateResumeChecked}
							setLastUpdateResumeChecked={setLastUpdateResumeChecked}
							resumeDuration={resumeDuration}
							setResumeDuration={setResumeDuration}
							setStatusFilters={setStatusFilters}
							mandatoryQuestions={listApplicantsData?.must_have_questions}
							salaryJobs={salaryJobs}
						/>
					</RenderIf>
					<RenderIf condition={handlerModal?.name === "offerletter"}>
						<Offerletter
							handlerCloseModal={() => handlerHideModal(handlerModal.name, null)}
							markStatus={markStatus}
							jobApplication={4}
							candidateName={[
								candidateData?.created_by?.personal_info?.first_name,
								candidateData?.created_by?.personal_info?.last_name,
							]
								.filter(Boolean)
								.join(" ")}
						/>
					</RenderIf>

					<RenderIf condition={handlerModal?.name === "warning"}>
						<Warning
							data={handlerModal}
							handlerClose={() => handlerHideModal(handlerModal.name, null)}
							quickMovetoUnfit={quickMovetoUnfit}
							quickMovetoReject={quickMovetoReject}
						/>
					</RenderIf>
				</Modal>
			</div>

			<ExactTime showModal={modalExactTime} setShowModal={setmodalExactTime} />
		</LayoutWithNavigation>
	);
};

const CandidateManagementWithProvider = (props) => (
	<ScrollProvider>
		<CandidateManagement {...props} />
	</ScrollProvider>
);

export default withRouter(CandidateManagementWithProvider);
