import {
    ArrowsLeftRight,
    FileArrowDown,
    ShareFat,
} from "@phosphor-icons/react";

import Tooltip from "shared/component/atoms/Tooltips/Tooltip";
import useTranslate from "shared/hooks/useTranslate";

const ListButtonActionCandidates = ({
    setBulkDownload,
    handlerShowModal,
}) => {
    const t = useTranslate()
    return (
        <>
            <Tooltip
                description={t("MOVE")}
            >
                <ArrowsLeftRight
                    className="bulk-option-logo"
                    width={24}
                    height={24}
                    color="#667085"
                    onClick={() => {
                        handlerShowModal("move");
                        setBulkDownload(true);
                    }}
                />
            </Tooltip>
            <Tooltip
                description={t("DOWNLOAD")}
            >
                <FileArrowDown
                    className="bulk-option-logo"
                    width={24}
                    height={24}
                    color="#667085"
                    onClick={() => {
                        handlerShowModal("download");
                        setBulkDownload(true);
                    }}
                />
            </Tooltip>
            <Tooltip
                description={t("FORWARD")}
            >
                <ShareFat
                    className="bulk-option-logo"
                    width={24}
                    height={24}
                    color="#667085"
                    onClick={() => {
                        handlerShowModal("forward");
                        setBulkDownload(true);
                    }}
                />
            </Tooltip>
            <div className="vertical-line-lite" />
            <div
                className="status-btn"
                onClick={() => {
                    handlerShowModal("status");
                    setBulkDownload(true);
                }}
            >
                {t("SET_STATUS")}
            </div>
        </>
    );
}

export default ListButtonActionCandidates;