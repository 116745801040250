import React from 'react';
import { Modal } from 'react-bootstrap';
import { RenderIf } from '../../../utils/common';
import Image from 'shared/component/UI/Image';
import NewButton from '../../atoms/NewButton/NewButton';

type ModalCustomProps = {
	isCentered?: boolean;
	isShow: boolean;
	setShow: (value: boolean) => void;
	size?: 'sm' | 'lg' | 'xl';
	typeModal?: 'custom' | 'form' | 'confirmation' | 'alert';
	title: string;
	className?: string;
	contentClassName?: string;
	showCloseButton?: boolean;
	children?: React.ReactNode;
	handleCloseModal?: () => void;
	handlerConfirm?: () => void;
	titleButtonConfirm?: string;
	titleButtonCancel?: string;
	useChildren?: boolean;
	showButtonCancel?: boolean;
	disbaledButtonConfirm?: boolean;
	childrenButtonConfirm?: React.ReactNode | string;
};

export default function ModalCustom(props: ModalCustomProps) {
	const alert = require('../../../../assets/svg/alert-image.svg') as string;

	return (
		<Modal
			centered={props.isCentered}
			show={props.isShow}
			onHide={() => props.setShow(false)}
			size={props.size}
			className={props.className}
			contentClassName="border-0 p-0  tw rounded-xl"
		>
			<Modal.Header
				closeButton={props.showCloseButton}
				className="tw rounded-t-xl border-b-2 p-med"
				style={{ backgroundColor: '#F9FAFB' }}
			>
				<Modal.Title className="text-xl">{props.title}</Modal.Title>
			</Modal.Header>

			<Modal.Body className="p-med">
				<RenderIf condition={!props.useChildren && props.typeModal === 'alert'}>
					<Image className="" src={alert} />
					<h1 className="modal-employer-heading">Are you sure?</h1>
					<p className="text-center mt-3 fw-normal tw text-gray-500">
						The interview method is applied for this Job Ad only and one time setup,
						once you confirm the method you will not able to change it anymore.
					</p>
				</RenderIf>

				<RenderIf condition={props.useChildren}>{props.children}</RenderIf>
			</Modal.Body>

			<Modal.Footer className="d-flex gap-3 tw border-t-2 rounded-b-xl box-border bg-gray-50 p-med">
				<RenderIf condition={props.showButtonCancel}>
					<NewButton
						size="lg"
						className="col"
						typeButton="outline-gray"
						onClick={() => props.setShow(false)}
						type="button"
					>
						Close
					</NewButton>
				</RenderIf>
				<NewButton
					size="lg"
					disabled={props.disbaledButtonConfirm}
					className="col"
					typeButton="primary"
					onClick={() => props.setShow(false)}
					type="button"
				>
					{props.childrenButtonConfirm ? props.childrenButtonConfirm : 'Confirm'}
				</NewButton>
			</Modal.Footer>
		</Modal>
	);
}
