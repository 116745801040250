import React from "react";
import * as Variable from "../../../../shared/utils/variables";
import { useSelector } from "react-redux";
import "./Header.scss";
import { useHistory } from "react-router-dom";
import { JOB_AD } from "shared/utils/routeLink";
import { Button } from "shared/component";
import { PiPlus } from "react-icons/pi";
import usePermission from "shared/hooks/usePermission";
import useTranslate from "shared/hooks/useTranslate";

const Header = () => {
	const history = useHistory();
	const permission = usePermission();
	const t = useTranslate();
	return (
		<div className="job-ads-listing__header">
			<h4 className="job-ads-listing__header-title">{t("JOB_ADS_LABEL")}</h4>
			{!!permission && permission.job_posting && (
				<Button
					type="primary"
					size="sm"
					customIcon={<PiPlus size={20} color="#ffffff" className="mr-1" />}
					onClick={() => history.push(JOB_AD)}
					title={t("POST_A_JOB_LABEL")}
				/>
			)}
		</div>
	);
};

export default Header;
