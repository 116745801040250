import { homeUrl } from "./apiEndPoints";
import axios from "axios";

const cookies = require("react-cookies");

const clearCookies = () => {
	cookies.remove("lang", { path: "/", domain: homeUrl });
	cookies.remove("ptkjauthtoken", { path: "/", domain: homeUrl });
	cookies.remove("ptkjrefreshtoken", {
		path: "/",
		domain: homeUrl,
	});
	cookies.remove("tokenexpiry", { path: "/", domain: homeUrl });
	cookies.remove("moduleSelector", { path: "/", domain: homeUrl });
	localStorage.clear();
	setTimeout(() => {
		window.location.reload();
	}, 200);
};

const makeRequest = (options) => {
	return new Promise((resolve, reject) => {
		let url = new URL(options.url);
		if (
			cookies.load("ptkjauthtoken", { domain: homeUrl }) &&
			cookies.load("ptkjrefreshtoken", { domain: homeUrl })
		) {
			options = {
				...options,
				...(options.body && { data: options.body }),
				url: url.href,
				headers: {
					...options.headers,
					Authorization: `Bearer ${cookies.load("ptkjauthtoken")}`,
					"Accept-Language": `${cookies.load("lang") === "eng" ? "en-us" : "id"}`,
				},
			};
		}
		if (
			cookies.load("ptkjauthtoken", { domain: homeUrl }) &&
			cookies.load("ptkjrefreshtoken", { domain: homeUrl })
		) {
			options = {
				...options,
				...(options.body && { data: options.body }),
				url: url.href,
				headers: {
					...options.headers,
					Authorization: `Bearer ${cookies.load("ptkjauthtoken")}`,
					"Accept-Language": `${cookies.load("lang") === "eng" ? "en-us" : "id"}`,
				},
			};
		}

		axios(options)
			.then(async (response) => {
				let apiResponse,
					body = response.data;
				const UNAUTHORIZED =
					response.status === 401 &&
					cookies.load("ptkjauthtoken") &&
					cookies.load("ptkjrefreshtoken");
				try {
					apiResponse = typeof body === "string" ? JSON.parse(body) : body;
				} catch (e) {
					apiResponse = body;
				}

				if (UNAUTHORIZED) {
					clearCookies();
				} else {
					resolve(apiResponse);
				}
			})
			.catch((err) => {
				let apiResponse;
				const TOKEN_EXPIRED =
					cookies.load("ptkjauthtoken") &&
					cookies.load("ptkjrefreshtoken") &&
					err.response.data.code === 400 &&
					err.response.data.message.includes("token");
				if (TOKEN_EXPIRED) {
					clearCookies();
					return;
				}
				try {
					apiResponse = JSON.parse(err).response.data;
				} catch (e) {
					apiResponse = err?.response?.data;
				}
				resolve(apiResponse);
			});
	});
};

export default makeRequest;
