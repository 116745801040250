import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Button from '../../shared/component/ButtonComponent/Button';
import Dropdown from '../../shared/component/DropdownComponent/Dropdown';
import Input from '../../shared/component/InputComponent/Input';
import JobListCardComponent from '../../shared/component/JobListCardComponent/JobListCardComponent';
import LoadingComponent from '../../shared/component/LoadingComponent/LoadingComponent';
import Image from '../../shared/component/UI/Image';
import {
  formatString,
  getCurrencySymbol,
  getLocale,
} from '../../shared/utils/string';
import * as Variable from '../../shared/utils/variables';
import * as RoutePath from '../../shared/utils/routeLink';

import './JobSearching.scss';
import { TEMP_LIST } from './TempData';
import useToast from 'shared/hooks/useToast';

// const ITEMS_PER_PAGE = 20;

const tempPromise = (page) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        code: 200,
        data: TEMP_LIST,
      });
    }, 500);
  });

const JobSearching = ({ ENV_NAME, ...props }) => {
  const toast = useToast();
  const [height, setHeight] = useState(0);
  const [page, setPage] = useState('');
  const [selectedJobs, setSelectedJobs] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState('');
  const [total, setTotal] = useState({
    has_next: false,
    total_items: 0,
  });
  const [search, setSearch] = useState('');
  const [location, setLocation] = useState('');
  const jobInfoRef = useRef();

  // Set onScoll function to set the height of the page
  useEffect(() => {
    const onSizeChange = () => {
      if (jobInfoRef?.current) {
        let height =
          window.innerHeight -
          Math.max(jobInfoRef?.current?.getBoundingClientRect()?.top, 8) -
          8;
        setHeight(height);
      }
    };
    onSizeChange();
    // clean up code
    window.removeEventListener('scroll', onSizeChange);
    window.removeEventListener('resize', onSizeChange);
    window.addEventListener('scroll', onSizeChange, { passive: true });
    window.addEventListener('resize', onSizeChange);
    return () => {
      window.removeEventListener('scroll', onSizeChange);
      window.removeEventListener('resize', onSizeChange);
    };
  }, []);

  useEffect(() => {
    setPage(1);
    getJobs(1);
  }, [filters]); // eslint-disable-line

  useEffect(() => {
    if (page > 1) getJobs(page);
  }, [page]); // eslint-disable-line

  const getJobs = async (pg) => {
    setIsLoading(true);
    let res = await tempPromise(pg);

    if (res?.code === 200) {
      setTotal((p) => ({ ...p, has_next: true }));
      setJobs(res?.data);
      setSelectedJobs(res?.data?.[0]);
    } else {
      setTotal((p) => ({ ...p, has_next: false }));
      toast.error(
        res.message || Variable.SOMETHING_WENT_WRONG_TRY_AGAIN[ENV_NAME],
      );
    }
    setIsLoading(false);
  };

  return (
    <div className="job-searching">
      <header className="blue-header">
        <div className="container">
          <div className="d-flex gap-xs mb-med align-items-center">
            <Input
              dropdownType="search"
              className="flex-grow-1"
              value={search}
              onChange={setSearch}
            />
            <Input
              dropdownType="location"
              className="flex-grow-1"
              value={location}
              onChange={setLocation}
            />
            <Button type="secondary" title="Find Job" size="sm" />
          </div>
          <div className="filter-cont">
            <Dropdown className="text-nowrap" type="date" />
            <Dropdown className="text-nowrap" type="salary" />
            <Dropdown className="text-nowrap" type="jobType" />
            <Dropdown className="text-nowrap" type="workMode" />
            <Dropdown className="text-nowrap" type="experienceLevel" />
            <Dropdown className="text-nowrap" type="industry" />
            <Dropdown className="text-nowrap" type="underTenApp" />
          </div>
        </div>
      </header>
      <div className="container gap-std d-flex py-xl">
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="job-listing">
              <span className="listing-count">
                {formatString(Variable.JOB_MATCHES[ENV_NAME], 1, 20, 1082)}
              </span>
              {jobs?.map((i) => (
                <JobListCardComponent
                  key={i?.id}
                  data={i}
                  selected={selectedJobs?.id === i?.id}
                  onClick={() => setSelectedJobs(i)}
                  onBookmark={(data, isBookmark) =>
                    console.log(
                      isBookmark ? 'Bookmarking' : 'Unbookmarking',
                      data?.id,
                    )
                  }
                />
              ))}
            </div>
            <div ref={jobInfoRef} className="job-info" style={{ height }}>
              {selectedJobs && (
                <>
                  <div className="jl-company-logo mb-md">
                    <Image src={selectedJobs?.company_logo} />
                  </div>
                  <h3 className="jl-company-name mb-sm">
                    {selectedJobs?.name}
                  </h3>
                  <Link
                    to="#"
                    className="text-decoration-none btn-primary-default">
                    {selectedJobs?.company}
                  </Link>
                  <div className="my-xxs">{selectedJobs?.location}</div>
                  <div className="mb-std font-weight-semibold">
                    {`${getCurrencySymbol(
                      selectedJobs?.currency || 'IDR',
                      getLocale(ENV_NAME),
                    )} ${Number(
                      selectedJobs?.lower_salary || 0,
                    )?.toLocaleString(getLocale(ENV_NAME))} - ${Number(
                      selectedJobs?.upper_salary || 0,
                    )?.toLocaleString(getLocale(ENV_NAME))}`}
                  </div>
                  <div className="d-flex align-items-center">
                    {formatString(
                      Variable.POSTED_AGO[ENV_NAME],
                      moment(selectedJobs?.posted_at).fromNow(),
                    )}
                    <div className="mx-xxs">|</div>
                    {formatString(
                      Variable.URGENCY[ENV_NAME],
                      selectedJobs?.urgency,
                    )}
                    <div className="mx-xxs">|</div>
                    {formatString(
                      Variable.APPLICANTS[ENV_NAME],
                      selectedJobs?.applicants,
                    )}
                    <div className="mx-xxs">|</div>
                    <Link
                      to={RoutePath.JOB_DETAIL + '/' + selectedJobs?.id}
                      className="text-decoration-none btn-primary-default font-weight-semibold">
                      {Variable.OPEN_IN_NEW_TAB[ENV_NAME]}
                    </Link>
                  </div>
                  <div className="job-info-sticky-top">
                    <div className="job-info-btn-cont">
                      <Button
                        type="primary"
                        title={Variable.APPLY_NOW[ENV_NAME]}
                        size="sm"
                        className
                        onClick={(e) => e.stopPropagation()}
                      />
                      <Button
                        type="secondary"
                        title={Variable.ADD_TO_COMPARE[ENV_NAME]}
                        size="sm"
                        className
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                    <div className="divider" />
                  </div>
                  <h4 className="text-lg text-label-black font-weight-semibold mb-std">
                    {Variable.FULL_JOB_DESCRIPTION[ENV_NAME]}
                  </h4>
                  <h3 className="text-md text-label-black font-weight-semibold mb-sm">
                    {Variable.QUALIFICATIONS[ENV_NAME]}
                  </h3>
                  <p className="mb-std text-sm">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pellentesque mattis ipsum quis laoreet finibus. Donec elit
                    dolor, lobortis vitae ultricies non, vulputate porttitor
                    neque. Vestibulum ut rhoncus lectus, a laoreet tortor.
                    Maecenas et lorem dapibus, congue ex sit amet, vehicula
                    sapien. Vivamus dapibus mauris eu sem varius ullamcorper.
                    Vivamus dignissim faucibus velit, ut pharetra lacus finibus
                    malesuada. Aenean a auctor mi. Suspendisse in ante sed est
                    mattis molestie eget pellentesque dolor. Nam nec ipsum
                    aliquam, lacinia nisl nec, faucibus nulla. Sed aliquet, odio
                    id venenatis ultrices, tortor tortor ultricies massa, semper
                    convallis risus enim sed mauris. Orci varius natoque
                    penatibus et magnis dis parturient montes, nascetur
                    ridiculus mus. Donec in ultricies eros. Praesent fringilla
                    ultrices neque eu imperdiet. Cras in hendrerit dolor. Sed at
                    finibus mi, ut malesuada tortor. Cras sollicitudin facilisis
                    tellus, sit amet interdum massa blandit sagittis. Curabitur
                    eu risus sapien. Vivamus consequat lectus sed dolor
                    pellentesque, in vehicula ipsum convallis. Donec et
                    vulputate neque. Phasellus aliquam feugiat varius.
                    Suspendisse lobortis purus quis ullamcorper maximus. Sed
                    augue enim, accumsan in neque vitae, consectetur commodo
                    magna. Aenean eget lorem eget metus scelerisque gravida eget
                    in neque. Fusce libero leo, molestie in vehicula id,
                    ullamcorper in urna. Suspendisse dapibus ultricies nunc.
                    Etiam sodales fermentum lacus. Etiam non turpis in velit
                    efficitur euismod. Pellentesque elementum diam at est
                    faucibus sollicitudin. Nunc suscipit dolor risus, suscipit
                    condimentum dui vestibulum eget. Phasellus tellus odio,
                    elementum vel justo ac, tincidunt tincidunt turpis.
                    Vestibulum ultrices quam odio. Etiam tempor orci eu eros
                    ullamcorper, et convallis diam efficitur. Ut sagittis, sem
                    ac fringilla porttitor, erat erat sagittis nulla, non
                    euismod quam purus sed mauris. Pellentesque quis viverra mi,
                    a euismod risus. Vivamus consequat suscipit lorem ac porta.
                    Praesent pretium diam blandit felis mollis, vel fringilla
                    nibh lobortis. Nulla ac convallis nulla, at molestie neque.
                    Aliquam vestibulum nisl in massa consequat, ut scelerisque
                    erat aliquam. Nunc ullamcorper malesuada lorem in
                    ullamcorper. Vestibulum quis condimentum augue. Sed et porta
                    quam. In finibus lacus non enim volutpat tristique.
                    Curabitur auctor purus vitae diam feugiat lobortis. Praesent
                    ac felis at quam venenatis laoreet. Mauris hendrerit, elit
                    ac pulvinar rutrum, erat lacus facilisis ante, sed iaculis
                    mauris nisl vitae neque. In hac habitasse platea dictumst.
                    Pellentesque tempor sodales posuere. Curabitur nec felis sit
                    amet est pharetra ultrices nec eu felis. Proin nec convallis
                    enim. Mauris tortor diam, consectetur in cursus ut, tempus a
                    arcu. Sed eleifend, orci vel sollicitudin porta, augue justo
                    accumsan nisl, vel tristique tortor turpis quis elit.
                    Suspendisse eu ligula a leo sodales tempus. Duis vitae
                    turpis leo. Integer feugiat viverra ligula, sit amet rutrum
                    ante.
                  </p>
                  <h3 className="text-md text-label-black font-weight-semibold mb-sm">
                    {Variable.QUALIFICATIONS[ENV_NAME]}
                  </h3>
                  <p className="mb-std text-sm">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pellentesque mattis ipsum quis laoreet finibus. Donec elit
                    dolor, lobortis vitae ultricies non, vulputate porttitor
                    neque. Vestibulum ut rhoncus lectus, a laoreet tortor.
                    Maecenas et lorem dapibus, congue ex sit amet, vehicula
                    sapien. Vivamus dapibus mauris eu sem varius ullamcorper.
                    Vivamus dignissim faucibus velit, ut pharetra lacus finibus
                    malesuada. Aenean a auctor mi. Suspendisse in ante sed est
                    mattis molestie eget pellentesque dolor. Nam nec ipsum
                    aliquam, lacinia nisl nec, faucibus nulla. Sed aliquet, odio
                    id venenatis ultrices, tortor tortor ultricies massa, semper
                    convallis risus enim sed mauris. Orci varius natoque
                    penatibus et magnis dis parturient montes, nascetur
                    ridiculus mus. Donec in ultricies eros. Praesent fringilla
                    ultrices neque eu imperdiet. Cras in hendrerit dolor. Sed at
                    finibus mi, ut malesuada tortor. Cras sollicitudin facilisis
                    tellus, sit amet interdum massa blandit sagittis. Curabitur
                    eu risus sapien. Vivamus consequat lectus sed dolor
                    pellentesque, in vehicula ipsum convallis. Donec et
                    vulputate neque. Phasellus aliquam feugiat varius.
                    Suspendisse lobortis purus quis ullamcorper maximus. Sed
                    augue enim, accumsan in neque vitae, consectetur commodo
                    magna. Aenean eget lorem eget metus scelerisque gravida eget
                    in neque. Fusce libero leo, molestie in vehicula id,
                    ullamcorper in urna. Suspendisse dapibus ultricies nunc.
                    Etiam sodales fermentum lacus. Etiam non turpis in velit
                    efficitur euismod. Pellentesque elementum diam at est
                    faucibus sollicitudin. Nunc suscipit dolor risus, suscipit
                    condimentum dui vestibulum eget. Phasellus tellus odio,
                    elementum vel justo ac, tincidunt tincidunt turpis.
                    Vestibulum ultrices quam odio. Etiam tempor orci eu eros
                    ullamcorper, et convallis diam efficitur. Ut sagittis, sem
                    ac fringilla porttitor, erat erat sagittis nulla, non
                    euismod quam purus sed mauris. Pellentesque quis viverra mi,
                    a euismod risus. Vivamus consequat suscipit lorem ac porta.
                    Praesent pretium diam blandit felis mollis, vel fringilla
                    nibh lobortis. Nulla ac convallis nulla, at molestie neque.
                    Aliquam vestibulum nisl in massa consequat, ut scelerisque
                    erat aliquam. Nunc ullamcorper malesuada lorem in
                    ullamcorper. Vestibulum quis condimentum augue. Sed et porta
                    quam. In finibus lacus non enim volutpat tristique.
                    Curabitur auctor purus vitae diam feugiat lobortis. Praesent
                    ac felis at quam venenatis laoreet. Mauris hendrerit, elit
                    ac pulvinar rutrum, erat lacus facilisis ante, sed iaculis
                    mauris nisl vitae neque. In hac habitasse platea dictumst.
                    Pellentesque tempor sodales posuere. Curabitur nec felis sit
                    amet est pharetra ultrices nec eu felis. Proin nec convallis
                    enim. Mauris tortor diam, consectetur in cursus ut, tempus a
                    arcu. Sed eleifend, orci vel sollicitudin porta, augue justo
                    accumsan nisl, vel tristique tortor turpis quis elit.
                    Suspendisse eu ligula a leo sodales tempus. Duis vitae
                    turpis leo. Integer feugiat viverra ligula, sit amet rutrum
                    ante.
                  </p>
                  <div className="divider" />
                  <h4 className="text-lg text-label-black font-weight-semibold mb-std">
                    {Variable.JOB_DETAILS[ENV_NAME]}
                  </h4>
                  <div className="job-info-details-grid"></div>
                  <div className="divider" />
                  <div className="d-flex align-items-center gap-xs">
                    <Button
                      type="outline"
                      title={Variable.MORE_JOB_SEARCH[ENV_NAME]}
                      size="sm"
                      className
                      onClick={(e) => e.stopPropagation()}
                    />
                    <Button
                      type="textGray"
                      title={Variable.REPORT[ENV_NAME]}
                      size="sm"
                      className
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { ENV_NAME: state.auth.selectedEnvironment || 'bhs' };
};

const mapStateToDispatch = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapStateToDispatch,
)(withRouter(JobSearching));
