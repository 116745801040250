import React, { useState } from "react";
import { useEffect } from "react";
import * as Variable from "../../../shared/utils/variables";

function Countdown({ init = 59, disableTimer, ENV_NAME, textColor = "#fff" }) {
	const [counter, setCounter] = useState(init);

	useEffect(() => {
		const timer =
			counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

		if (counter === 0) disableTimer();

		return () => clearInterval(timer);
	}, [counter]); //eslint-disable-line

	return (
		<span
			style={{
				color: textColor,
				fontWeight: "600",
				fontSize: "1rem",
				letterSpacing: "0.5px",
			}}
		>
			<span className="cursorPointer text-disabled font-weight-bold">
				{Variable.RESEND_IN[ENV_NAME]} {`0:${String(counter).padStart(2, "0")}`}
			</span>
		</span>
	);
}

export default Countdown;
