import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import checkCircle from '../../assets/svg/check_circle.svg';
import progressDummy from '../../assets/svg/circle_progress.svg';
import outlineCircle from '../../assets/svg/outline_circle.svg';
import succesCircle from '../../assets/svg/success_file.svg';
import uploadIcon from '../../assets/svg/upload_file.svg';
import * as Variable from '../../shared/utils/variables';
import './VerificationComponent.scss';

import Button from '../../shared/component/ButtonComponent/Button';
import ModalInfo from '../../shared/component/ModalComponent/ModalInfo';
import Image from '../../shared/component/UI/Image';

// const PROGRESS_STATE = [
//   { name: 'default' },
//   { name: 'success' },
//   { name: 'failed' },
// ];

const VerificationComponent = ({ ENV_NAME, ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const [secondStep, setSecondStep] = useState(false);
  const [templateFile, setTemplateFile] = useState(null);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: '.pdf',
    multiple: false,
    maxFiles: 1,
    maxSize: 20000000,
    onDrop: (_, fileRejections) => onFileReject(_, fileRejections),
  });

  const onFileReject = (_, fileRejections) => {
    if (fileRejections?.length > 0) {
      fileRejections?.[0]?.errors.forEach((i) => {
        if (i?.code === 'file-invalid-type') {
          // setShowModal(true);
          console.log('first');
        }
      });
      return;
    }
  };

  useEffect(() => {
    // if (acceptedFiles?.length > 0 && !isEqual(acceptedFiles, templateFile)) {
    //   setTemplateFile(acceptedFiles);
    //   console.log("second accepted")
    // }
    if (
      acceptedFiles?.length > 0 &&
      acceptedFiles?.[0]?.type !== 'application/pdf'
    ) {
      setShowModal(true);
    } else {
      setTemplateFile(acceptedFiles);
    }
  }, [acceptedFiles]); //eslint-disable-line

  return (
    <div className="vComp">
      <div className="vComp-main">
        <div className="vComp-left container">
          <div className="vComp-left-content">
            {Variable.VERIFICATION_TITLE[ENV_NAME]}
          </div>
          <div className="vComp-left-progress">
            <div className="d-flex align-items-center py-4 mt-4">
              <Image src={outlineCircle} />
              <h4 className="d-flex align-items-center m-0">Upload NPWP</h4>
            </div>
            <div className="d-flex align-items-center pt-3">
              <Image src={outlineCircle} />
              <h4 className="d-flex align-items-center m-0 nonActive">
                Upload TDP/SIUP
              </h4>
            </div>
          </div>
        </div>
        <div className="vComp-right container">
          <div className="vComp-right-content">
            <div className="title">
              <h1>{Variable.UPLOAD_NPWP[ENV_NAME]}</h1>
              <p>
                {Variable.STEP_LABEL[ENV_NAME]} {'1/2'}
              </p>
            </div>
            {secondStep ? (
              <div className="upload uploadSuccess">
                <section>
                  <div className="upload-img">
                    <Image src={succesCircle} />
                  </div>
                  <div className="upload-title d-flex justify-content-center ml-0">
                    <span className="uploadText">
                      {templateFile?.[0]?.name}
                    </span>
                    <Image src={checkCircle} />
                  </div>
                  <Button
                    type="textLink"
                    size="sm"
                    title={Variable.REPLACE_LABEL[ENV_NAME]}
                    className="d-flex justify-content-center"
                    onClick={() => {
                      setSecondStep(false);
                      setTemplateFile(null);
                    }}
                  />
                </section>
              </div>
            ) : (
              <div className="upload uploadDefault">
                <section>
                  {templateFile?.length > 0 ? (
                    <>
                      <div className="upload-img">
                        <Image src={progressDummy} />
                      </div>
                      <div className="upload-title">
                        <h3>
                          {Variable.UPLOADING_FILE[ENV_NAME]}
                          <span className="uploadText">
                            {templateFile?.[0]?.name}
                          </span>
                        </h3>
                        <Button
                          type="textLink"
                          size="sm"
                          title={Variable.CANCEL_LABEL[ENV_NAME]}
                          className="d-flex justify-content-center"
                          onClick={() => setTemplateFile(null)}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="upload-img">
                          <Image src={uploadIcon} />
                        </div>
                        <div className="upload-title">
                          <h3>
                            {Variable.DRAG_FILE_LABEL[ENV_NAME]}
                            <span className="cursorPointer uploadLink">
                              {Variable.CHOOSE_FILE[ENV_NAME]}
                            </span>
                          </h3>
                          <h4>{Variable.MAXIMUM_FILE[ENV_NAME]}</h4>
                        </div>
                      </div>
                    </>
                  )}
                </section>
              </div>
            )}
            <div className="d-flex w-100">
              <Button
                type="outline"
                size="sm"
                title={Variable.SKIP_LABEL[ENV_NAME]}
                btnClassName="vCompSkip"
                onClick={() => {}}
              />
              {templateFile?.length > 0 ? (
                <Button
                  type="primary"
                  size="sm"
                  title={Variable.NEXT_STEP_LABEL[ENV_NAME]}
                  disable={false}
                  className="marLeft-auto"
                  btnClassName="vCompNext"
                  onClick={() => {
                    setSecondStep(!secondStep);
                  }}
                />
              ) : (
                <Button
                  type="primary"
                  size="sm"
                  title={Variable.NEXT_STEP_LABEL[ENV_NAME]}
                  disable={true}
                  className="marLeft-auto"
                  btnClassName="vCompNext"
                  // onClick={() => setShowModal(true)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalInfo
        isShow={showModal}
        isHide={() => setShowModal(false)}
        type="error"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { ENV_NAME: state.auth.selectedEnvironment || 'bhs' };
};

const mapStateToDispatch = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapStateToDispatch,
)(withRouter(VerificationComponent));
