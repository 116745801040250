import { Plus } from '@phosphor-icons/react';
import moment from 'moment';
import { useState } from 'react';
import {
  DataTime,
  ListTime,
  PropsHandleInputListTime,
} from '../../../../store/types/interview';
import CopyModal from '../components/CopyModal';
import ListInputTime from '../components/ListInputTime';
import Timezone from '../components/Timezone';

export default function FormTime({
  form,
  setForm,
}: {
  form: any;
  setForm: any;
}) {
  const [showModal, setshowModal] = useState(false);
  const [selectDate, setselectDate] = useState<ListTime[]>();
  const [timeZone, settimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  const [timeSlot, settimeSlot] = useState(
    form.slot || [
      {
        id: '1',
        date: moment().format('YYYY-MM-DD'),
        timezone: timeZone,
        listTime: [
          {
            id: '1',
            start_time: '',
            end_time: '',
          },
        ],
      },
    ],
  );

  // make function to add listTime to timeSlot
  const handleAddTimeOrDate = (type: string, item: DataTime) => {
    const newTimeSlot = [...timeSlot];
    let newId = (Math.random() * 1000).toFixed(0);

    if (type === 'date') {
      newTimeSlot.push({
        id: newId,
        date: '',
        timezone: timeZone,
        listTime: [
          {
            id: newId,
            start_time: '',
            end_time: '',
          },
        ],
      });
    } else {
      const index = newTimeSlot.findIndex((x) => x.id === item.id);

      newTimeSlot[index].listTime?.push({
        id: newId,
        start_time: '',
        end_time: '',
      });
    }
    convertListTimeToForm(newTimeSlot);
    settimeSlot(newTimeSlot);
  };

  const handleRemove = (item: DataTime, idTime: string) => {
    const newTimeSlot = [...timeSlot];
    if (item.id === idTime) {
      const index = newTimeSlot.findIndex((x) => x.id === item.id);
      newTimeSlot.splice(index, 1);
    }

    // if item.id !== idTime remove item from listTime
    if (item.id !== idTime) {
      const index = newTimeSlot.findIndex((x) => x.id === item.id);
      const indexTime = newTimeSlot[index].listTime?.findIndex(
        (x: ListTime) => x.id === idTime,
      );
      newTimeSlot[index].listTime?.splice(indexTime, 1);
    }
    convertListTimeToForm(newTimeSlot);

    settimeSlot(newTimeSlot);
  };

  const handleCopyTime = (item: DataTime) => {
    setselectDate(item.listTime);
    setshowModal(true);
  };

  // looping date range to get list date use moment js
  const loopDateRange = (rangeDate: any) => {
    // reverse when rangeDate[0] is lower than rangeDate[1] (swap)
    if (rangeDate[0] > rangeDate[1]) {
      rangeDate.reverse();
    }

    let newTimeSlot: { id: string; date:string; listTime?: { id: string; start_time: string; end_time: string; }[] }[] = [];

    let loop = moment(rangeDate[0]).subtract(1, 'days').format('YYYY-MM-DD');
    let end = moment(rangeDate[1]).format('YYYY-MM-DD');

    while (loop < end) {
      let newDate = moment(loop).add(1, 'days').format('YYYY-MM-DD');
      loop = newDate;

      let newId = (Math.random() * 1000).toFixed(0);
      newTimeSlot.push({
        id: newId,
        date: loop,
        listTime: selectDate?.map((item, index) => {
          return {
            id: index === 0 ? newId : (Math.random() * 1000).toFixed(0),
            start_time: item.start_time,
            end_time: item.end_time,
          };
        }),
      });
    }

    let join: DataTime[] | any = [...timeSlot, ...newTimeSlot];

    convertListTimeToForm(join);
    settimeSlot(join);
  };

  const handleChangeValue = ({
    event,
    type,
    dataDate,
    dataTime,
  }: PropsHandleInputListTime) => {
    const tempTimeSlot = [...timeSlot];
    const findDataUpdate = tempTimeSlot.find((item) => item.id === dataDate.id);
    const { name, value } = event.target;

    if (type === 'date') {
      if (findDataUpdate) {
        findDataUpdate.date = value;
        settimeSlot(tempTimeSlot);
      }
    }

    if (type === 'time') {
      const findTimeUpdate: any = findDataUpdate?.listTime?.find(
        (item: ListTime) => item.id === dataTime?.id,
      );

      if (findTimeUpdate) {
        findTimeUpdate[name] = value;

        settimeSlot(tempTimeSlot);
      }
    }
    convertListTimeToForm(timeSlot);
  };

  // convert listTime to format {date, start_time, end_time}
  const convertListTimeToForm = (data: any) => {
    let newData: any = [];

    data.map((item: any) => {
      return item.listTime.map((time: any) => {
        return newData.push({
          date: item.date,
          start_time: time.start_time,
          end_time: time.end_time,
          timezone: timeZone,
        });
      });
    });

    setForm((prev: any) => ({
      ...prev,
      slot: newData,
    }));
    return newData;
  };

  // console.log(convertListTimeToForm(timeSlot), '<<<conver');

  return (
    <section className="position-relative bg-white mt-xl p-lg rounded-xl tw container w-75">
      {/* Head Section */}
      <Timezone settimeZone={settimeZone} />

      {/* Question and Add Button */}
      <div className="position-relative d-flex justify-content-between align-items-center mt-lg">
        <p>What hours are you available?</p>

        <button
          onClick={() => handleAddTimeOrDate('date', timeSlot[0])}
          className="py-3 px-3 rounded-lg d-flex gap-2 justify-content-center align-items-center border-0 tw bg-orange-500 text-white text-sm fw-semibold">
          <Plus height={18} width={18} weight="bold" />
          Add schedule
        </button>
      </div>

      {/* Content Form Time Slot */}
      <div className="position-relative mt-xl row gap-4">
        {
          // sort data by date
          timeSlot
            .sort((a: DataTime, b: DataTime) =>
              moment(a.date).diff(moment(b.date)),
            )
            .map((item: DataTime, index: number) => (
              <ListInputTime
                handleAddTimeOrDate={handleAddTimeOrDate}
                handleRemove={handleRemove}
                handleChangeValue={handleChangeValue}
                item={item}
                key={index}
                handleCopy={() => handleCopyTime(item)}
              />
            ))
        }
      </div>
      <CopyModal
        setshowModal={setshowModal}
        showModal={showModal}
        loopDateRange={loopDateRange}
      />
    </section>
  );
}
