import { useEffect, useState } from "react";
import cookies from "react-cookies";
import { Helmet } from "react-helmet";
import cx from "classnames";
import { connect, useSelector } from "react-redux";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import moment from "moment";
import "moment/locale/id";

import { homeUrl } from "../../shared/utils/apiEndPoints";
import * as RoutePath from "../../shared/utils/routeLink";
import * as ActionTypes from "../../store/actions/actionTypes";
import LoadingComponent from "../../shared/component/LoadingComponent/LoadingComponent";
import ModalLogin from "../../shared/component/ModalComponent/ModalLogin";
import { CaretDown } from "../../shared/component/svg/Icons";
import { SCROLL_TO_TOP } from "../../shared/utils/variables";

import CandidateManagement from "../../component/CandidateManagement/CandidateManagement";
import InterviewAdd from "../../component/CandidateManagement/InterviewAdd/InterviewAdd";
import InterviewSchedule from "../../component/CandidateManagement/InterviewSchedule/InterviewSchedule";
import InterviewType from "../../component/CandidateManagement/InterviewType/InterviewType";
import TimeSlots from "../../component/CandidateManagement/TimeSlots/TimeSlots";
import DummyComponent from "../../component/DummyComponent/DummyComponent";
// import Employer from "../../component/EmployerComponent/Employer";
import HomeComponent from "../../component/HomeComponent/HomeComponent";
import JobAdListing from "component/JobAdListing/JobAdListing";
import JobCreation from "../../component/JobCreation/JobCreation";
import JobSearching from "../../component/JobSearching/JobSearching";
import VerificationComponent from "../../component/VerificationComponent/VerificationComponent";
import SignUpComponent from "../../container/SignUpComponent/SignUpComponent";
import ForgetPassComponent from "../ForgetPassComponent/ForgetPassComponent";
import AddEditUser from "./../../component/UserManagementComponent/AddEditUser";
import LogStatusHandler from "./LogStatusHandler";
import VerifyCompany from "../../component/VerifyCompany/VerifyCompany";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";
import HeaderDashboard from "shared/layouts/HeaderDashboard/HeaderDashboard";
import UserManagement from "component/UserManagementComponent/UserManagement";
import PreviewJobAd from "component/JobAdListing/components/PreviewJobAd/PreviewJobAd";

import "./MainComponent.scss";
import PrivacyPolicy from "component/PrivacyPolicy";
import TermsOfUse from "component/TermsofUse";
import Profile from "component/ProfileComponent/Profile";
import ChangePassword from "component/ChangePasswordComponent/ChangePassword";
import CompanyManagement from "component/CompanyManagement";
import usePermission from "shared/hooks/usePermission";
import ChangeSystemPassword from "component/ChangePasswordComponent/ChangeSystemPassword";
import WhatsappStickyBtn from "shared/component/atoms/WhatsappStickyBtn";
import ParentCompanySelector from "component/ParentCompanySelector/ParentCompanySelector";
import { USER_TYPES } from "constants/constants";
import ModuleChecker from "shared/hoc/ModuleChecker";

// This function will return the Component on any given address
const getActiveComp = (name) => {
	/**
	 * if name contains job-add and have id change name to /job-add
	 * @param {string} name
	 * @returns {string} name
	 */
	if (name.includes(RoutePath.JOB_AD)) {
		name = RoutePath.JOB_AD;
	}

	if (name && name.includes(RoutePath.CANDIDATE_MANAGEMENT)) {
		name = RoutePath.CANDIDATE_MANAGEMENT;
	}

	switch (name) {
		case RoutePath.ROOT: {
			return {
				activeComponent: HomeComponent,
				isActiveComponent: RoutePath.ROOT,
				hideFooter: true,
				hideHeader: true,
			};
		}
		case RoutePath.ALL_COMPONENT: {
			return {
				activeComponent: DummyComponent,
				isActiveComponent: RoutePath.ALL_COMPONENT,
			};
		}
		case RoutePath.FORGOT_PASSWORD: {
			return {
				activeComponent: ForgetPassComponent,
				isActiveComponent: RoutePath.FORGOT_PASSWORD,
			};
		}
		case RoutePath.SIGN_UP: {
			return {
				activeComponent: SignUpComponent,
				isActiveComponent: RoutePath.SIGN_UP,
				hideHeader: true,
				hideFooter: true,
			};
		}
		case RoutePath.VERIFICATION: {
			return {
				activeComponent: VerificationComponent,
				isActiveComponent: RoutePath.VERIFICATION,
				hideHeader: true,
				hideFooter: true,
			};
		}
		case RoutePath.JOB_SEARCH: {
			return {
				activeComponent: JobSearching,
				isActiveComponent: RoutePath.JOB_SEARCH,
			};
		}
		case RoutePath.EMPLOYER: {
			return {
				activeComponent: UserManagement,
				isActiveComponent: RoutePath.EMPLOYER,
				isAuthenticated: true,
				hideHeader: true,
				hideFooter: true,
				permission: "user_management",
			};
		}
		case RoutePath.VERIFY_COMPANY: {
			return {
				activeComponent: VerifyCompany,
				isActiveComponent: RoutePath.VERIFY_COMPANY,
				hideFooter: true,
				hideHeader: true,
			};
		}
		case RoutePath.ADD_USER: {
			return {
				activeComponent: AddEditUser,
				isActiveComponent: RoutePath.ADD_USER,
				hideHeader: true,
				hideFooter: true,
				permission: "user_management",
			};
		}
		case RoutePath.JOB_AD: {
			return {
				activeComponent: JobCreation,
				isActiveComponent: RoutePath.JOB_AD,
				hideFooter: true,
				isAuthenticated: true,
			};
		}

		case RoutePath.PRIVACY_POLICY: {
			return {
				activeComponent: PrivacyPolicy,
				isActiveComponent: RoutePath.PRIVACY_POLICY,
			};
		}
		case RoutePath.PROFILE: {
			return {
				activeComponent: Profile,
				isActiveComponent: RoutePath.PROFILE,
				isAuthenticated: true,
			};
		}
		case RoutePath.CHANGE_PASSWORD: {
			return {
				activeComponent: ChangePassword,
				isActiveComponent: RoutePath.CHANGE_PASSWORD,
				isAuthenticated: true,
			};
		}
		case RoutePath.CHANGE_SYSTEM_PASSWORD: {
			return {
				activeComponent: ChangeSystemPassword,
				isActiveComponent: RoutePath.CHANGE_SYSTEM_PASSWORD,
				hideFooter: true,
				isAuthenticated: true,
			};
		}
		case RoutePath.COMPANY_MANAGEMENT: {
			return {
				activeComponent: CompanyManagement,
				isActiveComponent: RoutePath.COMPANY_MANAGEMENT,
				permission: "company_profile_management",
				isAuthenticated: true,
			};
		}
		case RoutePath.TERMS_OF_USE: {
			return {
				activeComponent: TermsOfUse,
				isActiveComponent: RoutePath.TERMS_OF_USE,
			};
		}

		case RoutePath.CANDIDATE_MANAGEMENT: {
			return {
				activeComponent: CandidateManagement,
				isActiveComponent: RoutePath.CANDIDATE_MANAGEMENT,
				hideFooter: true,
				isAuthenticated: true,
				hideHeader: true,
				permission: "candidate_management",
			};
		}

		// This page is in component Candidate Management
		case RoutePath.INTERVIEW_SCHEDULE: {
			return {
				activeComponent: InterviewSchedule,
				isActiveComponent: RoutePath.INTERVIEW_SCHEDULE,
			};
		}

		// This page is in component Candidate Management
		case RoutePath.TIME_SLOT: {
			return {
				activeComponent: TimeSlots,
				isActiveComponent: RoutePath.TIME_SLOT,
			};
		}

		// This page is in component Candidate Management
		case RoutePath.INTERVIEW_TYPE: {
			return {
				activeComponent: InterviewType,
				isActiveComponent: RoutePath.INTERVIEW_TYPE,
			};
		}

		case RoutePath.JOB_AD_LISTING: {
			return {
				activeComponent: JobAdListing,
				isActiveComponent: RoutePath.JOB_AD_LISTING,
				isAuthenticated: true,
				hideHeader: true,
				hideFooter: true,
			};
		}

		case RoutePath.PREVIEW_JOB_AD: {
			return {
				activeComponent: PreviewJobAd,
				isActiveComponent: RoutePath.PREVIEW_JOB_AD,
				isAuthenticated: true,
				hideFooter: true,
			};
		}

		// This page is in component Candidate Management
		case RoutePath.INTERVIEW_ADD: {
			return {
				activeComponent: InterviewAdd,
				isActiveComponent: RoutePath.INTERVIEW_ADD,
			};
		}
		case RoutePath.SELECT_PARENT_COMPANY: {
			return {
				activeComponent: ParentCompanySelector,
				isActiveComponent: RoutePath.SELECT_PARENT_COMPANY,
				hideHeader: true,
				hideFooter: true,
			};
		}
		default: {
			return {
				activeComponent: LoadingComponent,
				isActiveComponent: name,
			};
		}
	}
};

const MainComponent = ({ ENV_NAME, ...props }) => {
	let location = useLocation();
	const [component, setComponent] = useState({
		activeComponent: LoadingComponent,
		isActiveComponent: "",
		permission: "",
	});
	const [showScrollButton, setShowScrollButton] = useState(false);
	const permission = usePermission();
	const history = useHistory();
	const userDetails = useSelector((state) => state.auth.userDetails);

	// We remove console log functionality in production
	useEffect(() => {
		const handleScrollButtonVisibility = () => {
			setShowScrollButton(window.scrollY > window.screen.height * 0.5);
		};

		if (!!cookies.load("verify_company") && !!cookies.load("ptkjauthtoken")) {
			props.history.push(RoutePath.VERIFY_COMPANY);
		}

		if (homeUrl === "portalsekolah.co.id") {
			console.log = function () {};
		}
		window.addEventListener("scroll", handleScrollButtonVisibility);

		return () => {
			window.removeEventListener("scroll", handleScrollButtonVisibility);
		};
	}, []);

	// useEffect(() => {
	// 	if (props.auth?.isAuthenticated === true && props.auth?.userDetails !== null) {
	// 		props.history.push(RoutePath.JOB_AD_LISTING);
	// 	} else {
	// 		props.history.push(RoutePath.ROOT);
	// 	}
	// }, [props.auth]);

	// For every link change, we set the component according to the page
	useEffect(() => {
		let component = getActiveComp(location.pathname);

		if (
			!!component.permission &&
			!Object.keys(permission).includes(component.permission) &&
			!!cookies.load("ptkjauthtoken") &&
			userDetails.user_type === USER_TYPES.JOB_MARKET_ADMIN &&
			!!cookies.load("verify_company")
		) {
			history.push(RoutePath.JOB_AD_LISTING);
			return;
		}

		setComponent(component);
		window.scrollTo({ top: 0, left: 0, behavior: "instant" });
	}, [location]);

	// I don't really know what's the best way to detect the cookie change
	// But for every cookie change, we set our language prefs aswell
	useEffect(() => {
		let lang = cookies.load("lang");
		let curr = cookies.load("curr");
		let momentLocale = moment.locale() === "id" ? "bhs" : "eng";
		if (lang !== ENV_NAME || lang !== momentLocale) {
			props.changeLanguage(lang);
			if (lang !== "eng") {
				moment.locale("id");
				moment.updateLocale("id", {
					longDateFormat: {
						LT: "HH:mm",
						LTS: "HH:mm:ss",
						L: "DD/MM/YYYY",
						LL: "D MMMM YYYY",
						LLL: "D MMMM YYYY [pukul] HH:mm",
						LLLL: "dddd, D MMMM YYYY [pukul] HH:mm",
					},
				});
			} else moment.locale("en");
		}

		if (curr && curr?.id !== props.curr?.id) {
			props.changeCurrency(curr);
		}
	});

	return (
		<>
			<Helmet>
				<title>Portal Kerja</title>
			</Helmet>
			{/* This is to check the login status of a user */}
			<LogStatusHandler />
			<ModalLogin show={props.loginModalShow} onHide={() => props.setLoginModalShow(false)} />
			{!component.hideHeader &&
				(props?.auth?.isAuthenticated ? <HeaderDashboard isSticky /> : <HeaderComponent />)}
			<ModuleChecker>
				<component.activeComponent />
			</ModuleChecker>
			{!component.hideFooter && <FooterComponent />}

			<WhatsappStickyBtn
				className={cx({
					changeWaPosition: !showScrollButton,
				})}
			/>
			<button
				className={`btn btn-primary btn-scrollTop ${showScrollButton ? "" : "hide"}`}
				onClick={() => {
					window.scrollTo({
						top: 0,
						behavior: "smooth",
					});
				}}
				title={SCROLL_TO_TOP[ENV_NAME]}
			>
				<CaretDown stroke="white" className="caret-top" />
			</button>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		...state,
		loginModalShow: state.auth.loginModalShow,
		curr: state.auth.currency,
		ENV_NAME: state.auth.selectedEnvironment || "bhs",
	};
};

const mapStateToDispatch = (dispatch) => {
	return {
		changeLanguage: (payload) =>
			dispatch({
				type: ActionTypes.UPDATE_LANGUAGE,
				payload: payload,
			}),
		changeCurrency: (payload) =>
			dispatch({
				type: ActionTypes.UPDATE_CURRENCY,
				payload: payload,
			}),
		setLoginModalShow: (payload = true) => {
			if (payload) {
				dispatch({
					type: ActionTypes.OPEN_LOGIN_MODAL,
				});
			} else {
				dispatch({
					type: ActionTypes.CLOSE_LOGIN_MODAL,
				});
			}
		},
	};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(MainComponent));
