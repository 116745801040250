export const TEMP_LIST = [
  {
    id: 1,
    name: "Receptionist - Shopee Solo Technopark Co-working Space (6 Months Contract)",
    company: "PT Swakarya Insan Mandiri (Branch Jakarta 4)",
    company_logo: "https://jobsumbar.com/wp-content/uploads/2019/07/Lowongan-Kerja-Bukittinggi-PT.-Swakarya-Insan-Mandiri-Terbaru.png",
    location: "Malang, Jawa Timur",
    currency: "IDR",
    upper_salary: "6000000",
    lower_salary: "3500000",
    work_mode: "Remote",
    job_type: "Freelance",
    applicants: 280,
    posted_at: "2023-01-03T02:56:40.158000Z",
    urgency: "1 week",
    hot_job: true,
    bookmarked: false,
  },
  {
    id: 2,
    name: "Group Management Performance Reporting",
    company: "PT Tokopedia",
    company_logo: "https://assets.tokopedia.net/assets-tokopedia-lite/v2/arael/kratos/36c1015e.png",
    location: "Setiabudi, Jakarta",
    currency: "IDR",
    upper_salary: "10000000",
    lower_salary: "7500000",
    work_mode: "Remote",
    job_type: "Full Time",
    applicants: 300,
    posted_at: "2023-01-29T02:56:40.158000Z",
    urgency: "1 month",
    hot_job: true,
    bookmarked: false,
  },
  {
    id: 3,
    name: "Software Engineer",
    company: "PT Bukalapak",
    company_logo: "https://www.iconlogovector.com/uploads/images/2022/01/bukalapak3.png",
    location: "Jakarta",
    currency: "IDR",
    upper_salary: "20000000",
    lower_salary: "15000000",
    work_mode: "On Site",
    job_type: "Contract",
    applicants: 1200,
    posted_at: "2023-01-15T02:56:40.158000Z",
    urgency: "1 week",
    hot_job: false,
    bookmarked: true,
  },
  {
    id: 4,
    name: "Receptionist - Shopee Solo Technopark Co-working Space (6 Months Contract)",
    company: "PT Swakarya Insan Mandiri (Branch Jakarta 4)",
    company_logo: "https://jobsumbar.com/wp-content/uploads/2019/07/Lowongan-Kerja-Bukittinggi-PT.-Swakarya-Insan-Mandiri-Terbaru.png",
    location: "Malang, Jawa Timur",
    currency: "IDR",
    upper_salary: "6000000",
    lower_salary: "3500000",
    work_mode: "Remote",
    job_type: "Freelance",
    applicants: 280,
    posted_at: "2023-02-01T02:56:40.158000Z",
    urgency: "1 week",
    hot_job: false,
    bookmarked: false,
  },
  {
    id: 5,
    name: "Receptionist - Shopee Solo Technopark Co-working Space (6 Months Contract)",
    company: "PT Swakarya Insan Mandiri (Branch Jakarta 4)",
    company_logo: "https://jobsumbar.com/wp-content/uploads/2019/07/Lowongan-Kerja-Bukittinggi-PT.-Swakarya-Insan-Mandiri-Terbaru.png",
    location: "Malang, Jawa Timur",
    currency: "IDR",
    upper_salary: "6000000",
    lower_salary: "3500000",
    work_mode: "Remote",
    job_type: "Freelance",
    applicants: 280,
    posted_at: "2023-01-03T02:56:40.158000Z",
    urgency: "1 week",
    hot_job: false,
    bookmarked: false,
  },
  {
    id: 6,
    name: "Receptionist - Shopee Solo Technopark Co-working Space (6 Months Contract)",
    company: "PT Swakarya Insan Mandiri (Branch Jakarta 4)",
    company_logo: "https://jobsumbar.com/wp-content/uploads/2019/07/Lowongan-Kerja-Bukittinggi-PT.-Swakarya-Insan-Mandiri-Terbaru.png",
    location: "Malang, Jawa Timur",
    currency: "IDR",
    upper_salary: "6000000",
    lower_salary: "3500000",
    work_mode: "Remote",
    job_type: "Freelance",
    applicants: 280,
    posted_at: "2023-01-03T02:56:40.158000Z",
    urgency: "1 week",
    hot_job: false,
    bookmarked: false,
  },
  {
    id: 7,
    name: "Receptionist - Shopee Solo Technopark Co-working Space (6 Months Contract)",
    company: "PT Swakarya Insan Mandiri (Branch Jakarta 4)",
    company_logo: "https://jobsumbar.com/wp-content/uploads/2019/07/Lowongan-Kerja-Bukittinggi-PT.-Swakarya-Insan-Mandiri-Terbaru.png",
    location: "Malang, Jawa Timur",
    currency: "IDR",
    upper_salary: "6000000",
    lower_salary: "3500000",
    work_mode: "Remote",
    job_type: "Freelance",
    applicants: 280,
    posted_at: "2023-01-03T02:56:40.158000Z",
    urgency: "1 week",
    hot_job: false,
    bookmarked: false,
  },
  {
    id: 8,
    name: "Receptionist - Shopee Solo Technopark Co-working Space (6 Months Contract)",
    company: "PT Swakarya Insan Mandiri (Branch Jakarta 4)",
    company_logo: "https://jobsumbar.com/wp-content/uploads/2019/07/Lowongan-Kerja-Bukittinggi-PT.-Swakarya-Insan-Mandiri-Terbaru.png",
    location: "Malang, Jawa Timur",
    currency: "IDR",
    upper_salary: "6000000",
    lower_salary: "3500000",
    work_mode: "Remote",
    job_type: "Freelance",
    applicants: 280,
    posted_at: "2023-01-03T02:56:40.158000Z",
    urgency: "1 week",
    hot_job: false,
    bookmarked: false,
  },
]