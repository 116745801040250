import { CheckSquare, List, Megaphone, Users, X } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import * as RoutePath from "../../utils/routeLink";
import "./Sidebar.scss";
import clx from "classnames";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Pacakges from "../../../assets/icons/sidebar/packages.svg";
import Talentsearch from "../../../assets/icons/sidebar/talent_search.svg";
import Image from "../../../shared/component/UI/Image";
import { RenderIf } from "../../utils/common";
import { Text } from "shared/component/Typography/Typography";
import { useEnvironment } from "shared/hooks/useEnvironment";
import LabelStatus from "shared/component/LabelStatus/LabelStatus";
import { useHistory } from "react-router-dom";
import { COLLAPSE_SIDEBAR } from "store/reducers/sidebarReducer";
import usePermission from "shared/hooks/usePermission";
import useTranslate from "shared/hooks/useTranslate";

const SidebarItem = ({ item, index, ENV_NAME, isCollapse, width }) => {
	const history = useHistory();
	const route = useLocation();
	const t = useTranslate();
	return (
		<div
			onClick={() => history.push(item.route)}
			key={index}
			className={[
				"sidebar-item",
				(item.activeRoutes &&
					item.activeRoutes.includes(`/${route.pathname.split("/")[1]}`)) ||
				route.pathname === item.route
					? "active"
					: "",
				!!item.className ? item.className : "",
				// width < 960 || !isCollapse ? '' : '',
				width > 960 && isCollapse ? "" : "collapsed",
			].join(" ")}
		>
			<RenderIf condition={item.type === "image"}>
				<Image src={item.icon} />
			</RenderIf>
			<RenderIf condition={item.type === "icon"}>
				<item.icon
					height={24}
					width={24}
					color={item.iconColor || "#fff"}
					weight={
						(item.activeRoutes &&
							item.activeRoutes.includes(`/${route.pathname.split("/")[1]}`)) ||
						route.pathname === item.route
							? "fill"
							: "regular"
					}
				/>
			</RenderIf>
			<RenderIf condition={width > 960 && isCollapse}>
				<div className="d-flex align-items-center w-100">
					<p className="menu-name">{item.name}</p>
					{item.isComingSoon && (
						<LabelStatus
							size="xxs"
							type="warning"
							text={t("SOON")}
							className="ms-2"
							style={{
								padding: "0.4rem",
							}}
						/>
					)}
				</div>
			</RenderIf>
		</div>
	);
};

const Sidebar = () => {
	const [width, setwidth] = useState(window.innerWidth);
	const ENV_NAME = useEnvironment();
	const dispatch = useDispatch();
	const isCollapse = useSelector((state) => state.sidebar.isCollapse);
	const handleSidebarCollapse = () => {
		dispatch({ type: COLLAPSE_SIDEBAR });
	};
	const permission = usePermission();
	const t = useTranslate();

	const sidebarItems = [
		{
			name: t("POST_JOB_LABEL"),
			icon: CheckSquare,
			type: "icon",
			iconColor: "#193560",
			className: "post-job-menu",
			route: RoutePath.JOB_AD,
			permission: "job_posting",
		},
		{
			name: "Job Ads",
			icon: Megaphone,
			type: "icon",
			route: RoutePath.JOB_AD_LISTING,
			activeRoutes: [RoutePath.CANDIDATE_MANAGEMENT, RoutePath.JOB_AD_LISTING],
			excludeFromPermission: true,
		},
		{
			name: t("TALENT_SEARCH_LABEL"),
			icon: Talentsearch,
			type: "image",
			route: "#",
			isComingSoon: true,
			permission: "talent_search_utilization",
		},
		{
			name: t("PACKAGES_LABEL"),
			icon: Pacakges,
			type: "image",
			route: "#",
			isComingSoon: true,
			excludeFromPermission: true,
		},
		{
			name: t("USERS"),
			icon: Users,
			type: "icon",
			route: RoutePath.EMPLOYER,
			id: "users",
			activeRoutes: [RoutePath.EMPLOYER, RoutePath.ADD_USER],
			permission: "user_management",
		},
	];

	useEffect(() => {
		window.addEventListener("resize", () => {
			setwidth(window.innerWidth);
		});
	}, []);

	return (
		<div
			className={clx(`navigation-div tw transition-all duration-300 z-20`, {
				"collapse-sidebar": !isCollapse || width < 960,
			})}
		>
			<RenderIf condition={width > 960 && isCollapse}>
				{/* <Image className={'logo'} src={Logo} />
				 */}
				<div className="collapse cursorPointer" onClick={handleSidebarCollapse}>
					<X width={24} height={24} />
					<Text size="md" fontWeight="medium">
						{t("COLLAPSE")}
					</Text>
				</div>
			</RenderIf>
			<RenderIf condition={width < 960 || !isCollapse}>
				{/* <Hamburger /> */}
				<List
					width={32}
					height={32}
					className="mt-4 list-icon cursorPointer"
					color="#FFFFFF"
					onClick={handleSidebarCollapse}
				/>
			</RenderIf>
			<div
				className={`sidebar-items-div ${
					isCollapse && width > 960 ? "" : "collapse-sidebar"
				}`}
			>
				{sidebarItems.map((item, index) => {
					return !!item.permission &&
						Object.keys(permission).includes(item.permission) ? (
						<SidebarItem
							item={item}
							index={index}
							ENV_NAME={ENV_NAME}
							isCollapse={isCollapse}
							width={width}
						/>
					) : !item.permission ? (
						<SidebarItem
							item={item}
							index={index}
							ENV_NAME={ENV_NAME}
							isCollapse={isCollapse}
							width={width}
						/>
					) : null;
				})}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(Sidebar));
