import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import * as RoutePath from "../../shared/utils/routeLink";
import makeRequest from "shared/utils/request";
import { generateRequestOptions } from "shared/utils/apiEndPoints";
import useTranslate from "shared/hooks/useTranslate";
import PasswordInput from "shared/component/atoms/InputType/PasswordInput";
import { Button } from "shared/component";

import { RootState } from "store/types/RootStateTypes";

import "./ChangePassword.scss";

interface FormFields {
	old_password: string;
	new_password: string;
	confirm_password: string;
}

const ChangeSystemPassword = () => {
	const t = useTranslate();
	const { userId } = useSelector((state: RootState) => ({
		userId: state?.auth?.userDetails?.id,
	}));

	const ERRORS = {
		pass_not_match: t("PASSWORD_NOT_MATCHED"),
		strong_pass_error: t("PASSWORD_VALIDATION_ERR_MSG"),
		incorrect_pass: t("INCORRECT_PASSWORD"),
	};
	const REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

	const history = useHistory();
	const [form, setForm] = useState<FormFields>({
		old_password: "",
		new_password: "",
		confirm_password: "",
	});
	const [showHelper, setShowHelper] = useState({
		new_password: false,
		confirm_password: false,
	});
	const [errors, setErrors] = useState({});
	const [isEdited, setIsEdited] = useState(false);

	useEffect(() => {
		updateProfile();
	}, []);

	// User will only be able to see the change system password screen
	// on first time login only.
	const updateProfile = async () => {
		const res = await makeRequest({
			...generateRequestOptions("editProfile"),
			data: { is_initial_login: false },
		});

		if (res.code !== 200) toast.error(res.message);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setForm((p) => ({ ...p, [name]: value }));
		setErrors((p) => ({ ...p, [name]: false, pass_not_match: false, incorrect_pass: false }));

		setShowHelper((prev) => ({ ...prev, [name]: !REGEX.test(value) }));
		setIsEdited(true);
	};

	const resetState = () => {
		setForm({ old_password: "", new_password: "", confirm_password: "" });
		setIsEdited(false);
	};

	const handleSave = async () => {
		if (!isEdited) return;

		const { old_password, new_password, confirm_password } = form;

		const errors = {};
		if (new_password !== confirm_password) errors["pass_not_match"] = true;

		const hasError = Object.values(errors).some((value) => value);
		if (hasError) return setErrors(errors);

		const res = await makeRequest({
			...generateRequestOptions("changePassword", { urlParams: userId }),
			data: { old_password, new_password, confirm_password },
		});

		if (res?.code === 200) {
			resetState();
			history.push(RoutePath.JOB_AD_LISTING);
		} else if (res?.detail?.[0]?.includes("Wrong password")) {
			setErrors((p) => ({ ...p, incorrect_pass: true }));
			setForm((p) => ({ ...p, new_password: "", confirm_password: "" }));
		} else toast.error(res.message);
	};

	return (
		<div className="cp-container csp">
			<div className="cp">
				<div className="cp-header">
					<h3>{t("CHANGE_PASSWORD")}</h3>
					<div>
						<p className="cp-header-text-1">{t("KEEP_SAFE_TEXT")}</p>
						<p className="cp-header-text-2">{t("KEEP_SAFE_DESC")}</p>
					</div>
				</div>
				<div className="cp-body">
					<PasswordInput
						label={t("CURRENT_PASSWORD_2")}
						name="old_password"
						value={form?.old_password}
						onChange={handleChange}
						placeholder={`${t("TYPE_2")} ${t("CURRENT_PASSWORD_2")}`}
						isError={errors["incorrect_pass"]}
						errorText={errors["incorrect_pass"] && ERRORS["incorrect_pass"]}
					/>
					<PasswordInput
						label={`${t("NEW_PASSWORD")}`}
						name="new_password"
						value={form?.new_password}
						onChange={handleChange}
						placeholder={`${t("TYPE_2")} ${t("NEW_PASSWORD")}`}
						isError={
							errors["new_password"] ||
							errors["pass_not_match"] ||
							showHelper["new_password"]
						}
						helperText={showHelper?.new_password}
					/>
					<PasswordInput
						label={`${t("CONFIRM_NEW_PASSWORD")}`}
						name="confirm_password"
						value={form?.confirm_password}
						onChange={handleChange}
						placeholder={`${t("RETYPE")} ${t("NEW_PASSWORD")}`}
						isError={
							errors["confirm_password"] ||
							errors["pass_not_match"] ||
							showHelper["confirm_password"]
						}
						helperText={showHelper?.confirm_password}
						errorText={errors["pass_not_match"] && ERRORS["pass_not_match"]}
					/>
				</div>
				<div className="cp-footer">
					<Button
						type="primary"
						size="sm"
						className="w-100"
						btnClassName="w-100"
						title={t("SAVE_LABEL")}
						onClick={handleSave}
						disabled={
							!form?.old_password ||
							!form?.new_password ||
							!form?.confirm_password ||
							errors["new_password"] ||
							errors["confirm_password"]
						}
					/>
					<Button
						type="textGray"
						size="sm"
						className="txt-btn"
						title={t("GOTO_JOB_ADS")}
						onClick={() => history.push(RoutePath.JOB_AD_LISTING)}
					></Button>
				</div>
			</div>
		</div>
	);
};

export default ChangeSystemPassword;
