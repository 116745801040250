import { FC } from "react";
import cx from "classnames";

import "./InputRadio.scss";

interface InputRadioProps {
    name: string;
    value?: string;
    className?: string;
    classNameInput?: string;
    classNameLabel?: string;
    onChange: (e: any) => void;
    label: string;
    checked?: boolean;
}

const InputRadio: FC<InputRadioProps> = ({
    name,
    value,
    className,
    classNameInput,
    classNameLabel,
    onChange,
    label,
    checked,
}) => {
    return (
        <label
            className={cx("input-radio-btn d-flex align-items-center gap-3 p-3 m-0", className)}
        >
            <input
                type="radio"
                name={name}
                value={value}
                onChange={onChange}
                className={classNameInput}
                style={{ height: "2rem", width: "2rem", }}
                {...{ checked }}
            />
            <span
                className={cx("fw-normal text-md text-nowrap", classNameLabel)}
            >
                {label}
            </span>
        </label>
    );
}

export default InputRadio;
