/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import makeRequest from "../utils/request";
import { generateRequestOptions } from "../utils/apiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types/RootStateTypes";

import useToast from "./useToast";
import { Nullable } from "types";

interface User {
	personal_info: {
		id: number;
		first_name: string;
		last_name: Nullable<string>;
		profile_picture: Nullable<string>;
		position: Nullable<string>;
		email: string;
		username: string;
		hrms_type: string | null;
	};
}
const useUserDetails = () => {
	const { userId } = useSelector((state: RootState) => ({
		userId: state?.auth?.userDetails?.id,
	}));

	const toast = useToast();
	const [userDetails, setUserDetails] = useState({} as User);

	useEffect(() => {
		const getUserDetails = async (userId: number) => {
			const res = await makeRequest(
				generateRequestOptions("getProfile", { urlParams: userId }),
			);

			if (res.code === 200) setUserDetails(res.data);
		};

		if (!userId) return;
		getUserDetails(userId);
	}, [userId]);

	return userDetails;
};

export default useUserDetails;
