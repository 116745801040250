import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Oval } from "react-loader-spinner";
import { Button } from "shared/component";
import ModalForm from "shared/component/ModalComponent/ModalForm";
import Image from "shared/component/UI/Image";
import useTranslate from "shared/hooks/useTranslate";
import DefaultUser from "../../assets/svg/default_user.svg";
import WarningIcon from "../../assets/svg/warn-triangle.svg";
import { isFileSizeLessOrEqual } from "shared/utils/file";
import { toast } from "react-toastify";
import { Nullable } from "types";
import { PiRepeatBold } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import { formatString } from "shared/utils/string";
import ModalInfo from "shared/component/ModalComponent/ModalInfo";
import makeRequest from "shared/utils/request";
import { generateRequestOptions } from "shared/utils/apiEndPoints";

interface Props {
	show?: boolean;
	picture?: Nullable<string>;
	onHide: () => void;
	onFetchData: () => void;
}

const ALLOWED_FILES = ["image/png", "image/jpeg"];

const ProfilePhotoModal = ({ show, picture, onHide, onFetchData }: Props) => {
	const FILE_SIZE: number = 1024 * 1024 * 5;
	const t = useTranslate();
	const [deleteModal, setDeleteModal] = useState(false);
	const [isEdited, setIsEdited] = useState(true);
	const [isUploading, setIsUploading] = useState(false);
	const [temp, setTemp] = useState<{ file: Nullable<File>; url: string }>({
		file: null,
		url: "",
	});
	const fileRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setTemp({
			file: null,
			url: picture || "",
		});
	}, [picture]);

	useEffect(() => {
		// create the preview
		if (temp?.file) {
			const objectUrl = temp?.file ? URL.createObjectURL(temp?.file) : "";
			setTemp((p) => ({ ...p, url: objectUrl }));

			// free memory when ever this component is unmounted
			return () => URL.revokeObjectURL(objectUrl);
		}
	}, [temp?.file]);

	const resetState = () => {
		setTemp({ file: null, url: "" });
		setIsEdited(false);
	};

	const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];

		if (file) {
			if (!ALLOWED_FILES.includes(file?.type)) return toast.error(t("UNSUPPORTED_FILE"));

			if (isFileSizeLessOrEqual(file, FILE_SIZE)) {
				setTemp((p) => ({ ...p, file }));
				setIsEdited(true);
			} else toast.error(t("PIC_SIZE_ERR"));
		}

		if (fileRef.current) fileRef.current.value = "";
	};

	const handleSave = async () => {
		if (!isEdited) {
			toast.success(t("PROFILE_IMG_UPDATE_SUCCESS"));
			onFetchData();
			onHide();
			return;
		}

		const formData = new FormData();
		formData.append("profile_picture", temp?.file || "");

		setIsUploading(true);
		const res = await makeRequest({ ...generateRequestOptions("editProfile"), data: formData });

		if (res?.code === 200) {
			resetState();
			toast.success(t("PROFILE_IMG_UPDATE_SUCCESS"));
			onFetchData();
			onHide();
		} else toast.error(res.message);
		setIsUploading(false);
	};

	const handleDeletePicture = async () => {
		if (temp?.file) {
			// local file just clear it
			setTemp({ file: null, url: "" });
			setDeleteModal(false);
			return;
		}

		const res = await makeRequest({
			...generateRequestOptions("editProfile"),
			data: { profile_picture: null },
		});

		if (res?.code === 200) {
			setDeleteModal(false);
			resetState();
			toast.success(t("PROFILE_IMG_REMOVED"));
		} else toast.error(res.message);
	};

	return (
		<>
			<ModalForm
				className="apply-job"
				title={`${t("PROFILE_PHOTO")}`}
				show={show}
				onHide={() => {
					onHide();
				}}
				footer={
					<div className="flex-all-center gap-md w-100">
						<Button
							type="outline"
							size="md"
							className="flex-grow-1"
							btnClassName="w-100"
							onClick={onHide}
							title={t("CLOSE_LABEL")}
							disabled={isUploading}
						/>
						<Button
							type="primary"
							size="md"
							className="flex-grow-1"
							btnClassName="w-100"
							onClick={handleSave}
							title={t("SAVE_LABEL")}
							disabled={isUploading}
						/>
					</div>
				}
			>
				<div className="ppic">
					<div className="avatar">
						<div className="profile">
							<Oval
								height={50}
								width={50}
								color="#475D80"
								wrapperClass="oval-spinner"
								visible={isUploading}
								ariaLabel="oval-loading"
								secondaryColor="#EAECF0"
								strokeWidth={7}
								strokeWidthSecondary={7}
							/>

							<Image src={temp?.url || DefaultUser} alt="Default" />
						</div>
						<p>{formatString(t("FILE_SIZE_FORMAT"), "PNG", "JPG", 5)}</p>
					</div>
					<div className="btns">
						<input
							ref={fileRef}
							name="file"
							type="file"
							id="file"
							accept=".jpg, .png, .jpeg"
							onChange={(e) => {
								onFileChange(e);
							}}
							hidden
						/>

						<p
							className="upload"
							onClick={() => fileRef.current && fileRef.current.click()}
						>
							<PiRepeatBold /> {picture ? t("CHANGE_PHOTO") : t("UPLOAD_PHOTO")}
						</p>
						<p
							className="delete"
							onClick={() => {
								if (!temp?.file && !temp?.url) return;

								setDeleteModal(true);
							}}
						>
							<FaRegTrashAlt /> {t("DELETE_FILE")}
						</p>
					</div>
				</div>
			</ModalForm>

			{/* Delete Modal */}
			<ModalInfo
				isShow={deleteModal}
				isHide={() => setDeleteModal(false)}
				onConfirm={handleDeletePicture}
				type="delete"
				title={t("ARE_YOU_SURE") + "?"}
				subtitle={t("DELETE_PIC_DESC")}
				cancelLabel={t("NO")}
				confirmLabel={t("YES")}
				customIcon={<Image src={WarningIcon} className="modal-image-icon" />}
			/>
		</>
	);
};

export default ProfilePhotoModal;
