import cx from "classnames";
import React, { useState } from "react";
import en from "react-phone-number-input/locale/en.json";
import Input, {
	Country,
	getCountries,
	getCountryCallingCode,
} from "react-phone-number-input/input";

import warningIcon from "assets/svg/warning.svg";

import "./InputPhoneNumber.scss";
import Image from "shared/component/UI/Image";

const CountrySelect = ({ value, onChange, ...rest }) => {
	const countryCallingCodes = getCountries()
		.map((country) => {
			return Number(getCountryCallingCode(country));
		})
		.sort((a, b) => {
			return a - b;
		});

	const countryCallingCodesFilter = countryCallingCodes
		.filter((item, index) => countryCallingCodes.indexOf(item) === index)
		.map((code) => code.toString());

	return (
		<div className="country-select">
			<select
				{...rest}
				value={value}
				onChange={(event) => onChange(event.target.value)}
				className="phone-input-wrapper__select-country"
			>
				{countryCallingCodesFilter.map((code) => {
					return (
						<option key={code} value={code}>
							+{code}
						</option>
					);
				})}
			</select>
		</div>
	);
};

const InputPhoneNumber = ({ value, onChange, isError, label, isRequired, placeholder }) => {
	const [country, setCountry] = useState("62");
	const findCountry: Country = getCountries().filter(
		(region) => getCountryCallingCode(region) === country,
	)[0];

	return (
		<div>
			<label
				className={cx("as-label pb-0", {
					isRequired: isRequired,
				})}
			>
				{label}
			</label>
			<div
				className={cx("phone-input-wrapper", {
					"error-bor": isError,
				})}
			>
				<CountrySelect
					labels={en}
					value={country}
					onChange={(value) => {
						setCountry(value);
						onChange("");
					}}
				/>
				<Input
					style={{
						marginLeft: "4px",
					}}
					value={value}
					onChange={onChange}
					country={findCountry}
					placeholder={placeholder}
				/>
				{isError && <Image className="warning-icon" src={warningIcon} />}
			</div>
		</div>
	);
};

export default InputPhoneNumber;
