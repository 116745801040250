import { FC } from "react";
import { Modal } from "react-bootstrap";
import cx from "classnames";

import { Text, Title } from "shared/component/Typography/Typography";

import Button from "shared/component/ButtonComponent/Button";
import useTranslate from "shared/hooks/useTranslate";
import IconStatus from "./IconStatus";

import "./ModalConfirm.scss";

type ModalDeactivateProps = React.PropsWithChildren & {
	title: string | React.ReactNode;
	show?: boolean;
	showIcon?: boolean;
	description?: string;
	type: "error" | "success";
	confirmLabel?: string;
	cancelLabel?: string;
	contentClassName?: string;
	disabled?: boolean;
	dialogClassName?: string;
	handleClose?: () => any;
	handleConfirm?: () => any;
};

const ModalConfirmation: FC<ModalDeactivateProps> = ({
	show,
	showIcon,
	handleClose,
	handleConfirm,
	type,
	title,
	description,
	children,
	cancelLabel,
	contentClassName,
	dialogClassName,
	confirmLabel,
	disabled
}) => {
	const t = useTranslate();

	return (
		<Modal
			show={show}
			contentClassName={cx("border-0 p-0", contentClassName)}
			onHide={handleClose}
			dialogClassName={cx("modal-dialog-centered modal-confirmation", dialogClassName)}
		>
			<Modal.Body className="modal-confirmation__modal-body">
				{showIcon && (
					<div className="modal-confirmation__alert-icon-circle">
						<IconStatus type={type} />
					</div>
				)}
				<div>
					<Title size="xs" fontWeight="semibold" className="modal-title-label">
						{title}
					</Title>
					{description ? (
						<Text className="tw text-gray-500" fontWeight="medium">
							{description}
						</Text>
					) : (
						children
					)}
				</div>
				<div className="d-flex modal-confirmation__modal-body__button-groups">
					<Button
						type="outline"
						size="md"
						className="modal-confirmation__modal-body__button-groups-wrapper"
						btnClassName="modal-confirmation__modal-body__button-groups-btn"
						onClick={handleClose}
						title={cancelLabel || t("CANCEL_LABEL")}
					/>
					<Button
						type={type === "error" ? "error" : "primary"}
						size="md"
						className="modal-confirmation__modal-body__button-groups-wrapper"
						btnClassName="modal-confirmation__modal-body__button-groups-btn"
						onClick={handleConfirm}
						title={confirmLabel || t("CONFIRM")}
						disabled={disabled}
					/>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalConfirmation;
