import React from "react";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import * as Variable from "../../utils/variables";
import { classHelper } from "../../utils/stringUtils";

import "./ButtonComponent.scss";
import { RenderIf } from "../../utils/common";
import { ENV, State } from "../../../types";
import Image from "shared/component/UI/Image";
import { PiArrowLeft } from "react-icons/pi";

// DOCUMENTATION
// type : [primary, secondary, error, outline, textGray, textLink]
// size : [xs, sm, md, lg, xl]

interface Props {
	typeButton?: "submit" | "reset" | "button";
	iconClassName?: string;
	children?: React.ReactNode;
	style?: {};
	type: "primary" | "secondary" | "error" | "outline" | "textGray" | "textLink" | string;
	disabled?: boolean;
	error?: boolean;
	title?: string;
	leftIcon?: any;
	rightIcon?: any;
	ENV_NAME?: string;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	size?: "xs" | "sm" | "md" | "lg" | "xl";
	color?: React.CSSProperties;
	customIcon?: React.ReactNode;
	className?: string;
	btnClassName?: string;
}

const ButtonComponent = ({
	type,
	typeButton = "submit", // 'submit
	disabled = false,
	title,
	leftIcon,
	rightIcon,
	onClick,
	size,
	children,
	customIcon,
	className,
	btnClassName,
	iconClassName,
	style,
}: Props) => {
	const { ENV_NAME } = useSelector((state: State) => ({
		ENV_NAME: state?.auth?.selectedEnvironment as ENV,
	}));

	return (
		<div className={classHelper("J-btnComp", className)}>
			<button
				type={typeButton}
				className={classHelper(
					[
						type === "primary" && "J-btnComp-primary",
						type === "secondary" && "J-btnComp-secondary",
						type === "error" && "J-btnComp-error",
						type === "outline" && "J-btnComp-outline",
						type === "textGray" && "J-btnComp-textGray",
						type === "textLink" && "J-btnComp-textLink",
						size || "xl",
						disabled && type === "primary" && "J-btnComp-primary-disable",
						disabled && type === "secondary" && "J-btnComp-secondary-disable",
						disabled && type === "error" && "J-btnComp-error-disable",
						disabled && type === "outline" && "J-btnComp-outline-disable",
						disabled && type === "textGray" && "J-btnComp-textGray-disable",
						disabled && type === "textLink" && "J-btnComp-textLink-disable",
						btnClassName,
					].join(" "),
				)}
				disabled={disabled}
				style={style}
				onClick={onClick}
			>
				<RenderIf condition={leftIcon}>
					<span className="positionRelative">
						<Image
							src={leftIcon}
							className={`${
								type === "outline" || type === "textGray" || type === "textLink"
									? "padRight J-btnComp"
									: "padRight J-btnComp-whiteImg"
							}`}
						/>
					</span>
				</RenderIf>
				{customIcon && <span className={iconClassName}>{customIcon}</span>}
				{children || title || Variable.BUTTON_CTA[ENV_NAME]}

				<RenderIf condition={rightIcon}>
					<span className="positionRelative">
						<Image
							src={rightIcon}
							className={`${
								type === "outline" || type === "textGray" || type === "textLink"
									? "padLeft J-btnComp"
									: "padLeft J-btnComp-whiteImg"
							}`}
						/>
					</span>
				</RenderIf>
			</button>
		</div>
	);
};

export default ButtonComponent;
