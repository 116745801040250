/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/";
import queryString from "query-string";
import { pick } from "lodash";
import moment from "moment";

import { JOB_AD, JOB_AD_LISTING } from "shared/utils/routeLink";
import Button from "shared/component/ButtonComponent/Button";
import Input from "shared/component/InputComponent/Input";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";

import { ENV, Nullable } from "../../types";

import Form from "./Reusable/Form";
import useTranslate from "shared/hooks/useTranslate";
import { JobDetail } from "./PreviewJob";
import useToast from "shared/hooks/useToast";

interface Props {
	ENV_NAME: ENV;
	onNext: () => void;
	onUpdateJobAdId: (id: number) => void;
}

interface FormFields {
	job_title?: string;
	posting_location_city: string;
	posting_location_district: string;
	status?: string;
	published_at?: string;
}

interface FormError {
	job_title: boolean;
	posting_location_city: boolean;
	posting_location_district: boolean;
}

interface CityDistrict {
	id: number;
	name: string;
	province?: string;
}

const TitleAndCity = ({ ENV_NAME, onNext, onUpdateJobAdId }: Props) => {
	const history = useHistory();
	const jobIdFromUrl = (queryString.parse(history.location.search)?.job_ad ||
		null) as Nullable<string>;

	const t = useTranslate();
	const toast = useToast();
	const [form, setForm] = useState({} as FormFields);
	const [errors, setErrors] = useState({} as FormError);

	const [location, setLocation] = useState({
		city: "",
		district: "",
		province: "",
	}); // for smart search
	const [city, setCity] = useState<string>("");
	const [cities, setCities] = useState<CityDistrict[]>([]);
	const [district, setDistrict] = useState<string>("");
	const [districts, setDistricts] = useState<CityDistrict[]>([]);
	const [duplicates, setDuplicates] = useState({} as JobDetail);

	const [isEdited, setIsEdited] = useState(false);
	const [isFetchingLocation, setIsFetchingLocation] = useState(true);
	const search = useLocation().search;
	const methodRepost = new URLSearchParams(search).get("method");
	const statusJob = new URLSearchParams(search).get("status");

	useEffect(() => {
		if (jobIdFromUrl) {
			getJob(jobIdFromUrl);
		}
	}, [jobIdFromUrl]);

	const getJob = async (jobId: string) => {
		const res = await makeRequest(generateRequestOptions("getJobAd", { urlParams: jobId }));

		if (res?.code === 200) {
			const pickedFields = pick(res.data, [
				"job_title",
				"posting_location_city",
				"posting_location_district",
				"status",
				"published_at",
			]);

			if (methodRepost === "duplicate") {
				setDuplicates(res.data);
			}

			setCity(pickedFields?.posting_location_city);
			setDistrict(pickedFields?.posting_location_district);
			setForm(pickedFields as FormFields);
		} else toast.error(res.message);
	};

	useEffect(() => {
		const debounceSearch = setTimeout(fetchCityAndDistrict, 500);
		return () => clearTimeout(debounceSearch);
	}, [location?.city, location?.district, isFetchingLocation]);

	useEffect(() => {
		if (isFetchingLocation && !location?.city)
			setForm((prev) => ({ ...prev, posting_location_city: "" }));
		if (isFetchingLocation && !location?.district)
			setForm((prev) => ({ ...prev, posting_location_district: "" }));
	}, [location?.city, location?.district]);

	const fetchCityAndDistrict = async () => {
		if (isFetchingLocation) {
			if (!location?.city && !location?.district) {
				// setDistricts([]);
				// setCities([]);
				return;
			}

			const res = await makeRequest(
				generateRequestOptions("getLocation", {
					queryParams: {
						city: location?.city,
						page_size: 7,
						...(!location?.city && location?.district
							? { district: location?.district, city }
							: {}),
					},
				}),
			);

			if (res.code === 200) {
				if (city)
					setCities(
						res.data.map(
							(
								d: { district: string; city: string; province: string },
								idx: number,
							) => ({
								id: idx + 1,
								name: d?.city,
								province: d?.province,
							}),
						),
					);

				if (district)
					setDistricts(
						res.data.map((d: { district: string; city: string }, idx: number) => ({
							id: idx + 1,
							name: d?.district,
						})),
					);
			} else toast.error(res.message);
		}
	};

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setForm((prev) => ({
			...prev,
			[name]: value,
		}));
		setErrors((prev) => ({ ...prev, [name]: false }));
		setIsEdited(true);
	};

	const handleSave = async () => {
		if (!isEdited && !methodRepost) return onNext();

		const { job_title } = form;

		const errors = {} as FormError;
		if (!job_title) errors["job_title"] = true;
		if (!city) errors["city"] = true;

		const hasError = Object.values(errors).some((err) => err);
		if (hasError) return setErrors(errors);

		let data: FormFields = {
			job_title,
			posting_location_city: city,
			posting_location_district: district,
		};

		let dataDuplicate: JobDetail = {
			...duplicates,
			job_title,
			posting_location_city: city,
			posting_location_district: district,
		};

		if (methodRepost === "duplicate" && statusJob === "inactive") {
			data = dataDuplicate;
		}

		let url = { ...generateRequestOptions("createJobAd"), data };

		if (jobIdFromUrl && statusJob !== "inactive") {
			url = {
				...generateRequestOptions("updateJobAd", { urlParams: jobIdFromUrl }),
				data,
			};
		}

		if (methodRepost === "carry_over") {
			data = {
				...form,
				status: "draft",
			};
			url = {
				...generateRequestOptions("carryOverJobAd", { urlParams: jobIdFromUrl }),
				data,
			};
		}

		const res = await makeRequest(url);
		if (res?.code === 200) {
			resetForm();
			onNext();
			onUpdateJobAdId(res.data.id);
			if (!!methodRepost) {
				history.push(
					`${JOB_AD}/?job_ad=${res.data.id}&status=${res.data.status}&method=${methodRepost}`,
				);
				return;
			}
			history.push(`${JOB_AD}/?job_ad=${res.data.id}&status=${res.data.status}`);
		} else toast.error(res.message);
	};

	const resetForm = () => setForm({} as FormFields);

	const isScheduledJob = form.published_at && moment(form.published_at).isAfter(new Date());

	return (
		<div className="form-block titleAndCity">
			<Form title={t("JOB_TITLE_HEAD")} isRequired>
				<Input
					label={t("JOB_TITLE")}
					placeholder={t("INPUT_JOB_TITLE")}
					value={form?.job_title || ""}
					name="job_title"
					onChange={onChange}
					inputType="label"
					className="w-100"
					isError={errors["job_title"]}
					disabled={
						(form.status === "active" && !isScheduledJob) ||
						methodRepost === "carry_over"
					}
				/>
			</Form>
			<Form title={t("JOB_CITY_HEAD")} isRequired>
				<div className="flex-grow-1 location__search">
					<Input
						label={t("CITY")}
						placeholder={t("INPUT_CITY")}
						value={city || ""}
						name="city"
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							setLocation((prev) => ({ ...prev, city: e.target.value }));
							setCity(e.target.value);
							setIsFetchingLocation(true);
							setIsEdited(true);
						}}
						inputType="label"
						className="w-100"
						required
						disabled={
							(form.status === "active" && !isScheduledJob) ||
							methodRepost === "carry_over"
						}
						isError={(location?.city && cities.length === 0) || false}
						noHint={(location?.city && cities.length === 0) || false}
						hintText={t("NO_LOCATION_TEXT")}
					/>
					{location?.city && cities.length ? (
						<div className="J-ddComp-menu mt-3 p-2">
							{cities?.map((city) => (
								<div
									className="menuDD"
									key={city?.id}
									onClick={() => {
										setCity(city?.name);
										setLocation((prev) => ({
											...prev,
											city: "",
											province: city?.province || "",
										}));
										// setCities([]);
										setIsFetchingLocation(false);
										setIsEdited(true);
									}}
								>
									<p>{city?.name}</p>
								</div>
							))}
						</div>
					) : (
						""
					)}
				</div>
				<div className="flex-grow-1 location__search">
					<Input
						label={t("DISTRICT")}
						placeholder={t("INPUT_DISTRICT")}
						value={district || ""}
						name="district"
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							setLocation((prev) => ({ ...prev, district: e.target.value }));
							setDistrict(e.target.value);
							setIsFetchingLocation(true);
							setIsEdited(true);
						}}
						inputType="label"
						className="w-100"
						disabled={
							(form.status === "active" && !isScheduledJob) ||
							methodRepost === "carry_over"
						}
						isError={(location?.district && districts.length === 0) || false}
						noHint={(location?.district && districts.length === 0) || false}
						hintText={t("NO_LOCATION_TEXT")}
					/>
					{!location?.city && location?.district && districts.length ? (
						<div className="J-ddComp-menu mt-3 p-2">
							{districts?.map((district) => (
								<div
									className="menuDD"
									key={district?.id}
									onClick={() => {
										setDistrict(district?.name);
										setLocation((prev) => ({ ...prev, district: "" }));
										// setDistricts([]);
										setIsFetchingLocation(false);
										setIsEdited(true);
									}}
								>
									<p>{district?.name}</p>
								</div>
							))}
						</div>
					) : (
						""
					)}
				</div>
			</Form>
			<div className="btn-grp">
				<Button
					type="outline"
					title={t("CANCEL_BTN_LABEL")}
					onClick={() => history.push(JOB_AD_LISTING)}
					btnClassName="customBtn"
				/>
				<Button
					type="primary"
					title={t("SAVE_CONTINUE_LABEL")}
					onClick={handleSave}
					btnClassName="customBtn btn-save"
					disabled={
						!form?.job_title ||
						!city ||
						(city !== "" && location?.city !== "") ||
						(district !== "" && location?.district !== "") ||
						false
					}
				/>
			</div>
		</div>
	);
};

export default TitleAndCity;
