import { ChangeEvent, useState } from "react";
import { CloseEyeIcon, OpenEyeIcon } from "shared/component/svg/Icons";
import useTranslate from "shared/hooks/useTranslate";

interface Props {
	label: string;
	name: string;
	value: string;
	placeholder?: string;
	isError?: boolean;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	helperText?: boolean;
	errorText?: boolean;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const PasswordInput = ({
	label,
	name,
	value,
	placeholder,
	isError,
	required,
	disabled,
	readOnly,
	helperText,
	errorText,
	onChange,
	onBlur,
}: Props) => {
	const t = useTranslate();
	const [showPassword, setShowPassword] = useState(false);

	return (
		<div className="form-group J-inputComp w-100 lm-form password">
			<label htmlFor={name} className={`as-label pb-0 ${required ? "isRequired" : ""}`}>
				{label}
			</label>
			<div className="position-relative">
				<input
					id={name}
					type={showPassword ? "text" : "password"}
					className={`J-inputComp-input password-input ${isError ? "field-error" : ""} ${
						disabled ? "disable" : ""
					}`}
					name={name}
					value={value}
					autoComplete="none"
					onChange={onChange}
					onBlur={onBlur}
					placeholder={placeholder}
					disabled={disabled}
					readOnly={readOnly}
				/>
				<div
					className={`pwd-eye cursorPointer ${!showPassword ? "active" : ""}`}
					onClick={() => setShowPassword((prev) => !prev)}
				>
					{showPassword ? (
						<OpenEyeIcon width="1.6rem" height="1.6rem" stroke="#056cf2" />
					) : (
						<CloseEyeIcon width="1.6rem" height="1.6rem" stroke="#BEC4CF" />
					)}
				</div>
			</div>

			{helperText && (
				<p className={`helper-text ${isError ? "error-text" : ""}`}>
					{t("PASSWORD_VALIDATION_ERR_MSG")}
				</p>
			)}

			{errorText && (
				<p className={`helper-text ${isError ? "error-text" : ""}`}>{errorText}</p>
			)}
		</div>
	);
};

export default PasswordInput;
