import { X } from '@phosphor-icons/react';
import { Modal } from 'react-bootstrap';
import cx from "classnames";
import useTranslate from 'shared/hooks/useTranslate';

const Move = ({
    setMoveto,
    moveto,
    bulkDownload,
    candidateCheckboxes,
    handlerCloseModal,
    bulkSetCandidateCategory
}) => {
    const t = useTranslate()
    return (
        <>
            <Modal.Header>
                <span>
                    {
                        bulkDownload
                            ? t("MOVE_TOTAL_CANDIDATE").replace("{{_total_}}", candidateCheckboxes.filter((x) => x === true).length)
                            : t("MOVE")
                    }
                </span>
                <X className="close" onClick={handlerCloseModal} />
            </Modal.Header>
            <Modal.Body>
                <div
                    onClick={() => setMoveto(1)}
                    className={cx('option-div', { 'selected': moveto === 1 })}
                >
                    <p className="option-heading m-0">{t("GOOD_FIT")}</p>
                </div>
                <div
                    onClick={() => setMoveto(2)}
                    className={cx('option-div', { 'selected': moveto === 2 })}
                >
                    <p className="option-heading m-0">{t("PERHAPS")}</p>
                </div>
                <div
                    onClick={() => setMoveto(3)}
                    className={cx('option-div', { 'selected': moveto === 3 })}
                >
                    <p className="option-heading m-0">{t("UNFIT")}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div onClick={handlerCloseModal} className="btn btn-cancel">
                    {t("CANCEL_LABEL")}
                </div>
                <div
                    onClick={bulkSetCandidateCategory}
                    className={cx('btn btn-download', { 'disabled': !moveto })}
                >
                    {t("MOVE")}
                </div>
            </Modal.Footer>
        </>
    );
}

export default Move;
