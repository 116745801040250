import { useState, useEffect, FC, useMemo } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

import "./CustomDualRangeSlider.scss";

export type MIN_MAX_NUMBER = {
	min: number;
	max: number;
};

const RANGE_MAX_VALUES = 1000000;

interface DualSliderProps extends MIN_MAX_NUMBER {
	onUpdate: (e: MIN_MAX_NUMBER) => void;
	showMinMaxValue: boolean;
	step: number;
	minText: string;
	maxText: string;
	value: MIN_MAX_NUMBER;
}

const CustomDualRangeSlider: FC<DualSliderProps> = ({
	min,
	max,
	onUpdate,
	showMinMaxValue,
	step,
	minText,
	maxText,
	value: updatedValue,
}) => {
	const [value, setValue] = useState<MIN_MAX_NUMBER>({ min, max });
	const [rangeValue, setRangeValue] = useState<MIN_MAX_NUMBER>({ min: 0, max: 0 });
	const [inisiateFirstTime, setInisiateFirstTime] = useState<boolean>(false);

	useEffect(() => {
		setValue({ min: updatedValue?.min || 0, max: updatedValue?.max || 0 });
		if (updatedValue?.max > 0 && !inisiateFirstTime || updatedValue.max > rangeValue.max + RANGE_MAX_VALUES) {
			setRangeValue({
				min: updatedValue?.min,
				max: updatedValue?.max + RANGE_MAX_VALUES,
			})
			setInisiateFirstTime(true);
		}
	}, [updatedValue]);

	const handleOnChange = (newValue) => {
		const updatedValues = {
			min: newValue?.min,
			max: newValue?.max,
		};
		onUpdate(updatedValues);
	};

	const handleOnChangeComplete = (e) => {
		if (rangeValue.max - RANGE_MAX_VALUES < e?.max) {
			setRangeValue({
				min: 0,
				max: e?.max + RANGE_MAX_VALUES,
			})
		}
	}

	return (
		<div>
			<InputRange
				allowSameValues={false}
				step={step}
				value={value}
				onChange={handleOnChange}
				minValue={0}
				maxValue={rangeValue.max}
				onChangeComplete={handleOnChangeComplete}
			/>
			{showMinMaxValue && (
				<div className="d-flex justify-content-between mt-3">
					<p className="mb-0">{minText ?? value?.min}</p>
					<p className="mb-0">{maxText ?? value?.max}</p>
				</div>
			)}
		</div>
	);
};

export default CustomDualRangeSlider;
