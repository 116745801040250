import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import makeRequest from "../../shared/utils/request";

export const JOB_CREATION = "JOB_CREATION";
export const JOB_CREATION_FORM_ACTIVE = "JOB_CREATION_FORM_ACTIVE";
export const JOB_CREATION_MESSAGE = "JOB_CREATION_MESSAGE";
export const JOB_CREATION_ERROR = "JOB_CREATION_ERROR";
export const PRELOAD_QUESTION = "PRELOAD_QUESTION";
export const PRELOAD_QUESTION_SELECTED = "PRELOAD_QUESTION_SELECTED";
export const PRELOAD_QUESTION_ANSWERS = "PRELOAD_QUESTION_ANSWERS";
export const CUSTOM_QUESTION_SELECTED = "CUSTOM_QUESTION_SELECTED";
export const JOB_POSTER = "JOB_POSTER";

export const setJobCreation = (payload) => ({
	type: JOB_CREATION,
	payload,
});

export const setJobPoster = (payload) => ({
	type: JOB_POSTER,
	payload,
});

export const setJobCreationFormActive = (payload) => ({
	type: JOB_CREATION_FORM_ACTIVE,
	payload,
});

export const setJobCreationMessage = (payload) => ({
	type: JOB_CREATION_MESSAGE,
	payload,
});

export const setJobCreationError = (payload) => ({
	type: JOB_CREATION_ERROR,
	payload,
});

export const setPreloadQuestionSelected = (payload) => ({
	type: PRELOAD_QUESTION_SELECTED,
	payload,
});

export const setCustomQuestionSelected = (payload) => ({
	type: CUSTOM_QUESTION_SELECTED,
	payload,
});

export const setPreloadQuestionAnswers = (payload) => ({
	type: PRELOAD_QUESTION_ANSWERS,
	payload,
});

export const fetchListCity = async ({ pages, search, province }) => {
	try {
		const res = await makeRequest({
			...generateRequestOptions("fetchCity", {
				queryParams: {
					search: search,
					page: pages,
					province: province,
				},
			}),
		});

		if (res.code === 200) {
			return res;
		}

		return res;
	} catch (error) {
		return error;
	}
};

// =============== Start CRUD endpoint for job poster ===============
export const fetchJobPoster = async (idJobPoster) => {
	try {
		const res = await makeRequest({
			...generateRequestOptions("fetchJobCreation", {
				urlParams: idJobPoster,
			}),

			json: true,
		});

		if (res.code === 200) {
			return res;
		}

		return res;
	} catch (error) {
		console.log(error?.response?.data, "<<<<error");
		return error;
	}
};

export const insertJobPoster = async (dataForm) => {
	try {
		let res = await makeRequest({
			...generateRequestOptions("addJobCreation"),

			data: dataForm,
			json: true,
		});

		if (res.code === 200) {
			return res;
		} else {
			alert("something wrong");
			console.log(res, "<<<<res");
		}
	} catch (error) {
		console.log(error?.response?.data, "<<<<error");
		return error;
	}
};

// this function for handle update job poster every user click save & continue
export const updateJobPoster = async ({ idJobPoster, dataForm }) => {
	try {
		const res = await makeRequest({
			...generateRequestOptions("updateJobCreation", {
				urlParams: idJobPoster,
			}),

			data: dataForm,
			json: true,
		});

		if (res.code === 200) {
			return res;
		} else {
			alert("somehting wrong");
		}

		return res;
	} catch (error) {
		console.log(error?.response?.data, "<<<<error");
		return error;
	}
};

export const carryOverJobAd = async ({ idJobPoster, dataForm }) => {
	try {
		const res = await makeRequest({
			...generateRequestOptions("carryOverJobAd", {
				urlParams: idJobPoster,
			}),

			data: dataForm,
			json: true,
		});

		if (res.code === 200) {
			return res;
		} else {
			alert("somehting wrong");
		}

		return res;
	} catch (error) {
		console.log(error?.response?.data, "<<<<error");
		return error;
	}
};

// this function for handle post job poster when user click post job
export const postJobPoster = async (idJobPoster) => {
	try {
		const res = await makeRequest({
			...generateRequestOptions("postJobCreation", {
				urlParams: idJobPoster,
			}),
			data: {
				status: "active",
			},
			json: true,
		});

		if (res.code === 200) {
			return res;
		}

		return res;
	} catch (error) {
		console.log(error?.response?.data, "<<<<error");
		return error;
	}
};
// =============== End CRUD endpoint for job poster ===============

// fetch data question preload
export const fetchAnswerPreload = async (idJobPoster) => {
	try {
		const res = await makeRequest({
			...generateRequestOptions("fetchAnswerQuestion", {
				urlParams: idJobPoster,
			}),
			json: true,
		});

		if (res.code === 200) {
			return res;
		}

		return res;
	} catch (error) {
		console.log(error?.response?.data, "<<<<error");
		return error;
	}
};

// insert data question from preload question
export const insertCopyPreloadQuestion = async (form) => {
	try {
		const res = await makeRequest({
			...generateRequestOptions("copyPreloadQuestion"),
			data: form,
			json: true,
		});

		if (res.code === 200) {
			console.log(res, "<<<<res");
			return res;
		} else {
			console.log(res, "<<<<res error copy action");
			return res;
		}
	} catch (error) {
		console.log(error?.response?.data, "<<<<error copy action");
		return error;
	}
};

// update data answer question from preload and custom question
export const updateAnswerQuestion = async ({ idJobPoster, dataForm }) => {
	try {
		const res = await makeRequest({
			...generateRequestOptions("updateAnswerQuestion", {
				urlParams: idJobPoster,
			}),

			data: dataForm,
			json: true,
		});

		if (res.code === 200) {
			return res;
		}

		return res;
	} catch (error) {
		console.log(error?.response?.data, "<<<<error");
		return error;
	}
};

// insert data question for custom question
export const insertCustomQuestion = async (form) => {
	try {
		const res = await makeRequest({
			...generateRequestOptions("addCustomQuestion"),
			data: form,
			json: true,
		});

		if (res.code === 200) {
			console.log(res, "<<<<res");
			return res;
		} else {
			console.log(res, "<<<<res error copy action");
			return res;
		}
	} catch (error) {
		console.log(error?.response?.data, "<<<<error copy action");
		return error;
	}
};

// delete each answer question custom or preload by id question
export const deleteAnswerQuestion = async (idJobPoster) => {
	try {
		const res = await makeRequest({
			...generateRequestOptions("deleteAnswerQuestion", {
				urlParams: idJobPoster,
			}),
			json: true,
		});

		if (res.code === 200) {
			console.log(res, "<<<<res delete");
			return res;
		} else {
			console.log(res, "<<<<res error copy action");
			return res;
		}
	} catch (error) {
		console.log(error?.response?.data, "<<<<error copy action");
		return error;
	}
};
