import { ChangeEvent, FC } from 'react';
import cx from 'classnames';

import './Checkbox.scss';

export interface CheckboxProps {
  id?: string,
  name?: string,
  label?: string,
  value?: string,
  className?: string,
  checkedType?: 'full' | 'half',
  disabled?: boolean,
  labelPosition?: 'left' | 'right',
  checked?: boolean,
  enableLabelChecked?: boolean,
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void,
  onLabelClicked?: (e?: any) => void,
}

const Checkbox: FC<CheckboxProps> = ({
  id,
  name,
  label,
  className,
  disabled,
  checked = false,
  enableLabelChecked = true,
  labelPosition = 'right',
  value = '',
  checkedType = 'full',
  onChange,
  onLabelClicked,
}) => {
  return (
    <div className={cx(
        'checkbox-wrapper label-position',
        className,
        {
          'label-position__left': labelPosition === 'left',
          'label-position__right': labelPosition === 'right',
        }
      )}
    >
      <label htmlFor={id} className="checkbox-label-input">
        <input
          type="checkbox"
          className="checkbox-input"
          id={id}
          name={name}
          disabled={disabled}
          aria-label={label}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        {checkedType === 'full' && (
          <span className="checkmark checkmark-checklist"/>
        )}
        {checkedType === 'half' && (
          <span className="checkmark checkmark-line" />
        )}
      </label>
      {label && (
        <label
          className="checkbox-label"
          {...(enableLabelChecked && { htmlFor: id })}
          {...(onLabelClicked && { onClick: () => onLabelClicked(id) })}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
