export const formatUptoNDecimal = (number, scale = 2) => {
	return number?.toFixed(scale)?.replace(/[.,]00$/, "");
};

export const numberToRomanNumeral = (num) => {
	if (isNaN(num)) return NaN;
	var digits = String(+num).split(""),
		key = [
			"",
			"C",
			"CC",
			"CCC",
			"CD",
			"D",
			"DC",
			"DCC",
			"DCCC",
			"CM",
			"",
			"X",
			"XX",
			"XXX",
			"XL",
			"L",
			"LX",
			"LXX",
			"LXXX",
			"XC",
			"",
			"I",
			"II",
			"III",
			"IV",
			"V",
			"VI",
			"VII",
			"VIII",
			"IX",
		],
		roman = "",
		i = 3;
	while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
	return Array(+digits.join("") + 1).join("M") + roman;
};

// convert string number to currency format
export const convertToCurrency = (number) => {
	// console.log(typeof number, '<<<numbersss');
	return String(number)
		?.replace(/\D/g, "")
		.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// convert currency format to string number
export const convertToNumber = (currency) => {
	return currency.replace(/,/g, "");
};

export const convertCurrToNumber = (number) => {
	return Number(number.replace(/\./g, ""));
};

export const isValueAllowed = (value, type = "", customRegex = null) => {
	switch (type) {
		case "numberWithDecimal":
			return /^(\d*(?:[.,]\d*)?|[.,]\d+)$/.test(value);
		case "numberWithoutDecimal":
			return /^(\d*)$/.test(value);
		case "phone":
			return /^(\+?\d*)$/.test(value);
		case "custom":
			return customRegex.test(value);
		default:
			return false;
	}
};
