import { FC, useCallback, useEffect, useMemo, useState } from "react"

import { FormInput } from "shared/component"
import CustomDualRangeSlider from "shared/component/atoms/CustomDualRangeSlider"

import { QuestionsFiltersChoices } from "../../types";
import { MIN_MAX_NUMBER } from "shared/component/atoms/CustomDualRangeSlider/CustomDualRangeSlider";
import useTranslate from "shared/hooks/useTranslate";

const STEP_RANGE = 100000;

type QuestionsFiltersChoicesProps = 'onChange'
    | 'value'
    | 'numeric_accepted_answer'
    | 'title'
    | 'isCurrencyRange'
    | 'salary_amount'
    | 'salary_maximum_amount'
    | 'salary_starting_amount'
    | 'salary_type';

const QuestionRangeSlider: FC<
    Pick<
        QuestionsFiltersChoices,
        QuestionsFiltersChoicesProps
    >
> = ({
    onChange,
    value,
    numeric_accepted_answer,
    isCurrencyRange,
    title,
    salary_amount,
    salary_maximum_amount,
    salary_starting_amount,
    salary_type,
}) => {
    const t = useTranslate();
    const [minMaxValue, setMinMaxValue] = useState<MIN_MAX_NUMBER>({
        min: 0,
        max: numeric_accepted_answer || 0,
    })

    useEffect(() => {
        if (value) setMinMaxValue(value as MIN_MAX_NUMBER);
    }, [value])

    const handleUpdateMinMax = e => {
        setMinMaxValue(e);
        onChange({ max: Number(e.max), min: Number(e.min) })
    }

    const handleChangeMinValue = val => {
        setMinMaxValue({ ...minMaxValue, min: Number(val) });
        onChange({ ...minMaxValue, min: Number(val) })
    }

    const handleChangeMaxValue = val => {
        setMinMaxValue({ ...minMaxValue, max: Number(val) });
        onChange({ ...minMaxValue, max: Number(val) })
    }

    const maxValues = useMemo(() => {
        const DEFAULT_MAX_VALUE = 100000000;
        if (salary_type === "range_amount" || salary_type === "maximum_amount") {
            return (typeof value === 'object' ? value.max : salary_maximum_amount) ?? DEFAULT_MAX_VALUE
        }
        if (salary_type === "exact_amount") {
            return salary_amount ?? DEFAULT_MAX_VALUE
        }
        if (salary_type === "starting_amount") {
            return (typeof value === 'object' ? value.max : (salary_starting_amount || 0)  + 1000000) ?? DEFAULT_MAX_VALUE
        }
        return DEFAULT_MAX_VALUE;
    }, []);

    return (
        <div className='mt-3'>
            {title && (
                <div className='px-3 mt-1 question-filter__title' dangerouslySetInnerHTML={{ __html: title }} />
            )}
            <div className='px-3 py-4 flex-all-center'>
                <FormInput
                    type="text"
                    placeholder="Min"
                    format="currency"
                    className="me-3 mt-2"
                    value={minMaxValue?.min || 0}
                    {...(isCurrencyRange
                        ? {
                            withIcon: true,
                            typeIcon: 'freeText',
                            freeText: 'IDR',
                        }
                        : {})}
                    onCurrencyChange={handleChangeMinValue}
                />
                <span>{t("TO")}</span>
                <FormInput
                    type="text"
                    placeholder="Max"
                    format="currency"
                    className="ms-3 mt-2"
                    value={minMaxValue?.max || 0}
                    {...(isCurrencyRange
                        ? {
                            withIcon: true,
                            typeIcon: 'freeText',
                            freeText: 'IDR',
                        }
                        : {})}
                    onCurrencyChange={handleChangeMaxValue}
                />
            </div>
            <div className='px-3 py-4'>
                <CustomDualRangeSlider
                    minText="Min"
                    maxText="Max"
                    min={0}
                    max={maxValues}
                    value={{
                        min: minMaxValue.min,
                        max: minMaxValue.max,
                    }}
                    step={STEP_RANGE}
                    onUpdate={handleUpdateMinMax}
                    showMinMaxValue={true}
                />
            </div>
        </div>
    )
}

export default QuestionRangeSlider