import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/";
import { pick } from "lodash";
import queryString from "query-string";
import { PiArrowLeft, PiWarningBold } from "react-icons/pi";

import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";

import { Input, Select, Toggle, Button } from "shared/component";
import formatNumber from "../../shared/utils/formatNumber";

import Form from "./Reusable/Form";
import Banner from "./Reusable/Banner";

import { ENV, Nullable } from "../../types";
import useTranslate from "shared/hooks/useTranslate";
import { CURRENCY_LIST } from "shared/utils/variables";
import useToast from "shared/hooks/useToast";
import { convertCurrToNumber } from "shared/utils/numberUtils";

type Salary_Type = "exact_amount" | "range_amount" | "starting_amount" | "maximum_amount";
export type Period_Unit = "per_hour" | "per_day" | "per_week" | "per_month" | "per_year";

interface Props {
	ENV_NAME: ENV;
	jobAdId: Nullable<number>;
	onNext: () => void;
	onPrev: () => void;
}

interface FormFields {
	display_salary: boolean;
	salary_type: Nullable<Salary_Type>;
	salary_currency: string;
	salary_rate: Nullable<Period_Unit>;
	salary_amount: string | number;
	salary_starting_amount: string | number;
	salary_maximum_amount: string | number;
}

interface FormError {
	salary_type: boolean;
	display_salary: boolean;
	salary_currency: boolean;
	salary_rate: boolean;
	salary_amount: boolean;
	salary_starting_amount: boolean;
	salary_maximum_amount: boolean;
}

type Model_Type<T> = {
	id: T;
	name: string;
};

const Salary = ({ ENV_NAME, jobAdId, onNext, onPrev }: Props) => {
	const t = useTranslate();
	const toast = useToast();
	const SALARY_TYPE: Model_Type<Salary_Type>[] = [
		{ id: "range_amount", name: t("RANGE") },
		{ id: "exact_amount", name: t("EXACT_AMOUNT") },
		{ id: "starting_amount", name: t("STARTING_AMOUNT") },
		{ id: "maximum_amount", name: t("MAX_AMOUNT") },
	];
	const PERIOD_UNIT: Model_Type<Period_Unit>[] = [
		{ id: "per_hour", name: t("PER_HOUR") },
		{ id: "per_day", name: t("PER_DAY") },
		{ id: "per_week", name: t("PER_WEEK") },
		{ id: "per_month", name: t("PER_MONTH_2") },
		{ id: "per_year", name: t("PER_YEAR") },
	];

	const history = useHistory();
	const jobIdFromUrl = (queryString.parse(history.location.search)?.job_ad ||
		null) as Nullable<string>;

	const [form, setForm] = useState<FormFields>({
		salary_type: null,
		display_salary: false,
		salary_currency: "idr",
		salary_rate: "per_hour",
		salary_amount: "",
		salary_starting_amount: "",
		salary_maximum_amount: "",
	});
	const [errors, setErrors] = useState({} as FormError);
	const [isEdited, setIsEdited] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	useEffect(() => {
		setForm((prev) => ({ ...prev, salary_currency: "idr" }));
	}, []);

	useEffect(() => {
		if (jobIdFromUrl) {
			getJob(jobIdFromUrl);
			setIsEditing(true);
		}
	}, [jobIdFromUrl]);

	const getJob = async (jobId: string) => {
		const res = await makeRequest(generateRequestOptions("getJobAd", { urlParams: jobId }));

		if (res?.code === 200) {
			const pickedFields = pick(res.data, [
				"display_salary",
				"salary_type",
				"salary_currency",
				"salary_rate",
				"salary_amount",
				"salary_starting_amount",
				"salary_maximum_amount",
			]);
			pickedFields.salary_currency = "idr";

			if (pickedFields.salary_rate === null) pickedFields.salary_rate = "per_hour";

			pickedFields.salary_amount = formatNumber(
				pickedFields?.salary_amount,
				pickedFields?.salary_currency,
			);
			pickedFields.salary_starting_amount = formatNumber(
				pickedFields?.salary_starting_amount,
				pickedFields?.salary_currency,
			);
			pickedFields.salary_maximum_amount = formatNumber(
				pickedFields?.salary_maximum_amount,
				pickedFields?.salary_currency,
			);

			if (["starting_amount", "maximum_amount"]?.includes(pickedFields?.salary_type)) {
				pickedFields.salary_amount =
					pickedFields?.salary_starting_amount || pickedFields?.salary_maximum_amount;
			}

			setForm(pickedFields as FormFields);
		} else toast.error(res.message);
	};

	const onChange = (e: ChangeEvent<HTMLFormElement>, type: Nullable<string> = null) => {
		const { name, value } = e.target;
		switch (type) {
			case "toggle":
				setForm((prev) => ({
					...prev,
					[name]: e.target.checked,
				}));
				break;
			default:
				setForm((prev) => ({
					...prev,
					[name]: [
						"salary_amount",
						"salary_starting_amount",
						"salary_maximum_amount",
					].includes(name)
						? formatNumber(value, form?.salary_currency)
						: value,
				}));
		}
		setErrors((prev) => ({ ...prev, [name]: false }));
		setIsEdited(true);
	};

	const handleSave = async () => {
		// Need to save the data after btn Next is Clicked
		// if (isEditing && !isEdited) return onNext();

		const {
			display_salary,
			salary_type,
			salary_currency,
			salary_rate,
			salary_amount,
			salary_starting_amount,
			salary_maximum_amount,
		} = form;
		const errors = {} as FormError;
		if (!salary_type) errors["salary_type"] = true;
		// if (!salary_currency) errors["salary_currency"] = true;
		if (!salary_rate) errors["salary_rate"] = true;
		if (salary_type === "range_amount") {
			if (!salary_starting_amount) errors["salary_starting_amount"] = true;
			if (!salary_maximum_amount) errors["salary_maximum_amount"] = true;

			if (
				convertCurrToNumber(salary_starting_amount) >
				convertCurrToNumber(salary_maximum_amount)
			) {
				toast.error(t("RANGE_SALARY_ERR"));

				return;
			}
		}

		if (
			["exact_amount", "starting_amount", "maximum_amount"].includes(
				form.salary_type || "",
			) &&
			!salary_amount
		)
			errors["salary_amount"] = true;
		const hasError = Object.values(errors).some((err) => err);
		if (hasError) return setErrors(errors);

		const data = {
			display_salary,
			salary_type,
			salary_currency,
			salary_rate,
			salary_amount,
			salary_starting_amount,
			salary_maximum_amount,
		};
		data.salary_currency = "idr";
		// TODO: Used for exact_amount, starting_amount, maximum_amount
		data.salary_amount = +data?.salary_amount?.toString()?.replace(/[^0-9]/g, "") || 0;

		// TODO: used for range_amount salary type
		data.salary_starting_amount = +data?.salary_starting_amount
			?.toString()
			?.replace(/[^0-9]/g, "");
		data.salary_maximum_amount = +data?.salary_maximum_amount
			?.toString()
			?.replace(/[^0-9]/g, "");
		if (salary_type === "exact_amount") {
			data.salary_amount = +data?.salary_amount;
			data.salary_starting_amount = 0;
			data.salary_maximum_amount = 0;
		}
		if (salary_type === "starting_amount") {
			data.salary_starting_amount = +data?.salary_amount;
			data.salary_amount = 0;
			data.salary_maximum_amount = 0;
		}
		if (salary_type === "maximum_amount") {
			data.salary_maximum_amount = +data?.salary_amount;
			data.salary_amount = 0;
			data.salary_starting_amount = 0;
		}

		const res = await makeRequest({
			...generateRequestOptions("updateJobAd", { urlParams: jobAdId || jobIdFromUrl }),
			data,
		});
		if (res?.code === 200) {
			resetForm();
			onNext();
		} else toast.error(res.message);
	};

	const resetForm = () =>
		setForm({
			salary_type: null,
			display_salary: false,
			salary_currency: "idr",
			salary_rate: null,
			salary_amount: "",
			salary_starting_amount: "",
			salary_maximum_amount: "",
		});

	return (
		<div className="form-block salary">
			<Form title={t("SALARY_HEAD")} isRequired>
				<Toggle
					type="switch"
					leftText={t("DISPLAY_SALARY_TEXT")}
					name="display_salary"
					checked={form?.display_salary}
					onChange={(e: ChangeEvent<HTMLFormElement>) => {
						onChange(e, "toggle");
					}}
				/>
				{!form?.display_salary && (
					<Banner
						className="field-warning mb-0"
						icon={<PiWarningBold size={14} />}
						text={t("SALARY_DISPLAY_WARNING")}
					/>
				)}
				<div className="form-row">
					<Select
						label={t("SHOW_SALARY_BY")}
						name="salary_type"
						className="m-0 p-0 job-type-input"
						onChange={onChange}
						value={form.salary_type}
						items={SALARY_TYPE}
						searchable={false}
						noDefault
						isError={errors["salary_type"]}
					/>
					<Select
						label={t("CURRENCY")}
						placeholder={t("CURRENCY")}
						name="salary_currency"
						value={"idr"}
						items={CURRENCY_LIST}
						onChange={onChange}
						searchable={false}
						className="job-type-input"
						isError={errors["salary_currency"]}
						disabled
					/>
				</div>
				<div className="form-row">
					{["exact_amount", "starting_amount", "maximum_amount"].includes(
						form.salary_type || "",
					) && (
						<Input
							label={t("AMOUNT")}
							placeholder="5.000"
							value={form?.salary_amount || ""}
							name="salary_amount"
							onChange={onChange}
							inputType="label"
							className="job-type-input"
							isError={errors["salary_amount"]}
						/>
					)}
					{form.salary_type === "range_amount" && (
						<Input
							label={t("STARTING_AMOUNT")}
							placeholder="5.000"
							value={form?.salary_starting_amount}
							name="salary_starting_amount"
							onChange={onChange}
							inputType="label"
							className="job-type-input"
							isError={errors["salary_starting_amount"]}
						/>
					)}
					{form?.salary_type === "range_amount" && (
						<span className="to-sep">{t("TO")}</span>
					)}

					{form.salary_type === "range_amount" && (
						<Input
							label={t("MAX_AMOUNT")}
							placeholder="5.000"
							value={form?.salary_maximum_amount || ""}
							name="salary_maximum_amount"
							onChange={onChange}
							inputType="label"
							className="job-type-input"
							isError={errors["salary_maximum_amount"]}
						/>
					)}
					<Select
						label={t("PERIOD")}
						placeholder={t("PERIOD")}
						name="salary_rate"
						value={form?.salary_rate || PERIOD_UNIT[0]}
						items={PERIOD_UNIT}
						onChange={onChange}
						searchable={false}
						className="job-type-input"
						isError={errors["salary_rate"]}
					/>
				</div>
			</Form>
			<div className="btn-grp">
				<Button
					type="outline"
					title={t("PREVIOUS_LABEL")}
					onClick={onPrev}
					btnClassName="customBtn"
					customIcon={<PiArrowLeft size={18} className="icon icon-prev" />}
				/>
				<Button
					type="primary"
					title={t("SAVE_CONTINUE_LABEL")}
					onClick={handleSave}
					btnClassName="customBtn btn-save"
					disabled={!isEdited && !isEditing}
				/>
			</div>
		</div>
	);
};

export default Salary;
