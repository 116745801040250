import Alert from 'assets/svg/alert-image.svg';
import { Modal } from 'react-bootstrap';
import useTranslate from 'shared/hooks/useTranslate';
import Image from 'shared/component/UI/Image';

export default function EmployerAssist({ setEmployerAssist, handlerClose, setEmpAssist }) {
    const t = useTranslate()
    return (
        <Modal.Body className="modal-employer-assist">
            <Image src={Alert} />
            <p className="modal-employer-heading">
                {t("DIALOG_CONFIRM_TITLE")}
            </p>
            <ul>
                <li>{t("EMP_ASSIT_EXPLANATION_1")}</li>
                <li>{t("EMP_ASSIT_EXPLANATION_2")}</li>
            </ul>
            <div className="d-flex w-100">
                <div className="cancel-btn" onClick={() => handlerClose(false)}>
                    {t("CANCEL_LABEL")}
                </div>
                <div
                    className="accept-red-btn"
                    onClick={() => {
                            setEmployerAssist(false);
                            handlerClose(false);
                            setEmpAssist(false)
                    }}
                >
                    {t("YES_IM_SURE")}
                </div>
            </div>
        </Modal.Body>
    );
}
