import { FC, useEffect, useState } from 'react';
import { X } from '@phosphor-icons/react';
import { Modal } from 'react-bootstrap';
import { CANDIDATE_TYPES } from 'types/types';
import { FormInput } from 'shared/component';

import Checkbox from 'shared/component/atoms/Checkbox';
import useTranslate from 'shared/hooks/useTranslate';

interface ForwardProps {
    bulkDownload: boolean;
    candidateCheckboxes: boolean[];
    handlerCloseModal: () => void;
    staff: CANDIDATE_TYPES[];
    sendCandidateLink: (ids: string, bulkDownload: boolean) => void;
}

const Forward: FC<ForwardProps> = ({
    bulkDownload,
    candidateCheckboxes,
    handlerCloseModal,
    staff,
    sendCandidateLink
}) => {
    const t = useTranslate()
    const [search, setSearch] = useState<string>('');
    const [searchStaff, setSearchStaff] = useState<CANDIDATE_TYPES[]>([]);
    const [staffChecked, setStaffChecked] = useState<number[]>([]);

    useEffect(() => {
        let newStaff = [...staff];

        if (search.length > 0) {
            // TECHDEBT: ensure that this filter is suitable or there are smart-filter's endpoint from BE team
            // IF smart filter exist, ensure to use debounce functionality
            newStaff = staff.filter(
                ({ first_name, last_name }) => 
                    first_name?.toLowerCase?.()?.includes(search?.toLowerCase()) || 
                    last_name?.toLowerCase?.()?.includes(search?.toLowerCase())
                );
            setSearchStaff(new Array(newStaff.length).fill(false));
        }
        setSearchStaff(newStaff)
    }, [staff, search])

    return (
        <>
            <Modal.Header>
                <span>
                    {
                        bulkDownload
                            ? t("FORWARD_TOTAL_CANDIDATE").replace("{{_total_}}", candidateCheckboxes.filter((x) => x === true).length)
                            : t("FORWARD")
                    }
                </span>
                <X className="close" onClick={handlerCloseModal} />
            </Modal.Header>
            <Modal.Body>
                <FormInput
                    withIcon
                    onCloseIcon={() => setSearch('')}
                    className="w-100"
                    typeIcon="search"
                    placeholder={t("SEARCH_CANDIDATE_NAME")}
                    isDoubleIcon={search.length > 0}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={() => {}}
                />
                <p className="list-heading">{t("STAFF_LIST")}</p>
                <div className="staff-list">
                    {searchStaff.map((value, index) => (
                        <div className="staff-member-div" key={index}>
                            <div className="d-flex align-items-center">
                                <div className="image-div"></div>
                                <div>
                                    <p className="staff-name">{`${value.first_name} ${value.last_name}`}</p>
                                    <p className="staff-role">{value.position}</p>
                                </div>
                            </div>
                            <div>
                                <Checkbox
                                    id={`${index}`}
                                    checked={staffChecked.includes(value.id)}
                                    onChange={()=>{
                                        let newStaffChecked = [...staffChecked];
                                        if (staffChecked.includes(value.id)) {
                                            newStaffChecked = newStaffChecked.filter(id => id === value.id)
                                        } else {
                                            newStaffChecked.push(value.id);
                                        }
                                        setStaffChecked([...staffChecked, value.id])
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div
                    onClick={()=>{
                        sendCandidateLink(staff.map(val => staffChecked.includes(val.id)) as any, bulkDownload)
                        handlerCloseModal();
                    }}
                    className="btn btn-send"
                >
                    {t("SEND")}
                </div>
            </Modal.Footer>
        </>
    );
}

export default Forward;
