import React from "react";
import { Modal } from "react-bootstrap";
import Image from "../../shared/component/UI/Image";
import AlertCircleIcon from "../../assets/svg/alert_circle_icon.svg";

const ModalError = ({ isShow, onHide, modalText, modalTitle }) => {
	return (
		<Modal
			show={isShow}
			onHide={onHide}
			dialogClassName="modal-dialog-centered modal-report-job modal-error"
			size="md"
			onBackdropClick={onHide}
		>
			<Modal.Body
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					padding: "32px 24px",
				}}
			>
				<Image src={AlertCircleIcon} className="modal-error__alert-icon-circle" />
				<div>
					<h3 className="modal-error__modal-title">{modalTitle}</h3>
					<p className="modal-error__modal-text">{modalText}</p>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ModalError;
