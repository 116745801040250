import { WarningCircle, X } from '@phosphor-icons/react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import useTranslate from 'shared/hooks/useTranslate';
import Card from 'shared/component/CardComponent/Card';
import { Button } from 'shared/component';
import { setInterviewMethod } from 'store/actions/interview';

export default function Interview({ handlerCloseModal, handlerConfirm }) {
    const t = useTranslate();
    const dispatch = useDispatch();
    const [setActive, setsetActive] = useState('');

    const handleSelectMethodInterview = (item) => {
        setsetActive(item);
        dispatch(setInterviewMethod(item));
    };

    return (
        <>
            <Modal.Header className="d-flex justify-content-between align-items-cente tw rounded-t-xl bg-gray-50 py-md px-lg">
                <h4 className="text-xl fw-semibold mb-0 pb-0">
                    {t("INTERVIEW_SCHEDULE_METHOD")}
                </h4>
                <X
                    width={24}
                    height={24}
                    className="close"
                    onClick={handlerCloseModal}
                />
            </Modal.Header>

            <Modal.Body className="position-relative rows py-md px-xl">
                <p>{t("PLEASE_SELECT_INTERVIEW_SCHEDULE_METHOD")}</p>

                <div
                    className="alert alert-danger mt-lg d-flex gap-2 align-items-center gap-2 border-0 text-sm"
                    role="alert"
                    style={{
                        backgroundColor: '#FEF3F2',
                        color: '#D92D20',
                    }}
                >
                    <span>
                        <WarningCircle
                            style={{
                                backgroundColor: '#FEE4E2',
                            }}
                            className="p-2 rounded-circle bd-highligh"
                            height={24}
                            width={24}
                        />
                    </span>
                    {t("WARNING_MESSAGE_1")}
                </div>

                <div className="mt-lg d-flex flex-column gap-4">
                    {method.map((item, index) => (
                        <Card
                            key={index}
                            onClick={() => handleSelectMethodInterview(item.value)}
                            title={item.name}
                            active={setActive === item.value}
                            description={item.details}
                        />
                    ))}
                </div>
            </Modal.Body>

            <Modal.Footer className="row m-0 p-md bg-gray-50">
                <Button
                    onClick={handlerCloseModal}
                    type="outline"
                    className="col"
                    size="sm"
                    btnClassName="w-100"
                >
                    {t("CANCEL_LABEL")}
                </Button>

                <Button
                    className="col"
                    btnClassName="w-100"
                    type="primary"
                    size="sm"
                    disabled={setActive === ''}
                    onClick={() => handlerConfirm(setActive)}
                >
                    {t("CONFIRM")}
                </Button>
            </Modal.Footer>
        </>
    );
}

const method = [
    {
        name: 'Time slot',
        value: 'slots',
        details:
        'Company will provide some time slots that available for candidate to pick according to the schedule they are available.',
    },
    {
        name: 'Exact time',
        value: 'exact',
        details:
        'Company will share the date and time for interview, candidate can approve, decline, or propose new time.',
    },
];
