import { useState, useEffect } from "react";

import { EPStore, generateRequestOptions } from "shared/utils/apiEndPoints";
import makeRequest from "shared/utils/request";
import { IPagination } from "types/PaginationInterface";

interface IResponse<T> extends IPagination {
	code: number;
	message?: string;
	data?: T;
	result_counter?: Record<string, number>;
}

interface IUseFetch<T> {
	data: IResponse<T>["data"];
	error?: string;
	loading: boolean;
	getDatas: (requestOptionsGetDatas?: IRequestOptions | undefined) => Promise<IResponse<T>>;
	response: IResponse<T> | null;
}

interface IRequestOptions {
	queryParams?: object;
	urlParams?: string;
}

interface IOptions {
	lazy?: boolean;
}

const useFetch = <T>(
	url: keyof typeof EPStore,
	requestOptions?: IRequestOptions,
	options?: IOptions,
): IUseFetch<T> => {
	const [data, setData] = useState<IUseFetch<T>["data"]>();
	const [error, setError] = useState<IUseFetch<T>["error"]>("");
	const [loading, setLoading] = useState<IUseFetch<T>["loading"]>(false);
	const [response, setResponse] = useState<IUseFetch<T>["response"]>(null);

	const getDatas = async (
		requestOptionsGetDatas: IRequestOptions | undefined = requestOptions,
	) => {
		setLoading(true);
		let res: IResponse<T> = await makeRequest({
			...generateRequestOptions(url, requestOptionsGetDatas),
			headers: {
				"content-type": "application/json",
			},
			json: true,
		});

		if (res.code === 200) {
			setData(res.data);
			setLoading(false);
			setResponse(res);
		} else {
			setError(res.message);
			setLoading(false);
		}
		return res;
	};

	useEffect(() => {
		if (options?.lazy) return;
		getDatas(requestOptions);
	}, []);

	return {
		data,
		error,
		loading,
		getDatas,
		response,
	};
};

export default useFetch;
