import moment from 'moment';

import { FormInput } from 'shared/component';
import Image from 'shared/component/UI/Image';

import Postcomment from '../../../../assets/svg/post-comment.svg';
import DefaultImg from '../../../../assets/svg/default-profile-pic.svg';
import useTranslate from 'shared/hooks/useTranslate';
import { FC } from 'react';

interface CommentProps {
    comment: string;
    setComment: (v: string) => void;
    postComments: () => void;
    candidateComments: any[];
}

const Comment: FC<CommentProps> = ({
    comment,
    setComment,
    postComments,
    candidateComments
}) => {
const t = useTranslate();

return (
    <div id="commentdiv-id">
        <p className="heading size-sm">{t("COMMENT")}</p>
        <div className="d-flex">
            <FormInput
                isDoubleIcon={comment.length > 0}
                className="w-100 mt-4"
                type="text"
                placeholder={t("LEAVE_A_COMMENT")}
                value={comment}
                onChange={e => setComment(e.target.value)}
                onKeyDown={e => e.code === 'Enter' && postComments()}
            />
            <div onClick={postComments} className="post cursorPointer mt-4">
                <Image src={Postcomment} />
            </div>
        </div>
        <div className="posted-comments-div">
            {candidateComments.map((comment, i) => (
                <div className="posted-comment" key={i}>
                    <p className="comment">
                        {comment.comment}
                    </p>
                    <div className="comment-details-div">
                        <div className="d-flex align-items-center">
                            <Image className="profile-pic" src={DefaultImg} />
                            <span className="comment-owner">
                                {[comment.created_by.first_name, comment.created_by.last_name].filter(Boolean).join(" ")}
                            </span>
                        </div>
                        <p className="comment-time">
                            {`${moment(comment.created_at).format("DD MMM YYYY")} ${new Date(comment.created_at).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    </div>
    );
}

export default Comment;
