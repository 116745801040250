import React from 'react';
import './SkeletonLoader.scss';

interface SkeletonLoaderProps {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ width, height, style }) => {
  const skeletonStyle: React.CSSProperties = {
    width: width || '100px',
    height: height || '12px',
    ...style,
  };

  return <div className="skeleton-loader" style={skeletonStyle}></div>;
};

export default SkeletonLoader;
