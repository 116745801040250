import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";

import "./Editor.scss";
import { JOB_DETAIL_ERR } from "../../../utils/variables";
import formatNumber from "shared/utils/formatNumber";
import { formatString } from "shared/utils/string";
import { PiWarningCircleBold } from "react-icons/pi";
import { countCharactersWithoutTags } from "shared/utils/stringUtils";

export default function TinyMCE({
	ENV_NAME,
	label,
	description,
	name,
	value,
	minLimit,
	maxLimit,
	handleChange,
	placeholder,
	...props
}) {
	const editorRef = useRef(null);

	return (
		<div className="editor-wrapper">
			<div className="editor-header">
				<p htmlFor={name} className="editor-header--label">
					{label}
				</p>
				{description && <p className="editor-header--desc">{description}</p>}
			</div>

			<div className={`editor-container ${props.isError ? "editorError" : ""}`}>
				<Editor
					textareaName={name}
					placeholder={placeholder}
					onEditorChange={handleChange}
					onInit={(evt, editor) => (editorRef.current = editor)}
					initialValue={props.initialValue}
					value={value}
					// apiKey="e92y97qdb1jgw7bo6wbkhrreh1opf2uvlhgpfxrw95st8fro"
					apiKey="48k6zu2dd2bi2kjfhrs4yquqc7ixdavlg3nxwhp509tvmhzy"
					init={{
						height: 200,
						menubar: false,
						plugins: "lists",
						toolbar: "bold italic underline numlist bullist",
						content_style:
							"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
					}}
				/>
				<div className="editor-footer">
					<p className="error-text mb-0">
						{props.isError && (
							<>
								<PiWarningCircleBold size={18} className="icon icon-warning" />
								{formatString(
									JOB_DETAIL_ERR[ENV_NAME],
									label.toLowerCase(),
									minLimit,
								)}
							</>
						)}
					</p>
					<p className="count mb-0">
						{formatNumber(countCharactersWithoutTags(value)) || 0} /{" "}
						{formatNumber(maxLimit)}
					</p>
				</div>
			</div>
		</div>
	);
}

// list of plugins
// 'undo redo | formatselect | ' +
// 'bold italic backcolor | alignleft aligncenter ' +
// 'alignright alignjustify | bullist numlist outdent indent | ' +
// 'removeformat | help',
