import { CaretDown } from '@phosphor-icons/react';
import moment from 'moment-timezone';
import { useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { FormInput } from '../../../../shared/component';

export default function Timezone({ settimeZone }: { settimeZone: any }) {
  const target = useRef(null);
  const [show, setShow] = useState(false);
  const [filter, setfilter] = useState<string>('');
  const [timeZoneSelected, settimeZoneSelected] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  const timezones = moment.tz
    .names()
    .map((tz) => ({
      id: tz,
      name: '(UTC' + moment().tz(tz).format('Z') + ') ' + tz,
    }))
    .sort((a, b) => {
      let [ahh, amm]: any = a.name.split('UTC')[1].split(')')[0].split(':');
      let [bhh, bmm]: any = b.name.split('UTC')[1].split(')')[0].split(':');
      return +ahh * 60 + amm - (+bhh * 60 + bmm);
    });

  // handle change input filter
  const handleInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setfilter(e.target.value.toLowerCase());
  };

  // handle select enum timezone
  const handleSelectTimezone = (timezone: string) => {
    settimeZoneSelected(timezone);
    settimeZone(timezone);
    setShow(false);
  };

  return (
    <div className="position-relative d-flex justify-content-between align-items-center">
      <h3>Interview time slots</h3>

      <div className="position-relative ">
        <button
          className="border-0 bg-transparent d-flex gap-2 align-items-center"
          ref={target}
          onClick={() => setShow(!show)}>
          <p className="m-0 fw-semibold tw text-app-primary">
            {timezones.find((time) => time.id === timeZoneSelected)?.name}
          </p>
          <CaretDown
            className="position-relative tw text-app-primary cursor-pointer"
            weight="bold"
          />
        </button>
        <Overlay
          target={target.current}
          show={show}
          placement="bottom-end"
          onHide={() => {
            setShow(false);
          }}
          rootClose>
          {({
            placement: _placement,
            arrowProps: _arrowProps,
            show: _show,
            popper: _popper,
            hasDoneInitialMeasure: _hasDoneInitialMeasure,
            ...props
          }) => (
            <div
              className="bg-white border border-gray-500 tw rounded-3 shadow w-25"
              {...props}>
              <div className="p-4 border-bottom">
                <FormInput
                  onChange={handleInputFilter}
                  withIcon={true}
                  typeIcon={'search'}
                  placeholder="Search timezone"
                  value={filter}
                />
              </div>

              <div className="tw overflow-auto" style={{ maxHeight: '18rem' }}>
                {timezones
                  .filter((time) => time.name.toLowerCase().includes(filter))
                  .map((item, index) => (
                    <p
                      onClick={() => handleSelectTimezone(item.id)}
                      key={index}
                      className={[
                        'text-sm tw text-black px-4 py-2 cursor-pointer',
                        item.id === timeZoneSelected
                          ? 'fw-semibold bg-app-primary-50 '
                          : 'fw-light',
                      ].join(' ')}>
                      {item.name.toUpperCase()}
                    </p>
                  ))}
              </div>
            </div>
          )}
        </Overlay>
      </div>
    </div>
  );
}
