import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router";
import cookies from "react-cookies";
import { Anchor, Overlay } from "react-bootstrap";
import { Link } from "react-router-dom";

import * as RoutePath from "../../shared/utils/routeLink";
import * as Variable from "../../shared/utils/variables.js";
import * as ActionTypes from "../../store/actions/actionTypes";
import { homeUrl } from "../../shared/utils/apiEndPoints";

import "./HeaderComponent.scss";
import Image from "../../shared/component/UI/Image";
import Button from "../../shared/component/ButtonComponent/Button";
import homepageIcon from "../../assets/svg/homepage.svg";
import IdIcon from "../../assets/icons/indo.png";
import EnIcon from "../../assets/icons/eng.png";
import { ReactComponent as BellLogo } from "../../assets/svg/bell.svg";
import { ReactComponent as HAccHelp } from "../../assets/svg/h-acc-Help.svg";
import { ReactComponent as HAccJob } from "../../assets/svg/h-acc-job.svg";
import { ReactComponent as HAccSetting } from "../../assets/svg/h-acc-setting.svg";
import { ReactComponent as HAccSignOut } from "../../assets/svg/h-acc-SignOut.svg";
import { ReactComponent as HAccUser } from "../../assets/svg/h-acc-User.svg";
import { ReactComponent as HAccUserBlank } from "../../assets/svg/h-acc-notification-user.svg";
import hamburgerIcon from "../../assets/svg/hamburger_icon.svg";
import { CaretDown } from "../../shared/component/svg/Icons";
// import useUserDetails from "../../shared/hooks/userDetails"; // Not implemented
import Notification from "container/Notification/Notification";
import HeaderMobileComponent from "./HeaderMobileComponent";
import useAuth from "shared/hooks/useAuth";
import useUserDetails from "shared/hooks/userDetails";

export const MENU_ENUM = {
	JOBS: "jobs",
	COMPANIES: "companies",
};

const ProfilePicture = ({ picture }) => {
	if (!picture) return <HAccUserBlank />;
	return <Image className="profile-image" src={picture} />;
};

const HeaderComponent = ({
	isAuthenticated,
	setLoginModalShow,
	ENV_NAME,
	active,
	userDetails,
	...props
}) => {
	const user = useUserDetails();
	const [showLangDropdown, setShowLangDropdown] = useState(false);
	const [showCurrDropdown, setShowCurrDropdown] = useState(false);
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [profileDropdown, setProfileDropdown] = useState(false);
	const [notifDropdown, setNotifDropdown] = useState(false);
	const history = useHistory();
	const languageRef = useRef(null);
	const notifRef = useRef(null);
	const currencyRef = useRef(null);
	const profileRef = useRef(null);
	const { handleLogout: logout } = useAuth();

	// Function to show and hide dropdown
	const openDropdown = (open = true, type = "language") => {
		if (type === "currency") {
			setShowCurrDropdown(open);
		} else if (type === "language") {
			setShowLangDropdown(open);
		} else if (type === "profile") {
			setProfileDropdown(open);
		} else if (type === "notif") {
			setNotifDropdown(open);
		}
	};

	const goToHomepage = () => {
		props.history.push(RoutePath.ROOT);
	};

	const goToJobEmployer = () => window.open("https://" + homeUrl, "_self");

	const goToMegaMenu = () => {
		// props.history.push(RoutePath.ALL_COMPONENT);
	};

	const goToSignUpComponent = () => {
		props.history.push(RoutePath.SIGN_UP);
	};

	// Function to change the language
	const changeLang = async (lang) => {
		if (lang !== ENV_NAME) {
			let val = lang;
			await cookies.save("lang", val, {
				domain: homeUrl,
				path: "/",
				expires: new Date("9999-12-31T12:00:00.000Z"),
			});
			await props.changeLanguage(val);
			window.location.reload();
		}
	};

	const handleLogout = () => {
		logout().then((status) => {
			if (status) {
				history.push(RoutePath.ROOT);
			}
		});
	};

	const changeCurr = async (curr) => {
		if (curr !== props.currency) {
			let val = curr;
			cookies.save("curr", val, {
				domain: homeUrl,
				path: "/",
				expires: new Date("9999-12-31T12:00:00.000Z"),
			});
			await props.changeCurrency(val);
			window.location.reload();
		}
	};

	useEffect(() => {
		if (!isAuthenticated && profileDropdown) {
			openDropdown(false, "profile");
		}
	}, [isAuthenticated, profileDropdown]);

	return (
		<>
			<nav className="header-nav">
				<div className="container hn">
					<div className="hn-left">
						<Link to={RoutePath.ROOT}>
							<Image
								src={homepageIcon}
								onClick={() => goToHomepage()}
								className="cursorPointer hn-left-img"
							/>
						</Link>
					</div>
					<div className="show-mobile">
						<HeaderMobileComponent
							isAuthenticated={isAuthenticated}
							ENV_NAME={ENV_NAME}
							visible={showMobileMenu}
							onClose={() => setShowMobileMenu(false)}
							onLoginClick={() => setLoginModalShow(true)}
							onSignUpClick={goToSignUpComponent}
							changeLang={changeLang}
							userDetails={userDetails}
							changeCurr={changeCurr}
							currency={props.currency}
							handleLogout={handleLogout}
							onSwitchSite={goToJobEmployer}
							profilePic={user?.personal_info?.profile_picture || ""}
						/>
					</div>
					<div className="hn-right">
						<div
							className="show-mobile cursorPointer"
							onClick={() => setShowMobileMenu(true)}
						>
							<Image
								className="cursorPointer"
								onClick={() => goToMegaMenu()}
								src={hamburgerIcon}
							/>
						</div>
						{/* <div
							className={`item cursorPointer show-desktop positionRelative ${
								notifDropdown ? "active" : ""
							}`}
							onMouseEnter={() => openDropdown(true, "notif")}
							onMouseLeave={() => openDropdown(false, "notif")}
							onClick={() => openDropdown(true, "notif")}
							ref={notifRef}
						>
							<BellLogo width="2.4rem" height="2.4rem" />
							<Overlay
								target={notifRef.current}
								show={notifDropdown}
								placement="bottom-start"
							>
								{({
									placement,
									arrowProps,
									show: _show,
									popper,
									...OverlayProps
								}) => (
									<div
										{...OverlayProps}
										id="hn-dropdown-lang"
										className="hn-dropdown hn-lang"
									>
										<Notification />
									</div>
								)}
							</Overlay>
						</div> */}
						<div
							className={`item cursorPointer show-desktop positionRelative ${
								showLangDropdown ? "active" : ""
							}`}
							onMouseEnter={() => openDropdown(true, "language")}
							onMouseLeave={() => openDropdown(false, "language")}
							onClick={() => openDropdown(true, "language")}
							ref={languageRef}
						>
							<div className="flex-all-center">
								<Image
									className="hn-language"
									src={ENV_NAME === "eng" ? EnIcon : IdIcon}
								/>
								<CaretDown
									className="ms-xs"
									stroke="#193560"
									width="2rem"
									height="2rem"
								/>
							</div>
							<Overlay
								target={languageRef.current}
								show={showLangDropdown}
								placement="bottom-end"
							>
								{({
									placement,
									arrowProps,
									show: _show,
									popper,
									...OverlayProps
								}) => (
									<div
										{...OverlayProps}
										id="hn-dropdown-lang"
										className="hn-dropdown hn-lang"
									>
										<button
											className={`hn-dropdown-item ${
												ENV_NAME === "bhs" ? "active" : ""
											}`}
											onClick={() => changeLang("bhs")}
										>
											<Image
												className="hn-dropdown-item-icon lang-logo"
												src={IdIcon}
												alt="logo"
											/>
											<div className="hn-dropdown-item-label">
												Bahasa Indonesia
											</div>
										</button>
										<button
											className={`hn-dropdown-item ${
												ENV_NAME === "eng" ? "active" : ""
											}`}
											onClick={() => changeLang("eng")}
										>
											<Image
												className="hn-dropdown-item-icon lang-logo"
												src={EnIcon}
												alt="logo"
											/>
											<div className="hn-dropdown-item-label">English</div>
										</button>
									</div>
								)}
							</Overlay>
						</div>
						{/* <div
							className={`item cursorPointer show-desktop ${
								showCurrDropdown ? "active" : ""
							}`}
							onMouseEnter={() => openDropdown(true, "currency")}
							onMouseLeave={() => openDropdown(false, "currency")}
							onClick={() => openDropdown(true, "currency")}
							ref={currencyRef}
						>
							<div className="flex-all-center">
								<h5 className="mb-0 px-0 hn-currency-label">
									{props.currency?.name}
								</h5>
								<CaretDown
									className="ms-xs"
									stroke="#193560"
									width="2rem"
									height="2rem"
								/>
							</div>
							<Overlay
								target={currencyRef.current}
								show={showCurrDropdown}
								placement="bottom"
							>
								{({
									placement,
									arrowProps,
									show: _show,
									popper,
									...OverlayProps
								}) => (
									<div
										{...OverlayProps}
										id="hn-dropdown-curr"
										className="hn-dropdown"
									>
										{Variable.CURRENCY_LIST?.map((i) => (
											<button
												className={`hn-dropdown-item ${
													props.currency?.id === i?.id ? "active" : ""
												}`}
												onClick={() => changeCurr(i)}
												key={i?.id}
											>
												<div className="hn-dropdown-item-label">{i?.name}</div>
											</button>
										))}
									</div>
								)}
							</Overlay>
						</div> */}
						{isAuthenticated ? (
							<div
								className={`item cursorPointer show-desktop flex-all-center ${
									profileDropdown ? "active" : ""
								}`}
								onMouseEnter={() => openDropdown(true, "profile")}
								onMouseLeave={() => openDropdown(false, "profile")}
								onClick={() => openDropdown(true, "profile")}
								ref={profileRef}
							>
								<div>
									{userDetails?.id ? (
										<ProfilePicture
											picture={user?.personal_info?.profile_picture || ""}
										/>
									) : (
										<HAccUserBlank />
									)}
								</div>
								<div className="flex-all-center">
									<h5 className="mb-0 hn-profile-label">
										{`${userDetails?.first_name || ""} ${
											userDetails?.last_name || ""
										}`}
									</h5>
									<CaretDown stroke="#193560" width="2rem" height="2rem" />
								</div>
								<Overlay
									target={profileRef.current}
									show={profileDropdown}
									placement="bottom-end"
								>
									{({
										placement,
										arrowProps,
										show: _show,
										popper,
										...OverlayProps
									}) => (
										<div
											{...OverlayProps}
											id="hn-dropdown-profile"
											className="hn-dropdown"
										>
											<Anchor
												href={"https://myjob." + homeUrl + "/profil"}
												className="hn-dropdown-item"
											>
												<HAccUser />
												<div className="hn-dropdown-item-label mxw-auto">
													{Variable.VIEW_PROFILE[ENV_NAME]}
												</div>
											</Anchor>
											<button className="hn-dropdown-item">
												<HAccSetting />
												<div className="hn-dropdown-item-label mxw-auto">
													{Variable.ACCOUNT_SETTINGS[ENV_NAME]}
												</div>
											</button>
											<button className="hn-dropdown-item">
												<HAccHelp />
												<div className="hn-dropdown-item-label mxw-auto">
													{Variable.HELP[ENV_NAME]}
												</div>
											</button>
											<div className="hn-border-top" />
											<button
												className="hn-dropdown-item hn-exit-btn"
												onClick={handleLogout}
											>
												<HAccSignOut />
												<div className="hn-dropdown-item-label mxw-auto">
													{Variable.LOGOUT[ENV_NAME]}
												</div>
											</button>
										</div>
									)}
								</Overlay>
							</div>
						) : (
							<>
								<Button
									type="outline"
									title={Variable.LOGIN[ENV_NAME]}
									size="sm"
									className="mx-2 show-desktop"
									onClick={() => setLoginModalShow(true)}
								/>
								<Button
									type="primary"
									title={Variable.REGISTER[ENV_NAME]}
									size="sm"
									className="mx-2 show-desktop"
									onClick={goToSignUpComponent}
								/>
							</>
						)}
						{!userDetails ? (
							<>
								<span className="hn-border show-desktop"></span>
								<h5
									className="cursorPointer show-desktop"
									onClick={() => goToJobEmployer()}
								>
									{Variable.FOR_CANDIDATES[ENV_NAME]}
								</h5>
							</>
						) : null}
					</div>
				</div>
			</nav>
		</>
	);
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
	currency: state.auth.currency || { id: "IDR", name: "IDR" },
	userDetails: state.auth.userDetails,
	jobActive: state.auth.jobTabActive,
	jobs: state.auth.jobs,
});

const mapStateToDispatch = (dispatch) => {
	return {
		setJobActive: (payload) => {
			dispatch({ type: ActionTypes.ACTIVE_MENU_TAB, payload: payload });
		},
		logout: () => dispatch({ type: ActionTypes.LOGOUT }),
		setLoginModalShow: (payload = true) => {
			if (payload) {
				dispatch({
					type: ActionTypes.OPEN_LOGIN_MODAL,
				});
			} else {
				dispatch({
					type: ActionTypes.CLOSE_LOGIN_MODAL,
				});
			}
		},
		changeLanguage: (payload) =>
			dispatch({
				type: ActionTypes.UPDATE_LANGUAGE,
				payload: payload,
			}),
		changeCurrency: (payload) =>
			dispatch({
				type: ActionTypes.UPDATE_CURRENCY,
				payload: payload,
			}),

		// NOT IMPLEMENTED
		// dispatch_trendingJobs: (payload) => {
		// 	dispatch({type: ActionTypes.SET_JOBS_TRENDING , payload: payload });
		// },
		// dispatch_popularJobs: (payload) => {
		// 	dispatch({type: ActionTypes.SET_JOBS_POPULAR, payload: payload });
		// }
	};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(HeaderComponent));
