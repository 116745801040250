import React from 'react';

type BadgesStatusProps = {
  status: 'pending' | 'rejected' | 'viewed' | 'shortlisted';
  title: string;
  className?: string;
};

export default function BadgesStatus(props: BadgesStatusProps) {
  return (
    <div
      className={[
        'd-flex align-items-center justify-content-center px-sm py-xs rounded-xs tw fw-semibold text-sm',
        props.status === 'pending' && 'bg-app-secondary-50 text-app-secondary',
        props.status === 'rejected' && 'bg-app-error-50 text-app-error',
        props.status === 'viewed' && 'bg-app-primary-50 text-app-primary',
        props.status === 'shortlisted' && 'bg-app-success-50 text-app-success',
      ].join(' ')}
      // parsing props to styles.main
    >
      {props.title}
    </div>
  );
}
