import { FormInput, Textarea } from '../../../../shared/component';
import { FormExact } from '../../../../store/types/interview';

type FormSecondProps = {
  form: FormExact;
  handleChangeValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
};

export default function FormSecond(props: FormSecondProps) {
  return (
    <div className="row gap-4 mt-xl">
      <FormInput
        titleLabel="Interview PIC (optional)"
        placeholder="Interview your text"
        name="interview_pic"
        value={props.form.interview_pic}
        onChange={props.handleChangeValue}
      />

      <Textarea
        onChange={props.handleChangeValue}
        children={null}
        classNameLabel=""
        cols={10}
        isError={false}
        required={false}
        rows={5}
        titleLabel={'Input message for candidate (optional)'}
        characterLimit={200}
        name="message"
        value={
          props.form.message ||
          `Dear Leonardo,

Thank you for your interest in joining Visi Prima. We appreciate the time you've taken to apply for this position of Android Developer.
                `
        }
        placeholder={`Input your message`}
      />
    </div>
  );
}
