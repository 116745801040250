import React from 'react';
import './NewButton.scss';

type NewButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  size: 'sm' | 'md' | 'lg' | 'xl';
  type: 'button' | 'submit' | 'reset';
  typeButton:
    | 'primary'
    | 'outline-primary'
    | 'error'
    | 'outline-error'
    | 'outline-gray'
    | 'link-gray'
    | 'link-primary'
    | 'secondary';
};

export default function NewButton(props: NewButtonProps) {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={[
        'new-button tw transition duration-300',
        props.typeButton,
        //   Size Condition
        props.size,
        //   Disabled Condition
        props.disabled ? 'opacity-50 cursor-not-allowed' : '',

        props.className,
      ].join(' ')}
      type={props.type}>
      {props.children}
    </button>
  );
}
