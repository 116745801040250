import React, { forwardRef } from 'react';
import './InputType.scss';

const InputType = ({
	type,
	className,
	placeholder,
	value,
	onChange,
	disabled,
	style,
	required,
	name,
	withIcon,
	isError,
	id,
	min,
	max,
	...props
}, ref) => {
	return (
		<input
			ref={ref}
			type={type ?? 'text'}
			className={[
				'inputType',
				'tw transition-all duration-300',
				withIcon && 'withIcon',
				isError && 'isError',
				className,
			].join(' ')}
			onChange={onChange}
			placeholder={placeholder ?? 'Type here...'}
			value={value}
			disabled={disabled}
			style={style}
			required={required}
			id={name}
			name={name}
			min={min}
			max={max}
			onClick={props.onClick}
			{...props}
		/>
	);
}

export default forwardRef(InputType);