import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { classHelper } from '../../utils/stringUtils';
import {
  DIALOG_CONFIRM_BODY,
  DIALOG_CONFIRM_TITLE,
  NO,
  YES,
} from '../../utils/variables';
import Image from '../UI/Image';

import deleteIcon from '../../../assets/svg/delete.svg';

import Button from '../ButtonComponent/Button';
import './ModalComponent.scss';

const ConfirmBox = ({
  isBlue = false,
  isHideSecondaryButton = false,
  ...props
}) => {
  const ENV_NAME = props.selectedEnvironment || 'bhs';

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size={props.size}
      dialogClassName={`confirm-box modal-dialog-centered ${
        props.size ? 'lg-w80' : ''
      }`}>
      <Modal.Body>
        <div className={classHelper('row', props.className)}>
          <div className="col-12 text-center mt-4">
            <Image src={props.icon || deleteIcon} />
          </div>
          <div className="col-12 text-center">
            <div className="delete-title">
              {props?.headTitle || DIALOG_CONFIRM_TITLE[ENV_NAME]}
            </div>
            {props?.body || (
              <div className="delete-subtitle">
                {DIALOG_CONFIRM_BODY[ENV_NAME]}
              </div>
            )}
          </div>
          <div className="col-12 button-wrapper mt-4">
            {!isHideSecondaryButton && (
              <Button
                type="outline"
                onClick={() =>
                  typeof props.onAction === 'function' && props.onAction(false)
                }
                title={props.labelNo || NO[ENV_NAME]}></Button>
            )}
            {!props.isHidePrimaryButton && (
              <Button
                type="error"
                onClick={() =>
                  typeof props.onAction === 'function' && props.onAction(true)
                }
                title={props.labelYes || YES[ENV_NAME]}></Button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default connect((state) => ({
  selectedEnvironment: state.auth?.selectedEnvironment,
}))(ConfirmBox);
