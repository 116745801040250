import React from "react";
import "./SearchInputComponent.scss";
import { MagnifyingGlass } from "@phosphor-icons/react";

type SearchInputComponentProps = {
	placeholder: string;
	classNameInput?: string;
	value?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	className?: string;
};

const SearchInputComponent = ({
	placeholder,
	classNameInput,
	value,
	className,
	onChange,
}: SearchInputComponentProps) => {
	return (
		<div className={`search-input-wrapper ${className || ""}`}>
			<div className="search-input-wrapper__icon">
				<MagnifyingGlass size={20} color="#667085" />
			</div>
			<input
				onChange={onChange}
				className={`search-input-wrapper__input ${classNameInput || ""}`}
				placeholder={placeholder}
				value={value}
			/>
		</div>
	);
};

export default SearchInputComponent;
