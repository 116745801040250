import { ArrowsCounterClockwise, CaretDown, X } from '@phosphor-icons/react';
import { useState } from 'react';
import NewButton from '../../../shared/component/atoms/NewButton/NewButton';
import { RenderIf } from '../../../shared/utils/common';
import BadgesStatus from '../components/BadgesStatus';
import { fetchDetailSchedule } from '../../../store/actions/interview';

type DataTimeSchedule = {
  candidates: Array<any>;
  date: Date;
  id: number;
  meeting_type: string;
  schedule_color: string;
  schedule_name: string;
  slot_target: string;
  time: string;
};

type DetailSchedule = {
  allow_interview_reschedule: boolean;
  date_time_schedule_exact: Date;
  display_remaining_capacity: boolean;
  id: number;
  interview_pic: string;
  job_ad: number;
  job_applications: Array<any>;
  location: string | null;
  max_capacity_in_slots: number;
  max_date_to_interview: Date;
  meeting_type: string;
  message: Text;
  no_of_reschedule_allowed: number;
  phone_number: string;
  schedule_color: string;
  schedule_name: string;
  slot_target: string;
  video_call_link: string;
};

type ListScheduleTimeProps = {
  className?: string;
  dataTime: DataTimeSchedule;
  handleReschedule?: void | any | null;
  handleCancel?: void | any | null;
};

export default function ListScheduleTime(props: ListScheduleTimeProps) {
  const [showDetail, setshowDetail] = useState(false);
  const [detailScheduleSlot, setdetailScheduleSlot] =
    useState<DetailSchedule>();

  const handleDetailSchedule = async (id: number) => {
    return await fetchDetailSchedule(id).then((res) => {
      console.log(res);
      setdetailScheduleSlot(res.data);
      setshowDetail(!showDetail);
    });
  };

  return (
    <div
      className={[
        'p-std d-flex justify-content-between align-items-start border border-end tw transition-all duration-300 overflow-auto',
      ].join(' ')}>
      <div className="d-flex gap-4">
        {/* Time and Type */}
        <div>
          <p className="text-sm fw-normal text-black">{props.dataTime.time}</p>
          <p className="f-wnormal text-sm fw-semibold tw capitalize">
            {props.dataTime.slot_target}
          </p>
        </div>

        {/* PIC and Location */}
        <div className="text-black d-flex text-sm flex-column gap-5 fw-normal w-75">
          <div>
            <p className="text-sm">List Nama</p>
            <p className="text-sm">
              Interview type :{' '}
              <b className="tw capitalize">{props.dataTime.meeting_type}</b>
            </p>
          </div>

          <RenderIf condition={showDetail}>
            {/* Location | Video Call | Phone Section */}
            <div className="text-black">
              <b className="text-sm">
                <RenderIf condition={props.dataTime.meeting_type === 'video'}>
                  Video Call
                </RenderIf>

                <RenderIf condition={props.dataTime.meeting_type === 'phone'}>
                  Phone
                </RenderIf>

                <RenderIf condition={props.dataTime.meeting_type === 'onsite'}>
                  On Site
                </RenderIf>
              </b>

              <p className="mt-3 text-md">
                <RenderIf condition={props.dataTime.meeting_type === 'video'}>
                  {detailScheduleSlot?.video_call_link}
                </RenderIf>

                <RenderIf condition={props.dataTime.meeting_type === 'phone'}>
                  {detailScheduleSlot?.phone_number}
                </RenderIf>

                <RenderIf condition={props.dataTime.meeting_type === 'onsite'}>
                  {detailScheduleSlot?.location}
                </RenderIf>
              </p>
            </div>

            {/* PIC Section */}
            <div>
              <b className="text-sm">Interview PIC</b>
              <p className="mt-3 text-sm fw-std">
                {detailScheduleSlot?.interview_pic}
              </p>
            </div>

            {/* Button */}
            <div className="d-flex gap-2">
              <NewButton type="button" typeButton="outline-primary" size="sm">
                <ArrowsCounterClockwise weight="bold" />
                Reschedule
              </NewButton>
              <NewButton type="button" typeButton="outline-gray" size="sm">
                <X weight="bold" />
                Cancel
              </NewButton>
            </div>
          </RenderIf>
        </div>
      </div>

      {/* Status and Dropdown */}
      <div className="d-flex align-items-center gap-lg">
        <div>
          <BadgesStatus status="pending" title="Pending" />
        </div>

        <button
          onClick={() => handleDetailSchedule(props.dataTime.id)}
          className="border-0 bg-transparent d-flex gap-3 align-items-center text-sm text-black">
          <CaretDown
            className={[
              'tw text-gray-50 transition-all duration-300',
              showDetail ? 'rotate-180' : '',
            ].join(' ')}
          />
          Details
        </button>
      </div>
    </div>
  );
}
