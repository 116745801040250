import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import clx from "classnames";

import Button from "../../shared/component/ButtonComponent/Button";
import CustomInput from "../../shared/component/CustomInput/CustomInput";
import isValidEmail from "../../shared/utils/isValidEmail";
import * as Variable from "../../shared/utils/variables";

import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import makeRequest from "./../../shared/utils/request";
import "./AddEditUser.scss";
import { Text, Title } from "shared/component/Typography/Typography";
import Checkbox from "shared/component/NewComponents/Checkbox/Checkbox";
import SkeletonLoader from "shared/component/atoms/SkeletonLoader";
import useToast from "shared/hooks/useToast";
import { USER_TYPES } from "constants/constants";

const ACCESS_TYPE = {
	ADMIN: "admin",
	CUSTOM: "custom",
};

function AddEditUser({ ENV_NAME, userType, userId, setSelectedUser, ...props }) {
	const toast = useToast();
	const [errors, setErrors] = useState({});
	const [accessType, setAccessType] = useState(ACCESS_TYPE.ADMIN);
	const [monitor, setMonitor] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [userForm, setUserForm] = useState({
		full_name: "",
		designation: "",
		department: "",
		email: "",
	});
	const [permissions, setPermissions] = useState({
		manage_candidates: "all_jobs",
		job_posting: true,
		talent_search_utilization: true,
		user_management: true,
		company_profile_management: true,
		billing_management: true,
	});
	const [userPermission, setUserPermission] = useState(null);

	const resetState = () => {
		setUserForm({
			full_name: "",
			designation: "",
			department: "",
			email: "",
		});
		setPermissions({
			manage_candidates: "all_jobs",
			job_posting: true,
			talent_search_utilization: true,
			user_management: true,
			company_profile_management: true,
			billing_management: true,
		});
		setAccessType(ACCESS_TYPE.ADMIN);
		setMonitor({});
		setErrors({});
		setSelectedUser(null);
	};

	useEffect(() => {
		if (userId) retrieveUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId]);

	useEffect(() => {
		if (accessType === ACCESS_TYPE.ADMIN) {
			setPermissions({
				manage_candidates: "all_jobs",
				job_posting: true,
				talent_search_utilization: true,
				user_management: true,
				company_profile_management: true,
				billing_management: true,
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessType]);

	const retrieveUser = async () => {
		setIsLoading(true);
		const res = await makeRequest(generateRequestOptions("getProfile", { urlParams: userId }));
		if (res?.code === 200) {
			// console.log(user);
			setUserForm({
				full_name: `${res?.data?.first_name} ${res?.data?.last_name || ""}`,
				designation: res?.data?.position,
				department: res?.data?.department,
				email: res?.data?.email,
			});

			if (isEmpty(res?.data?.staff_user_permissions)) {
				setIsLoading(false);
				setPermissions({
					manage_candidates: false,
					job_posting: false,
					talent_search_utilization: false,
					user_management: false,
					company_profile_management: false,
					billing_management: false,
				});
				setAccessType(ACCESS_TYPE.CUSTOM);
				return;
			}

			const permissionsClone = { ...permissions };
			for (const permission in permissionsClone) {
				if (!res?.data?.staff_user_permissions.includes(permission))
					permissionsClone[permission] = false;
			}

			if (res?.data?.staff_user_permissions?.includes("all_jobs"))
				permissionsClone["manage_candidates"] = "all_jobs";
			else if (res?.data?.staff_user_permissions?.includes("specific_jobs"))
				permissionsClone["manage_candidates"] = "specific_jobs";
			else permissionsClone["manage_candidates"] = false;

			setPermissions(permissionsClone);
			setUserPermission(permissionsClone);
			setIsLoading(false);

			if (
				Object.values(permissionsClone).includes(false) ||
				Object.values(permissionsClone).includes("specific_jobs")
			) {
				setAccessType(ACCESS_TYPE.CUSTOM);
				return;
			}
			setAccessType(ACCESS_TYPE.ADMIN);
		}
	};

	const handleChange = (e, type = "user_info") => {
		if (type === "permission") {
			if (e.target.checked && e.target.name === "manage_candidates")
				setPermissions({
					...permissions,
					[e.target.name]: "all_jobs",
				});
			else
				setPermissions({
					...permissions,
					[e.target.name]: e.target.checked,
				});
		} else {
			const { name, value } = e.target;
			const userFormClone = { ...userForm };
			userFormClone[name] = value;

			setUserForm(userFormClone);
			setMonitor({
				...monitor,
				[name]: value ? true : false,
			});

			if (errors[name] && userFormClone[name]) {
				setErrors({
					...errors,
					[name]: false,
				});
			}
		}
	};

	const handleAddUser = async () => {
		const { full_name, designation, department, email } = userForm;
		const errorsData = {};
		const requestData = {
			user_type: USER_TYPES.JOB_MARKET_ADMIN,
			email,
		};

		const staff_permissions = Object.entries(permissions)
			.slice(1)
			.map(([k, v]) => v === true && k);

		staff_permissions.push(permissions["manage_candidates"]);
		requestData["staff_user_permissions"] = staff_permissions.filter((p) => p);

		if (!full_name) errorsData["full_name"] = true;
		else requestData["first_name"] = full_name;

		if (!designation) errorsData["designation"] = true;
		else requestData["position"] = designation;

		if (!department) errorsData["department"] = true;
		else requestData["department"] = department;

		if (!isEmpty(errorsData)) {
			return setErrors(errorsData);
		}

		const res = await makeRequest({
			...generateRequestOptions("addUserStaff"),
			json: true,
			body: requestData,
		});

		if (res.code === 200) {
			resetState();
			props.hideForm();
			props.fetchUsersData();
			toast.success(Variable.ACCOUNT_ADDED[ENV_NAME]);
		} else toast.error(res.message);
	};

	const buttonVisibility = useMemo(() => {
		return Object.values(userForm).every(
			(value) => typeof value === "string" && value.trim() !== "",
		);
	}, [userForm]);

	const handleUpdateUser = async () => {
		const { full_name, designation, department, email } = userForm;
		const errorsData = {};
		const requestData = {
			user_type: USER_TYPES.JOB_MARKET_ADMIN,
		};

		const staff_permissions = Object.entries(permissions)
			.slice(1)
			.map(([k, v]) => v === true && k);
		staff_permissions.push(permissions["manage_candidates"]);
		requestData["staff_user_permissions"] = staff_permissions.filter((p) => p);

		if (monitor["full_name"]) {
			if (!full_name) errorsData["full_name"] = true;
			else requestData["first_name"] = full_name;
		}

		if (monitor["designation"]) {
			if (!designation) errorsData["designation"] = true;
			else requestData["position"] = designation;
		}

		if (monitor["department"]) {
			if (!department) errorsData["department"] = true;
			else requestData["department"] = department;
		}

		if (monitor["email"]) requestData["email"] = email;

		if (!isEmpty(errorsData)) {
			return setErrors(errorsData);
		}

		const res = await makeRequest({
			...generateRequestOptions("updateUserStaff", { urlParams: userId }),
			json: true,
			body: requestData,
		});

		if (res.code === 200) {
			resetState();
			props.hideForm();
			props.fetchUsersData();
			toast.success(Variable.ACCOUNT_UPDATED[ENV_NAME]);
		} else toast.error(res.message);
	};

	return (
		<div className="user-form py-4">
			<div className="container d-flex justify-content-center align-items-center flex-column">
				<div className="col-sm-10">
					<Title
						className="text-start align-self-start header-title"
						size="sm"
						fontWeight="medium"
						color="#101828"
						style={{
							marginBottom: "2.4rem",
						}}
					>
						{userId ? Variable.EDIT_PROFILE[ENV_NAME] : Variable.ADD_USER[ENV_NAME]}
					</Title>
				</div>

				<div className="col-sm-10">
					<div className="user-form__container">
						<Text className="mb-5" size="xl" fontWeight="semibold">
							{Variable.USER_INFORMATION[ENV_NAME]}
						</Text>
						<div className="form-group J-inputComp w-100 lm-form">
							<CustomInput
								label={Variable.FULL_NAME[ENV_NAME]}
								name="full_name"
								value={userForm?.full_name}
								inputClasses={`J-inputComp-input ${
									errors?.full_name ? "borError" : ""
								}`}
								placeholder={Variable.FULL_NAME[ENV_NAME]}
								onChange={handleChange}
								isRequired
								isLoading={isLoading}
								loaderProps={{ width: "100%", height: "45px" }}
							/>
						</div>
						<div className="form-group J-inputComp w-100 lm-form">
							<CustomInput
								label={Variable.DESIGNATION[ENV_NAME]}
								name="designation"
								value={userForm?.designation}
								inputClasses={`J-inputComp-input ${
									errors?.designation ? "borError" : ""
								}`}
								placeholder={"EX : IT"}
								onChange={handleChange}
								isRequired
								isLoading={isLoading}
								loaderProps={{ width: "100%", height: "45px" }}
							/>
						</div>
						<div className="form-group J-inputComp w-100 lm-form">
							<CustomInput
								label={Variable.DEPARTMENT[ENV_NAME]}
								name="department"
								value={userForm?.department}
								inputClasses={`J-inputComp-input ${
									errors?.department ? "borError" : ""
								}`}
								placeholder={"HR"}
								onChange={handleChange}
								isRequired
								isLoading={isLoading}
								loaderProps={{ width: "100%", height: "45px" }}
							/>
						</div>
						<div className="form-group J-inputComp w-100 lm-form">
							<CustomInput
								label={Variable.EMAIL[ENV_NAME]}
								type="email"
								name="email"
								value={userForm?.email}
								inputClasses={`J-inputComp-input ${
									userForm?.email && !isValidEmail(userForm?.email)
										? "borError"
										: ""
								}`}
								placeholder={`olivia@portalkerja.com`}
								onChange={handleChange}
								inputIcon={<FontAwesomeIcon icon={faEnvelope} />}
								isRequired
								isLoading={isLoading}
								loaderProps={{ width: "100%", height: "45px" }}
							/>
						</div>
					</div>

					<div className="user-form__container">
						<div className="d-flex align-items-center justify-content-between">
							<Text size="xl" fontWeight="semibold" className="mb-0">
								{Variable.ACCESS_MANAGEMENT[ENV_NAME]}
							</Text>
							<div className="access-mgmt__toggle">
								<span
									className={`${
										accessType === ACCESS_TYPE.ADMIN ? "active" : ""
									}`}
									onClick={() => setAccessType(ACCESS_TYPE.ADMIN)}
								>
									{Variable.ADMIN[ENV_NAME]}
								</span>
								<span
									className={`${
										accessType === ACCESS_TYPE.CUSTOM ? "active" : ""
									}`}
									onClick={() => {
										if (userId) {
											setPermissions(userPermission);
										}
										setAccessType(ACCESS_TYPE.CUSTOM);
									}}
								>
									{Variable.CUSTOM[ENV_NAME]}
								</span>
							</div>
						</div>
						<hr className="mt-5 mb-4" />
						{isLoading ? (
							<SkeletonLoader width="100%" height="105px" />
						) : (
							<div className="access_mgmt access-mgmt__permission">
								<Checkbox
									id="manage_candidates"
									name="manage_candidates"
									disabled={accessType === ACCESS_TYPE.ADMIN}
									checked={permissions["manage_candidates"]}
									onChange={(e) => handleChange(e, "permission")}
									className="checkbox-access-mgmt"
								/>
								<div
									className={clx("access-mgmt__permission-description", {
										disabled: accessType === ACCESS_TYPE.ADMIN,
									})}
								>
									<Text
										size="xl"
										fontWeight="semibold"
										className="title-description"
									>
										{Variable.MANAGE_CANDIDATES[ENV_NAME]}
									</Text>
									<Text size="md" className="description-description">
										{Variable.MANAGE_CANDIDATES_DESC[ENV_NAME]}
									</Text>

									<div className="access-mgmt__permission-description-radios">
										<div>
											<input
												type="radio"
												name="all_jobs"
												value={"all_jobs"}
												checked={
													permissions["manage_candidates"] === "all_jobs"
												}
												onChange={(e) =>
													setPermissions({
														...permissions,
														manage_candidates: e.target.value,
													})
												}
												disabled={accessType === ACCESS_TYPE.ADMIN}
											/>
											<span className="title-description">
												{Variable.ALL_JOBS[ENV_NAME]}
											</span>
										</div>
										<div>
											<input
												type="radio"
												name="specific_jobs"
												value={"specific_jobs"}
												checked={
													permissions["manage_candidates"] ===
													"specific_jobs"
												}
												onChange={(e) =>
													setPermissions({
														...permissions,
														manage_candidates: e.target.value,
													})
												}
												disabled={accessType === ACCESS_TYPE.ADMIN}
											/>
											<span className="ml-3 title-description">
												{Variable.SPECIFIC_JOBS[ENV_NAME]}
											</span>
										</div>
									</div>
								</div>
							</div>
						)}

						<hr className="my-4" />

						<div className="access_mgmt access-mgmt__permission">
							{isLoading ? (
								<SkeletonLoader width="100%" height="65px" />
							) : (
								<>
									<Checkbox
										type="checkbox"
										id="job_posting"
										name="job_posting"
										disabled={accessType === ACCESS_TYPE.ADMIN}
										checked={permissions["job_posting"]}
										onChange={(e) => handleChange(e, "permission")}
										className="checkbox-access-mgmt"
									/>
									<div
										className={clx("access-mgmt__permission-description", {
											disabled: accessType === ACCESS_TYPE.ADMIN,
										})}
									>
										<Text
											size="xl"
											className="title-description"
											fontWeight="semibold"
										>
											{Variable.JOB_POSTING[ENV_NAME]}
										</Text>
										<p>{Variable.JOB_POSTING_DESC[ENV_NAME]}</p>
									</div>
								</>
							)}
						</div>
						<hr className="my-4" />

						<div className="access_mgmt access-mgmt__permission">
							{isLoading ? (
								<SkeletonLoader width="100%" height="65px" />
							) : (
								<>
									<Checkbox
										type="checkbox"
										id="talent_search_utilization"
										name="talent_search_utilization"
										disabled={accessType === ACCESS_TYPE.ADMIN}
										checked={permissions["talent_search_utilization"]}
										onChange={(e) => handleChange(e, "permission")}
										className="checkbox-access-mgmt"
									/>
									<div
										className={clx("access-mgmt__permission-description", {
											disabled: accessType === ACCESS_TYPE.ADMIN,
										})}
									>
										<Text
											size="xl"
											className="title-description"
											fontWeight="semibold"
										>
											{Variable.TALENT_SEARCH_UTILIZATION[ENV_NAME]}
										</Text>
										<p>{Variable.TALENT_SEARCH_UTILIZATION_DESC[ENV_NAME]}</p>
									</div>
								</>
							)}
						</div>
						<hr className="my-4" />

						<div className="access_mgmt access-mgmt__permission">
							{isLoading ? (
								<SkeletonLoader width="100%" height="65px" />
							) : (
								<>
									<Checkbox
										type="checkbox"
										id="user_management"
										name="user_management"
										disabled={accessType === ACCESS_TYPE.ADMIN}
										checked={permissions["user_management"]}
										onChange={(e) => handleChange(e, "permission")}
										className="checkbox-access-mgmt"
									/>
									<div
										className={clx("access-mgmt__permission-description", {
											disabled: accessType === ACCESS_TYPE.ADMIN,
										})}
									>
										<Text
											size="xl"
											className="title-description"
											fontWeight="semibold"
										>
											{Variable.USER_MANAGEMENT[ENV_NAME]}
										</Text>
										<p>{Variable.USER_MANAGEMENT_DESC[ENV_NAME]}</p>
									</div>
								</>
							)}
						</div>
						<hr className="my-4" />

						<div className="access_mgmt access-mgmt__permission">
							{isLoading ? (
								<SkeletonLoader width="100%" height="65px" />
							) : (
								<>
									<Checkbox
										type="checkbox"
										id="company_profile_management"
										name="company_profile_management"
										disabled={accessType === ACCESS_TYPE.ADMIN}
										checked={permissions["company_profile_management"]}
										onChange={(e) => handleChange(e, "permission")}
										className="checkbox-access-mgmt"
									/>
									<div
										className={clx("access-mgmt__permission-description", {
											disabled: accessType === ACCESS_TYPE.ADMIN,
										})}
									>
										<Text
											size="xl"
											fontWeight="semibold"
											className="title-description"
										>
											{Variable.COMPANY_PROFILE_MANAGEMENT[ENV_NAME]}
										</Text>
										<p>{Variable.COMPANY_PROFILE_MANAGEMENT_DESC[ENV_NAME]}</p>
									</div>
								</>
							)}
						</div>
						<hr className="my-4" />

						<div className="access_mgmt access-mgmt__permission">
							{isLoading ? (
								<SkeletonLoader width="100%" height="65px" />
							) : (
								<>
									<Checkbox
										type="checkbox"
										id="billing_management"
										name="billing_management"
										disabled={accessType === ACCESS_TYPE.ADMIN}
										checked={permissions["billing_management"]}
										onChange={(e) => handleChange(e, "permission")}
										className="checkbox-access-mgmt"
									/>
									<div
										className={clx("access-mgmt__permission-description", {
											disabled: accessType === ACCESS_TYPE.ADMIN,
										})}
									>
										<Text
											size="xl"
											fontWeight="semibold"
											className="title-description"
										>
											{Variable.BILLING_MANAGEMENT[ENV_NAME]}
										</Text>
										<p>{Variable.BILLING_MANAGEMENT_DESC[ENV_NAME]}</p>
									</div>
								</>
							)}
						</div>
					</div>

					<div className="d-flex gap-5 button-groups">
						<Button
							type="outline"
							size="md"
							title={Variable.CANCEL_LABEL[ENV_NAME]}
							onClick={() => {
								resetState();
								props.hideForm();
							}}
							style={{
								width: "144px",
							}}
						/>
						<Button
							type="primary"
							size="md"
							title={Variable.SAVE_LABEL[ENV_NAME]}
							onClick={() => (userId ? handleUpdateUser() : handleAddUser())}
							style={{
								width: "144px",
							}}
							disabled={!buttonVisibility}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddEditUser;
