import { ChangeEvent, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { toast } from "react-toastify";

import * as RoutePath from "../../shared/utils/routeLink";
import makeRequest from "shared/utils/request";
import { generateRequestOptions } from "shared/utils/apiEndPoints";
import Image from "shared/component/UI/Image";
import ChangePasswordIcon from "../../assets/svg/key_icon.svg";
import useTranslate from "shared/hooks/useTranslate";
import PasswordInput from "shared/component/atoms/InputType/PasswordInput";
import { Button } from "shared/component";
import ModalInfo from "shared/component/ModalComponent/ModalInfo";
import useAuth from "shared/hooks/useAuth";

import * as ActionTypes from "store/actions/actionTypes";
import { RootState } from "store/types/RootStateTypes";

import "./ChangePassword.scss";

interface FormFields {
	old_password: string;
	new_password: string;
	confirm_password: string;
}

interface Props {
	userId: number;
	setLoginModalShow: () => void;
}

const ChangePassword = ({ userId, setLoginModalShow }: Props) => {
	const t = useTranslate();
	const { handleLogout } = useAuth();

	const ERRORS = {
		pass_not_match: t("PASSWORD_NOT_MATCHED"),
		strong_pass_error: t("PASSWORD_VALIDATION_ERR_MSG"),
		incorrect_pass: t("INCORRECT_PASSWORD"),
	};
	const history = useHistory();
	const [form, setForm] = useState<FormFields>({
		old_password: "",
		new_password: "",
		confirm_password: "",
	});
	const [errors, setErrors] = useState({});
	const [isEdited, setIsEdited] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setForm((p) => ({ ...p, [name]: value }));
		setErrors((p) => ({ ...p, [name]: false, pass_not_match: false, incorrect_pass: false }));
		setIsEdited(true);
	};

	const resetState = () => {
		setForm({ old_password: "", new_password: "", confirm_password: "" });
		setIsEdited(false);
	};

	const handleSave = async () => {
		if (!isEdited) return;

		const { old_password, new_password, confirm_password } = form;

		const errors = {};
		if (new_password !== confirm_password) errors["pass_not_match"] = true;

		const hasError = Object.values(errors).some((value) => value);
		if (hasError) return setErrors(errors);

		console.log("Validation Passed");

		const res = await makeRequest({
			...generateRequestOptions("changePassword", { urlParams: userId }),
			data: { old_password, new_password, confirm_password },
		});

		if (res?.code === 200) {
			setShowModal(true);
			resetState();
			handleLogout();
		} else if (res?.detail?.[0]?.includes("Wrong password")) {
			setErrors((p) => ({ ...p, incorrect_pass: true }));
			setForm((p) => ({ ...p, new_password: "", confirm_password: "" }));
		} else toast.error(res.message);
	};

	const handleInputValidation = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

		if (value.length) setErrors((p) => ({ ...p, [name]: !REGEX.test(value) }));
	};

	return (
		<div className="cp-container">
			<div className="cp">
				<div className="cp-header">
					<Image src={ChangePasswordIcon} alt="Change Password" />
					<h3>{t("CHANGE_PASSWORD")}</h3>
					<p>{t("SET_NEW_PASSWORD_TITLE")}</p>
				</div>
				<div className="cp-body">
					<PasswordInput
						label={`${t("ENTER")} ${t("CURRENT_PASSWORD")}`}
						name="old_password"
						value={form?.old_password}
						onChange={handleChange}
						placeholder={t("CURRENT_PASSWORD")}
						isError={errors["incorrect_pass"]}
						errorText={errors["incorrect_pass"] && ERRORS["incorrect_pass"]}
					/>
					<PasswordInput
						label={`${t("ENTER")} ${t("NEW_PASSWORD")}`}
						name="new_password"
						value={form?.new_password}
						onChange={handleChange}
						placeholder={t("NEW_PASSWORD")}
						isError={errors["new_password"] || errors["pass_not_match"]}
						helperText={form?.new_password.length !== 0 && !errors["pass_not_match"]}
						onBlur={handleInputValidation}
					/>
					<PasswordInput
						label={`${t("ENTER")} ${t("CONFIRM_PASSWORD")}`}
						name="confirm_password"
						value={form?.confirm_password}
						onChange={handleChange}
						placeholder={t("CONFIRM_PASSWORD")}
						isError={errors["confirm_password"] || errors["pass_not_match"]}
						helperText={
							form?.confirm_password.length !== 0 && !errors["pass_not_match"]
						}
						errorText={errors["pass_not_match"] && ERRORS["pass_not_match"]}
						onBlur={handleInputValidation}
					/>
				</div>
				<div className="cp-footer">
					<Button
						type="outline"
						size="sm"
						title={t("BACK")}
						customIcon={<BiArrowBack size={18} className="mr-1" />}
						onClick={() => history.push(RoutePath.PROFILE)}
					/>
					<Button
						type="primary"
						size="sm"
						title={t("CHANGE_PASSWORD")}
						onClick={handleSave}
						disabled={
							!form?.old_password ||
							!form?.new_password ||
							!form?.confirm_password ||
							errors["new_password"] ||
							errors["confirm_password"]
						}
					/>
				</div>
			</div>

			<ModalInfo
				isShow={showModal}
				onConfirm={() => {
					history.push(RoutePath.ROOT);
					setLoginModalShow();
				}}
				type="success"
				title={t("PASS_CHANGE_SUCCESS")}
				subtitle={t("PASS_CHANGE_SUCCESS_DESC")}
				confirmLabel={t("BACK_TO_LOGIN")}
				confirmButtonType="primary"
				// customIcon={<Image src={WarningIcon} className="modal-image-icon" />}
			/>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	userId: state?.auth?.userDetails?.id,
});

const mapStateToDispatch = (dispatch) => {
	return {
		setLoginModalShow: () => {
			dispatch({
				type: ActionTypes.OPEN_LOGIN_MODAL,
			});
		},
	};
};

export default connect(mapStateToProps, mapStateToDispatch)(ChangePassword);
