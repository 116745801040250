import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import { X, Calendar, DownloadSimple } from "@phosphor-icons/react";

import { useSelector } from 'react-redux';

import cx from "classnames";
import downloadjs from "downloadjs";
import DateTimePicker from "react-datetime";

import { b64toBlob } from "shared/utils/b64toBlob";
import { RootState } from 'store/types/RootStateTypes';
import { generateRequestOptions } from "shared/utils/apiEndPoints";

import Image from "shared/component/UI/Image";
import makeRequest from "shared/utils/request";
import useTranslate from "shared/hooks/useTranslate";
import OfferLetter from "assets/svg/offer_letter.svg";

import "react-datetime/css/react-datetime.css";
import useToast from "shared/hooks/useToast";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface OfferletterProps {
    handlerCloseModal: () => void;
    markStatus: (status: string, botStatus?: boolean) => Promise<any>;
    jobApplication: number;
    candidateName: string;
}

const Offerletter: FC<OfferletterProps> = ({
    handlerCloseModal,
    markStatus,
    jobApplication,
    candidateName
}) => {
    const t = useTranslate();
    const toast = useToast();
    const userDetails = useSelector(
        (state: RootState) => state.auth.userDetails
    );

    const [oldate, setOldate] = useState<any>(null);
    const [pdfFile, setPdfFile] = useState<any>(null);
    const [numPages, setNumPages] = useState<any>(null);
    const [startDate, setStartDate] = useState<any>(null);
    const [pdfFileData, setPdfFileData] = useState<any>(null);
    
    const [offerLetterType, setOfferLetterType] = useState<number | null>(null)

    const [title, setTitle] = useState<string>("")
    const [salary, setSalary] = useState<string>("")
    const [probation, setProbation] = useState<string>("")
    const [showPage, setShowPage] = useState<string>("basic")
    const [employmentType, setEmploymentType] = useState<string>("")

    const getDisabledStatus = () => Boolean(title && salary && employmentType && probation && oldate && startDate);

    const getPreview = async() => {
        try {
            let res = await makeRequest(
                {...generateRequestOptions("getOfferletterPreview", {}),
                body: {
                    "offer_letter_date": oldate?.format("dd-mm-yyyy"),
                    "agreed_salary": salary,
                    "job_application": jobApplication,
                    "job_type": employmentType,
                    "position_title": title,
                    "probation_period": probation,
                    "join_date": startDate?.format("dd-mm-yyyy"),
                }
            })
            if (res.code === 200) {
                setPdfFile(res.data)
                setPdfFileData(atob(res.data.file_data))
                setShowPage("previewpage")
            } else {
                toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"))
            }
        } catch (error) {
            console.error(error);
            toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"))
        }
    }

    const sendOfferingLetter = async() => {
        try {
            let res = await makeRequest({
                ...generateRequestOptions("sendOfferingLetter"),
                body: {
                    "offer_letter_date": oldate.format("YYYY-MM-DD"),
                    "agreed_salary": salary,
                    "job_application": jobApplication,
                    "job_type": employmentType,
                    "position_title": title,
                    "probation_period": probation,
                    "join_date": startDate.format("YYYY-MM-DD"),
                    is_sent: true
                }
            })

            if (res.code === 200) {
                markStatus("Offered")
            } else {
                toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"))
            }
        } catch (error) {
            console.error(error);
            toast.error(t("SOMETHING_WENT_WRONG_TRY_AGAIN"))
        } finally {
            handlerCloseModal();
        }
    }

    const downloadPdf = () => {
        const blob = b64toBlob(pdfFile.file_data)
        downloadjs(blob, pdfFile.file_name)
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    return (
        <>
            <Modal.Header>
                <span>{t("SEND_OFFER_LETTER")}</span>
                <X className="close" onClick={handlerCloseModal} />
            </Modal.Header>
            <Modal.Body>
                {showPage === "basic" && (
                    <>
                        <div
                            onClick={() => setOfferLetterType(1)}
                            className={cx("option-div", { "selected": offerLetterType === 1 })}
                        >
                            <p className="option-heading">{t("USE_PK_OFFER_LETTER_TEMPLATE")}</p>
                        </div>
                        <div
                            onClick={() => setOfferLetterType(2)}
                            className={cx("option-div", { "selected": offerLetterType === 2 })}
                        >
                            <p className="option-heading">
                                {t("SET_NAME_STATUS_ONLY").replace("{{_status_}}", t("OFFERED"))}
                            </p>
                        </div>
                    </>
                )}
                {showPage === "detailspage" && <>
                    <div style={{ margin: "20px 0px" }}>
                        <Image src={userDetails?.company_detail?.company_logo || OfferLetter} style={{ width: '40px' }} />
                        <span style={{ color: "#1D29390", fontWeight: 600, marginLeft: 10 }}>
                            {userDetails?.company_detail?.name}
                        </span>
                    </div>
                    <div className="form-div">
                        <div>
                            <label>{t("POSITION_TITLE")}</label>
                            <input 
                                type="text"
                                className="bordered"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}    
                            />
                        </div>
                        <div>
                            <label>{t("AGREED_SALARY")}</label>
                            <input
                                type="number"
                                className="bordered"
                                value={salary}
                                onChange={(e) => setSalary(e.target.value)}
                            />
                        </div>
                        <div className="col-12 d-flex flex-direction-column">
                            <div className="date-div mar-right">
                                <label>{t("OFFER_LETTER_DATE")}</label>
                                <div className="date-outer-div">
                                    <Calendar width={20} height={20} style={{ marginRight: 5 }} />
                                    <DateTimePicker
                                        value={oldate}
                                        onChange={setOldate}
                                        dateFormat={"DD/MM/YYYY"}
                                        timeFormat={false}
                                        className ={"form-control form-calendar p-0"}
                                        closeOnSelect
                                        inputProps={{ readOnly: true, placeholder:"dd/mm/yyyy" }}
                                    />
                                </div>
                            </div>
                            <div className="date-div">
                                <label>{t("START_JOINING_DATE")}</label>
                                <div className="date-outer-div">
                                    <Calendar width={20} height={20} style={{ marginRight: 5 }} />
                                    <DateTimePicker
                                        value={startDate}
                                        onChange={setStartDate}
                                        dateFormat={"DD/MM/YYYY"}
                                        timeFormat={false}
                                        className = {"form-control form-calendar p-0"}
                                        closeOnSelect
                                        inputProps={{ readOnly: true, placeholder:"dd/mm/yyyy" }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-flex flex-direction-column">
                            <div className="date-div mar-right">
                                <label>{t("TYPE_OF_EMPLOYMENT")}</label>
                                <input
                                    type="text"
                                    className="bordered"
                                    value={employmentType}
                                    onChange={(e) => setEmploymentType(e.target.value)}
                                />
                            </div>
                            <div className="date-div">
                                <label>{t("LENGTH_OF_PROBATION_PERIOD")}</label>
                                <input
                                    type="text"
                                    className="bordered"
                                    value={probation}
                                    onChange={(e) => setProbation(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </>}
                {showPage === "previewpage" && pdfFileData && (
                    <>
                        <div className="pdf-header">
                            {
                                t("OFFER_LETTER_PAGES")
                                    .replace("{{_candidateName_}}", candidateName)
                                    .replace("{{_numPages_}}", numPages)
                            }
                        </div>
                        <div className="pdf-viewer-div">
                            <Document
                                onLoadSuccess={onDocumentLoadSuccess}
                                options={{ workerSrc: "/pdf.worker.js" } as any}
                                file={{ data: pdfFileData }}
                            >
                            {Array.from(new Array(numPages), (_, index) => (
                                <Page
                                    key={`page_${index + 1}`} 
                                    pageNumber={index + 1}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                />
                            ))}
                            </Document>
                        </div>
                        <div className="pdf-foot">
                            {t("FULL_PREVIEW")}
                        </div>
                        <div className="download-btn" onClick={downloadPdf}>
                            <DownloadSimple />
                            <span>{t("DOWNLOAD")}</span>
                        </div>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                {showPage === "basic" && (
                    <>
                        <div onClick={handlerCloseModal} className="btn btn-cancel">
                            {t("CANCEL_LABEL")}
                        </div>
                        <div
                            className={cx("btn btn-download", { "disabled": !offerLetterType })}
                            onClick={()=>{
                                if(offerLetterType === 2) {
                                    markStatus("Offered")
                                    handlerCloseModal()
                                } else {
                                    setShowPage("detailspage")
                                }
                            }}
                        >
                            {t("NEXT")}
                        </div>
                    </>
                )}
                {showPage === "detailspage" && (
                    <>
                        <div onClick={() => setShowPage("basic")} className="btn btn-cancel">
                            {t("BACK")}
                        </div>
                        <div
                            onClick={getPreview}
                            className={cx("btn btn-download", { "disabled": !getDisabledStatus()})}
                        >
                            {t("NEXT")}
                        </div>
                    </>
                )}
                {showPage === "previewpage" && (
                    <>
                        <div onClick={() => setShowPage("detailspage")} className="btn btn-cancel">
                            {t("BACK")}
                        </div>
                        <div onClick={sendOfferingLetter} className="btn btn-download">
                            {t("SEND_TO_CANDIDATE")}
                        </div>
                    </>
                )}
            </Modal.Footer>
        </>
    );
}

export default Offerletter;
