import _ from "lodash";

//converting camelCase to startCase
export const startCase = (camelCase) =>
	camelCase
		.replace(/([A-Z])/g, (match) => ` ${match}`)
		.replace(/^./, (match) => match.toUpperCase());

export const classHelper = (...classes) => {
	const newClass = classes
		.map((cls) => {
			if ((typeof cls === "object" || typeof cls === "function") && cls !== null) {
				const filteredClasses = Object.keys(cls).filter((condCls) => cls[condCls]);
				if (filteredClasses.length > 0) {
					return filteredClasses.join(" ");
				}
				return null;
			}
			return cls;
		})
		.filter((cls) => typeof cls === "string" && cls.trim().length > 0);
	return newClass.length > 0 ? newClass.join(" ") : "";
};

export const uniqueKeyId = (t = 21) => {
	let e = "",
		r = crypto.getRandomValues(new Uint8Array(t));
	for (; t--; ) {
		let n = 63 & r[t];
		e +=
			n < 36
				? n.toString(36)
				: n < 62
				? (n - 26).toString(36).toUpperCase()
				: n < 63
				? "_"
				: "-";
	}
	return e;
};

// Capitalize each word and join
export const capitalize = (words) => {
	const wordsArr = Array.isArray(words) ? words : [words];
	const formattedArr = wordsArr.map((item) => _.startCase(item).replace(/\s/g, " "));

	return formattedArr.join(", ");
};

// Count only the characters while ignoring the html tags
export const countCharactersWithoutTags = (htmlString = "") => {
	// // Remove HTML tags using a regular expression
	// var stringWithoutTags = htmlString.replace(/<[^>]*>/g, "");

	// // Count the remaining characters
	// var characterCount = stringWithoutTags.length;

	// return characterCount;

	// Create a DOMParser
	const parser = new DOMParser();

	// Parse the HTML string
	const doc = parser.parseFromString(htmlString, "text/html");

	// Get the text content without HTML tags and trim whitespace
	const textWithoutTags = (doc.body.textContent || "").trim();

	// Count the non-whitespace characters
	const characterCount = textWithoutTags.replace(/\s/g, "").length;

	return characterCount;
};
