import { ArrowLeft } from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Empty from '../../../assets/svg/empty.svg';
import Button from '../../../shared/component/ButtonComponent/Button';
import LayoutWithNavigation from '../../../shared/layouts/LayoutWithNavigation';
import { RenderIf } from '../../../shared/utils/common';
import { fetchListTimeSchedule } from '../../../store/actions/interview';
import CardEmpty from '../components/CardEmpty';
import './InterviewSchedule.scss';
import ListSchedule from './ListSchedule';
import ModalReschedule from './ModalReschedule';
import TabNavigatioInterview from './TabNavigatioInterview';

export default function InterviewSchedule() {
  const route = useHistory();
  const [menuActive, setmenuActive] = useState('upcoming');
  const [dataTemp, setdataTemp] = useState([]);
  const [showModal, setshowModal] = useState(false);

  // make function to group by date and return array of object
  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue,
      );
      return result;
    }, {});
  };

  // convert grouping data to array of object
  const convertGroupingData = (data) => {
    const result = [];
    for (const [key, value] of Object.entries(data)) {
      result.push({ date: key, data: value });
    }
    return result;
  };

  useEffect(() => {
    const handleFetchListTimeSlot = async () => {
      return await fetchListTimeSchedule().then((res) => {
        setdataTemp(convertGroupingData(groupBy(res.data, 'date')));
      });
    };

    handleFetchListTimeSlot();
  }, []);

  console.log(dataTemp, 'dataTemp');

  return (
    <LayoutWithNavigation className="interview-schedule">
      {/* Container */}
      <div className="position-relative mx-auto container w-75 mt-lg tw rounded-xl bg-white p-lg">
        {/* Content */}
        {/* Head Section */}
        <div className="position-relative d-flex justify-content-between align-items-center">
          <Button
            type="textGray"
            size="sm"
            onClick={() => route.push('/candidate-management')}
            btnClassName="border tw border-gray-500 d-flex gap-2 align-items-center">
            <ArrowLeft weight="bold" height={14} width={14} />
            Dashboard
          </Button>
          <h3 className="text-display-xs heading">Interview Schedule</h3>
          <Button
            type="primary"
            size="sm"
            onClick={() => route.push('/time-slot')}>
            Time Slot List
          </Button>
        </div>

        <div>
          <button onClick={() => setshowModal(true)}>Test button</button>
        </div>

        {/* Tab Navigation */}
        <TabNavigatioInterview
          menuActive={menuActive}
          setmenuActive={setmenuActive}
        />

        {/* List of Interview Schedule */}
        <RenderIf condition={dataTemp.length > 0}>
          <div className="position-relative mt-xl d-flex flex-wrap gap-4">
            {dataTemp.map((item, index) => (
              <ListSchedule dataSchedule={item} key={index} />
            ))}
          </div>
        </RenderIf>

        {/* Condition when data is null */}
        <RenderIf condition={dataTemp.length === 0}>
          <CardEmpty imageSource={Empty} />
        </RenderIf>
      </div>

      <ModalReschedule setshowModal={setshowModal} showModal={showModal} />
    </LayoutWithNavigation>
  );
}
