import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import cx from "classnames";
import * as RoutePath from "../../shared/utils/routeLink";
import * as Variable from "../../shared/utils/variables.js";
import * as ActionTypes from "../../store/actions/actionTypes";
import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import Button from "../../shared/component/ButtonComponent/Button";
import Input from "../../shared/component/InputComponent/Input";
import { CloseEyeIcon, OpenEyeIcon } from "../../shared/component/svg/Icons";

import ResetPasswordSuccess from "../ResetPasswordComponent/ResetPasswordSuccess";

import onboardRegister from "../../assets/svg/onboard-register.svg";
import emailSentIcon from "../../assets/svg/email.svg";

import "swiper/css";
import "swiper/css/pagination";
import "./SignUpComponent.scss";
import Image from "../../shared/component/UI/Image";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import Select from "shared/component/SelectComponent/Select";
import useTranslate from "shared/hooks/useTranslate";
import useFetch from "shared/hooks/useFetch";
import useToast from "shared/hooks/useToast";
import { Typography } from "shared/component/Typography/Typography";
import useSearchParams from "shared/hooks/useSearchParams";
import InputPhoneNumber from "shared/component/PhoneInput/InputPhoneNumber";
import useDebounce from "shared/hooks/useDebounce";

const SignUpComponent = ({ setLoginModalShow, ENV_NAME, ...props }) => {
	const toast = useToast();
	const [form, setForm] = useState({
		first_name: "",
		last_name: "",
		email: "",
		mobile_phone_number: "",
		password: "",
		business_name: "",
		industry: "",
	});
	const [showPassword, setShowPassword] = useState(false);
	const [isPasswordValid, setIsPasswordValid] = useState(true);
	const [errors, setErrors] = useState({});
	const [emailSent, setEmailSent] = useState(false);
	const [showDropdown, setShowDropdown] = useState(false);
	const t = useTranslate();
	const { getParam } = useSearchParams();
	const [searchBusiness, setSearchBusiness] = useState("");
	const searchDebounce = useDebounce(searchBusiness, 500);
	const adminExist = getParam("admin_exist");
	const userExist = getParam("user_exist");

	const [selectedBusisness, setSelectedBusiness] = useState(0);
	const { data: dataIndustry } = useFetch("getListIndustry", {
		queryParams: {
			page_size: 100,
		},
	});

	const { data: dataParentCompany, getDatas: getParentCompanyList } = useFetch(
		"getListParentCompany",
		{},
		{ lazy: true },
	);

	useEffect(() => {
		const urlParams = new URLSearchParams(props.history.location.search);
		const isEmailSent = urlParams.get("is_sent");
		const email = urlParams.get("email");
		setEmailSent(isEmailSent);
		setForm((prev) => ({
			...prev,
			email,
		}));
	}, []);

	const onChange = (e) => {
		const { name, value } = e.target;
		setForm((prev) => ({
			...prev,
			[name]: value,
		}));

		setErrors((prev) => ({ ...prev, [name]: false }));
		if (name === "password") {
			const passwordValidation = validatePassword(value);
			if (!passwordValidation) {
				setIsPasswordValid(false);
				return;
			}
			setIsPasswordValid(true);
		}
	};

	const {
		getDatas: getBusinessesNames,
		loading: loadingBusinessName,
		data: dataBusinessName,
	} = useFetch("getRegisteredBusiness", {}, { lazy: true });

	useEffect(() => {
		if (searchDebounce.length > 0) {
			getBusinessesNames({
				queryParams: {
					name: searchDebounce,
				},
			});
		}
	}, [searchDebounce]);

	const isHaveParentCompany = useMemo(() => {
		if (selectedBusisness === 0) return false;
		return !!selectedBusisness && dataParentCompany && dataParentCompany.length > 0;
	}, [selectedBusisness, dataParentCompany]);

	const errorRegistrationValidation = (message) => {
		const errrTypes = [
			{
				message: "Superadmin user already exist for this parent company.",
				errMessage: Variable.ERROR_ADMIN_EXIST[ENV_NAME],
			},
			{
				message: "User already exists.",
				errMessage: Variable.ERROR_USER_EXIST[ENV_NAME],
			},
			{
				message: "Admin user already exist for this company",
				errMessage: Variable.ERROR_ADMIN_EXIST[ENV_NAME],
			},
		];

		return errrTypes.find((err) => message.includes(err.message));
	};

	const handleRegisterUser = async () => {
		const {
			email,
			password,
			first_name,
			last_name,
			mobile_phone_number,
			business_name,
			industry,
		} = form;

		const errorsData = {};
		if (!first_name) errorsData["first_name"] = true;
		if (!email) errorsData["email"] = true;
		if (!mobile_phone_number) errorsData["mobile_phone_number"] = true;
		if (!password) errorsData["password"] = true;
		if (!business_name) errorsData["business_name"] = true;
		if (!industry) errorsData["industry"] = true;

		const hasErrors = Object.values(errorsData).some((err) => err);
		if (hasErrors) return setErrors(errorsData);

		const res = await makeRequest({
			...generateRequestOptions("jobPosterSignUp"),
			data: {
				email,
				password,
				first_name,
				last_name,
				mobile_phone_number: mobile_phone_number,
				business_name: business_name,
				industry,
			},
		});

		if (res.code === 200) {
			await makeRequest({
				...generateRequestOptions("resendVerificationEmail"),
				data: { email },
			});

			if (Number(isHaveParentCompany)) {
				props.history.push(
					`${RoutePath.SELECT_PARENT_COMPANY}?email=${encodeURIComponent(form?.email)}`,
				);
				localStorage.setItem(
					"form_register",
					JSON.stringify({
						...form,
						businessParentCompany: dataParentCompany,
					}),
				);
				return;
			}

			setEmailSent(true);
			props.history.push(
				`${RoutePath.SIGN_UP}?is_sent=true&email=${encodeURIComponent(form?.email)}`,
			);
			return;
		}

		if (!!errorRegistrationValidation(res.message)) {
			const err = errorRegistrationValidation(res.message);
			toast.error(err.errMessage);
			return;
		}

		toast.error(res.message);
	};

	const handleResendEmail = async () => {
		const email = new URLSearchParams(props.history.location.search).get("email");
		if (!email) return toast.error(Variable.EMAIL_INVALID[ENV_NAME]);

		const res = await makeRequest({
			...generateRequestOptions("resendVerificationEmail"),
			data: { email },
		});

		if (res.code === 200) setEmailSent(true);
		else toast.error(res.message);
	};

	const validatePassword = (password) => {
		const hasValidLength = password.length >= 8;
		const hasNumber = /\d/.test(password);
		const hasUpperCase = /[A-Z]/.test(password);
		const hasLowerCase = /[a-z]/.test(password);

		return hasValidLength && hasNumber && hasUpperCase && hasLowerCase;
	};

	const listIndustries = useMemo(() => {
		if (dataIndustry) {
			return dataIndustry.map((industry) => ({
				id: industry?.industry_en,
				name:
					ENV_NAME === "bhs"
						? industry.industry_id ?? industry.industry_en
						: industry.industry_en,
			}));
		}
	}, [ENV_NAME, dataIndustry]);

	const handleSmartSuggestionBussiness = (value) => {
		setForm((prev) => ({
			...prev,
			business_name: value,
		}));
		setShowDropdown(false);
		if (Number(adminExist)) {
			toast.error(Variable.ERROR_ADMIN_EXIST[ENV_NAME]);
		}

		if (Number(userExist)) {
			toast.error(Variable.ERROR_USER_EXIST[ENV_NAME]);
		}
	};

	return (
		<div className="sign-up container-fluid suc">
			{emailSent ? (
				<div>
					<HeaderComponent />
					<ResetPasswordSuccess
						ENV_NAME={ENV_NAME}
						icon={emailSentIcon}
						title={Variable.CHECK_YOUR_EMAIL[ENV_NAME]}
						description={
							Variable.SIGNUP_EMAIL_SENT_1[ENV_NAME] +
							"<strong>" +
							form?.email +
							"</strong>" +
							Variable.SIGNUP_EMAIL_SENT_2[ENV_NAME]
						}
						initialTimer={59}
						btnWithTimer={true}
						onClick={handleResendEmail}
					/>
				</div>
			) : (
				<div className="row p-0">
					<div className="col-md-6 d-none d-md-block p-0">
						<div className="sign-up suc-left">
							<div className="onboarding-wrapper">
								<Image src={onboardRegister} />
							</div>
						</div>
					</div>
					<div className="col-md-6 p-0">
						<div className="sign-up suc-right">
							<h2>{Variable.REGISTER[ENV_NAME]}</h2>
							<div>
								<div className="suc-form">
									<Input
										label={Variable.FIRST_NAME[ENV_NAME]}
										placeholder={
											Variable.INPUT[ENV_NAME] +
											" " +
											Variable.FIRST_NAME[ENV_NAME]
										}
										value={form?.first_name || ""}
										name="first_name"
										onChange={onChange}
										inputType="label"
										className="w-100"
										required
										isError={errors["first_name"]}
									/>
									<Input
										label={`${Variable.LAST_NAME[ENV_NAME]} (${Variable.OPTIONAL[ENV_NAME]})`}
										placeholder={
											Variable.INPUT[ENV_NAME] +
											" " +
											Variable.LAST_NAME[ENV_NAME]
										}
										value={form?.last_name || ""}
										name="last_name"
										onChange={onChange}
										inputType="label"
										className="w-100"
									/>
									<Input
										label={`${Variable.EMAIL[ENV_NAME]}`}
										placeholder={
											Variable.INPUT[ENV_NAME] +
											" " +
											Variable.EMAIL[ENV_NAME]
										}
										value={form?.email || ""}
										name="email"
										onChange={onChange}
										inputType="email"
										showIcon={false}
										className="w-100"
										required
										isError={errors["email"]}
									/>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "8px",
											position: "relative",
										}}
									>
										<Input
											required
											dropdownType="search"
											className="flex-grow-1 w-mobile-100"
											label={`${Variable.REGISTERED_BUSINESS_NAME[ENV_NAME]}`}
											placeholder={
												Variable.REGISTERED_BUSINESS_NAME_PLACEHOLDER[
													ENV_NAME
												]
											}
											inputType="label"
											value={form.business_name}
											name="business_name"
											onChange={(e) => {
												onChange(e);
												setSearchBusiness(e.target.value);
												setSelectedBusiness(0);
												if (!loadingBusinessName) {
													setShowDropdown(true);
													return;
												}
												setShowDropdown(false);
											}}
											showIcon={false}
											// onChange={(e) => setSearch(e.target.value)}
											// onKeyDown={handleOnEnterPressFindJobs}
											onClickClose={() =>
												setForm((prev) => ({ ...prev, business_name: "" }))
											}
											isError={errors["business_name"]}
										/>
										{showDropdown &&
											dataBusinessName &&
											dataBusinessName.length > 0 && (
												<div
													className="J-ddComp-menu"
													style={{
														borderRadius: "8px",
														border: "1px solid #CCDAEF",
														boxShadow:
															"0px 7px 13px 0px rgba(0, 0, 0, 0.11)",
														padding: 0,
														position: "absolute",
														top: 70,
														zIndex: "1000",
													}}
												>
													{dataBusinessName.map((business) => {
														return (
															<div
																className="dropdown-item-result"
																key={business.id}
																onClick={() => {
																	handleSmartSuggestionBussiness(
																		business.name,
																	);
																	getParentCompanyList({
																		queryParams: {
																			company: business.id,
																			page_size: 1000,
																		},
																	});
																	setSelectedBusiness(
																		business.id,
																	);
																}}
															>
																<Typography.Text
																	size="md"
																	className="dropdown-item-result-text"
																>
																	{business.name}
																</Typography.Text>
															</div>
														);
													})}
												</div>
											)}
									</div>
									<Select
										name="industry"
										label={t("INDUSTRY_LABEL")}
										noDefault
										required
										className="m-0 p-0"
										onChange={onChange}
										value={form.industry}
										items={listIndustries}
										placeholder={t("INDUSTRY_INPUT_PLACEHOLDER")}
										searchable={false}
										isError={errors["industry"]}
									/>
									<InputPhoneNumber
										value={form.mobile_phone_number}
										label={Variable.PHONE[ENV_NAME]}
										isRequired
										placeholder="82181332552"
										onChange={(value) => {
											setForm((prev) => ({
												...prev,
												mobile_phone_number: value,
											}));
											errors["mobile_phone_number"] = false;
										}}
										isError={errors["mobile_phone_number"]}
									/>
									<div className="form-group J-inputComp mb-1p6rem w-100 lm-form">
										<label
											htmlFor="password"
											className="as-label pb-0 isRequired"
										>
											{Variable.PASSWORD[ENV_NAME]}
										</label>
										<div className="position-relative">
											<input
												id="password"
												type={showPassword ? "text" : "password"}
												className={cx(
													"J-inputComp-input",
													errors["password"] || !isPasswordValid
														? "field-error"
														: "",
													"J-inputComp-no-icon",
												)}
												name="password"
												value={form.password}
												autoComplete="none"
												onChange={onChange}
												placeholder={`${Variable.INPUT[ENV_NAME]} ${Variable.PASSWORD[ENV_NAME]}`}
												required
												showIcon={false}
											/>
											<div
												className={`pwd-eye cursorPointer ${
													!showPassword ? "active" : ""
												}`}
												onClick={() => setShowPassword((prev) => !prev)}
											>
												{showPassword ? (
													<OpenEyeIcon
														width="1.6rem"
														height="1.6rem"
														stroke="#056cf2"
													/>
												) : (
													<CloseEyeIcon
														width="1.6rem"
														height="1.6rem"
														stroke="#BEC4CF"
													/>
												)}
											</div>
										</div>
										{form.password.length > 0 && isPasswordValid && (
											<Typography.Text
												color="#667085"
												size="sm"
												fontWeight="regular"
											>
												{Variable.PASSWORD_VALIDATION_ERR_MSG[ENV_NAME]}
											</Typography.Text>
										)}
										{!isPasswordValid && (
											<Typography.Text
												size="sm"
												className="password-valid-msg"
											>
												{Variable.PASSWORD_VALIDATION_ERR_MSG[ENV_NAME]}
											</Typography.Text>
										)}
									</div>
								</div>
								<Button
									type="primary"
									size="sm"
									title={Variable.REGISTER_ACCOUNT[ENV_NAME]}
									onClick={handleRegisterUser}
									disabled={!isPasswordValid}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {
		setLoginModalShow: (payload = true) => {
			if (payload) {
				dispatch({
					type: ActionTypes.OPEN_LOGIN_MODAL,
				});
			} else {
				dispatch({
					type: ActionTypes.CLOSE_LOGIN_MODAL,
				});
			}
		},
	};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(SignUpComponent));
