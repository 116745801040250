import React from 'react';

export const CloseCircleIcon = ({ color, width, height, className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 17 16"
			fill="none"
			className={className}
		>
			<path
				d="M8.5 14C11.8137 14 14.5 11.3137 14.5 8C14.5 4.68629 11.8137 2 8.5 2C5.18629 2 2.5 4.68629 2.5 8C2.5 11.3137 5.18629 14 8.5 14Z"
				stroke={color}
				stroke-miterlimit="10"
			/>
			<path
				d="M10.5 6L6.5 10"
				stroke={color}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.5 10L6.5 6"
				stroke={color}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export const CheckCircleIcon = ({ color, width, height, className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 17 16"
			fill="none"
			className={className}
		>
			<path
				d="M15.1654 7.38674V8.00007C15.1645 9.43769 14.699 10.8365 13.8383 11.988C12.9775 13.1394 11.7676 13.9817 10.3889 14.3893C9.01032 14.797 7.53687 14.748 6.18835 14.2498C4.83982 13.7516 3.68847 12.8308 2.906 11.6248C2.12354 10.4188 1.75189 8.99212 1.84648 7.55762C1.94107 6.12312 2.49684 4.75762 3.43088 3.66479C4.36493 2.57195 5.62722 1.81033 7.02949 1.4935C8.43176 1.17668 9.89888 1.32163 11.212 1.90674M15.1654 2.66674L8.4987 9.34007L6.4987 7.34007"
				stroke={color}
				stroke-width="1.375"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export const PDFFileIcon = ({}) => {
	return (
		<svg
			width="62"
			height="62"
			viewBox="0 0 62 62"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_1382_23411)">
				<path
					d="M12.0779 -0.000268409C8.52715 -0.000268409 5.63672 2.87515 5.63672 6.40484V55.5942C5.63672 59.1239 8.52715 61.9993 12.0779 61.9993H49.915C53.4658 61.9993 56.3562 59.124 56.3562 55.5942V15.1468C56.3562 15.1468 56.4576 13.9625 55.9404 12.81C55.4555 11.7296 54.6654 10.9777 54.6654 10.9777C54.6634 10.975 54.6615 10.9724 54.6595 10.9698L45.272 1.81626C45.2668 1.81104 45.2616 1.80587 45.2564 1.80075C45.2564 1.80075 44.4549 1.04208 43.2668 0.535258C41.8678 -0.0614971 40.4259 0.00149815 40.4259 0.00149815L40.4474 -0.000488281L12.0779 -0.000268409Z"
					fill="#FF2116"
				/>
				<path
					d="M12.0767 2.10352H40.4462C40.4534 2.10359 40.4605 2.10359 40.4677 2.10352C40.4677 2.10352 41.6021 2.11444 42.4319 2.46841C43.2305 2.80909 43.7968 3.3248 43.8006 3.32826C43.8006 3.32827 43.8045 3.33223 43.8045 3.33223L53.1685 12.4644C53.1685 12.4644 53.7327 13.0565 54.0062 13.6658C54.2269 14.1576 54.2405 15.0575 54.2405 15.0575C54.2395 15.0723 54.2388 15.0872 54.2385 15.1021V55.5941C54.2385 57.9949 52.3288 59.8952 49.9137 59.8952H12.0767C9.66158 59.8952 7.75195 57.995 7.75195 55.5941V6.40472C7.75195 4.00376 9.66158 2.10352 12.0767 2.10352Z"
					fill="white"
				/>
				<path
					d="M18.3008 43.1762C16.8446 41.7286 18.4201 39.7393 22.6958 37.627L25.3861 36.2979L26.4342 34.0183C27.0107 32.7644 27.871 30.7186 28.3461 29.472L29.21 27.2054L28.6147 25.528C27.8828 23.4655 27.6218 20.366 28.0861 19.2515C28.7152 17.7419 30.7755 17.8968 31.5912 19.515C32.2283 20.7788 32.1632 23.0676 31.408 25.9541L30.7889 28.3204L31.3342 29.2407C31.6342 29.7469 32.5102 30.9487 33.2811 31.9114L34.7299 33.7029L36.5326 33.469C42.2597 32.7261 44.2213 33.9887 44.2213 35.7991C44.2213 38.084 39.7243 38.2726 35.948 35.6359C35.0983 35.0427 34.5148 34.4533 34.5148 34.4533C34.5148 34.4533 32.149 34.9321 30.9843 35.2442C29.782 35.5663 29.1821 35.7681 27.4209 36.3591C27.4209 36.3591 26.803 37.2511 26.4003 37.8997C24.9023 40.3124 23.1531 42.3119 21.9039 43.0397C20.5053 43.8545 19.039 43.9101 18.3008 43.1762ZM20.5861 42.3646C21.4046 41.8617 23.0614 39.9137 24.2083 38.1058L24.6726 37.3738L22.5583 38.4306C19.2929 40.0627 17.799 41.601 18.5758 42.5315C19.0122 43.0542 19.5342 43.0108 20.5861 42.3646ZM41.7971 36.4459C42.5977 35.8885 42.4815 34.7651 41.5764 34.3121C40.8719 33.9596 40.3042 33.8872 38.4742 33.9141C37.3497 33.9903 35.5414 34.2156 35.235 34.2841C35.235 34.2841 36.2284 34.9666 36.6694 35.2175C37.2565 35.5508 38.6832 36.1696 39.7249 36.4866C40.7524 36.7992 41.3468 36.7663 41.7971 36.4459ZM33.2684 32.9221C32.7839 32.4158 31.96 31.359 31.4375 30.5737C30.7542 29.6828 30.4116 29.0543 30.4116 29.0543C30.4116 29.0543 29.9121 30.6513 29.5024 31.6122L28.224 34.7533L27.8534 35.4657C27.8534 35.4657 29.8238 34.8235 30.8263 34.5632C31.8881 34.2875 34.0431 33.8662 34.0431 33.8662L33.2684 32.9221ZM30.5199 21.9652C30.6435 20.9341 30.696 19.9046 30.3628 19.3855C29.4385 18.381 28.3229 19.2187 28.5118 21.6067C28.5754 22.4101 28.7762 23.7834 29.0446 24.6298L29.5326 26.1688L29.8761 25.0097C30.065 24.3722 30.3547 23.0021 30.5199 21.9652Z"
					fill="#FF2116"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1382_23411">
					<rect width="62" height="62" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const PNGFileIcon = ({}) => {
	return (
		<svg
			width="63"
			height="62"
			viewBox="0 0 63 62"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_1382_23412)">
				<path
					d="M12.7459 -0.000268409C9.19512 -0.000268409 6.30469 2.87515 6.30469 6.40484V55.5942C6.30469 59.1239 9.19512 61.9993 12.7459 61.9993H50.583C54.1338 61.9993 57.0242 59.124 57.0242 55.5942V15.1468C57.0242 15.1468 57.1255 13.9625 56.6083 12.81C56.1235 11.7296 55.3334 10.9777 55.3334 10.9777C55.3314 10.975 55.3294 10.9724 55.3275 10.9698L45.9399 1.81626C45.9348 1.81104 45.9296 1.80587 45.9243 1.80075C45.9243 1.80075 45.1228 1.04208 43.9347 0.535258C42.5358 -0.0614971 41.0939 0.00149815 41.0939 0.00149815L41.1154 -0.000488281L12.7459 -0.000268409Z"
					fill="#FFCA0D"
				/>
				<path
					d="M12.7447 2.10352H41.1142C41.1213 2.10359 41.1285 2.10359 41.1356 2.10352C41.1356 2.10352 42.2701 2.11444 43.0998 2.46841C43.8985 2.80909 44.4648 3.3248 44.4685 3.32826C44.4685 3.32827 44.4724 3.33223 44.4724 3.33223L53.8365 12.4644C53.8365 12.4644 54.4007 13.0565 54.6741 13.6658C54.8948 14.1576 54.9084 15.0575 54.9084 15.0575C54.9074 15.0723 54.9068 15.0872 54.9064 15.1021V55.5941C54.9064 57.9949 52.9968 59.8952 50.5817 59.8952H12.7447C10.3295 59.8952 8.41992 57.995 8.41992 55.5941V6.40472C8.41992 4.00376 10.3295 2.10352 12.7447 2.10352Z"
					fill="white"
				/>
				<path
					d="M55.168 39.4998L43.093 26.4248C42.9069 26.2358 42.685 26.0857 42.4404 25.9833C42.1958 25.8809 41.9332 25.8281 41.668 25.8281C41.4027 25.8281 41.1402 25.8809 40.8955 25.9833C40.6509 26.0857 40.4291 26.2358 40.243 26.4248L29.093 37.5748C28.9069 37.7638 28.685 37.9138 28.4404 38.0163C28.1958 38.1187 27.9332 38.1714 27.668 38.1714C27.4027 38.1714 27.1402 38.1187 26.8955 38.0163C26.6509 37.9138 26.4291 37.7638 26.243 37.5748L21.093 32.4248C20.9069 32.2358 20.685 32.0857 20.4404 31.9833C20.1958 31.8809 19.9332 31.8281 19.668 31.8281C19.4027 31.8281 19.1402 31.8809 18.8955 31.9833C18.6509 32.0857 18.4291 32.2358 18.243 32.4248L7.66797 41.9998"
					stroke="#FFCA0D"
					stroke-width="2.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M24.668 23C26.3248 23 27.668 21.6569 27.668 20C27.668 18.3431 26.3248 17 24.668 17C23.0111 17 21.668 18.3431 21.668 20C21.668 21.6569 23.0111 23 24.668 23Z"
					fill="#FFCA0D"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1382_23412">
					<rect width="62" height="62" fill="white" transform="translate(0.667969)" />
				</clipPath>
			</defs>
		</svg>
	);
};
