import { Calendar, Envelope, MagnifyingGlass } from '@phosphor-icons/react';

export default function SetIconInput({
  type,
  classNameIcon,
  typeIcon,
  height,
  width,
  freeText,
}) {
  return (
    <span
      className={[
        'formInputType icon',
        type !== 'password' && 'left',
        classNameIcon,
      ].join(' ')}>
      {/* Calendar Icon */}
      {typeIcon === 'calendar' && (
        <Calendar height={height ?? 24} width={width ?? 24} />
      )}

      {/* Email Icon */}
      {typeIcon === 'email' && (
        <Envelope height={height ?? 24} width={width ?? 24} />
      )}

      {/* Search Icon */}
      {typeIcon === 'search' && (
        <MagnifyingGlass height={height ?? 24} width={width ?? 24} />
      )}

      {/* Free Text Label  */}
      {
        typeIcon === 'freeText' && (
          <p className="freeText m-0 pt-1">{freeText}</p>
        ) /* Free Text Label */
      }
    </span>
  );
}
