import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { classHelper } from "../../utils/stringUtils";

import * as Variable from "../../utils/variables";
import "./JobListCardComponent.scss";
import Image from "../UI/Image";
import { BookmarkIcon, BriefcaseOutlineIcon, BuildingOutlineIcon, IdCardIcon, MoneyOutlineIcon } from "../svg/Icons";
import { formatString, getCurrencySymbol, getLocale, nFormatter } from "../../utils/string";
import hotJob from "../../../assets/icons/hot-job.png";
import Button from "../ButtonComponent/Button";
import moment from "moment";

const JobListCardComponent = ({ data, selected, ENV_NAME, onClick, ...props }) => {
	return (
		<div className={classHelper("J-listCardComp", selected ? "active" : "", props.className)} onClick={onClick}>
			{data?.hot_job && <Image src={hotJob} className="J-listCardComp-hotJob" />}
			<div className="J-listCardComp-icon">
				<Image src={data?.company_logo || ""} />
			</div>
			<div className="J-listCardComp-data">
				<h3 className="J-listCardComp-title">{data?.name}</h3>
				<div className="mb-sm">{data?.company}</div>
				<div className="J-listCardComp-item">{data?.location}</div>
				<div className="J-listCardComp-item item-salary">
					<MoneyOutlineIcon width="1.4rem" height="1.4rem" stroke="#1D2939" className="me-xxs" />
					{`${getCurrencySymbol(data?.currency || "IDR", getLocale(ENV_NAME))} ${nFormatter(
						data?.lower_salary || 0,
						1,
						ENV_NAME
					)} - ${nFormatter(data?.upper_salary || 0, 1, ENV_NAME)} ${Variable.PER_MONTH[ENV_NAME]}`}
				</div>
				<div className="J-listCardComp-item">
					<BuildingOutlineIcon width="1.4rem" height="1.4rem" stroke="#1D2939" className="me-xxs" />
					{data?.work_mode}
					<div className="mx-xs">|</div>
					<BriefcaseOutlineIcon width="1.4rem" height="1.4rem" stroke="#1D2939" className="me-xxs" />
					{data?.job_type}
					<div className="mx-xs">|</div>
					<IdCardIcon width="1.4rem" height="1.4rem" stroke="#1D2939" className="me-xxs" />
					{formatString(Variable.APPLICANTS[ENV_NAME], data?.applicants)}
				</div>
				<div className="J-listCardComp-item smaller">
					{formatString(Variable.POSTED_AGO[ENV_NAME], moment(data?.posted_at).fromNow())}
					<div className="mx-xxs">|</div>
					{formatString(Variable.URGENCY[ENV_NAME], data?.urgency)}

					<div className="ms-auto">
						<Button
							type="textGray"
							title={
								<BookmarkIcon
									stroke={data?.bookmarked ? "#193560" : "#667085"}
									fill={data?.bookmarked ? "#193560" : "transparent"}
								/>
							}
							size="xs"
							buttonClassName="px-0"
							onClick={(e) => {
								e.stopPropagation();
								if (props.onBookmark) props.onBookmark(data, !data?.bookmarked);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	ENV_NAME: state.auth.selectedEnvironment || "bhs",
});

const mapStateToDispatch = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(JobListCardComponent));
