import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/";
import queryString from "query-string";
import { PiArrowLeft, PiMinusCircle, PiPlusCircle, PiWarningCircle } from "react-icons/pi";

import makeRequest from "../../shared/utils/request";
import { generateRequestOptions } from "../../shared/utils/apiEndPoints";
import { Button, Input, Select } from "../../shared/component";

import Form from "./Reusable/Form";

import { ENV, Nullable } from "../../types";
import useTranslate from "shared/hooks/useTranslate";
import useToast from "shared/hooks/useToast";

interface Props {
	ENV_NAME: ENV;
	jobAdId: Nullable<number>;
	onNext: () => void;
	onPrev: () => void;
}

interface FormFields {
	urgency: string;
	hires: number;
	custom_hires?: string;
}

interface FormError {
	urgency: boolean;
	hires: boolean;
	custom_hires: boolean;
}

const Urgency = ({ ENV_NAME, jobAdId, onNext, onPrev }: Props) => {
	const t = useTranslate();
	const toast = useToast();
	const URGENCY = [
		{ id: "1_to_3_days", name: `1 - 3 ${t("DAYS_2").toLowerCase()}` },
		{ id: "3_to_7_days", name: `3 - 7 ${t("DAYS_2").toLowerCase()}` },
		{ id: "1_to_2_weeks", name: `1 - 2 ${t("WEEKS_2").toLowerCase()}` },
		{ id: "2_to_4_weeks", name: `2 - 4 ${t("WEEKS_2").toLowerCase()}` },
		{ id: "more_than_4_weeks", name: t("MORE_THAN_4_WEEKS") },
	];
	const history = useHistory();
	const jobIdFromUrl = (queryString.parse(history.location.search)?.job_ad ||
		null) as Nullable<string>;
	const jobStatus = (queryString.parse(history.location.search)?.status ||
		null) as Nullable<string>;

	const [form, setForm] = useState({
		urgency: "",
		hires: 1,
	});
	const [errors, setErrors] = useState({} as FormError);
	const [isEdited, setIsEdited] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	useEffect(() => {
		if (jobIdFromUrl) {
			getJob(jobIdFromUrl);
			setIsEditing(true);
		}
	}, [jobIdFromUrl]);

	const getJob = async (jobId: string) => {
		const res = await makeRequest(generateRequestOptions("getJobAd", { urlParams: jobId }));

		if (res?.code === 200) {
			setForm({ urgency: res?.data?.urgency || "", hires: res?.data?.hires || 1 });
		} else toast.error(res.message);
	};

	const onChange = (e: ChangeEvent<HTMLFormElement>, type: Nullable<string> = null) => {
		const { name, value } = e.target;
		switch (type) {
			case "numberWithoutDecimal":
				setForm((prev) => ({
					...prev,
					[name]: /^(\d*)$/.test(value) ? +value : +prev[name as keyof FormFields],
				}));
				break;
			default:
				setForm((prev) => ({
					...prev,
					[name]: value,
				}));
		}
		setErrors((prev) => ({ ...prev, [name]: false }));
		setIsEdited(true);
	};

	const handleHires = (type: string) => {
		setForm((prev) => ({
			...prev,
			hires: type === "dec" ? prev?.hires - 1 : prev?.hires + 1,
		}));

		setErrors((prev) => ({ ...prev, hires: false }));
		setIsEdited(true);
	};

	const handleSave = async () => {
		// if (isEditing && !isEdited) return onNext();

		const { urgency, hires } = form;
		const validValues = [
			"1_to_3_days",
			"3_to_7_days",
			"1_to_2_weeks",
			"2_to_4_weeks",
			"more_than_4_weeks",
		];

		const errors = {} as FormError;
		if (!hires || hires < 1 || hires > 999) errors["hires"] = true;
		const hasError = Object.values(errors).some((err) => err);
		if (hasError) return setErrors(errors);

		const data = {
			urgency,
			hires,
		};

		if (!validValues.includes(data.urgency)) data.urgency = "";

		const res = await makeRequest({
			...generateRequestOptions("updateJobAd", { urlParams: jobAdId || jobIdFromUrl }),
			data,
		});
		if (res?.code === 200) {
			resetForm();
			onNext();
		} else toast.error(res.message);
	};

	const resetForm = () => setForm({ urgency: "", hires: 0 });

	return (
		<div className="form-block urgency">
			<Form title={t("URGENCY_LABEL")}>
				<Select
					name="urgency"
					label={t("URGENCY_DESC")}
					className="m-0 p-0"
					onChange={onChange}
					value={form.urgency}
					items={URGENCY}
					searchable={false}
					noDefault
					defaultOpt={{
						id: t("SELECT_LABEL"),
						name: t("SELECT_LABEL") + " " + t("ESTIMATION").toLowerCase(),
					}}
					isError={errors["urgency"]}
				/>
			</Form>

			<Form title={t("HIRES")}>
				<div className="hires-header">
					<h3>{t("HIRES_DESC_1")}</h3>
					<p>{t("HIRES_DESC_2")}</p>
				</div>
				<div className="form-row hires">
					<PiMinusCircle
						size={28}
						className={`icon ${form?.hires <= 1 ? "icon-disable" : "icon-active"}`}
						onClick={() => handleHires("dec")}
					/>
					<Input
						placeholder={0}
						value={form?.hires}
						name="hires"
						onChange={(e: ChangeEvent<HTMLFormElement>) =>
							onChange(e, "numberWithoutDecimal")
						}
						inputType="label"
						className="job-type-input"
						isError={errors["hires"]}
						showWarning={false}
					/>
					<PiPlusCircle
						size={28}
						className="icon icon-active"
						onClick={() => handleHires("inc")}
					/>
				</div>
				{errors["hires"] && (
					<div className="warning">
						<PiWarningCircle size={24} color="#f04438" />{" "}
						<span>{`${t("MINIMUM")} 1. ${t("MAXIMUM")} 999`}</span>
					</div>
				)}
			</Form>

			<div className="btn-grp">
				<Button
					type="outline"
					title={t("PREVIOUS_LABEL")}
					onClick={onPrev}
					btnClassName="customBtn"
					customIcon={<PiArrowLeft size={18} className="icon icon-prev" />}
				/>
				<Button
					type="primary"
					title={t("SAVE_CONTINUE_LABEL")}
					onClick={handleSave}
					btnClassName="customBtn btn-save"
					disabled={!isEdited && !isEditing}
				/>
			</div>
		</div>
	);
};

export default Urgency;
