import { Check } from '@phosphor-icons/react';
import { Label } from '../../../../shared/component';
import { RenderIf } from '../../../../shared/utils/common';

type FormColorProps = {
  value: string;
  onClick: (value: string) => void;
};

export default function FormColor(props: FormColorProps) {
  const listColor: string[] = [
    '#FF4F00',
    '#FF758E',
    '#E55CFF',
    '#8247F5',
    '#0099FF',
    '#0AE8F0',
    '#17E885',
    '#CCF000',
    '#F8E436',
    '#FDAB39',
  ];

  return (
    <>
      <Label
        children={null}
        className={''}
        name={'scheduleColor'}
        label={'Schedule Color'}
        isRequired
      />
      <div
        className="positio-relative d-flex gap-3"
        style={{ marginTop: '-1.2rem' }}>
        {listColor.map((item: string) => (
          <section
            key={item}
            onClick={() => props.onClick(item)}
            className="position-relative p-2 d-flex justify-content-center align-items-center rounded-circle tw cursor-pointer"
            style={{
              height: '3.7rem',
              width: '3.7rem',
              backgroundColor: item,
            }}>
            <RenderIf condition={props.value === item}>
              <Check color="#fff" weight="bold" width={32} height={32} />
            </RenderIf>
          </section>
        ))}
      </div>
    </>
  );
}
